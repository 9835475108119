import { ColumnDef } from '@tanstack/react-table'
import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useRouter } from 'next/router'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { FinancialData, FinancialDataTableData } from '../types/financialData.types'

export const balanceSheetColumns = (startYear: number, endYear: number): ColumnDef<FinancialDataTableData>[] => {
  const columns: ColumnDef<FinancialDataTableData>[] = [
    {
      header: `Balance Sheet Items`,
      accessorKey: 'name',
      accessorFn: row => row.name,
      size: 240,
      cell: EditableTableLabelCell,
      meta: {
        isReadOnly: true,
      },
    },
    {
      header: 'Formulae',
      accessorFn: row => row.formula || '--',
      cell: data => {
        return (
          <Typography
            className={classNames(
              'flex items-center text-start justify-start border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 mr-2 whitespace-nowrap'
            )}
            variant={Variant.Callout}
            type="semibold">
            {data.row.original.formula?.replace(/\s+/g, '') || '--'}
          </Typography>
        )
      },
      meta: {
        isReadOnly: true,
      },
      size: 1,
    },
  ]
  const yearColumns = Array.from({ length: endYear - startYear + 2 }, (_, index) => {
    const colYear = (endYear - index).toString()
    const router = useRouter()
    const currentYear = String(router.query.year)
    return {
      accessorKey: colYear,
      accessorFn: (row: FinancialData) => row[colYear],
      header: colYear == currentYear ? `${colYear} *` : colYear,
    }
  })

  return [...columns, ...yearColumns]
}

export const balanceSheetAvgColumns = (startYear: number, endYear: number): ColumnDef<FinancialDataTableData>[] => {
  const columns: ColumnDef<FinancialDataTableData>[] = [
    {
      header: `Balance Sheet Items`,
      accessorKey: 'name',
      accessorFn: row => row.name,
      size: 240,
      cell: EditableTableLabelCell,
      meta: {
        isReadOnly: true,
      },
    },
    {
      header: 'Formulae',
      accessorFn: row => row.formula || '--',
      cell: data => {
        return (
          <Typography
            className={classNames(
              'flex items-center text-start justify-start border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 mr-2 whitespace-nowrap'
            )}
            variant={Variant.Callout}
            type="semibold">
            {data.row.original.formula?.replace(/\s+/g, '') || '--'}
          </Typography>
        )
      },
      meta: {
        isReadOnly: true,
      },
    },
  ]
  const yearColumns = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const colYear = (endYear - index).toString()
    return {
      accessorKey: colYear,
      accessorFn: (row: FinancialData) => row[colYear],
      header: `${colYear} Avg`,
    }
  })

  return [...columns, ...yearColumns]
}
