import { Tooltip } from '@mui/material'
import { TemplateVariableResponse } from 'api/templates/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import InfoLabel from 'components/input/components/infoLabel'
import TinyMceEditorControlledField from 'components/tinyMceEditor/TinyMceEditorControlledField'
import Lottie from 'lottie-react'
import { RADIO_BUTTON_GROUP as RadioButtonGroup } from 'organisms/fieldRenderers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import { capitalizeFirstLetter } from 'utils/utils'

import { ENTITY_FIELDS_VALUE } from '../../AddFunctionsModal'
import { weightSelectionOptions } from '../../config'

const AddAttribute = ({
  tabKey,
  control,
  isSource,
  fieldId,
  aiButtonDisabled,
  currentTransaction,
  handleLoadAILanguage,
  getResponsesUsed,
  disableFeatures = false,
  showAutoLoadButtons = false,
  clearAIDescriptions,
  showDescription,
  templateVariables,
}: AddAttributeProps): JSX.Element => {
  const { field } = useController({ control, name: `${fieldId}.weight`, defaultValue: 'limited' })
  const [aiButtonClicked, setAiButtonClicked] = useState(false)

  useEffect(() => {
    field.value && clearAIDescriptions(fieldId)
  }, [clearAIDescriptions, field.value, fieldId])

  const handleJarvisAI = useCallback(() => {
    handleLoadAILanguage('description', fieldId)()
    setAiButtonClicked(true)
  }, [fieldId, handleLoadAILanguage])

  const rightComponent = useMemo(() => {
    return (
      <div className="flex items-center  gap-4 ms-auto">
        {showAutoLoadButtons && (
          <>
            <Tooltip title={'Load AI generated language'} placement="top">
              <div>
                <Button
                  onClick={handleJarvisAI}
                  isDefaultSize={false}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  iconCLass="w-5 h-5"
                  iconPathClassName={`${aiButtonDisabled ? 'fill-gray-500' : 'fill-white'}`}
                  className={`whitespace-nowrap ms-auto ${
                    aiButtonDisabled ? '!bg-gray-100 !text-gray-500' : 'aira-bg-gradient !text-white'
                  }`}
                  disabled={aiButtonDisabled}
                  icon={IconsType.autoAwesome}>
                  <span className="text-footnote font-semibold">
                    <span className="!font-aleo">AI</span>ra {getResponsesUsed('description', fieldId)}
                  </span>
                </Button>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    )
  }, [showAutoLoadButtons, fieldId, handleJarvisAI, aiButtonDisabled, getResponsesUsed])

  return (
    <div className="w-full flex flex-col">
      <RadioButtonGroup
        row
        disabled={disableFeatures}
        id={`${fieldId}.weight`}
        control={control}
        label={
          !isSource
            ? currentTransaction?.functional_profile.target_party_identification + ' significance'
            : currentTransaction?.functional_profile.source_party_identification + ' significance'
        }
        classes={{ root: 'gap-24' }}
        radioOptions={weightSelectionOptions}
      />
      {showDescription && (
        <>
          <div className="flex mt-3 items-center w-full mb-2">
            <InfoLabel label={`${capitalizeFirstLetter(tabKey)} Description`} />
            {rightComponent}
          </div>
          <TinyMceEditorControlledField
            disabled={aiButtonDisabled}
            className="!h-[500px]  relative !bg-[#1a193b]"
            name={`${fieldId}.description`}
            statusbar
            templateVariables={templateVariables}
            control={control}>
            {aiButtonDisabled && (
              <div className="absolute inset-0 z-50 flex justify-center items-center opacity-90 overflow-hidden bg-white">
                <Lottie animationData={LoadingStars} className="w-full" />
              </div>
            )}
          </TinyMceEditorControlledField>
        </>
      )}
      {aiButtonClicked && <AITextDisclaimer />}
    </div>
  )
}

interface AddAttributeProps {
  tabKey: string
  control: Control<ENTITY_FIELDS_VALUE>
  isSource: boolean
  fieldId: number | string
  aiButtonDisabled: boolean
  disableFeatures?: boolean
  currentTransaction: TransactionByIdResponse
  handleLoadAILanguage: (fieldName: string, fieldId: string | number) => () => void
  getResponsesUsed: (fieldName: string, fieldId: string | number) => string
  showAutoLoadButtons: boolean
  clearAIDescriptions: (fieldId: string | number) => void
  showDescription: boolean
  templateVariables: TemplateVariableResponse[] | undefined
}

export default AddAttribute
