import { Tooltip } from '@mui/material'
import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { components as Component, GroupBase, ValueContainerProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

import { TxnSelectAndReorderContext } from '../TxnSelectAndReorder'

const TxnSelectAndReorderValueContainer = (
  props: ValueContainerProps<SelectOptions, true, GroupBase<SelectOptions>>
) => {
  const { menuOpen, setMenuOpen } = useContext(TxnSelectAndReorderContext)
  const [hiddenCount, setHiddenCount] = useState(0)
  const [tooltipOffset, setTooltipOffset] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const { children } = props
  const validHiddenCount =
    hiddenCount >= 0 && hiddenCount <= (Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0)
      ? hiddenCount
      : 0

  const values = Array.isArray(children) ? children?.[0] : null
  const input = Array.isArray(children) ? children?.[1] : null
  const hasValues = Array.isArray(props.selectProps.value) ? !!props.selectProps.value.length : false

  const calculateVisibility = useCallback(() => {
    if (!containerRef.current) return
    let visibleCount = 0
    if (hasValues && !menuOpen && containerRef.current) {
      const childNodes = Array.from(containerRef.current.children) as HTMLDivElement[]
      childNodes.forEach(node => {
        if (node.id == 'hiddenCountTooltip' || node.id == 'hideInput') return
        if (node.offsetLeft + node.clientWidth + 32 > (containerRef.current?.offsetWidth || 0) && visibleCount) {
          node.style.opacity = '0%'
          node.style.maxHeight = '0px'
        } else {
          setTooltipOffset(node.offsetLeft + node.clientWidth + 4)
          node.style.opacity = '100%'
          visibleCount = visibleCount + 1
          node.style.maxWidth =
            String((containerRef.current?.offsetWidth || 0) - (childNodes.length > 2 ? 32 : 10)) + 'px'
          if (node.style.maxHeight == '0px') node.style.maxHeight = 'unset'
        }
      })
    }
    setHiddenCount((Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0) - (visibleCount || 1))
  }, [hasValues, menuOpen, props.selectProps.value])

  useEffect(() => {
    calculateVisibility()

    const handleResize = () => {
      calculateVisibility()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [calculateVisibility, containerRef.current?.offsetWidth])

  return (
    <Component.ValueContainer
      innerProps={{
        onClick: () => setMenuOpen && setMenuOpen(true),
        ref: containerRef,
        style: {
          flexWrap: 'nowrap',
          position: 'relative',
        },
      }}
      {...props}>
      {hasValues ? (
        <>
          <div id="hideInput" className={menuOpen ? 'w-full' : 'w-0 h-0 opacity-0 overflow-hidden'}>
            {input}
          </div>
          {!menuOpen && (
            <>
              {validHiddenCount > 0 ? (
                <Tooltip
                  PopperProps={{
                    container: document.getElementById('reportWizardId'),
                  }}
                  classes={{
                    tooltip:
                      'p-0 bg-[#0F0F27] w-[350px] max-h-[280px] overflow-y-auto rounded-lg border border-solid border-gray-800',
                  }}
                  title={
                    <>
                      {(Array.isArray(props.selectProps.value) ? props.selectProps.value : []).map((txn, idx) => {
                        return (
                          idx >=
                            (Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0) -
                              validHiddenCount && (
                            <div
                              onMouseDown={e => {
                                e.stopPropagation()
                              }}
                              onTouchStart={e => e.stopPropagation()}
                              key={`relatedParty-${txn.value}`}
                              className={classNames('flex flex-col py-2 self-stretch max-w-full px-3 gap-1', {
                                'border-0 border-t border-gray-800 border-solid':
                                  idx >
                                  (Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0) -
                                    validHiddenCount,
                              })}>
                              <Typography
                                variant={Variant.Callout}
                                type="semibold"
                                className={classNames('text-gray-300 max-w-[340px] truncate')}>
                                {txn.label}
                              </Typography>
                            </div>
                          )
                        )
                      })}
                    </>
                  }>
                  <div
                    id="hiddenCountTooltip"
                    style={{
                      left: tooltipOffset,
                    }}
                    className={` ${
                      menuOpen ? '' : 'absolute'
                    } text-footnote bg-orange-700 rounded p-0.5 font-semibold text-orange-50 z-10`}>
                    +{validHiddenCount}
                  </div>
                </Tooltip>
              ) : (
                <></>
              )}
              {values}
            </>
          )}
        </>
      ) : (
        children
      )}
    </Component.ValueContainer>
  )
}

export default TxnSelectAndReorderValueContainer
