import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'
import { formatAsPercent } from 'utils/numberUtils'

import { getDataByYear, getYearRange, mappedYearsFinancialStructureData } from '../helpers/financialData.helpers'
import { FinancialDataTableData } from '../types/financialData.types'

export const usGaapConfig = ({
  financial_structure,
  startYear,
  endYear,
  pliMethod,
  readonly = false,
}: Props): FinancialDataTableData[] => {
  const selectedPliMethod = pliMethod

  if (!selectedPliMethod) return []
  const dataByYear = getDataByYear(financial_structure || [])

  const years = getYearRange(startYear, endYear).map(year => mappedYearsFinancialStructureData(dataByYear, year))

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    required: boolean,
    formula?: string,
    ...values: (number | null | undefined | string)[]
  ): FinancialDataTableData => ({
    name,
    ...Object.fromEntries(years.map((year, index) => [year?.year?.toString(), String(values?.[index] ?? '')])),
    isEditable: !is_calculated,
    key,
    formula,
    isEditAllowed: !readonly,
    required: required && !is_calculated,
  })

  const tableData: FinancialDataTableData[] = [
    ...selectedPliMethod.required_fields.map(field => {
      return {
        ...field,
        _requiredField: true,
      }
    }),
    ...selectedPliMethod.optional_fields.map(field => {
      return {
        ...field,
        _requiredField: false,
      }
    }),
  ]
    .filter(field => field.type != 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        field._requiredField,
        selectedPliMethod.formulae[field.name],
        ...years.map(year => year?.[field.name])
      )
    })
  tableData.push({
    ...createFinancialDataEntry(
      selectedPliMethod.name + '(%)',
      selectedPliMethod.abbreviation,
      true,
      false,
      selectedPliMethod.formulae[selectedPliMethod.name],
      ...years.map(year =>
        year?.['profit_level_indicator'] ? formatAsPercent(String(year['profit_level_indicator']), 2) : ''
      )
    ),
    unformatted: true,
  })

  return tableData
}

interface Props {
  startYear: number
  endYear: number
  pliMethod?: TransactionPliMethod
  financial_structure?: { [key: string]: number | null | string }[]
  readonly?: boolean
}
