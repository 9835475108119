import { Benchmark } from 'api/benchmarks/types'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import FormWithSubmission from 'organisms/formWithSubmission'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Control,
  Path,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form'
import { SelectOptions } from 'types/common.types'
import { FORM_FIELDS } from 'views/benchmarks/overview/organisms/updateBenchmarkModal/types'

import AddBenchMarkFileModal from './components/AddBenchMarkFileModal/AddBenchMarkFileModal'
import { FILE_IDS, FILES_ORDER, LABEL_MAP } from './constants/constants'
import { getFields } from './helper/fieldHelpers'
import getColumns from './helper/fileColumns'

const BasicDetailsForm = ({
  benchmarkId,
  watch,
  reset,
  control,
  setValue,
  handleSubmit,
  buttonText,
  icon,
  isBenchmarkingDetailsPage = false,
  disableYear,
  resetField,
  onSubmit,
}: BasicDetailsFormProps) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showAddFilesModal, setShowAddFilesModal] = useState(false)
  const [showInFullScreen, setShowInFullScreen] = useState<boolean>(false)
  const [selectedBenchmarkingFile, setSelectedBenchmarkingFile] = useState<
    | {
        file?: File | string | null
        name?: string | null
        type: FILE_IDS
      }
    | undefined
  >(undefined)
  const [previewUrl, setPreviewUrl] = useState('')
  const values = watch()
  useWatch({ control })

  const { from_year, to_year, quarterlyAnalysis } = values

  const updateQuarterlyAnalysis = useCallback(
    (quarterlyAnalysis: Benchmark['quarterly_analysis']) => {
      setValue('quarterlyAnalysis', quarterlyAnalysis)
    },
    [setValue]
  )

  const setFromYear = useCallback(
    (year: string) => {
      setValue('from_year', Number(year))
      if (to_year < Number(year)) {
        setValue('to_year', Number(year))
      } else if (to_year - Number(year) > 9) {
        setValue('to_year', Number(year) + 9)
      }
    },
    [setValue, to_year]
  )

  const setToYear = useCallback(
    (year: string) => {
      if (Number(year) > from_year && Number(year) - from_year < 9) {
        setValue('to_year', Number(year))
      } else if (Number(year) < from_year && from_year - Number(year) < 9) {
        setValue('to_year', from_year)
        setValue('from_year', Number(year))
      } else if (Number(year) > from_year && Number(year) - from_year > 9) {
        setValue('to_year', from_year + 9)
      } else {
        setValue('to_year', from_year)
      }
    },
    [from_year, setValue]
  )

  // const setFile = useCallback(
  //   (id: FILE_IDS) => (file: File | null) => {
  //     setValue(id, file)
  //     id != 'combined_benchmarking_file' &&
  //       !values?.[(id + '_name') as keyof FORM_FIELDS] &&
  //       setValue((id + '_name') as Path<FORM_FIELDS>, file?.name || '')
  //   },
  //   [setValue, values]
  // )

  const handleDelete = useCallback(
    (id: FILE_IDS) => () => {
      setValue(id, null)
      setValue((id + '_name') as Path<FORM_FIELDS>, '')
    },
    [setValue]
  )

  const handlePreview = useCallback(
    (id: FILE_IDS) => () => {
      setSelectedBenchmarkingFile({
        type: id,
        file: values[id],
        name: (values[(id + '_name') as keyof FORM_FIELDS] as string | null | undefined) || `${LABEL_MAP[id]} File`,
      })
      let url = ''
      const fileToPreview = values[id]
      if (fileToPreview) {
        if (fileToPreview instanceof File) {
          if (fileToPreview.type === 'application/pdf') {
            url = URL.createObjectURL(fileToPreview)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(fileToPreview)
            link.click()
          }
        } else if (typeof fileToPreview == 'string') {
          const pathname = new URL(fileToPreview).pathname
          const fileExtension = pathname?.split('.')?.pop()?.toLowerCase()
          if (fileExtension === 'pdf') {
            url = fileToPreview
          } else {
            const link = document.createElement('a')
            link.href = fileToPreview
            link.click()
          }
          url = fileToPreview
        }
        if (url) {
          setShowPreviewModal(true)
          setPreviewUrl(url)
        }
      }
    },
    [values]
  )

  const handleEdit = useCallback(
    (id: FILE_IDS) => () => {
      setSelectedBenchmarkingFile({
        type: id,
        file: values[id],
        name: (values[(id + '_name') as keyof FORM_FIELDS] as string | null | undefined) || `${LABEL_MAP[id]} File`,
      })
      setShowAddFilesModal(true)
    },
    [values]
  )

  const handleAddFileModalClose = useCallback(() => {
    setShowAddFilesModal(false)
    setSelectedBenchmarkingFile(undefined)
  }, [])

  const handleAddNewFile = useCallback(() => {
    setShowAddFilesModal(true)
    setSelectedBenchmarkingFile(undefined)
  }, [])

  const onAddNewFile = useCallback(
    (data: { file?: File | string | null; name?: string | null; type: FILE_IDS }) => {
      setShowAddFilesModal(false)
      setValue(data.type, data.file)
      setValue((data.type + '_name') as Path<FORM_FIELDS>, data.name)
    },
    [setValue]
  )

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [previewUrl])

  const tableData = useMemo(() => {
    const data: {
      id: FILE_IDS
      label: string
      name: string
    }[] = []
    FILES_ORDER.map(id => {
      if (!!values[id as FILE_IDS]) {
        data.push({
          id: id as FILE_IDS,
          label: LABEL_MAP[id as FILE_IDS],
          name:
            (values[(id + '_name') as keyof FORM_FIELDS] as string | null | undefined) ||
            `${LABEL_MAP[id as FILE_IDS]} File`,
        })
      }
    })
    return data
  }, [values])

  const fileTypeOptions = useMemo(() => {
    const data: SelectOptions[] = []
    FILES_ORDER.map(id => {
      if (!values[id as FILE_IDS]) {
        data.push({
          value: id,
          label: LABEL_MAP[id as FILE_IDS],
        })
      }
    })
    return data
  }, [values])

  const columns = useMemo(() => {
    return getColumns({ handleDelete, handlePreview, handleEdit })
  }, [handleDelete, handleEdit, handlePreview])

  const fields = useMemo(
    () =>
      getFields({
        benchmarkId,
        quarterlyAnalysis,
        updateQuarterlyAnalysis,
        disableYear,
        resetField,
        setFromYear,
        setToYear,
        from_year,
        to_year,
      }),
    [
      benchmarkId,
      quarterlyAnalysis,
      updateQuarterlyAnalysis,
      disableYear,
      resetField,
      setFromYear,
      setToYear,
      from_year,
      to_year,
    ]
  )

  return (
    <>
      <FormWithSubmission
        className="flex flex-col w-full gap-4 overflow-y-auto max-h-[75vh]"
        onSubmitHandler={onSubmit}
        reset={reset}
        control={control}
        fields={fields}
        handleSubmit={handleSubmit}>
        {!!tableData.length && !isBenchmarkingDetailsPage && (
          <BorderlessBox
            label="Benchmarking Files"
            className="w-full"
            topRightComponents={
              tableData.length < FILES_ORDER.length ? (
                <Button
                  onClick={handleAddNewFile}
                  className="!gap-1"
                  icon={IconsType.plus}
                  variant={ButtonVariant.Secondary}>
                  File
                </Button>
              ) : undefined
            }>
            <div className="w-full mt-1">
              <NewTable data={tableData} columns={columns} isSort={false} />
            </div>
          </BorderlessBox>
        )}{' '}
      </FormWithSubmission>
      <div className="flex justify-end mt-6 w-full">
        {!tableData.length && !isBenchmarkingDetailsPage && (
          <Button
            onClick={handleAddNewFile}
            icon={IconsType.plus}
            className="mr-auto !gap-1"
            variant={ButtonVariant.Secondary}>
            File
          </Button>
        )}
        <Button icon={icon} onClick={handleSubmit(onSubmit)}>
          {buttonText}
        </Button>
      </div>
      {previewUrl ? (
        <DocumentPreviewModal
          documentPreviewModal={showPreviewModal}
          isFullScreen={showInFullScreen}
          selectedFile={previewUrl}
          setDocumentPreviewModal={setShowPreviewModal}
          setIsFullScreen={setShowInFullScreen}
        />
      ) : null}
      <AddBenchMarkFileModal
        onClose={handleAddFileModalClose}
        key={showAddFilesModal.toString() + String(selectedBenchmarkingFile?.type)}
        isOpen={showAddFilesModal}
        fileTypeOptions={fileTypeOptions}
        benchmarkingFile={selectedBenchmarkingFile}
        onAddNewFile={onAddNewFile}
      />
    </>
  )
}

interface BasicDetailsFormProps {
  benchmarkId?: number
  watch: UseFormWatch<FORM_FIELDS>
  reset: UseFormReset<FORM_FIELDS>
  control: Control<FORM_FIELDS>
  setValue: UseFormSetValue<FORM_FIELDS>
  handleSubmit: UseFormHandleSubmit<FORM_FIELDS>
  buttonText: string
  icon?: IconsType
  disableYear?: boolean
  resetField?: UseFormResetField<FORM_FIELDS>
  onSubmit: (data: FORM_FIELDS) => Promise<void>
  isBenchmarkingDetailsPage?: boolean
}

export default BasicDetailsForm
