import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { attributes } from 'api/transactions/getTransaction/types'
import { TabData } from 'components/tabs/Tabs'

import Conclusion from '../components/conclusion'
import FunctionalFunctions from '../components/functionalFunctions'
import { TABS_KEYS } from '../constants/functionAnalysis.constants'

export const getTabsData = ({
  transactionResponse,
  selectedTransactionId,
  transactionRefetch,
  assetAttributes,
  attributesFetching,
  transactionResponseLoading,
  functionAttributes,
  riskAttributes,
}: TabsDataProps): TabData[] => {
  return [
    {
      label: 'Functions',
      content: (
        <FunctionalFunctions
          attributesFetching={attributesFetching}
          transactionResponseLoading={transactionResponseLoading}
          selectedTransactionId={selectedTransactionId}
          transactionResponse={transactionResponse}
          tabKey={TABS_KEYS.FUNCTION}
          transactionRefetch={transactionRefetch}
          attributesOnTabKey={functionAttributes}
        />
      ),
    },
    {
      label: 'Assets',
      content: (
        <FunctionalFunctions
          attributesFetching={attributesFetching}
          transactionResponseLoading={transactionResponseLoading}
          selectedTransactionId={selectedTransactionId}
          transactionResponse={transactionResponse}
          tabKey={TABS_KEYS.ASSET}
          transactionRefetch={transactionRefetch}
          attributesOnTabKey={assetAttributes}
        />
      ),
    },
    {
      label: 'Risks',
      content: (
        <FunctionalFunctions
          attributesFetching={attributesFetching}
          transactionResponseLoading={transactionResponseLoading}
          selectedTransactionId={selectedTransactionId}
          transactionResponse={transactionResponse}
          tabKey={TABS_KEYS.RISK}
          transactionRefetch={transactionRefetch}
          attributesOnTabKey={riskAttributes}
        />
      ),
    },

    {
      label: 'Conclusion',
      content: (
        <Conclusion
          attributesFetching={attributesFetching}
          transactionResponseLoading={transactionResponseLoading}
          transactionResponse={transactionResponse}
          transactionRefetch={transactionRefetch}
        />
      ),
    },
  ]
}

interface TabsDataProps {
  transactionResponse?: TransactionByIdResponse
  selectedTransactionId: number
  transactionRefetch: VoidFunction
  assetAttributes: attributes[]
  functionAttributes: attributes[]
  riskAttributes: attributes[]
  attributesFetching: boolean
  transactionResponseLoading: boolean
}
