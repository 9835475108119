import classNames from 'classnames'
import { ROUTES } from 'constants/routes'
import { TourHighlightIds } from 'hooks/useFTUTour/types'
import useYearHandler from 'hooks/useYearHandler/useYearHandler'
import { useRouter } from 'next/router'
import ReportWizard from 'organisms/reportWizard/ReportWizard'
import Sidebar from 'organisms/sidebar'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import useBreadcrumbs from './breadcrums'
import Navbar from './components/navbar/Navbar'
import AppLayoutSection from './organisms/appLayoutSection'

const AppLayout = ({ children }: AppLayoutProps) => {
  const { pathname } = useRouter()
  const [activeLink, setActiveLink] = useState<string>()
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)
  const breadcrumbs = useBreadcrumbs()
  const isFullwidth = pathname === ROUTES.HOME || pathname === ROUTES.USER_PROFILE

  useYearHandler()

  useEffect(() => {
    if (
      pathname.includes(ROUTES.USER_PROFILE) ||
      pathname === ROUTES.HOME ||
      pathname.includes(ROUTES.SETTINGS) ||
      pathname.includes(ROUTES.USER_ACCESS_MANAGEMENT) ||
      pathname.includes(ROUTES.ACTIVITY_LOG) ||
      pathname.includes(ROUTES.HELP_CENTER)
    ) {
      setActiveLink(pathname)
    }
  }, [pathname])

  return (
    <div className="flex">
      {!isFullwidth && <Sidebar setActiveLink={setActiveLink} activeLink={activeLink} />}
      <div
        className={classNames('bg-blue-50 min-h-screen overflow-y-hidden transition-width', {
          'w-[calc(100%-4.5rem)]': !isSidebarOpen && !isFullwidth,
          'w-[calc(100%-13.75rem)]': isSidebarOpen && !isFullwidth,
          'w-full': isFullwidth,
        })}>
        <div>
          <Navbar isFullwidth={isFullwidth} />
          <div id={TourHighlightIds.Layout} className="relative">
            <AppLayoutSection breadcrumbs={breadcrumbs} activeLink={activeLink}>
              {children}
            </AppLayoutSection>
            {pathname !== ROUTES.HOME && <ReportWizard></ReportWizard>}
          </div>
        </div>
      </div>
    </div>
  )
}

interface AppLayoutProps {
  children: React.ReactNode
}

export default AppLayout
