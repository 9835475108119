import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import InfoLabel from 'components/input/components/infoLabel'
import React from 'react'
import { Control, FieldValues, Path, useWatch } from 'react-hook-form'

import HTMLViewEdit from '../HTMLViewEdit'

type HTMLInputCardFieldProps<T> = {
  label: string
  id: Path<T & FieldValues>
  control: Control<T & FieldValues>
  saveButtonText?: string
  handleDelete?: (item: Path<T & FieldValues>) => void
  handleSave?: () => Promise<unknown> | void
  containerClassname?: string
  classname?: string
  leftGroupButtons?: string[]
  warnOnDirty?: boolean
  saveButtonIcon?: IconsType
  required?: boolean | undefined
}

function HTMLInputCardField<T>({
  label,
  id,
  control,
  handleDelete,
  handleSave,
  saveButtonText = 'Save',
  containerClassname,
  classname,
  warnOnDirty,
  saveButtonIcon,
  leftGroupButtons,
  required,
}: HTMLInputCardFieldProps<T>) {
  const value = useWatch({ control, name: id })
  return (
    <BorderBoxWrapper className={cx('py-3 px-4 flex items-center bg-white flex-shrink-0', containerClassname)}>
      <InfoLabel newInput={false} label={label} required={required} />
      {!!value &&
        getIcons(IconsType.greenTick, { className: 'ml-1 w-4 h-4 flex-shrink-0', pathClassName: 'fill-[#16a34a]' })}
      <HTMLViewEdit
        className={classname}
        label={label}
        id={id}
        control={control}
        saveButtonText={saveButtonText}
        handleDelete={handleDelete}
        onSave={handleSave}
        warnOnDirty={warnOnDirty}
        saveButtonIcon={saveButtonIcon}
        leftGroupButtons={leftGroupButtons}
      />
    </BorderBoxWrapper>
  )
}

export default HTMLInputCardField
