export function formatNumberWithSuffix(number: number, decimal = 1) {
  if (typeof number !== 'number') {
    return number ?? ''
  }
  let formattedNumber = number.toFixed(decimal).toString()

  if (number >= 1e9) {
    // Convert to billions
    formattedNumber = (number / 1e9).toFixed(decimal) + 'B'
  } else if (number >= 1e6) {
    // Convert to millions
    formattedNumber = (number / 1e6).toFixed(decimal) + 'M'
  } else if (number >= 1e3) {
    // Convert to thousands
    formattedNumber = (number / 1e3).toFixed(decimal) + 'K'
  }

  formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return formattedNumber
}

export function formatAsPercent(value: string | number, precision = 0): string {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value

  if (isNaN(numericValue)) {
    return ''
  }

  const percentValue = numericValue * 100
  const formattedValue = percentValue.toFixed(precision)
  return `${formattedValue}%`
}

export function formatNumberStringToUS(input = ''): string {
  // Remove characters that are not -, +, 0-9, or .
  const cleanedString = input.replace(/([^0-9.+-])+/g, '')
  if (!cleanedString) return ''
  // Convert the cleaned string to a number
  const numberValue = Number(cleanedString)
  if (!isNaN(numberValue)) {
    return numberValue.toLocaleString('en-US') + `${cleanedString.endsWith('.') ? '.' : ''}`
  }

  return input
}
