import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs } from '@mui/material'
import { PORTAL_IDS } from 'components/Portal/constants'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ROUTES_TITLES } from 'organisms/layout/constants/layout.constants'
import { useEffect, useMemo, useRef } from 'react'
import { getTextFromReactNode } from 'utils/stringUtils'

const AppLayoutHeader = ({ activeLink, breadcrumbs }: AppLayoutHeaderProps) => {
  const { query } = useRouter()
  const name = query?.name || query?.txnName || query?.transactionName

  const renderTitle = useMemo(() => {
    return getTextFromReactNode(ROUTES_TITLES[activeLink || ''] || '') || ''
  }, [activeLink])

  const pageTitle = useMemo(
    () => [name, renderTitle, 'Integral'].filter(value => !!value).join(' - '),
    [name, renderTitle]
  )

  const headerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const divElement = headerRef.current
    if (divElement) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentRect.height > 5) {
            divElement.classList.add('mb-3')
          } else {
            divElement.classList.remove('mb-3')
          }
        }
      })
      const timeoutId = setTimeout(() => {
        resizeObserver.observe(divElement)
      }, 100)
      return () => {
        clearTimeout(timeoutId)
        resizeObserver.unobserve(divElement)
      }
    }
  }, [activeLink])

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      {activeLink !== '/home' && (
        <div ref={headerRef} className="flex flex-col p-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center gap-3"></div>
              <div className="ml-1">
                <Breadcrumbs
                  separator={<NavigateNextIcon className="text-gray-400" fontSize="small" />}
                  aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
              </div>

              <div id={PORTAL_IDS.BREADCRUMBS_RIGHT_COMPONENT_ID} />
              {/* <BreadCrumbsRightComponent activeLink={activeLink} /> */}
            </div>

            <div id={PORTAL_IDS.APP_HEADER_RIGHT_COMPONENT_ID}></div>
          </div>
        </div>
      )}
    </>
  )
}

interface AppLayoutHeaderProps {
  activeLink?: string
  breadcrumbs: (JSX.Element | undefined)[]
}

export default AppLayoutHeader
