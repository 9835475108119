import { ArrowUpward, AutoAwesomeOutlined, Close, RestartAlt, StopCircleOutlined } from '@mui/icons-material'
import { User } from 'api/login/login.types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'tinymce'
import { capitalizeFirstLetter } from 'utils/utils'

import { AI_REQUEST, ai_requests } from '../../helpers/ai.helpers'

// Helper function to strip base64 image sources
const stripBase64Images = (html: string): { strippedHtml: string; imageSources: { [key: string]: string } } => {
  const imageSources: { [key: string]: string } = {}
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const images = doc.getElementsByTagName('img')

  Array.from(images).forEach(img => {
    const src = img.getAttribute('src')
    if (src && src.startsWith('data:image')) {
      const uniqueId = `img_${Math.random().toString(36).substring(2, 15)}_${Date.now()}`
      imageSources[uniqueId] = src
      img.setAttribute('data-original-src', uniqueId)
      img.setAttribute('src', '') // Remove base64 data from src
    }
  })

  return {
    strippedHtml: doc.body.innerHTML,
    imageSources,
  }
}

// Helper function to restore base64 image sources
const restoreBase64Images = (html: string, imageSources: { [key: string]: string }): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const images = doc.getElementsByTagName('img')

  Array.from(images).forEach(img => {
    const originalSrc = img.getAttribute('data-original-src')
    if (originalSrc && imageSources[originalSrc]) {
      img.setAttribute('src', imageSources[originalSrc])
      img.removeAttribute('data-original-src')
    }
  })

  return doc.body.innerHTML
}

// Helper function to strip code block delimiters
const stripCodeBlockDelimiters = (content: string): string => {
  return content.replace(/```(?:[a-zA-Z0-9-+]*)?\n?/g, '').replace(/```/g, '')
}

interface SidebarAIContentProps {
  editor: Editor
  user?: User
}

export const SidebarAIContent: React.FC<SidebarAIContentProps> = ({ editor, user }) => {
  const [query, setQuery] = useState('')
  const lastQueryRef = useRef('')
  const conversationThreadRef = useRef<AI_REQUEST['thread']>([])
  const [response, setResponse] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const responseContainerRef = useRef<HTMLDivElement>(null)
  const [currentStreamController, setCurrentStreamController] = useState<AbortController | null>(null)

  // Add effect to scroll response container when response updates
  useEffect(() => {
    if (responseContainerRef.current) {
      responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight
    }
  }, [response])

  const handleReset = useCallback(() => {
    setQuery('')
    setResponse('')
    lastQueryRef.current = ''
    conversationThreadRef.current = []
    currentStreamController?.abort('Sidebar is reset')
    setCurrentStreamController(null)
  }, [currentStreamController])

  useEffect(() => {
    editor.on('ExecCommand', e => {
      if (e.command == 'ToggleSidebar') {
        const isThisSideBarActive = editor.queryCommandValue('ToggleSidebar') == 'ai-sidebar'
        if (!isThisSideBarActive) {
          handleReset()
        } else {
          inputRef.current?.focus()
        }
      }
    })
  }, [editor, handleReset])

  const handleClose = useCallback(() => {
    editor.execCommand('ToggleSidebar', false, 'ai-sidebar')
  }, [editor])

  const handleAiRequest = useCallback(
    async (options: Omit<AI_REQUEST, 'thread'>) => {
      if (currentStreamController) {
        currentStreamController.abort('New request')
      }
      const newController = new AbortController()
      setCurrentStreamController(newController)
      setResponse('') // Clear previous response

      try {
        // Create AI request handler
        const aiRequestHandler = ai_requests(editor)

        // Strip base64 images from HTML content
        const { strippedHtml: strippedCompleteHTML, imageSources: completeHtmlImageSources } = stripBase64Images(
          options.complete_html || ''
        )
        const { strippedHtml: strippedUserSelection, imageSources: userSelectionImageSources } = stripBase64Images(
          options.user_selection || ''
        )

        // Create request with thread and stripped HTML
        const request: AI_REQUEST = {
          ...options,
          complete_html: strippedCompleteHTML,
          user_selection: strippedUserSelection,
          thread: conversationThreadRef.current,
        }
        let currentResponse = ''

        // Call AI with streaming response
        await aiRequestHandler(request, {
          stream: async callback => {
            if (!newController) return Promise.reject(new Error('No active stream'))

            return callback(newController.signal, (message: string) => {
              setResponse(res => res + message)
              currentResponse = currentResponse + message
            })
          },
          string: async () => '', // We don't use this but need to provide it
        })

        // Restore base64 images in the final response
        const restoredResponse = restoreBase64Images(currentResponse, {
          ...completeHtmlImageSources,
          ...userSelectionImageSources,
        })
        setResponse(restoredResponse)
        // Add the current interaction to the thread
        const newEvent = {
          eventUid: `mce-custom-ai-thread-event_${Date.now()}`,
          timestamp: new Date().toISOString(),
          request: {
            query: options.query,
            user_selection: options.user_selection,
          },
          response: {
            type: 'stream',
            data: currentResponse,
          },
        }
        conversationThreadRef.current = [...(conversationThreadRef.current || []), newEvent]
        setQuery('')
      } catch (error) {
        console.error('AI request error:', error)
        setResponse('Error: Could not get AI response. Please try again.')
        editor.notificationManager.open({
          text: 'Failed to get AI response. Please try again.',
          type: 'error',
        })
      } finally {
        if (newController) {
          newController.abort('Response completes')
          setCurrentStreamController(null)
        }
      }
    },
    [editor, currentStreamController]
  )

  const handleSubmit = useCallback(
    async (query: string) => {
      const trimmedQuery = query.trim()
      if (!trimmedQuery) return

      lastQueryRef.current = trimmedQuery
      const user_selection = editor.selection.getContent() || ''
      const complete_html = editor.getContent() || ''

      await handleAiRequest({
        query: trimmedQuery,
        user_selection: user_selection,
        complete_html: complete_html,
      })
    },
    [editor, handleAiRequest]
  )

  useEffect(() => {
    editor.addCommand('runAIQuery', (ui, value) => {
      const isThisSideBarActive = editor.queryCommandValue('ToggleSidebar') == 'ai-sidebar'
      if (value?.prompt) {
        if (!isThisSideBarActive) {
          editor.execCommand('ToggleSidebar', true, 'ai-sidebar')
        }
        handleReset()
        setQuery(value.prompt)
        handleSubmit(value.prompt)
      }
    })
  }, [editor, handleReset, handleSubmit])

  const isStreaming = !!(currentStreamController && !currentStreamController.signal.aborted)

  const disableRetry = isStreaming || !lastQueryRef.current

  const handleRetry = useCallback(() => {
    if (lastQueryRef.current && !disableRetry) {
      // Remove the last conversation entry if it has the same query
      const thread = conversationThreadRef.current
      if (thread && thread.length > 0) {
        const lastEvent = thread[thread.length - 1]
        if (lastEvent?.request.query === lastQueryRef.current) {
          conversationThreadRef.current = thread.slice(0, -1)
        }
      }
      setQuery(lastQueryRef.current)
      handleSubmit(lastQueryRef.current)
    }
  }, [disableRetry, handleSubmit])

  const strippedResponse = stripCodeBlockDelimiters(response)

  const handleInsert = useCallback(() => {
    if (isStreaming || !strippedResponse) return
    if (response) {
      // Look for code blocks that start with ``` and optionally have a language identifier
      const codeBlockRegex = /```(?:[a-zA-Z0-9-+]*)?\n?([\s\S]*?)```/g
      const extractedCodeBlocks: string[] = []

      // Keep finding code blocks until we run out of matches
      let currentMatch: RegExpExecArray | null = codeBlockRegex.exec(response)
      while (currentMatch !== null) {
        // currentMatch[1] contains just the content between the backticks
        const codeBlockContent = currentMatch[1].trim()
        extractedCodeBlocks.push(codeBlockContent)
        currentMatch = codeBlockRegex.exec(response)
      }

      const contentToInsert = extractedCodeBlocks.length > 0 ? extractedCodeBlocks.join('\n\n') : response

      if (editor.selection.isCollapsed()) {
        // If selection is collapsed (just cursor), select contents of body
        editor.setContent(stripCodeBlockDelimiters(contentToInsert))
        return
      }
      // Replace the selection (whether it's the whole document or a partial selection)
      editor.selection.setContent(stripCodeBlockDelimiters(contentToInsert))
    }
  }, [editor, isStreaming, response, strippedResponse])

  const onStopStream = useCallback(() => {
    if (currentStreamController && isStreaming) {
      currentStreamController.abort('stream stopped')
      setCurrentStreamController(null)
    }
  }, [currentStreamController, isStreaming])

  return (
    <div
      style={{
        paddingLeft: 16,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        gap: 12,
        flexDirection: 'column',
        width: 440,
        backgroundColor: '#1A193B',
        fontFamily: 'var(--inter-font)',
      }}>
      <div
        style={{
          flexGrow: 1,
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
          borderRadius: 8,
          border: '1px solid #374151',
          background: '#212048',
          minHeight: 0,
        }}>
        {/* Header */}
        <div style={{ display: 'flex', alignItems: 'center', gap: 12, alignSelf: 'stretch', flexShrink: 0 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 44,
              height: 44,
              borderRadius: 50,
              border: '1px solid #B889FF',
              background: 'linear-gradient(110deg, #6B84FF 11.7%, #C898FF 88.78%)',
            }}>
            <AutoAwesomeOutlined
              style={{
                color: 'white',
                width: 20,
                height: 20,
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flex: 1,
            }}>
            <div
              style={{
                color: '#D1D5DB',
                fontSize: 13,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
              }}>
              Hi{' '}
              {capitalizeFirstLetter(
                `${(user?.first_name ? `${user?.first_name} ` : '') + (user?.last_name || '')}` ||
                  user?.username ||
                  user?.email ||
                  'User'
              )}
            </div>
            <div
              style={{
                color: '#D1D5DB',
                fontSize: 13,
                fontWeight: 600,
                lineHeight: '19px',
              }}>
              How can I help you?
            </div>
          </div>
          <div
            onClick={handleClose}
            style={{
              alignSelf: 'flex-start',
              cursor: 'pointer',
              padding: 4,
              marginTop: -4,
              marginRight: -4,
            }}>
            <Close
              style={{
                color: '#D1D5DB',
                width: 14,
                height: 14,
              }}
            />
          </div>
        </div>

        {/* Response Area */}
        <div
          ref={responseContainerRef}
          dangerouslySetInnerHTML={{ __html: strippedResponse }}
          style={{
            width: '100%',
            overflowY: 'auto',
            whiteSpace: 'pre-wrap',
            padding: 12,
            gap: 8,
            borderRadius: 8,
            border: '1px solid #374151',
            flex: 1,
            minHeight: 0,
            color: '#D1D5DB',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '19px',
          }}></div>

        {/* Control Buttons */}
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: 12, alignSelf: 'stretch', flexShrink: 0 }}>
          <div
            onClick={handleInsert}
            style={{
              display: 'flex',
              padding: '8px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
              alignSelf: 'stretch',
              borderRadius: 8,
              background: isStreaming || !strippedResponse ? '#4B5563' : '#818CF8',
              color: isStreaming || !strippedResponse ? '#1F2937' : '#FFF',
              fontSize: 13,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '19px',
              cursor: isStreaming || !strippedResponse ? 'not-allowed' : 'pointer',
            }}>
            Insert
          </div>
          <div
            onClick={handleRetry}
            style={{
              display: 'flex',
              width: 36,
              height: 36,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              border: '1px solid #374151',
              color: isStreaming || !lastQueryRef.current ? '#374151' : '#A5B4FC',
              fontSize: 13,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '19px',
              cursor: disableRetry ? 'not-allowed' : 'pointer',
            }}>
            <RestartAlt style={{ width: 20, height: 20 }} />
          </div>
          <div
            onClick={onStopStream}
            style={{
              display: 'flex',
              width: 36,
              height: 36,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              border: isStreaming ? '1px solid #B91C1C' : '1px solid #374151',
              color: isStreaming ? '#B91C1C' : '#374151',
              fontSize: 13,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '19px',
              cursor: !isStreaming ? 'not-allowed' : 'pointer',
            }}>
            <StopCircleOutlined style={{ width: 20, height: 20 }} />
          </div>
        </div>
      </div>

      {/* Input Area */}
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '48px',
          padding: '6px 6px 6px 16px',
          alignItems: 'center',
          gap: '4px',
          borderRadius: '25px',
          border: '1px solid var(--gray-700, #374151)',
          background: '#212048',
        }}>
        <input
          ref={inputRef}
          type="text"
          value={query}
          disabled={isStreaming}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && query.trim() && !isStreaming && handleSubmit(query)}
          placeholder="Ask AIra anything ..."
          style={{
            flex: 1,
            padding: 8,
            border: 'none',
            borderRadius: 4,

            color: '#D1D5DB',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '19px',
          }}
        />
        <div
          style={{
            display: 'flex',
            padding: 6,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            borderRadius: '100%',
            border: '1px solid #B889FF',
            background: 'linear-gradient(110deg, #6B84FF 11.7%, #C898FF 88.78%)',
            filter: isStreaming || !query.trim() ? 'grayscale(50%)' : 'none',
            cursor: isStreaming || !query.trim() ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            !!query.trim() && !isStreaming && handleSubmit(query)
          }}>
          <ArrowUpward style={{ width: 20, height: 20, color: 'white' }} />
        </div>
      </div>
    </div>
  )
}
