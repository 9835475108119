import React from 'react'
import { components as Component, GroupBase, OptionProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

export const SelectCheckboxOptions = (props: OptionProps<SelectOptions, true, GroupBase<SelectOptions>>) => {
  const { data, isSelected, selectProps } = props
  const isSelectAll = data.value === 'select-all'
  const allOptions = (selectProps.options as SelectOptions[]).filter(opt => opt.value !== 'select-all')
  const selectedOptions = (selectProps.value as SelectOptions[]) || []

  const isAllSelected = allOptions.length === selectedOptions.length

  return (
    <Component.Option {...props}>
      <div
        className={`flex items-center text-start ${
          isSelectAll ? 'border-0 border-b border-gray-200 border-solid pb-2' : ''
        }`}>
        <input
          className="w-[18px] h-[18px] flex-shrink-0 accent-blue800 border-2 rounded border-solid border-neutral300 mr-1.5"
          type="checkbox"
          checked={isSelectAll ? isAllSelected : isSelected}
          readOnly
        />
        <div className="w-full text-callout font-semibold">
          {isSelectAll ? (
            'Select All'
          ) : props.data.additionalItems ? (
            <div className="flex items-center gap-2 w-full pr-2">
              <div className="truncate w-full">{props.children}</div>
              {props.data.additionalItems(props.data.value)}
            </div>
          ) : (
            props.children
          )}
        </div>
      </div>
    </Component.Option>
  )
}
