import { Close, SearchOutlined } from '@mui/icons-material'
import { TemplateVariableResponse } from 'api/templates/types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'tinymce'

type Props = {
  variables: TemplateVariableResponse[]
  editor: Editor
}

const TemplateVariableSidebar = ({ variables, editor }: Props) => {
  const [query, setQuery] = useState('')
  const [filteredVariables, setFilteredVariables] = useState<TemplateVariableResponse[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const handleClose = useCallback(() => {
    editor.execCommand('ToggleSidebar', false, 'templateVariables')
  }, [editor])

  const handleSubmit = useCallback(
    (query: string) => {
      if (!query.trim()) {
        setFilteredVariables(variables)
        return
      }
      const filtered = variables.filter(variable =>
        variable.display_name.toLowerCase().includes(query.trim().toLowerCase())
      )
      setFilteredVariables(filtered)
    },
    [variables]
  )

  useEffect(() => {
    setFilteredVariables(variables)
  }, [variables])

  const handleVariableClick = useCallback(
    (variable: TemplateVariableResponse) => {
      editor.insertContent(
        `<span data-variable-name="${variable.name}" class="mceNonEditable">${
          variable.computed_value || variable.display_name
        }</span>`
      )
    },
    [editor]
  )

  return (
    <div
      style={{
        paddingLeft: 16,
        height: '100%',
        display: 'flex',
        gap: 12,
        flexDirection: 'column',
        width: 440,
        backgroundColor: '#1A193B',
        fontFamily: 'var(--inter-font)',
        maxHeight: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          padding: 16,
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
          flex: 1,
          alignSelf: 'stretch',
          height: '100%',
          borderRadius: 8,
          border: '1px solid var(--gray-700, #374151)',
          background: '#212048',
        }}>
        {/* header */}
        <div
          style={{
            display: 'flex',
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 16,
          }}>
          <div
            style={{
              color: '#D1D5DB',
              fontSize: 13,
              fontWeight: 600,
              lineHeight: '19px',
              height: 'min-content',
            }}>
            System Variables
          </div>
          <div
            onClick={handleClose}
            style={{
              alignSelf: 'flex-start',
              cursor: 'pointer',
              padding: 4,
              marginTop: -4,
              marginRight: -4,
            }}>
            <Close
              style={{
                color: '#D1D5DB',
                width: 14,
                height: 14,
              }}
            />
          </div>
        </div>
        {/* search bar */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '48px',
            padding: '6px 6px 6px 16px',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '25px',
            border: '1px solid var(--gray-700, #374151)',
            background: '#212048',
          }}>
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={e => setQuery(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(query)}
            placeholder="Search a variable"
            style={{
              flex: 1,
              padding: 8,
              border: 'none',
              borderRadius: 4,

              color: '#D1D5DB',
              fontSize: 13,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
            }}
          />
          <div
            style={{
              display: 'flex',
              padding: 6,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
              borderRadius: '100%',
              border: '1px solid rgba(255, 255, 255, 0.50)',
              background: 'rgba(135, 157, 255, 0.50)',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSubmit(query)
            }}>
            <SearchOutlined style={{ width: 20, height: 20, color: 'white' }} />
          </div>
        </div>
        {/* variables list */}
        {filteredVariables.length ? (
          <div style={{ overflowY: 'auto', flex: 1, width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                padding: 8,
                alignSelf: 'stretch',

                borderRadius: 8,
                background: '#2B2A56',
              }}>
              <div
                style={{
                  color: '#D1D5DB',
                  fontSize: 13,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '19px',
                }}>
                Transaction
              </div>
              {filteredVariables.map(variable => (
                <div
                  onClick={() => handleVariableClick(variable)}
                  style={{
                    display: 'flex',
                    padding: '4px 8px',
                    gap: 10,
                    width: '100%',
                    alignSelf: 'stretch',
                    borderRadius: 8,
                    border: '1px solid var(--gray-700, #374151)',

                    color: '#D1D5DB',
                    fontSize: 13,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '19px',
                    cursor: 'pointer',
                  }}
                  key={variable.name}>
                  <span>
                    {variable.display_name}
                    {variable.computed_value ? (
                      <span
                        style={{
                          color: '#FB923C',
                          fontSize: 11,
                          fontWeight: 600,
                          lineHeight: '16px',
                        }}>
                        {' '}
                        - {variable.computed_value}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : query.trim() ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '16px',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#9CA3AF',
              }}>
              No variables found
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#6B7280',
              }}>
              Please try a different search term.
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '16px',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#9CA3AF',
              }}>
              No variables found
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplateVariableSidebar
