import axios from 'api'
import fetchWithAuth from 'api/fetch'
import { APIS } from 'constants/apis'

import { ChatListResponse, ChatPayload, ChatQuotaResponse, Conversation, OECDChatCountryList } from './types'

export const getOECDChatCountries = () =>
  axios
    .get<OECDChatCountryList>(`/api/v1/org/{organization}/recommendations/chat/oecd_chat_countries`)
    .then(res => res.data)

export const getChatList = () =>
  axios.get<ChatListResponse>('/api/v1/org/{organization}/recommendations/chat/').then(res => res.data)

export const getChatQuota = () =>
  axios.get<ChatQuotaResponse>(`/api/v1/org/{organization}/recommendations/chat/chat_quota`).then(res => res.data)

export const getChat = (id: number) =>
  axios.get<Conversation>(`/api/v1/org/{organization}/recommendations/chat/${id}`).then(res => res.data)

export const deleteChat = (id: number) =>
  axios.delete(`/api/v1/org/{organization}/recommendations/chat/${id}`).then(res => res.data)

export const sendChatMessageWithFetch = (payload: ChatPayload) =>
  fetchWithAuth(APIS.OECD_CHAT, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  })
