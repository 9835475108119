import { ColumnDef } from '@tanstack/react-table'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { associated_entity, primary_entity, TransactionResponse } from 'api/transactions/getTransaction/types'
import FlagReader from 'components/flagReader'
import Typography, { Variant } from 'components/typography'
import { truncateString } from 'utils/utils'

export const groupFunctionColumns = ({
  entityCosts,
  shouldHideCosts,
  isPrimarySource,
  transactionResponse,
  onClickSecondaryEntity,
}: {
  isPrimarySource: boolean
  shouldHideCosts: boolean
  transactionResponse: TransactionByIdResponse | TransactionResponse
  entityCosts: Record<string, { cost: string; costInUsd: number }>
  onClickSecondaryEntity: (entity: associated_entity) => () => void
}) => {
  const associatedEntityTransactionNature = !isPrimarySource
    ? `${transactionResponse.functional_profile.source_party_identification}-${
        transactionResponse.associated_entities_group_name || 'Associated Entities/Entity'
      }`
    : `${transactionResponse.functional_profile.target_party_identification}-${
        transactionResponse.associated_entities_group_name || 'Associated Entities/Entity'
      }`

  const columns: ColumnDef<associated_entity>[] = [
    {
      header: associatedEntityTransactionNature,
      id: 'distributionPurchasingEntity',
      accessorKey: 'distributionPurchasingEntity',
      cell: data => {
        const entityName = data.row.original.name_abbreviation
          ? data.row.original.name_abbreviation
          : data.row.original.name
        return (
          <div
            onClick={onClickSecondaryEntity(data.row.original)}
            className="flex items-center cursor-pointer text-blue800 gap-1">
            <FlagReader country={data.row.original.country} />
            <Typography variant={Variant.Callout} type="semibold" className="max-w-[10rem] break-words">
              {entityName}
            </Typography>
          </div>
        )
      },
    },
  ]
  const costBasedColumns: ColumnDef<associated_entity>[] = [
    {
      header: 'TXN Amounts (Local Currency)',
      id: 'txnAmount',
      accessorKey: 'txnAmount',
      cell: data => {
        return (
          <div className="py-[2.5px]">
            <Typography variant={Variant.Callout} type="semibold" className="gap-1 text-gray-700">
              {entityCosts[data.row.original.id].cost}
            </Typography>
          </div>
        )
      },
    },
  ]

  if (!shouldHideCosts) {
    costBasedColumns.forEach(column => {
      columns.push(column)
    })
  }

  return columns
}

export const getPrimaryEntityColumns = ({
  isPrimarySource,
  transactionResponse,
  onClickPrimaryEntity,
}: {
  isPrimarySource: boolean
  transactionResponse: TransactionByIdResponse | TransactionResponse
  onClickPrimaryEntity: () => void
}) => {
  const primaryEntityTransactionNature = isPrimarySource
    ? transactionResponse.functional_profile.source_party_identification
    : transactionResponse.functional_profile.target_party_identification

  const columns: ColumnDef<primary_entity>[] = [
    {
      header: primaryEntityTransactionNature,
      id: 'distributionPurchasingEntity',
      cell: data => {
        const entityName = data.row.original.name_abbreviation
          ? data.row.original.name_abbreviation
          : data.row.original.name
        return (
          <div onClick={onClickPrimaryEntity} className="flex items-center cursor-pointer text-blue800 gap-1">
            <FlagReader country={data.row.original.country} />
            <Typography variant={Variant.Callout} type="semibold" className="break-words">
              {truncateString(entityName, 50)}
            </Typography>
          </div>
        )
      },
    },
  ]

  return columns
}

export const getSecondaryEntityColumns = ({
  entityCosts,
  isPrimarySource,
  transactionResponse,
  onClickSecondaryEntity,
}: {
  isPrimarySource: boolean
  transactionResponse: TransactionByIdResponse | TransactionResponse
  entityCosts: Record<string, { cost: string; costInUsd: number }>
  onClickSecondaryEntity: (entity: associated_entity) => () => void
}) => {
  const associatedEntityTransactionNature = !isPrimarySource
    ? `${transactionResponse.functional_profile.source_party_identification}`
    : `${transactionResponse.functional_profile.target_party_identification}`

  const columns: ColumnDef<associated_entity>[] = [
    {
      header: associatedEntityTransactionNature,
      id: 'distributionPurchasingEntity',
      accessorKey: 'distributionPurchasingEntity',
      size: 350,
      cell: data => {
        const entityName = data.row.original.name_abbreviation
          ? data.row.original.name_abbreviation
          : data.row.original.name
        return (
          <div
            onClick={onClickSecondaryEntity(data.row.original)}
            className="flex items-center cursor-pointer text-blue800 gap-1">
            <FlagReader country={data.row.original.country} />
            <Typography variant={Variant.Callout} type="semibold" className="break-words">
              {truncateString(entityName, 50)}
            </Typography>
          </div>
        )
      },
    },
    {
      header: 'TXN Amount',
      id: 'txnAmount',
      accessorKey: 'txnAmount',
      size: 100,
      cell: data => {
        return (
          <Typography variant={Variant.Callout} type="semibold" className="gap-1 text-gray-700">
            {entityCosts[data.row.original.id].cost}
          </Typography>
        )
      },
    },
  ]

  return columns
}
