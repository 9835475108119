import { HelpCenterOutlined } from '@mui/icons-material'
import { Settings } from '@mui/icons-material'
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material'
import { BrowserClient, Feedback, getClient } from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import { editUser } from 'api/userAccess'
import NewFeedBack from 'assets/icons/newFeedBack'
import Rocket from 'assets/icons/rocket'
import Upload from 'assets/icons/upload'
import User from 'assets/icons/user'
import { AxiosError } from 'axios'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { capitalizeFirstLetter, getToastErrorMessage, truncateString } from 'utils/utils'

import { PAPER_STYLE } from '../../constants/sidebar.constants'

const UserInfo = (): JSX.Element => {
  const router = useRouter()

  const { user, onLogout, refetchUser } = useAuth()
  const client = getClient<BrowserClient>()
  const feedback = client?.getIntegration(Feedback)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const editUserMutation = useMutation(editUser)
  const onSubmitHandler = useCallback(() => {
    editUserMutation.mutate(
      [
        {
          is_training_completed: false,
        },
        user?.id || 0,
      ],
      {
        onSuccess: () => {
          refetchUser()
          handleClose()
        },
        onError: (error: unknown) => {
          if (error instanceof AxiosError) {
            getToastErrorMessage(error)
          }
        },
      }
    )
  }, [editUserMutation, refetchUser, user?.id])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()

    onLogout()
    handleClose()
  }

  const handleUserNavigation = useCallback(() => {
    router.push(ROUTES.USER_PROFILE)
    handleClose()
  }, [router])
  const handleSettingsNavigation = useCallback(() => {
    router.push(ROUTES.SETTINGS)
    handleClose()
  }, [router])
  const handleFeedback = useCallback(() => {
    feedback?.openDialog()
    handleClose()
  }, [feedback])

  const handleHelpCenterNavigation = useCallback(() => {
    router.push(ROUTES.HELP_CENTER)
    handleClose()
  }, [router])

  const name = `${(user?.first_name ? `${user?.first_name} ` : '') + (user?.last_name || '')}` || user?.username

  return (
    <>
      <Tooltip
        title={`${capitalizeFirstLetter(user?.first_name || '')}${user?.first_name && user?.email ? ' - ' : ''}${
          user?.email || ''
        }`}>
        <Avatar
          className="cursor-pointer bg-orange-100 text-orange-500"
          onClick={handleClick}
          src={user?.profile_photo || ''}>
          {name && name[0].toUpperCase()}
        </Avatar>
      </Tooltip>
      <Menu
        id="user-info-menu"
        aria-labelledby="user-info-menu-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { ...PAPER_STYLE, width: '15rem', maxHeight: '22rem', borderRadius: '0.5rem', marginTop: '0.5rem' },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="p-3 !py-1 flex flex-col items-start">
          <div className="flex items-center flex-col w-full">
            <Tooltip title={name} placement="top">
              <div>
                <Typography variant={Variant.Heading2} type="semibold">
                  {truncateString(name || '', 20)}
                </Typography>
              </div>
            </Tooltip>
            <Tooltip title={user?.email} placement="top">
              <div>
                <Typography variant={Variant.Callout} className="!text-gray-400">
                  {truncateString(user?.email || '', 25)}
                </Typography>
              </div>
            </Tooltip>
          </div>
          <MenuItem
            className="font-semibold text-callout !px-0 my-2 py-2 flex items-center justify-start gap-2 border-0 border-solid border-gray-200 border-b w-full"
            onClick={handleUserNavigation}>
            <User className="w-[18px]" pathClassName="stroke-gray-700" /> Profile
          </MenuItem>
          <MenuItem
            className="font-semibold text-callout !px-0 mb-2  py-2 flex items-center justify-start gap-2 border-0 border-solid border-gray-200 border-b w-full"
            onClick={handleSettingsNavigation}>
            <Settings className="w-[18px] text-gray-700" />
            Setting & Support
          </MenuItem>
          <MenuItem
            className="font-semibold text-callout !px-0  py-2 mb-2 flex items-center justify-start gap-2 border-0 border-solid border-gray-200 border-b w-full"
            onClick={handleHelpCenterNavigation}>
            <HelpCenterOutlined className="w-[18px] text-gray-700" />
            Help Center
          </MenuItem>

          {feedback && (
            <MenuItem
              className="font-semibold text-callout !px-0  py-2 mb-2 flex items-center justify-start gap-2 border-0 border-solid border-gray-200 border-b w-full"
              onClick={handleFeedback}>
              <NewFeedBack className="w-[18px]" pathClassName="fill-gray-700" />
              Feedback
            </MenuItem>
          )}
          <MenuItem
            className="font-semibold text-callout !px-0  py-2 mb-2 flex items-center justify-start gap-2 border-0 border-solid border-gray-200 border-b w-full"
            onClick={onSubmitHandler}>
            <Rocket className="w-[18px]" pathClassName="fill-gray-700" />
            Product Tour
          </MenuItem>
          <MenuItem
            className="font-semibold text-callout !px-0  py-2 flex items-center justify-start gap-2 w-full"
            onClick={handleLogout}>
            <Upload className="w-[18px h-[18px] rotate-90" pathClassName="stroke-gray-700" /> Log Out
          </MenuItem>
        </div>
      </Menu>
    </>
  )
}

export default UserInfo
