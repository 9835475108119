import { yupResolver } from '@hookform/resolvers/yup'
import { Info } from '@mui/icons-material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SelectedComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import { createTermsAndCharacteristics, updateTermsAndCharacteristics } from 'api/transactions/comparableResults'
import { CreateTermsAndCharacteristicsPayload } from 'api/transactions/comparableResults/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import GreenTick from 'assets/icons/greenTick'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import cx from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import Loading from 'components/loading'
import Modal from 'components/modal/Modal'
import NoContentContainer from 'components/noContentContainer/NoContentContainer'
import TinyMceEditor from 'components/tinyMceEditor'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE, integralCss } from 'components/tinyMceEditor/constants'
import { EditorRef } from 'components/tinyMceEditor/types'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { get } from 'lodash'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Editor as RawEditor } from 'tinymce'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { ControlledTransactionDataTableData } from '../../ControlledTransactionsData'
import { getTransactionData, getTransactionTableColumns } from './addComparableContorlledTransaction.helpers'
import { schema } from './AddComparableControlledTransaction.schema'
import {
  ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_VALUES,
  TransactionTableItem,
} from './AddComparableControlledTransaction.types'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  selectedComparabilityFactors?: SelectedComparabilityFactor[]
  selectedTerm?: ControlledTransactionDataTableData | undefined
  transactionOptions: SelectOptions[]
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  isGroupTransaction?: SelectOptions
}

const AddComparableControlledTransaction = ({
  isOpen,
  onClose,
  transactionResponse,
  isGroupTransaction,
  transactionOptions,
  selectedComparabilityFactors,
  selectedTerm,
  economicAnalysisTransactionResponse,
}: Props) => {
  const [expandedFactor, setExpandedFactor] = useState<number | undefined>()
  const [isDirtyForValueVolumeTable, setIsDirtyForValueVolumeTable] = useState<boolean>(false)
  const { control, watch, setValue, formState, handleSubmit, trigger, getValues } =
    useForm<ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_VALUES>({
      resolver: yupResolver(schema),
      mode: 'all',
      defaultValues: {
        valueVolumeTable: [],
        comparabilityFactors: [],
      },
    })

  const valueVolumeTable = watch('valueVolumeTable')
  const [length, setLength] = useState<number>(1)
  useEffect(() => {
    setLength(valueVolumeTable.length || 1)
  }, [valueVolumeTable?.length])

  useEffect(() => {
    trigger('valueVolumeTable')
  }, [trigger, valueVolumeTable])

  const handleDeleteRow = useCallback(
    (index: number) => {
      setIsDirtyForValueVolumeTable(true)
      const existingData = getValues('valueVolumeTable') || []
      const updatedData = [...existingData]
      updatedData.splice(index, 1)

      const reindexedData = updatedData.map((item, idx) => ({
        ...item,
        label: `${idx + 1}`,
        key: `${idx + 1}`,
      }))
      setValue('valueVolumeTable', reindexedData)
    },
    [setValue, getValues]
  )
  const handleAddRow = () => {
    setIsDirtyForValueVolumeTable(true)
    setLength(prevLength => prevLength + 1)
  }
  useEffect(() => {
    const existingData = getValues('valueVolumeTable') || []
    const newData = getTransactionData({ length })

    const updatedData = [...existingData, ...newData.slice(existingData.length)]

    setValue('valueVolumeTable', updatedData)
  }, [length, setValue, getValues])

  const [comparabilityFactors] = watch(['comparabilityFactors'])
  const description = watch('description')
  const setValueVolumeTable: (action: (data: TransactionTableItem[]) => TransactionTableItem[]) => void = useCallback(
    action => {
      setIsDirtyForValueVolumeTable(true)
      const tableValue = getValues('valueVolumeTable')
      setValue('valueVolumeTable', action(tableValue))
    },
    [getValues, setValue]
  )
  const transactionTableColumns = useMemo(() => {
    return getTransactionTableColumns(handleDeleteRow)
  }, [handleDeleteRow])
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()
  const { user } = useAuth()
  const editorRef = useRef<EditorRef>(null)
  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })
  const css = useMemo(() => {
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
  }, [localFileCss])
  const [htmlIsDirty, setHtmlIsDirty] = useState(false)
  const dirtyHtml = useCallback(
    (dirty: boolean) => {
      if (!htmlIsDirty) {
        setHtmlIsDirty(dirty)
      }
    },
    [htmlIsDirty]
  )
  const closeModal = useCallback(async () => {
    if (
      (htmlIsDirty || isDirtyForValueVolumeTable) &&
      !(await getConfirmation('You may have unsaved changes, are you sure you want to exit?', 'Attention!'))
    ) {
      return
    }
    setHtmlIsDirty(false)
    onClose()
  }, [getConfirmation, htmlIsDirty, isDirtyForValueVolumeTable, onClose])

  const queryClient = useQueryClient()

  const { refetchChecklist } = useReportWizardContext()

  const createTermsAndCharacteristicsMutation = useMutation({
    mutationFn: (payload: CreateTermsAndCharacteristicsPayload) => createTermsAndCharacteristics<true>(payload),
    onSuccess() {
      toast.success('Added controlled transaction')
      queryClient.refetchQueries([QUERIES.GET_TERMS_AND_CHARACTERISTICS.key], { type: 'active' })
      setHtmlIsDirty(false)
      onClose()
      refetchChecklist()
    },
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
  })
  const updateTermsAndCharacteristicsMutation = useMutation({
    mutationFn: (
      payload: CreateTermsAndCharacteristicsPayload & {
        id: number
      }
    ) => updateTermsAndCharacteristics<true>(payload),
    onSuccess() {
      toast.success('Updated controlled transaction')
      queryClient.refetchQueries([QUERIES.GET_TERMS_AND_CHARACTERISTICS.key], { type: 'active' })
      setHtmlIsDirty(false)
      onClose()
      refetchChecklist()
    },
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
  })

  useEffect(() => {
    if (isOpen) {
      if (selectedComparabilityFactors?.length) {
        let incompleteFactor = 0
        setValue(
          'comparabilityFactors',
          selectedComparabilityFactors.map(factor => {
            const value =
              selectedTerm?.comparability_factors_description.find(
                item => item.selected_comparability_factor == factor.id
              )?.description || ''
            if (!value && !incompleteFactor) {
              incompleteFactor = factor.id
              setExpandedFactor(factor.id)
            }
            return {
              name: factor.comparability_factor_full_name,
              value: value,
              id: factor.id,
            }
          })
        )
        if (incompleteFactor && selectedTerm) {
          setTimeout(() => {
            trigger('comparabilityFactors')
          }, 100)
        }
      }
      if (selectedTerm) {
        setValue('transaction', {
          label: `Transaction - ${selectedTerm.individual_transaction}`,
          value: selectedTerm.individual_transaction?.id || (transactionResponse ? transactionResponse.id : ''),
        })
        setValue('description', selectedTerm.description)
        setValue(
          'valueVolumeTable',
          Array.from({ length: selectedTerm.value.length }, (_, index) => ({
            isEditable: true,
            isEditAllowed: true,
            key: `${index + 1}`,
            label: `${index + 1}`,
            value: selectedTerm.value[index],
            volume: selectedTerm.volume[index],
          }))
        )
      }
    }
  }, [
    transactionResponse,
    isOpen,
    selectedComparabilityFactors,
    selectedTerm,
    selectedTerm?.comparability_factors_description,
    setValue,
    trigger,
  ])

  const onSubmit = useCallback(
    async (data: ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_VALUES) => {
      const valueVolumeTable = data.valueVolumeTable.filter(item => {
        const numericValue = Number(item.value)
        const validValue = !isNaN(numericValue) && item.value !== null
        const validVolume = typeof item.volume === 'string' && item.volume.trim() !== ''
        return validValue && validVolume
      })
      if (!selectedTerm) {
        economicAnalysisTransactionResponse &&
          createTermsAndCharacteristicsMutation.mutate({
            description: getValues('description') || '',
            economic_analysis: economicAnalysisTransactionResponse?.id,
            is_controlled_transaction: true,
            individual_transaction_id:
              isGroupTransaction && isGroupTransaction.value ? null : Number(data.transaction?.value),
            include_all_individual_transactions: isGroupTransaction && isGroupTransaction.value ? true : false,
            value: valueVolumeTable.map(value => Number(value.value)) || [],
            volume: valueVolumeTable.map(value => value.volume) || [],
            comparability_factors:
              data.comparabilityFactors?.map(factor => {
                return {
                  selected_comparability_factor: factor.id,
                  description: factor.value,
                }
              }) || [],
          })
      } else {
        economicAnalysisTransactionResponse &&
          updateTermsAndCharacteristicsMutation.mutate({
            id: selectedTerm.id,
            description: getValues('description') || '',
            economic_analysis: economicAnalysisTransactionResponse?.id,
            is_controlled_transaction: true,
            individual_transaction_id:
              isGroupTransaction && isGroupTransaction.value ? null : Number(data.transaction?.value),
            include_all_individual_transactions: isGroupTransaction && isGroupTransaction.value ? true : false,
            value: valueVolumeTable.map(value => Number(value.value)) || [],
            volume: valueVolumeTable.map(value => value.volume) || [],
            comparability_factors:
              data.comparabilityFactors?.map(factor => {
                return {
                  selected_comparability_factor: factor.id,
                  description: factor.value,
                }
              }) || [],
          })
      }
    },
    [
      isGroupTransaction,
      createTermsAndCharacteristicsMutation,
      economicAnalysisTransactionResponse,
      selectedTerm,
      updateTermsAndCharacteristicsMutation,
      getValues,
    ]
  )

  const loading = createTermsAndCharacteristicsMutation.isLoading || updateTermsAndCharacteristicsMutation.isLoading
  const handleDescriptionEditorSetup = (editor: RawEditor) => {
    const handleSave = () => {
      if (editor) {
        setValue('description', editor.getContent())
      }
    }

    editor.on('blur', handleSave)
  }

  const valueVolumeErrorMessage = useMemo(() => {
    if (formState.errors.valueVolumeTable?.message) {
      return formState.errors.valueVolumeTable?.message
    }
    if (formState.errors.valueVolumeTable && Array.isArray(formState.errors.valueVolumeTable)) {
      for (const itemError of formState.errors.valueVolumeTable) {
        if (itemError?.value && itemError?.value.message) {
          return itemError.value.message
        }

        if (itemError?.volume && itemError?.volume?.message) {
          return itemError.volume.message
        }
      }
    }
    return null
  }, [formState?.errors?.valueVolumeTable])

  const errorRef = useRef<HTMLDivElement>(null)
  const onSave = useCallback(() => {
    return handleSubmit(onSubmit, () => {
      if (valueVolumeErrorMessage && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    })
  }, [handleSubmit, onSubmit, valueVolumeErrorMessage])

  const handleComparabilityFactorsEditorSetup = (editor: RawEditor, index: number) => {
    const handleSave = () => {
      if (editor) {
        setValue(`comparabilityFactors.${index}.value`, editor.getContent())
      }
    }

    editor.on('blur', handleSave)
  }
  const isDirty = isDirtyForValueVolumeTable || htmlIsDirty
  const errorMessage = get(formState.errors, 'description')
  const isDescriptionErrored = !!errorMessage?.message && typeof errorMessage?.message == 'string'
  return (
    <Modal
      title={`${selectedTerm ? 'Edit' : 'Add New'} ${'Controlled Transaction'}`}
      isOpen={isOpen}
      onClose={closeModal}
      className="flex flex-col items-stretch w-full !text-start "
      containerClassName="w-full max-w-[1000px] p-6">
      <div className="relative w-full overflow-y-auto space-y-4" style={{ maxHeight: 'calc(100vh - 11rem)' }}>
        {loading && <Loading className="absolute inset-0 flex items-center justify-center bg-white z-20" />}
        <SelectDropDown
          control={control}
          disabled={!!selectedTerm}
          options={transactionOptions}
          isCustomFilter
          id="transaction"
          label="Transaction"
          required
        />
        <BorderlessBox className="gap-2" label="Comparability Factors">
          {comparabilityFactors?.length !== 0 ? (
            <div className="space-y-2 w-full">
              {comparabilityFactors?.map((factor, index) => {
                const id = 'comparabilityFactors.' + index + '.value'
                const errorMessage = get(formState.errors, id)
                const isErrored = !!errorMessage?.message && typeof errorMessage?.message == 'string'
                const value = factor.value
                return (
                  <BorderlessBoxAccordion
                    className="rounded-lg border border-solid !py-1.5 border-gray-200 "
                    variant="white"
                    isOpen={expandedFactor == factor.id}
                    onChange={() => setExpandedFactor(id => (id == factor.id ? undefined : factor.id))}
                    key={factor.id}
                    label={
                      <Typography
                        variant={Variant.Callout}
                        type="semibold"
                        className={cx(
                          'mr-auto flex items-center gap-1',
                          !isErrored ? 'text-gray-700' : 'text-red-600'
                        )}>
                        {factor.name}
                        {!!value && <GreenTick className="w-[14px] h-[14px]" />}
                      </Typography>
                    }>
                    <div className="doc-editor doc-editor-modal overflow-auto w-full my-2">
                      <TinyMceEditor
                        author={{
                          id: user?.id,
                          username: user?.username || 'unknown',
                          fullName: user
                            ? user.first_name || user.last_name
                              ? `${user.first_name} ${user.last_name}`
                              : user.username
                            : 'Unknown',
                          avatarUrl: user?.profile_photo || undefined,
                        }}
                        onSetup={editor => handleComparabilityFactorsEditorSetup(editor, index)}
                        height={'55vh'}
                        editorData={factor.value}
                        contentStyles={css}
                        leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
                        setIsDirty={dirtyHtml}
                      />
                    </div>
                  </BorderlessBoxAccordion>
                )
              })}
            </div>
          ) : (
            <NoContentContainer title="Please Add Comparability Factor(s)" className="!bg-white w-full" />
          )}
        </BorderlessBox>
        <BorderlessBoxAccordion
          className="gap-2"
          label={
            <Typography
              variant={Variant.Callout}
              type="semibold"
              className={`mr-auto mb-[0.125rem] mt-[-0.125rem] flex items-center ${
                !isDescriptionErrored ? 'text-gray-700' : 'text-red-600'
              }`}>
              <span className="my-1">Description</span>
            </Typography>
          }>
          <div className="doc-editor doc-editor-modal overflow-auto w-full">
            <TinyMceEditor
              author={{
                id: user?.id,
                username: user?.username || 'unknown',
                fullName: user
                  ? user.first_name || user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.username
                  : 'Unknown',
                avatarUrl: user?.profile_photo || undefined,
              }}
              onSetup={handleDescriptionEditorSetup}
              height={'55vh'}
              editorRef={editorRef}
              editorData={description || ''}
              contentStyles={css}
              leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
              setIsDirty={dirtyHtml}
            />
          </div>
        </BorderlessBoxAccordion>

        <BorderlessBox className="gap-2">
          <NewEditableTable setData={setValueVolumeTable} data={valueVolumeTable} columns={transactionTableColumns} />
          <Typography variant={Variant.Body} className="text-red-500  flex items-center">
            {valueVolumeErrorMessage && (
              <div ref={errorRef} className="mr-1">
                *
              </div>
            )}
            {valueVolumeErrorMessage}
          </Typography>
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-1">
              <Info className="h-4 w-4 text-orange-400" />
              <Typography variant={Variant.Callout} className="text-gray-600">
                Add more fields for volume and value
              </Typography>
            </div>
            <Button className="ml-auto" variant={ButtonVariant.Tertiary} icon={IconsType.plus} onClick={handleAddRow}>
              Add
            </Button>
          </div>
        </BorderlessBox>
      </div>
      {!selectedTerm ? (
        <Button onClick={onSave()} className="self-end" icon={IconsType.plus}>
          Add
        </Button>
      ) : (
        <Button onClick={onSave()} disabled={!isDirty} className="self-end" icon={IconsType.save}>
          Save
        </Button>
      )}
      <ConfirmationModal />
    </Modal>
  )
}

export default AddComparableControlledTransaction
