import axios from 'api'
import {
  Benchmark,
  BulkUpdateBenchmarkTransactionEntities,
  CreateBenchmark,
  ImportBenchmarksPayload,
  UpdateBenchmark,
  UpdateBenchmarkTransactionEntities,
} from 'api/benchmarks/types'
import { AxiosRequestConfig } from 'axios'
import { APIS } from 'constants/apis'
import { formatUrl } from 'utils/utils'

export const getAllBenchmarks = (year?: number) =>
  axios.get<Benchmark[]>(APIS.BENCHMARKS.LIST, { params: { year } }).then(response => response.data)

export const getBenchmarkById = (id: number | null) =>
  axios.get<Benchmark>(formatUrl(APIS.BENCHMARKS.ITEM, { id: id })).then(response => response.data)

export const getImportableBenchmarks = ({ fromYear, toYear }: { fromYear: number; toYear: number }) =>
  axios
    .get<Benchmark[]>(
      `/api/v1/org/{organization}/transaction/benchmarking/benchmarkings_to_import/?from_year=${fromYear}&to_year=${toYear}`
    )
    .then(response => response.data)

export const importBulkBenchmarks = (variables: ImportBenchmarksPayload) =>
  axios
    .post(`/api/v1/org/{organization}/transaction/benchmarking/import_benchmarkings/`, variables)
    .then(response => response.data)

export const updateBenchmark = (variables: CreateBenchmark | UpdateBenchmark) => {
  let method: 'post' | 'patch' = 'post'
  const url = formatUrl(APIS.BENCHMARKS.ITEM, { id: variables.id })
  if (variables.id) {
    method = 'patch'
  }
  const apiOptions: AxiosRequestConfig = { headers: {} }
  if (
    variables.accepted_rejection_matrix_file instanceof File ||
    variables.benchmark_studies_file instanceof File ||
    variables.combined_benchmarking_file instanceof File ||
    variables.company_info_file instanceof File
  ) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  return axios[method]<Benchmark>(url, variables, apiOptions).then(response => response.data)
}

export const deleteBenchmark = (id: number) =>
  axios.delete<Benchmark>(formatUrl(APIS.BENCHMARKS.ITEM, { id: id })).then(response => response.data)

export const updateBenchmarkTransactionEntities = (variables: UpdateBenchmarkTransactionEntities) =>
  axios
    .put(
      `/api/v1/org/{organization}/transaction/tested_party_benchmarking/add_benchmarking_to_tested_party/`,
      variables
    )
    .then(response => response.data)
export const bulkUpdateBenchmarkTransactionEntities = ({
  benchmarking,
  ...variables
}: BulkUpdateBenchmarkTransactionEntities) =>
  axios
    .patch(`/api/v1/org/{organization}/transaction/benchmarking/${benchmarking}/add_transactions/`, variables)
    .then(response => response.data)
