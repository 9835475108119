import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { Benchmark } from 'api/benchmarks/types'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import FlagReader from 'components/flagReader/FlagReader'
import Typography, { Variant } from 'components/typography'
import { complianceRegions } from 'organisms/benchmark/basicDetailsForm/constants/constants'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter } from 'utils/utils'
import BenchmarkNameCell from 'views/benchmarks/overview/components/benchmarkNameCell'

import { BenchMarkAnalysisTableData, BenchMarkAnalysisTableDataWithoutYears } from '../BenchMarkAnalysis'
import { TestedPartyBenchmarksTableData } from '../types'

export function filterBenchmarkDataByYear(
  data: BenchMarkAnalysisTableData[],
  year: number
): { [key: string]: string | null } {
  const yearStr = year.toString() as keyof BenchMarkAnalysisTableDataWithoutYears

  return data.reduce((filteredData: { [key: string]: string | null }, item: BenchMarkAnalysisTableData) => {
    if (item.hasOwnProperty(yearStr)) {
      filteredData[item.key as string] = item[yearStr] === '' ? null : (item[yearStr] as string)
    }
    return filteredData
  }, {})
}

export const getFilteredBenchmarkList = ({
  benchmarksList,
  comparableSearchValue,
  excludedBenchmarks,
}: {
  benchmarksList?: Benchmark[]
  comparableSearchValue: SelectOptions
  excludedBenchmarks?: number[]
}) =>
  benchmarksList?.filter(
    benchmark =>
      benchmark?.region === comparableSearchValue?.value &&
      (excludedBenchmarks?.length ? !excludedBenchmarks.includes(benchmark.id) : true)
  )

export const getBenchMarkOptionsWithYears = (data: Benchmark[]): SelectOptions[] => {
  return data.map(item => ({
    label: (
      <>
        {item.name}
        <span className="text-neutral-400 text-xs	font-semibold	ml-2	">
          ({item.from_year}-{item.to_year})
        </span>
      </>
    ),
    value: item.id,
    filtertext: `${item.name} ${item.from_year} ${item.to_year}`,
  }))
}

export const getTestedPartBenchmarksColumn = ({
  editBenchmarkEntity,
  handleDelete,
  handleLEClick,
  openPreviewModal,
  readonly,
}: {
  editBenchmarkEntity?: (row: TestedPartyBenchmarksTableData) => () => void
  handleDelete?: (row: TestedPartyBenchmarksTableData) => () => Promise<void>
  handleLEClick: (id: number) => () => void
  readonly?: boolean
  openPreviewModal?: (data: TestedPartyBenchmarksTableData) => void
}): ColumnDef<TestedPartyBenchmarksTableData>[] => {
  const columns: ColumnDef<TestedPartyBenchmarksTableData>[] = [
    {
      header: 'Benchmarking Name',
      accessorFn: row => row.benchmarkingData?.name || `Benchmark ${row.benchmarkId}`,
      sortingFn: 'alphanumeric',
      cell(props) {
        const benchmarkdata = props.row.original.benchmarkingData

        if (benchmarkdata) {
          return <BenchmarkNameCell data={benchmarkdata} />
        }

        return (
          <Typography variant={Variant.Callout} type="semibold" className="text-gray-700">
            {String(props.getValue() || '')}
          </Typography>
        )
      },
    },
    {
      header: 'Legal Entity',
      cell(props) {
        const entities = props.row.original.legalEntites

        return (
          <>
            <div className="text-start overflow-hidden text-ellipsis flex flex-wrap">
              {!!entities.length && entities.length <= 3 ? (
                entities.map(
                  (entity, entityIdx) =>
                    entity && (
                      <Typography
                        key={`selectedAttribute-${entity.id}`}
                        variant={Variant.Callout}
                        onClick={handleLEClick(entity.id)}
                        className="flex whitespace-nowrap text-blue-800 cursor-pointer">
                        {!!entityIdx && <span className="mr-2">,</span>}
                        <FlagReader
                          country={entity.country}
                          otherText={capitalizeFirstLetter(entity.name_abbreviation || entity.name)}
                        />
                      </Typography>
                    )
                )
              ) : (
                <>
                  {entities.map(
                    (entity, entityIdx) =>
                      entity &&
                      entityIdx < 3 && (
                        <Typography
                          key={`selectedAttribute-${entity.id}`}
                          variant={Variant.Callout}
                          onClick={handleLEClick(entity.id)}
                          className="text-blue-800 cursor-pointer flex whitespace-nowrap">
                          {!!entityIdx && <span className="mr-2">,</span>}
                          <FlagReader
                            country={entity.country}
                            otherText={capitalizeFirstLetter(entity.name_abbreviation || entity.name)}
                          />
                        </Typography>
                      )
                  )}
                  {
                    <Tooltip
                      title={
                        <>
                          {entities.map(
                            (entity, entityIdx) =>
                              entity &&
                              entityIdx >= 3 && (
                                <Typography
                                  key={`relatedParty-${entityIdx}`}
                                  variant={Variant.Callout}
                                  type="semibold"
                                  onClick={handleLEClick(entity.id)}
                                  className="text-blue-300 cursor-pointer max-w-[12.0625rem] truncate flex">
                                  <FlagReader
                                    country={entity.country}
                                    otherText={capitalizeFirstLetter(entity.name_abbreviation || entity.name)}
                                  />
                                </Typography>
                              )
                          )}
                        </>
                      }
                      placement="top">
                      <span className="flex items-center ml-2">
                        {entities.length ? (
                          <span className="bg-blue-100 rounded-[0.25rem] p-[0.25rem] text-blue-800 text-footnote">
                            +{entities.length - 3}
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </Tooltip>
                  }
                </>
              )}
            </div>
          </>
        )
      },
    },
    {
      header: 'Region of the Comparable Search',
      sortingFn: 'alphanumeric',
      accessorFn: row => {
        return row.benchmarkingData?.region
          ? complianceRegions.find(region => region.code === String(row.benchmarkingData?.region))?.label ||
              row.benchmarkingData.region.replace(/_/g, ' ').trim().toUpperCase()
          : ''
      },
      cell(props) {
        return (
          <Typography variant={Variant.Callout} type="semibold" className="text-gray-700">
            {String(props.getValue() || '--')}
          </Typography>
        )
      },
    },
    {
      id: 'actions',
      header: () => <div className="text-center grow">Action</div>,
      accessorFn: data => data.benchmarkId,
      cell(props) {
        if (!readonly) {
          return (
            <span className="flex gap-6 justify-center items-center">
              <Button
                onClick={editBenchmarkEntity && editBenchmarkEntity(props.row.original)}
                variant={ButtonVariant.Tertiary}>
                Edit
              </Button>
              <Button
                className="!h-4"
                isDefaultSize={false}
                onClick={handleDelete && handleDelete(props.row.original)}
                variant={ButtonVariant.Tertiary}
                iconCLass="w-4 h-4"
                iconPathClassName="stroke-orange-600 "
                icon={IconsType.delete}></Button>
            </span>
          )
        }
        if (props.row.original.benchmarkingData) {
          return (
            <span className="flex gap-6 justify-center items-center">
              <Typography
                variant={Variant.Callout}
                type="semibold"
                onClick={() => openPreviewModal && openPreviewModal(props.row.original)}
                className="max-w-[10rem] text-blue-800 text-center break-words cursor-pointer">
                View
              </Typography>
            </span>
          )
        }
      },
    },
  ]

  return columns
}
