import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { updateBenchmark } from 'api/benchmarks'
import { Benchmark, CreateBenchmark } from 'api/benchmarks/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Modal from 'components/modal/Modal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import BasicDetailsForm from 'organisms/benchmark/basicDetailsForm'
import { CHARACTER_LIMITS, FIELDS_IDS } from 'organisms/benchmark/basicDetailsForm/constants/constants'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { requiredDropdownOptionSchema } from 'schemas'
import { getCharacterLimitArgs } from 'schemas/schemas.helpers'
import { getToastErrorMessage } from 'utils/utils'
import * as yup from 'yup'

import { ADD_BENCHAMRK_FORM_FIELDS } from './types'

const AddBenchmarkModal = ({
  isAddBenchmarkModal,
  setAddBenchmarkModal,
  handleBenchmarkAddSuccess,
  year,
}: Props): JSX.Element => {
  const { refetchChecklist } = useReportWizardContext()
  const { control, handleSubmit, reset, setValue, watch } = useForm<ADD_BENCHAMRK_FORM_FIELDS>({
    defaultValues: {
      from_year: (year || new Date().getFullYear()) - 4,
      to_year: year || new Date().getFullYear(),
    },
    resolver: yupResolver(
      yup.object({
        [FIELDS_IDS.NAME]: yup
          .string()
          .required('Name is required field')
          .max(...getCharacterLimitArgs(CHARACTER_LIMITS[FIELDS_IDS.NAME], 'Name')),
        [FIELDS_IDS.REGION]: requiredDropdownOptionSchema,
      })
    ),
    mode: 'onBlur',
  })

  const handleModalClose = useCallback(() => {
    setAddBenchmarkModal(false)
  }, [setAddBenchmarkModal])

  const handleCreateBenchmarkMutation = useMutation(updateBenchmark)

  const onSubmit = useCallback(
    async (data: ADD_BENCHAMRK_FORM_FIELDS) => {
      const benchmarkData: CreateBenchmark = {
        name: data.name,
        year: year,
        region: String(data.region?.value) || '',
        quarterly_analysis: JSON.stringify(data.quarterlyAnalysis),
        from_year: data.from_year,
        to_year: data.to_year,
        company_info_file: data.company_info_file instanceof File ? data.company_info_file : undefined,
        company_info_file_name:
          data.company_info_file_name ||
          (data.company_info_file instanceof File ? data.company_info_file.name : '') ||
          '',
        accepted_rejection_matrix_file:
          data.accepted_rejection_matrix_file instanceof File ? data.accepted_rejection_matrix_file : undefined,
        accepted_rejection_matrix_file_name:
          data.accepted_rejection_matrix_file_name ||
          (data.accepted_rejection_matrix_file instanceof File ? data.accepted_rejection_matrix_file.name : '') ||
          '',
        benchmark_studies_file: data.benchmark_studies_file instanceof File ? data.benchmark_studies_file : undefined,
        benchmark_studies_file_name:
          data.benchmark_studies_file_name ||
          (data.benchmark_studies_file instanceof File ? data.benchmark_studies_file.name : '') ||
          '',
        combined_benchmarking_file:
          data.combined_benchmarking_file instanceof File ? data.combined_benchmarking_file : undefined,
        combined_benchmarking_file_name:
          data.combined_benchmarking_file_name ||
          (data.combined_benchmarking_file instanceof File ? data.combined_benchmarking_file.name : '') ||
          '',
      }
      try {
        const benchmark = await handleCreateBenchmarkMutation?.mutateAsync(benchmarkData)
        toast.success('Benchmark successfully created', {
          autoClose: 3000,
        })
        refetchChecklist()
        setAddBenchmarkModal(false)
        handleBenchmarkAddSuccess(benchmark)
      } catch (err: unknown | AxiosError) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Checking if AxiosError
        if (err?.name == 'AxiosError') {
          getToastErrorMessage(err as AxiosError)
        } else {
          toast.error('Failed to create benchmark')
        }
      }
    },
    [handleBenchmarkAddSuccess, handleCreateBenchmarkMutation, refetchChecklist, setAddBenchmarkModal, year]
  )

  return (
    <Modal
      containerClassName=" max-w-[100vw] p-8"
      title="Add/Edit Benchmark"
      onClose={handleModalClose}
      isOpen={isAddBenchmarkModal}>
      <BasicDetailsForm
        onSubmit={onSubmit}
        control={control}
        handleSubmit={handleSubmit}
        reset={reset}
        setValue={setValue}
        watch={watch}
        benchmarkId={undefined}
        buttonText="Save"
        icon={IconsType.save}
      />
    </Modal>
  )
}

interface Props {
  isAddBenchmarkModal: boolean
  setAddBenchmarkModal: React.Dispatch<React.SetStateAction<boolean>>
  year: number
  handleBenchmarkAddSuccess: (benchmark: Benchmark) => void
}

export default AddBenchmarkModal
