import { IconProps } from 'assets'

const Exclamation = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="exclamation">
        <path
          className={pathClassName}
          id="Vector"
          d="M11.9996 22.5246C11.7329 22.5246 11.4788 22.4746 11.2371 22.3746C10.9954 22.2746 10.7746 22.1329 10.5746 21.9496L2.54961 13.9246C2.36628 13.7246 2.22461 13.5038 2.12461 13.2621C2.02461 13.0204 1.97461 12.7663 1.97461 12.4996C1.97461 12.2329 2.02461 11.9746 2.12461 11.7246C2.22461 11.4746 2.36628 11.2579 2.54961 11.0746L10.5746 3.04961C10.7746 2.84961 10.9954 2.70378 11.2371 2.61211C11.4788 2.52044 11.7329 2.47461 11.9996 2.47461C12.2663 2.47461 12.5246 2.52044 12.7746 2.61211C13.0246 2.70378 13.2413 2.84961 13.4246 3.04961L21.4496 11.0746C21.6496 11.2579 21.7954 11.4746 21.8871 11.7246C21.9788 11.9746 22.0246 12.2329 22.0246 12.4996C22.0246 12.7663 21.9788 13.0204 21.8871 13.2621C21.7954 13.5038 21.6496 13.7246 21.4496 13.9246L13.4246 21.9496C13.2413 22.1329 13.0246 22.2746 12.7746 22.3746C12.5246 22.4746 12.2663 22.5246 11.9996 22.5246ZM11.9996 20.5246L20.0246 12.4996L11.9996 4.47461L3.97461 12.4996L11.9996 20.5246ZM10.9996 13.4996H12.9996V7.49961H10.9996V13.4996ZM11.9996 16.4996C12.2829 16.4996 12.5204 16.4038 12.7121 16.2121C12.9038 16.0204 12.9996 15.7829 12.9996 15.4996C12.9996 15.2163 12.9038 14.9788 12.7121 14.7871C12.5204 14.5954 12.2829 14.4996 11.9996 14.4996C11.7163 14.4996 11.4788 14.5954 11.2871 14.7871C11.0954 14.9788 10.9996 15.2163 10.9996 15.4996C10.9996 15.7829 11.0954 16.0204 11.2871 16.2121C11.4788 16.4038 11.7163 16.4996 11.9996 16.4996Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Exclamation
