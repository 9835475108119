import axios from '../index'
import {
  createMasterFileSectionPayload,
  MasterFileAppendices,
  MasterFileDocument,
  MasterFileSectionType,
} from './types'

export const getMasterFileSections = (year?: number) => {
  return axios
    .get<MasterFileSectionType[]>(`/api/v1/org/{organization}/organization/master_file_section/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}
export const getMasterFileList = (year?: number) => {
  return axios
    .get<MasterFileDocument[]>(`/api/v1/org/{organization}/reports/master_file_document/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}
export const getMasterFileByID = (id: number) => {
  return axios
    .get<MasterFileDocument>(`/api/v1/org/{organization}/reports/master_file_document/${id}`, {})
    .then(res => res.data)
}

export const createMasterFile = ({ year, master_file_id }: { year: number; master_file_id?: number }) => {
  return axios
    .get<MasterFileDocument>(`/api/v1/org/{organization}/reports/master_file_document/download_master_file/`, {
      params: {
        year: year,
        master_file: master_file_id,
      },
    })
    .then(res => res.data)
}

export const masterFileSectionsOrder = ({
  master_file_sections,
  id,
}: {
  master_file_sections: number[]
  id: number
}) => {
  return axios
    .put<MasterFileDocument[]>(
      `/api/v1/org/{organization}/reports/master_file_document/${id}/update_master_file_sections_order/`,
      {
        master_file_sections: master_file_sections,
      }
    )
    .then(res => res.data)
}
export const appendicesBulkUpload = ({
  payload,
}: {
  payload: {
    files: File[]
    year: number
    file_data: { name: string }[]
    existing_appendices_data: { id?: number; name: string }[]
  }
}) => {
  const { year, files, file_data, existing_appendices_data } = payload

  const formData = new FormData()
  formData.append('file_data', JSON.stringify(file_data))
  formData.append('existing_appendices_data', JSON.stringify(existing_appendices_data))
  files.forEach(file => {
    formData.append('files', file)
  })
  formData.append('year', JSON.stringify(year))

  return axios
    .put<MasterFileDocument[]>(
      `/api/v1/org/{organization}/organization/master_file_appendix/bulk_create_or_update/`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    .then(res => res.data)
}
export const getAppendicesList = ({
  year,
  master_file_section__isnull = false,
}: {
  year?: number
  master_file_section__isnull?: boolean
}) => {
  return axios
    .get<MasterFileAppendices[]>(`/api/v1/org/{organization}/organization/master_file_appendix/`, {
      params: {
        year: year,
        master_file_section__isnull: master_file_section__isnull,
      },
    })
    .then(res => res.data)
}
export const createMasterFileSection = (payload: createMasterFileSectionPayload) => {
  const { name, description, year, files, file_data } = payload
  const formData = new FormData()

  formData.append('name', name.toString())
  formData.append('description', description || '')
  formData.append('year', year.toString())
  formData.append('file_data', JSON.stringify(file_data))
  files.forEach(file => {
    formData.append('files', file)
  })
  return axios
    .postForm<MasterFileSectionType>(`/api/v1/org/{organization}/organization/master_file_section/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}
export const updateMasterFileSection = ({
  id,
  ...payload
}: Partial<createMasterFileSectionPayload> & { id: number }) => {
  const { name, description, files, existing_appendices_data, file_data } = payload
  const formData = new FormData()

  formData.append('name', name || '')
  formData.append('description', description || '')
  formData.append('existing_appendices_data', JSON.stringify(existing_appendices_data))
  formData.append('file_data', JSON.stringify(file_data))
  if (files) {
    files.forEach(file => {
      formData.append('files', file)
    })
  }
  return axios
    .patchForm<MasterFileSectionType>(`/api/v1/org/{organization}/organization/master_file_section/${id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

export const deleteMasterFileSection = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/organization/master_file_section/${id}`).then(res => res.data)
}
export const reorderMasterFileAppendices = ({
  master_file_appendices,
  id,
}: {
  master_file_appendices: number[]
  id: number
}) => {
  return axios
    .put(`/api/v1/org/{organization}/reports/master_file_document/${id}/update_master_file_appendices_order/`, {
      master_file_appendices,
    })
    .then(res => res.data)
}

export const updateMasterFileHtml = ({ html_file, id }: { html_file: File; id: number }) => {
  return axios
    .patchForm(`/api/v1/org/{organization}/reports/master_file_document/${id}/update_master_file_html/`, { html_file })
    .then(res => res.data)
}

export const signOffMasterFile = ({ id }: { id: number }) => {
  return axios
    .patch(`/api/v1/org/{organization}/reports/master_file_document/${id}/sign_off_master_file/`)
    .then(res => res.data)
}

export const deleteMasterFile = ({ id }: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/reports/master_file_document/${id}/`).then(res => res.data)
}
export const archiveMasterFile = ({ id }: { id: number }) => {
  return axios.put(`/api/v1/org/{organization}/reports/master_file_document/${id}/archive/`).then(res => res.data)
}

export const updateMasterFile = ({ id, name }: { id: number; name: string }) => {
  return axios.patch(`/api/v1/org/{organization}/reports/master_file_document/${id}/`, { name }).then(res => res.data)
}

export const importMasterFileSections = (payload: { year: number; master_file_sections: number[] }) => {
  return axios
    .post<MasterFileSectionType>(
      `/api/v1/org/{organization}/organization/master_file_section/import_master_file_sections/`,
      payload
    )
    .then(res => res.data)
}
