import { IconProps } from 'assets'
import React from 'react'

const CloseIntegralLogo = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="52"
      height="37"
      viewBox="0 0 52 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1000004150">
        <g id="Group 1686552443">
          <path
            className={pathClassName}
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.02675 21.2736C7.4647 19.7116 7.4647 17.179 9.02675 15.617L19.6332 5.01053C20.0236 4.62013 20.6566 4.62013 21.047 5.01053C21.4374 5.40094 22.0703 5.40094 22.4607 5.01053L23.1684 4.30286C23.9492 3.52206 23.9492 2.25612 23.1684 1.47531C21.6064 -0.0867324 19.0738 -0.086732 17.5117 1.47531L3.37008 15.617C1.80804 17.179 1.80804 19.7116 3.37008 21.2736L17.5117 35.4153C19.0738 36.9774 21.6064 36.9774 23.1684 35.4153L37.3101 21.2736C38.8721 19.7116 38.8721 17.179 37.3101 15.617L31.653 9.95992C30.872 9.1789 29.6057 9.1789 28.8247 9.95992C28.0437 10.7409 28.0437 12.0072 28.8247 12.7883L31.6534 15.617C33.2155 17.179 33.2155 19.7116 31.6534 21.2736L23.1684 29.7586C21.6064 31.3207 19.0738 31.3207 17.5117 29.7586L9.02675 21.2736Z"
            fill="#683CD0"
          />
          <path
            className={pathClassName}
            id="Subtract_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.9669 15.617C44.529 17.179 44.5289 19.7116 42.9669 21.2736L32.3608 31.8797C31.9702 32.2703 31.3369 32.2703 30.9463 31.8797C30.5557 31.4891 29.9225 31.4891 29.5319 31.8797L28.8249 32.5867C28.0439 33.3677 28.0442 34.6343 28.8252 35.4153C30.3873 36.9774 32.9199 36.9774 34.4819 35.4153L48.6236 21.2736C50.1856 19.7116 50.1856 17.179 48.6236 15.617L34.4819 1.47531C32.9199 -0.0867336 30.3873 -0.0867334 28.8252 1.47531L14.6836 15.617C13.1215 17.179 13.1215 19.7116 14.6836 21.2736L20.3399 26.93C21.121 27.711 22.3872 27.711 23.1683 26.93C23.9493 26.149 23.9493 24.8827 23.1683 24.1017L20.3402 21.2736C18.7782 19.7116 18.7782 17.179 20.3402 15.617L28.8252 7.13198C30.3873 5.56993 32.9199 5.56993 34.4819 7.13198L42.9669 15.617Z"
            fill="#A033E9"
          />
        </g>
      </g>
    </svg>
  )
}

export default CloseIntegralLogo
