import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'

import { getDataByYear, getYearRange, mappedYearsFinancialStructureData } from '../helpers/financialData.helpers'
import { FinancialDataTableData } from '../types/financialData.types'

export const balanceSheetConfig = ({
  balanceSheet,
  startYear,
  endYear,
  pliMethod,
}: balanceSheetConfigProps): FinancialDataTableData[] => {
  const dataByYear = getDataByYear(balanceSheet || [])

  if (!pliMethod) return []

  const years = getYearRange(startYear - 1, endYear).map(year => mappedYearsFinancialStructureData(dataByYear, year))

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    required: boolean,
    formula?: string,
    ...values: (number | null | string | undefined)[]
  ): FinancialDataTableData => {
    const data = {
      name,
      ...Object.fromEntries(years.map((year, index) => [year?.year?.toString(), String(values?.[index] ?? '')])),
      isEditable: !is_calculated,
      formula,
      isEditAllowed: true,
      key,
      required: required && !is_calculated,
    }
    return data
  }

  const tableData: FinancialDataTableData[] = [
    ...pliMethod.required_fields.map(field => {
      return {
        ...field,
        _requiredField: true,
      }
    }),
    ...pliMethod.optional_fields.map(field => {
      return {
        ...field,
        _requiredField: false,
      }
    }),
  ]
    .filter(field => field.type == 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        field._requiredField,
        pliMethod.formulae[field.name],
        ...years.map(year => year?.[field.name])
      )
    })

  return tableData
}

export const balanceSheetAvgConfig = ({
  balanceSheetAverages,
  startYear,
  endYear,
  pliMethod,
}: balanceSheetAvgConfigProps): FinancialDataTableData[] => {
  if (!balanceSheetAverages?.length) return []
  const dataByYear = getDataByYear(balanceSheetAverages)
  if (!pliMethod) return []
  const years = getYearRange(startYear, endYear).map(year => mappedYearsFinancialStructureData(dataByYear, year))

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    formula?: string,
    ...values: (number | null | string | undefined)[]
  ): FinancialDataTableData => {
    const data = {
      name,
      ...Object.fromEntries(years.map((year, index) => [year?.year?.toString(), String(values?.[index] ?? '')])),
      isEditable: !is_calculated,
      formula,
      isEditAllowed: false,
      key,
    }
    return data
  }

  const tableData: FinancialDataTableData[] = [...pliMethod.required_fields, ...pliMethod.optional_fields]
    .filter(field => field.type == 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        pliMethod.formulae[field.name],
        ...years.map(year => year?.[field.name])
      )
    })

  return tableData
}

interface balanceSheetConfigProps {
  balanceSheet?: {
    [key: string]: number | null | string
  }[]
  startYear: number
  endYear: number
  pliMethod: TransactionPliMethod | undefined
}

interface balanceSheetAvgConfigProps {
  balanceSheetAverages?: { [key: string]: number | null | string }[]
  startYear: number
  endYear: number
  pliMethod: TransactionPliMethod | undefined
}
