import cx from 'classnames'
import { IconPlacement } from 'components/button/button.types'

import { InputVariant, InputVariantConfig } from './input.types'

export const baseClass = [
  'border border-solid p-3',
  'rounded-xl flex items-center gap-1',
  'focus:bg-blue50 focus:border-blue800',
]

export const inputClass = (disabled: boolean, darkMode?: boolean) =>
  cx(
    `border-none block w-full bg-transparent border-gray-300 focus:outline-none focus:ring-0
    peer appearance-none text-[0.8125rem] placeholder:text-gray-400`,
    {
      'text-gray-400 cursor-not-allowed': disabled,
      'bg-[#161532] text-gray-300': darkMode && !disabled,
      'bg-transparent text-slate-800': !darkMode && !disabled,
    }
  )

const successInputConfig: InputVariantConfig = {
  background: 'bg-green50',
  border: 'border-green500',
}

const errorInputConfig: InputVariantConfig = {
  background: 'bg-red50',
  border: 'border-red500',
}

const defaultInputConfig: InputVariantConfig = {
  background: 'bg-white',
  border: 'border-neutral300',
}

const disabledInputConfig: InputVariantConfig = {
  background: 'bg-white text-grey400',
  border: 'border-grey400',
}
const darkInputConfig: InputVariantConfig = {
  background: 'bg-[#161532]',
  border: 'border-gray-800',
}

export const iconPlacementConfig = {
  [IconPlacement.Left]: 'flex-row',
  [IconPlacement.Right]: 'flex-row-reverse',
}

export const descriptiveTextConfig = {
  [InputVariant.Success]: 'text-green500',
  [InputVariant.Error]: 'text-red500',
  [InputVariant.Default]: 'text-neutral800',
  [InputVariant.Disabled]: 'text-grey400',
  [InputVariant.Dark]: 'text-gray-300',
}

export const variants: Record<InputVariant, InputVariantConfig> = {
  [InputVariant.Success]: successInputConfig,
  [InputVariant.Error]: errorInputConfig,
  [InputVariant.Default]: defaultInputConfig,
  [InputVariant.Disabled]: disabledInputConfig,
  [InputVariant.Dark]: darkInputConfig,
}
