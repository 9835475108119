import AppColors from 'constants/colors'
import { StylesConfig } from 'react-select'
import colors from 'tailwindcss/colors'
import { SelectOptions } from 'types/common.types'

export const selectStyle = ({
  height,
  isError,
  disableDropdown,
}: {
  height?: string
  isError: boolean
  disableDropdown: boolean
}): StylesConfig<SelectOptions, true> => {
  return {
    control: base => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.875rem',
        fontWeight: 400,
        color: '#1E293B',
        border: `1px solid ${isError ? '#F87171' : '#E5E7EB'}`,
        borderRadius: '0.75rem',
        height,
        backgroundColor: isError ? AppColors.red50 : disableDropdown ? colors.gray[50] : 'white',
      }
    },
    placeholder: base => {
      return {
        ...base,
        color: AppColors.gray['400'],
      }
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#F1F5F9' : 'white',
        color: '#1E293B',
        overflow: 'hidden',
        ':hover': {
          backgroundColor: '#F1F5F9',
          cursor: 'pointer',
        },
        ':active': {
          backgroundColor: '#F1F5F9',
        },
      }
    },
    menu: base => {
      return {
        ...base,
        borderRadius: '0.75rem',
        marginTop: '0.25rem',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        border: `1px solid #CBD5E1`,
        padding: '0.75rem',
        zIndex: 2000,
      }
    },
    menuPortal(base) {
      return { ...base, zIndex: 2000 }
    },
    menuList: base => {
      return {
        ...base,
        maxHeight: 250,
      }
    },
    multiValue: base => {
      return {
        ...base,
        borderRadius: 30,
      }
    },
    dropdownIndicator: base => {
      return {
        ...base,
        display: disableDropdown ? 'none' : base.display,
        height: '18px',
        width: '18px',
        padding: '0px',
        marginRight: '10px',
      }
    },
    singleValue(base) {
      return {
        ...base,
        color: disableDropdown ? '#333' : base.color,
      }
    },
  }
}
