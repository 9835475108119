import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SelectOptions } from 'types/common.types'

import DropDown from './dropDown'

const CustomDropDown = ({
  value,
  disabled = false,
  options,
  icon,
  onChange,
  className,
  dropDownClassname,
}: CustomDropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const handleDropDownTrigger = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      event.stopPropagation()
    }
    setIsOpen(prevState => !prevState)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const handleSelectedValue = useCallback(
    (value: string) => () => {
      onChange && onChange(value)
    },
    [onChange]
  )

  return (
    <div onClick={handleDropDownTrigger} className="relative w-full cursor-pointer" ref={wrapperRef}>
      <div
        className={cx(
          'flex items-center w-fit px-3 py-[0.375rem] rounded-lg h-8',
          disabled && 'bg-gray-200 hover:cursor-not-allowed',
          className
        )}>
        <div className="flex items-center gap-3 mr-5">
          {icon && getIcons(icon, { className: 'w-[1.125rem] h-[1.125rem]' })}
          <Typography variant={Variant.Callout} className="text-gray-900">
            {value}
          </Typography>
        </div>
        <div className="flex justify-end w-full">
          <ExpandMoreIcon className={`text-gray-400 w-[1.125rem] h-[1.125rem] ${isOpen ? 'rotate-180' : ''} `} />
        </div>
      </div>

      {isOpen && <DropDown handleSelectedValue={handleSelectedValue} options={options} className={dropDownClassname} />}
    </div>
  )
}

interface CustomDropDownProps {
  value: string | undefined
  options: SelectOptions[]
  icon?: IconsType
  disabled?: boolean
  onChange?: (value: string) => void
  className?: string
  dropDownClassname?: string
}

export default CustomDropDown
