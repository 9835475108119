import { TemplateVariableResponse } from 'api/templates/types'
import React, { ReactNode } from 'react'
import colors from 'tailwindcss/colors'

export const camelToKebabCase = (str: string) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

/**
 * Process HTML content to remove empty footnotes and merge adjacent footnotes.
 * @param {string} html - The HTML content to be processed.
 * @returns {string} - The processed HTML content.
 */
export const cleanupHTML = (html: string, keepFontFamily?: boolean): string => {
  if (!html) return html
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // Remove empty footnotes
  tempDiv.querySelectorAll('.footnote').forEach(footnote => {
    if (!(footnote?.textContent?.trim && footnote.textContent.trim())) {
      footnote.remove()
    }
  })

  // Merge adjacent footnotes
  const footnotes = tempDiv.querySelectorAll('.footnote')
  footnotes.forEach((footnote, index) => {
    if (index < footnotes.length - 1 && footnote.nextSibling === footnotes[index + 1]) {
      const combinedText = `${footnote?.textContent?.trim() || ''} ${footnotes[index + 1]?.textContent?.trim() || ''}`
      footnote.textContent = combinedText
      footnotes[index + 1].remove()
    }
  })
  let processedHTML = tempDiv.innerHTML
  if (!keepFontFamily) {
    processedHTML = processedHTML.replace(/font-family\s*:\s*[^;]+;/gi, '')
  }
  tempDiv.remove()

  return processedHTML
}

export const addStringAsABlob = (
  content: string,
  fileName?: string,
  srcType = 'text/plain',
  targetType = 'text/html'
) => {
  const fName = fileName || 'edited-template.html'
  const blob = new Blob([content], { type: srcType })
  const file = new File([blob], fName, { type: targetType })

  return file
}

export function generateAvatarColor(input: string): string {
  const hRange = [0, 360]
  const sRange = [50, 75]
  const lRange = [25, 60]

  // Simple hash function to generate a consistent number from a string
  function hashString(str: string): number {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash = hash & hash // Convert to 32bit integer
    }
    return Math.abs(hash)
  }

  // Get a consistent number from the string input
  const hash = hashString(input)

  // Generate HSL values within the specified ranges
  const h = hRange[0] + (hash % (hRange[1] - hRange[0]))
  const s = sRange[0] + (hash % (sRange[1] - sRange[0]))
  const l = lRange[0] + (hash % (lRange[1] - lRange[0]))

  // Return the HSL color string
  return `hsl(${h}, ${s}%, ${l}%)`
}

export function getTextFromReactNode(node: ReactNode): string {
  if (!node) return ''

  return React.Children.toArray(node)
    .map(child => {
      // Handle text nodes directly
      if (typeof child === 'string' || typeof child === 'number') {
        return child
      }

      // Handle nested children recursively
      if (React.isValidElement(child)) {
        return getTextFromReactNode(child.props.children)
      }

      return ''
    })
    .join('')
}

export function snakeCaseToCapitalisedWithSpace(snakeCase?: string): string {
  if (!snakeCase?.trim()) return ''

  return snakeCase
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function getConfidenceColor(value: number, threshold: number): string {
  if (typeof value == 'number' && value >= 0 && value <= 100) {
    if (value < threshold) {
      return colors.red[500]
    } else if (value > threshold) {
      return colors.green[600]
    } else {
      return colors.yellow[500]
    }
  }
  return ''
}

export const replaceTemplateVariables = (text: string, templateVariables?: TemplateVariableResponse[]): string => {
  if (!text || !templateVariables?.length) return text

  // Create a map of variable names to their computed values for faster lookup
  const variableMap = new Map(templateVariables.map(variable => [variable.name, variable.computed_value]))

  // Create a temporary div to parse HTML
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = text

  // Find all spans with data-variable-name attribute
  const variableSpans = tempDiv.querySelectorAll('span[data-variable-name]')

  // Replace each span's content with the computed value
  variableSpans.forEach(span => {
    const variableName = span.getAttribute('data-variable-name')
    if (variableName && variableMap.has(variableName) && variableMap.get(variableName)) {
      span.textContent = variableMap.get(variableName) || ''
    }
  })
  return tempDiv.innerHTML
}
