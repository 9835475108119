/* eslint-disable @typescript-eslint/no-explicit-any */

import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import _isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'
import { SelectOptions } from 'types/common.types'

import { SelectMenuButtonProps } from '../selectDropDown/SelectDropDown'
import { CustomMenuRenderer } from './components/customMenuRenderer/CustomMenuRenderer'
import { SelectCheckboxMultiValueContainer } from './components/selectCheckboxMultiValueContainer/SelectCheckboxMultiValueContainer'
import { SelectCheckboxOptions } from './components/selectCheckboxOptions/SelectCheckboxOptions'
import { SelectCheckboxValueContainer } from './components/selectCheckboxValueContainer/SelectCheckboxValueContainer'
import { selectStyle } from './styles'

type SelectCheckboxContextType = {
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  menuOpen?: boolean
  selectMenuButtonProps?: SelectMenuButtonProps
  showSelectMenuButton?: boolean
}
export const SelectCheckboxContext = React.createContext<SelectCheckboxContextType>({})

function SelectDropDownWithCheckBox<T>({
  control,
  options,
  setSelectedOptions,
  id,
  label,
  hide,
  defaultValue,
  disabled,
  required,
  selectMenuButtonProps,
  showSelectMenuButton,
  className,
  height,
  placeholder = 'Select the options',
  isCustomFilter = false,
  menuPortalTarget,
  addSelectAllOption = false,
  menuPlacement = 'auto',
}: SelectDropDownWithCheckBoxProps<T & FieldValues>) {
  const customFilter = useCallback(({ data }: { data: SelectOptions }, input: string) => {
    if (input) {
      if (data.filtertext) {
        if (data.filtertext.toLowerCase().includes(input.toLowerCase())) return true
      }
      return false
    }
    return true // if not search, then all match
  }, [])

  const [menuOpen, setMenuOpen] = useState(false)
  const filter = useMemo(() => {
    return isCustomFilter ? customFilter : undefined
  }, [customFilter, isCustomFilter])

  const menuRef = useRef<HTMLDivElement>(null)
  const modifiedOptions = useMemo(() => {
    return options && options?.length > 0
      ? addSelectAllOption
        ? [{ value: 'select-all', label: 'Select All' }, ...options]
        : options
      : []
  }, [addSelectAllOption, options])

  const handleOutsideClicks = useCallback(
    (event: MouseEvent) => {
      if (event.target && menuOpen && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false)
      }
    },
    [menuOpen]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClicks)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClicks)
    }
  }, [handleOutsideClicks])
  if (hide) return null
  return (
    <Controller
      name={id as string}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { name, onBlur, onChange, ref, value } = field

        const newValue = value?.map((opt: SelectOptions) => options?.find(option => option.value == opt.value) || opt)
        const selectedValues = value?.map((opt: SelectOptions) => opt.value)
        const isAllSelected = options && options.length > 0 && options.every(opt => selectedValues?.includes(opt.value))

        const handleSelection = (selected: MultiValue<SelectOptions>) => {
          const selectedArray = Array.from(selected)
          const hasSelectAll = selectedArray.some(opt => opt.value === 'select-all')

          if (hasSelectAll) {
            if (isAllSelected) {
              onChange([])
              setSelectedOptions?.([])
            } else {
              onChange(options)
              options && setSelectedOptions?.(options)
            }
          } else {
            onChange(selectedArray)
            setSelectedOptions?.(selectedArray)
          }
        }

        return (
          <div ref={menuRef} className={cx('w-full max-w-full', className)}>
            {label && (
              <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <SelectCheckboxContext.Provider
              value={{ setMenuOpen, menuOpen, selectMenuButtonProps, showSelectMenuButton }}>
              <Select
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                filterOption={filter}
                placeholder={placeholder}
                name={name}
                ref={ref}
                onBlur={onBlur}
                isClearable={false}
                isDisabled={disabled}
                defaultValue={defaultValue}
                menuShouldScrollIntoView
                isMulti
                menuIsOpen={menuOpen}
                menuPlacement={menuPlacement}
                menuPortalTarget={menuPortalTarget}
                value={newValue}
                styles={selectStyle({ height, isError: !!(error && !_isEmpty(error)), disableDropdown: !!disabled })}
                onChange={handleSelection}
                options={modifiedOptions}
                components={{
                  Menu: CustomMenuRenderer,
                  Option: SelectCheckboxOptions,
                  ValueContainer: SelectCheckboxValueContainer,
                  MultiValueContainer: SelectCheckboxMultiValueContainer,
                  IndicatorSeparator: () => null,
                }}
                onMenuOpen={() => setMenuOpen(true)}
              />
              {error && !_isEmpty(error) && (
                <Typography variant={Variant.Callout} className="pt-[0.125rem] text-red500">
                  {error.message}
                </Typography>
              )}
            </SelectCheckboxContext.Provider>
          </div>
        )
      }}
    />
  )
}

interface SelectDropDownWithCheckBoxProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  placeholder?: string
  options?: SelectOptions[]
  className?: string
  required?: boolean
  disabled?: boolean
  hide?: boolean
  selectMenuButtonProps?: SelectMenuButtonProps
  showSelectMenuButton?: boolean
  setSelectedOptions?: React.Dispatch<React.SetStateAction<SelectOptions[]>>
  defaultValue?: SelectOptions[]
  height?: string
  isCustomFilter?: boolean
  menuPortalTarget?: HTMLElement | null | undefined
  menuPlacement?: 'auto' | 'bottom' | 'top'
  addSelectAllOption?: boolean
}

export default SelectDropDownWithCheckBox
