import { ROUTES } from 'constants/routes'
import { ReactNode } from 'react'

export const ENTITIES = {
  TRANSACTION_MANAGEMENT: 'Transaction Management',
  BENCHMARK_STUDIES: 'Benchmark Studies',
  LEGAL_ENTITY_MANAGEMENT: 'Legal Entity Management',
  TEMPLATE_MANAGEMENT: 'Template Management',
  BENCHMARK_MANAGEMENT: 'Benchmark Management',
  IC_AGREEMENTS_MANAGEMENT: 'Intercompany Agreements',
  DOCUMENT_MANAGEMENT: 'Documents Dashboard',
}

export const BREADCRUMBS_CONFIG = () => {
  return {
    [ROUTES.TRANSACTION_MANAGEMENT]: {
      type: 'dashboard',
      name: 'Transaction',
      nameIdentifier: 'txnDashboard',
      parent: {
        name: ENTITIES.TRANSACTION_MANAGEMENT,
        route: `${ROUTES.TRANSACTION_MANAGEMENT}`,
      },
    },
    [`${ROUTES.TRANSACTION_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Transaction',
      nameIdentifier: 'txnName',
      parent: {
        name: ENTITIES.TRANSACTION_MANAGEMENT,
        route: `${ROUTES.TRANSACTION_MANAGEMENT}`,
      },
    },
    [ROUTES.CREATE_TRANSACTION]: {
      type: 'create',
      name: 'Transaction',
      itemIdentifier: 'transactionId',
      nameIdentifier: 'transactionName',
      parent: {
        name: ENTITIES.TRANSACTION_MANAGEMENT,
        route: `${ROUTES.TRANSACTION_MANAGEMENT}`,
      },
    },
    [ROUTES.BENCHMARK_STUDIES]: {
      type: 'dashboard',
      name: 'Benchmark Studies',
      nameIdentifier: 'Benchmark Studies',
      parent: {
        name: ENTITIES.BENCHMARK_STUDIES,
        route: `${ROUTES.BENCHMARK_STUDIES}`,
      },
    },
    [ROUTES.CREATE_BENCHMARK_STUDIES]: {
      type: 'create',
      name: 'Benchmark Study',
      itemIdentifier: 'id',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.BENCHMARK_STUDIES,
        route: `${ROUTES.BENCHMARK_STUDIES}`,
      },
    },
    [ROUTES.LEGAL_ENTITY_MANAGEMENT]: {
      type: 'dashboard',
      name: 'Legal Entity',
      nameIdentifier: 'lEDashboard',
      parent: {
        name: ENTITIES.LEGAL_ENTITY_MANAGEMENT,
        route: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
    },
    [`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Legal Entity',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.LEGAL_ENTITY_MANAGEMENT,
        route: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
    },
    [ROUTES.CREATE_LEGAL_ENTITY]: {
      type: 'create',
      name: 'Legal Entity',
      itemIdentifier: 'entityId',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.LEGAL_ENTITY_MANAGEMENT,
        route: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
    },
    [`${ROUTES.TEMPLATE_MANAGEMENT}`]: {
      type: 'dashboard',
      name: 'Template',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.TEMPLATE_MANAGEMENT,
        route: ROUTES.TEMPLATE_MANAGEMENT,
      },
    },
    [ROUTES.CREATE_ECONOMIC_ANALYSIS_TEMPLATE]: {
      type: 'create',
      name: 'Economic Analysis Template',
      itemIdentifier: 'templateId',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.TEMPLATE_MANAGEMENT,
        route: `${ROUTES.TEMPLATE_MANAGEMENT}`,
      },
    },
    [ROUTES.CREATE_FUNCTIONAL_ANALYSIS_TEMPLATE]: {
      type: 'create',
      name: 'Functional Analysis Template',
      itemIdentifier: 'templateId',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.TEMPLATE_MANAGEMENT,
        route: `${ROUTES.TEMPLATE_MANAGEMENT}`,
      },
    },
    [ROUTES.IC_AGREEMENTS_MANAGEMENT]: {
      type: 'dashboard',
      name: 'Intercompany Agreement',
      nameIdentifier: 'ICA',
      parent: {
        name: ENTITIES.IC_AGREEMENTS_MANAGEMENT,
        route: ROUTES.IC_AGREEMENTS_MANAGEMENT,
      },
    },
    [ROUTES.DOCUMENT_MANAGEMENT]: {
      type: 'dashboard',
      name: 'Documents Dashboard',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.DOCUMENT_MANAGEMENT,
        route: ROUTES.DOCUMENT_MANAGEMENT,
      },
    },
    [`${ROUTES.IC_AGREEMENTS_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Intercompany Agreement',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.IC_AGREEMENTS_MANAGEMENT,
        route: ROUTES.IC_AGREEMENTS_MANAGEMENT,
      },
    },
    [ROUTES.BENCHMARK_MANAGEMENT]: {
      type: 'dashboard',
      name: 'Benchmarking',
      nameIdentifier: 'benchmarkDashboard',
      parent: {
        name: ENTITIES.BENCHMARK_MANAGEMENT,
        route: ROUTES.BENCHMARK_MANAGEMENT,
      },
    },
  }
}

export const ROUTES_TITLES = {
  [ROUTES.USER_PROFILE]: 'Profile Settings',
  [ROUTES.HOME]: 'Home',
  [ROUTES.USER_ACCESS_MANAGEMENT]: 'User Management',
  [ROUTES.SETTINGS]: 'Settings and Support',
  [ROUTES.DASHBOARD]: 'Dashboard',
  [ROUTES.ORGANIZATION]: 'Organization',
  [ROUTES.LEGAL_ENTITY_MANAGEMENT]: 'Legal Entities',
  [ROUTES.TRANSACTION_MANAGEMENT]: 'Transactions',
  [ROUTES.DOCUMENT_MANAGEMENT]: 'Documents',
  [ROUTES.BENCHMARK_MANAGEMENT]: 'Benchmarks',
  [ROUTES.BENCHMARK_STUDIES]: 'Qualitative Analysis',
  [ROUTES.ACTIVITY_LOG]: 'Activity Logs',
  [ROUTES.HELP_CENTER]: 'Help Center',
  [ROUTES.IC_AGREEMENTS_MANAGEMENT]: 'IC Agreements',
  [ROUTES.CHAT_BOT]: (
    <>
      <span className="!font-aleo">AI</span>ra
    </>
  ),
  [ROUTES.TEMPLATE_MANAGEMENT]: 'Templates',
} as Record<string, NonNullable<ReactNode>>

export const ROUTES_WITHOUT_LAYOUT = [ROUTES.RESOURCE_NOT_FOUND, ROUTES.DOCUMENT_CHAT] as string[]
