import { TransactionPliMethod, TransactionPliMethodField } from 'api/transactions/getTransactionPliMethod/types'

import { filterDataByYear, getYearRange } from './helpers/financialData.helpers'
import { FinancialData } from './types/financialData.types'

export const financialStructurePayload = ({ usGaapData, startYear, endYear, pliMethod }: FinancialStructureProps) => {
  if (!pliMethod) return []

  const addFinancialStructurePayload = getYearRange(startYear, endYear).map(year => {
    const yearFinancialData = filterDataByYear(usGaapData, year)

    const data: {
      [key: string]: string | null | number
    } = {
      year: year.toString(),
    }
    ;([...pliMethod.required_fields, ...pliMethod.optional_fields] as TransactionPliMethodField[])
      .filter(field => field.type != 'Balance Sheet')
      .forEach(field => {
        if (!field.is_calculated) {
          data[field.name] = yearFinancialData[field.name]
        }
      })
    return data
  })

  return addFinancialStructurePayload.filter(val => !!val) as {
    [k: string]: string | null
  }[]
}

export const balanceSheetPayload = ({
  balanceSheetData,
  startYear,
  endYear,
  pliMethod,
  tested_party,
}: BalanceSheetProps) => {
  if (!pliMethod) return []

  const addFinancialStructurePayload = getYearRange(startYear - 1, endYear).map(year => {
    const yearFinancialData = filterDataByYear(balanceSheetData, year)

    const data: {
      [key: string]: string | null | number
    } & {
      tested_party: number
    } = {
      year: year.toString(),
      tested_party,
    }

    ;([...pliMethod.required_fields, ...pliMethod.optional_fields] as TransactionPliMethodField[])
      .filter(field => field.type == 'Balance Sheet')
      .forEach(field => {
        if (!field.is_calculated) {
          data[field.name] = yearFinancialData[field.name]
        }
      })
    return data
  })

  return addFinancialStructurePayload.filter(val => !!val) as {
    [k: string]: string | null
  }[]
}

interface FinancialStructureProps {
  usGaapData: FinancialData[]
  startYear: number
  endYear: number
  pliMethod: TransactionPliMethod | undefined
}

interface BalanceSheetProps {
  balanceSheetData: FinancialData[]
  startYear: number
  endYear: number
  pliMethod: TransactionPliMethod | undefined
  tested_party: number
}
