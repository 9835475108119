import { useMutation } from '@tanstack/react-query'
import { createCompliance, updateCompliance } from 'api/transactions/createTransaction'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Drawer from 'components/drawer/Drawer'
import FlagReader from 'components/flagReader/FlagReader'
import Loading from 'components/loading'
import RadioButton from 'components/radioButton/RadioButton'
import Typography, { Variant } from 'components/typography'
import useConfirmationForDrawer from 'hooks/useConfirmationForDrawer/useConfirmationForDrawer'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'
interface Props {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionFetching: boolean
  economicAnalysisTransactionResponse: economic_analysis[]
  economicAnalysisTransactionRefetch: () => void
  isOpen: boolean
  onClose: () => void
}
interface FIELD_VALUES {
  testedParty?: { isCompliant?: string }[]
}

const Compliance = ({
  transactionResponse,
  economicAnalysisTransactionFetching,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionResponse,
  isOpen,
  onClose,
}: Props) => {
  const { is_primary_entity_tested_party, tested_parties_compliance } = economicAnalysisTransactionResponse?.[0]
  const { getConfirmation, ConfirmationWindow } = useConfirmationForDrawer()
  const { refetchChecklist } = useReportWizardContext()
  const testedParties = useMemo(() => {
    if (is_primary_entity_tested_party) {
      return [
        {
          entity: transactionResponse?.primary_entity,
        },
      ]
    } else {
      return transactionResponse?.individual_transactions.map(txn => {
        return {
          entity: txn.associated_entity,
        }
      })
    }
  }, [
    is_primary_entity_tested_party,
    transactionResponse?.individual_transactions,
    transactionResponse?.primary_entity,
  ])

  const { setValue, register } = useForm<FIELD_VALUES>({})

  useEffect(() => {
    if (tested_parties_compliance && tested_parties_compliance?.length > 0) {
      testedParties?.map(party => {
        const partyFound = tested_parties_compliance.find(testedParty => testedParty.tested_party === party.entity?.id)
        if (!!partyFound) {
          setValue(
            `testedParty.${partyFound.tested_party}.isCompliant`,
            partyFound.arms_length_compliant === true ? 'yes' : partyFound.arms_length_compliant === false ? 'no' : 'na'
          )
        } else {
          setValue(`testedParty.${party.entity?.id || 0}.isCompliant`, 'na')
        }
      })
    } else {
      testedParties?.map(party => {
        setValue(`testedParty.${party.entity?.id || 0}.isCompliant`, 'na')
      })
    }
  }, [setValue, testedParties, tested_parties_compliance])

  const createComplianceMutation = useMutation(createCompliance, {
    onSuccess: () => {
      economicAnalysisTransactionRefetch()
      toast.success('Compliance added successfully', {
        autoClose: 1000,
      })
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })
  const updateComplianceMutation = useMutation(updateCompliance, {
    onSuccess: () => {
      economicAnalysisTransactionRefetch()
      toast.success('Compliance updated successfully', {
        autoClose: 1000,
      })
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })
  const handleCompliance = useCallback(
    async (entityId: number, armsLengthCompliant: boolean | null) => {
      const compliance = tested_parties_compliance?.find(party => party.tested_party === entityId)
      if (!!compliance) {
        if (!compliance.manual_override) {
          const getUpdateComfirmation = getConfirmation(
            <>Do you want to override Integral’s analysis on transaction compliance?</>,
            'warning!',
            'Proceed'
          )
          const canUpdateCompliance = await getUpdateComfirmation
          if (canUpdateCompliance) {
            updateComplianceMutation.mutate({
              arms_length_compliant: armsLengthCompliant,
              manual_override: true,
              id: compliance.id,
            })
          } else {
            setValue(`testedParty.${entityId}.isCompliant`, armsLengthCompliant ? 'no' : 'yes')
          }
        } else {
          updateComplianceMutation.mutate({
            arms_length_compliant: armsLengthCompliant,
            manual_override: true,
            id: compliance.id,
          })
        }
      } else {
        createComplianceMutation.mutate({
          economic_analysis: economicAnalysisTransactionResponse[0].id,
          tested_party: entityId,
          arms_length_compliant: armsLengthCompliant,
          manual_override: true,
        })
      }
    },
    [
      createComplianceMutation,
      economicAnalysisTransactionResponse,
      getConfirmation,
      setValue,
      tested_parties_compliance,
      updateComplianceMutation,
    ]
  )
  const loading = useMemo(() => {
    return (
      createComplianceMutation.isLoading || updateComplianceMutation.isLoading || economicAnalysisTransactionFetching
    )
  }, [createComplianceMutation.isLoading, economicAnalysisTransactionFetching, updateComplianceMutation.isLoading])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      zIndex={1500}
      title="Is this transaction compliant?"
      containerClassName="w-[60%] border rounded-lg border-solid !border-white !bg-blue-50/75 !backdrop-blur-[15px]"
      className="!gap-4 h-full relative">
      {loading && (
        <Loading className="absolute left-0 right-0 bottom-0 top-14 bg-gray-300 backdrop-blur-sm !mt-0 z-50	 flex items-center justify-center" />
      )}
      <div className="w-full flex flex-col h-full gap-4 overflow-y-auto ">
        {testedParties?.map((party, index) => (
          <BorderlessBox key={index} className="!py-3 border border-solid border-white !bg-white/50" variant="white">
            <div className="flex w-full justify-between">
              <div className="flex items-center gap-1">
                <FlagReader country={party?.entity?.country} />
                <Typography
                  variant={Variant.Callout}
                  type="semibold"
                  className="max-w-[30rem] break-words text-gray-700">
                  {party?.entity?.name_abbreviation || party?.entity?.name}
                </Typography>
              </div>
              <div className="flex items-center gap-6">
                <RadioButton
                  {...register(`testedParty.${party.entity?.id || 0}.isCompliant`)}
                  onChange={() => handleCompliance(party.entity?.id || 0, true)}
                  value="yes"
                  label="Yes"
                />
                <RadioButton
                  {...register(`testedParty.${party.entity?.id || 0}.isCompliant`)}
                  onChange={() => handleCompliance(party.entity?.id || 0, false)}
                  value="no"
                  label="No"
                />
                <RadioButton
                  {...register(`testedParty.${party.entity?.id || 0}.isCompliant`)}
                  onChange={() => handleCompliance(party.entity?.id || 0, null)}
                  value="na"
                  label="NA"
                />
              </div>
            </div>
          </BorderlessBox>
        ))}
      </div>

      <ConfirmationWindow />
    </Drawer>
  )
}
export default Compliance
