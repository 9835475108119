import { useMutation } from '@tanstack/react-query'
import { setTaxRulingAppendices } from 'api/documents'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import UploadTaxRulingsDocument from './Components/UploadTaxRulingsDocument'
import { getTaxRulingColumns } from './RelavantTaxDocsTab.helpers'

type props = {
  data: ReturnType<typeof getAppendicesConfig>['relevant_tax_rulings']['data']
}

export type relevantTaxRulingTableData = {
  file: string | null | undefined
  file_name: string | null | undefined
  documentType: string
  label: string
}

const RelavantTaxDocsTab = ({ data }: props) => {
  const { legalEntity, refetchChecklist, isFetching, getConfirmation } = useReportWizardContext()

  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPreview, setIsPreview] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState<relevantTaxRulingTableData>()

  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)

  const onClosePreview = useCallback(() => {
    setSelectedDocument(undefined)
    setIsPreview(false)
  }, [])

  const handlePreview = useCallback((data: relevantTaxRulingTableData) => {
    if (data.file) {
      setSelectedDocument(data)
      setIsPreview(true)
    }
  }, [])

  const toggleUploadModal = useCallback(() => {
    setUploadModalIsOpen(option => {
      if (option) {
        setSelectedDocument(undefined)
      }

      return !option
    })
  }, [])

  const tableData: relevantTaxRulingTableData[] = useMemo(() => {
    const returnData: relevantTaxRulingTableData[] = [
      {
        file: data?.oecd_guidelines_appendix.file,
        documentType: 'oecd_guidelines',
        file_name: data?.oecd_guidelines_appendix.file ? data?.oecd_guidelines_appendix.file_name : undefined,
        label: 'OECD Guidelines Appendix',
      },
      {
        file: data?.country_specific_guidelines_appendix.file,
        documentType: 'country_specific_guidelines',
        file_name: data?.country_specific_guidelines_appendix.file
          ? data?.country_specific_guidelines_appendix.file_name
          : undefined,
        label: 'Country Specific Guidelines',
      },
    ]
    return returnData.filter(row => !!row.file)
  }, [data])

  const documentTypeOptions: SelectOptions[] = useMemo(() => {
    const options: SelectOptions[] = [
      {
        label: 'OECD Guidelines Appendix',
        value: 'oecd_guidelines',
      },
      {
        label: 'Country Specific Guidelines',
        value: 'country_specific_guidelines',
      },
    ].filter(opt => {
      if (data?.country_specific_guidelines_appendix.is_present && opt.value == 'country_specific_guidelines') {
        return false
      }

      if (data?.oecd_guidelines_appendix.is_present && opt.value == 'oecd_guidelines') {
        return false
      }
      return true
    })
    return options
  }, [data])

  const deleteTaxRulingFileMutation = useMutation(setTaxRulingAppendices)

  const handleEditClick = useCallback((data: relevantTaxRulingTableData) => {
    setSelectedDocument(data)
    setUploadModalIsOpen(true)
  }, [])

  const handleDeleteClick = useCallback(
    async (data: relevantTaxRulingTableData) => {
      if (deleteTaxRulingFileMutation.isLoading) return
      if (await getConfirmation(`Are you sure you want to delete ${data.label} - ${data.file_name || data.label}?`)) {
        deleteTaxRulingFileMutation.mutate(
          {
            leId: legalEntity || 0,
            payload: {
              [data.documentType + '_file']: null,
              [data.documentType + '_file_name']: null,
            },
          },
          {
            onSuccess: () => {
              toast.success('Deleted the selected file!!')
              refetchChecklist()
            },
            onError: error => {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    },
    [deleteTaxRulingFileMutation, getConfirmation, legalEntity, refetchChecklist]
  )

  const columns = useMemo(() => {
    return getTaxRulingColumns({ handleDeleteClick, handleEditClick, handlePreview })
  }, [handleDeleteClick, handleEditClick, handlePreview])

  if (isFetching || deleteTaxRulingFileMutation.isLoading) return null

  return (
    <BorderlessBox
      bgClass="bg-[rgb(16,15,46)]"
      className={classNames('w-full py-3 gap-4 relative', uploadModalIsOpen && 'border border-solid border-gray-800')}>
      <UploadTaxRulingsDocument
        documentTypeOptions={documentTypeOptions}
        leId={legalEntity || 0}
        selectedDocument={selectedDocument}
        isOpen={uploadModalIsOpen}
        onClose={toggleUploadModal}
      />
      {!uploadModalIsOpen && (
        <>
          <BorderlessBox bgClass="bg-[#1A193B]" className="w-full !px-2 !py-1">
            <NewTable
              tableRowClassName="!bg-[#141431] !border-none hover:!bg-[#141431]"
              tableCellClassname="!border-none"
              tableHeadClassName="!bg-[#212048] !text-white"
              data={tableData}
              columns={columns}
            />
          </BorderlessBox>

          <DocumentPreviewModal
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            documentPreviewModal={isPreview && !!selectedDocument?.file}
            setDocumentPreviewModal={onClosePreview}
            selectedFile={selectedDocument?.file || undefined}
          />
        </>
      )}
    </BorderlessBox>
  )
}

export default RelavantTaxDocsTab
