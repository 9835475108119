import axios from 'api'
import { LegalEntityResponse } from 'api/legalEntity/types/legalEntity'

import { TransactionByIdResponse } from '../getTransaction/getTransactionByIdResponse'
import { TransactionResponse } from '../getTransaction/types'

interface ImportTransactionPayload {
  transactions: number[]
  year: number
}

export type InvolvedLegalEntities = Pick<LegalEntityResponse, 'id' | 'name' | 'name_abbreviation' | 'country'>

export const importBulkTransactions = ({ transactions, year }: ImportTransactionPayload) =>
  axios
    .post(`/api/v1/org/{organization}/transaction/transactions/import_transactions/`, {
      transactions,
      year,
    })
    .then(res => res.data)

export const importInvolvedLegalEntities = ({ transactions, year }: ImportTransactionPayload) =>
  axios
    .post<InvolvedLegalEntities[]>(
      `/api/v1/org/{organization}/transaction/transactions/involved_legal_entities_to_import/`,
      {
        transactions,
        year,
      }
    )
    .then(res => res.data)

export const getImportableTransaction = ({ fromYear, toYear }: { fromYear: string; toYear: string }) =>
  axios
    .get(
      `/api/v1/org/{organization}/transaction/transactions/transactions_to_import?from_year=${fromYear}&to_year=${toYear}`
    )
    .then(res => res.data)

export const uploadTransactionImportFile = ({ file }: { file: File }) =>
  axios
    .postForm<
      (TransactionResponse & {
        is_existing_transaction?: boolean
        account_number?: number
      })[]
    >(`/api/v1/org/{organization}/onboarding/transactions_onboarding/upload/`, { file })
    .then(res => res.data)

export const importUploadedTransaction = (payload: {
  selected_transactions: number[]
  rejected_transactions: number[]
}) =>
  axios
    .post(`/api/v1/org/{organization}/onboarding/transactions_onboarding/import_transactions/`, payload)
    .then(res => res.data)

export const downloadTemplateFile = () =>
  axios.get(
    `/api/v1/org/{organization}/onboarding/transactions_onboarding/download_transactions_import_excel_template/`,
    { responseType: 'blob' }
  )

export const getSameTransactionsFromPreviousYears = ({ id }: { id: number }) =>
  axios
    .get<TransactionByIdResponse[]>(
      `/api/v1/org/{organization}/transaction/transactions/${id}/same_transactions_in_org/`
    )
    .then(res => res.data)

export const importFinancialData = ({
  id,
  transactionId,
  years,
}: {
  id: number
  transactionId: number
  years?: number[]
}) =>
  axios
    .post(`/api/v1/org/{organization}/transaction/transactions/${id}/import_financial_data/`, {
      years,
      transaction: transactionId,
    })
    .then(res => res.data)
