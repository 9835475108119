import { Tooltip } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import React, { useEffect, useState } from 'react'
import { truncateString } from 'utils/utils'

export const EditableNameCell = <T extends { name: string; is_archived?: boolean }>({
  data,
  onEdit,
  handleViewFile,
  disabled,
  nameMaxLength,
  disableEdit,
}: {
  data: CellContext<T, unknown>
  onEdit: (data: T, name: string) => void
  handleViewFile?: (data: T) => void
  disabled?: boolean
  disableEdit?: boolean
  nameMaxLength?: number
}) => {
  const [editable, setEditable] = useState(false)
  const [name, setName] = useState<string>(data.row.original.name)

  const onToggleEdit = () => {
    if (editable) {
      onEdit(data.row.original, name)
    }
    setEditable(!editable)
  }

  return (
    <div className={`flex items-start gap-0`}>
      {editable ? (
        <input
          onChange={e => setName(e.target.value)}
          value={name}
          type="text"
          className="min-w-[20rem] w-full border-b border-neutral-300 border-t-0 border-r-0 border-l-0 border-solid hover:outline-none hover:border-b-neutral-300 hover:bg-gray-50 p-2 mr-2"
        />
      ) : (
        <div className="flex flex-col items-start">
          <Tooltip title={data.row.original.name}>
            <div>
              <Typography
                onClick={disabled ? undefined : handleViewFile ? () => handleViewFile(data.row.original) : undefined}
                variant={Variant.Callout}
                type="semibold"
                className={`whitespace-nowrap max-w-[150px] sm:max-w-[150px] lg:max-w-[180px] md:max-w-[200px] xl:max-w-[250px] 2xl:max-w-[500px] truncate ${
                  disabled
                    ? 'pointer-events-none !text-gray-600'
                    : handleViewFile
                    ? 'cursor-pointer text-blue-900'
                    : 'text-blue-900'
                }`}>
                {nameMaxLength ? truncateString(data.row.original.name, nameMaxLength) : data.row.original.name}
              </Typography>
            </div>
          </Tooltip>
          {data.row.original.is_archived && (
            <div className="bg-violet-100 mt-0.5 min-h-[20px] py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-violet-600 font-semibold">
              Archived
            </div>
          )}
        </div>
      )}
      <Button
        icon={editable ? IconsType.save : IconsType.edit}
        variant={editable ? ButtonVariant.Secondary : ButtonVariant.Tertiary}
        isDefaultSize={false}
        disabled={data.row.original.is_archived || disabled || disableEdit}
        iconPathClassName={
          data.row.original.is_archived || disabled || disableEdit
            ? 'stroke-gray-500'
            : editable
            ? 'fill-blue-900'
            : 'stroke-blue-900'
        }
        iconCLass="!w-[1rem] !h-[1rem]"
        className={`!w-[2rem] ${editable ? '!p-1 !rounded-full !border-blue-200 shrink-0 !h-[2rem]' : '!h-[1rem]'}`}
        onClick={onToggleEdit}
      />
    </div>
  )
}

export const CommonEditableNameCell = ({
  data,
  onEdit,
  resetData = false,
}: {
  data: string
  onEdit: (name: string) => void
  resetData?: boolean
}) => {
  const [editable, setEditable] = useState(false)
  const [name, setName] = useState<string>(data)

  const onToggleEdit = () => {
    if (editable) {
      onEdit(name)
    }
    setEditable(!editable)
  }
  useEffect(() => {
    if (!editable && resetData) {
      setName(data)
    }
  }, [data, editable, resetData])

  return (
    <div className="flex items-center gap-4">
      {editable ? (
        <input
          onChange={e => setName(e.target.value)}
          value={name}
          type="text"
          className="w-[20rem] border-b border-b-neutral-300 border-t-0 border-r-0 border-l-0 border-solid focus:outline-none focus:border-b-neutral-300 focus:bg-gray-50 p-2"
        />
      ) : (
        <Typography variant={Variant.Callout} type="semibold" className="text-gray-700 whitespace-nowrap">
          {name || data}
        </Typography>
      )}
      <Button
        icon={editable ? IconsType.save : IconsType.edit}
        variant={editable ? ButtonVariant.Secondary : ButtonVariant.Tertiary}
        isDefaultSize={false}
        iconPathClassName={editable ? 'fill-blue-900' : 'stroke-blue-900'}
        iconCLass="!w-[1rem] !h-[1rem]"
        className={`!w-[2rem] ${editable ? '!p-1 !rounded-full !border-blue-200 shrink-0 !h-[2rem]' : '!h-[1rem]'}`}
        onClick={onToggleEdit}
      />
    </div>
  )
}
