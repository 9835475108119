import { TemplateVariableResponse } from 'api/templates/types'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { Editor } from 'tinymce'

import TemplateVariableSidebar from './TemplateVariableSidebar'

const registerTemplateVariables = (editor: Editor, variables: TemplateVariableResponse[]): void => {
  let root: Root | null = null

  // Function to render sidebar content
  const renderSidebar = (sidebarElement: HTMLElement) => {
    if (!sidebarElement) return

    // Clear any existing content
    sidebarElement.innerHTML = ''

    // Create React root and render component
    const root = createRoot(sidebarElement)
    root.render(<TemplateVariableSidebar editor={editor} variables={variables} />)
  }

  editor.ui.registry.addSidebar('templateVariables', {
    icon: 'template-add',
    tooltip: 'Add Variables',
    onSetup(api) {
      const sidebarElement = api.element()
      renderSidebar(sidebarElement)
      return () => {
        if (root) {
          root.unmount()
          root = null
        }
      }
    },
  })
}

export default registerTemplateVariables
