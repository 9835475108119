import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Icon, ListItemIcon } from '@mui/material'
import { getIcons } from 'assets/index'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { ISidearField } from 'organisms/sidebar/config/sidebar.config'
import React, { useMemo } from 'react'
import { getTextFromReactNode } from 'utils/stringUtils'

import { config, titleConfig } from './entitiesButton.classConfig'

const EntitiesButton = ({
  activeLink,
  onActiveStateHandler,
  field,
  open,
  isSubFieldIncluded,
  isTextCenter,
  onSubFieldClick,
  isExtendedFieldsVisible,
  className,
}: EntitiesButtonProps) => {
  const fieldTitleString = useMemo(() => {
    return getTextFromReactNode(field.title)
  }, [field.title])
  const isActive = !open ? activeLink?.includes(field.path) : activeLink?.includes(field.path) && isSubFieldIncluded
  const ericaInactive =
    (!isActive || !isSubFieldIncluded) &&
    (fieldTitleString === 'AIra' ? ' hover:bg-blue-200 sidebar-erica-bg-gradient-1' : config.inactive)
  const ericaActive = isActive && (fieldTitleString === 'AIra' ? 'sidebar-erica-dark-bg-gradient-1' : config.active)

  return (
    <div
      onClick={onActiveStateHandler(field.path)}
      className={cx(config.base, 'flex-col relative w-full', ericaInactive, ericaActive, className)}>
      <div className="flex w-full items-center justify-between h-full">
        <div className={`flex items-center gap-[0.75rem] h-full ${open ? 'w-[11.75rem]' : 'w-full'}`}>
          {field.src && (
            <ListItemIcon
              sx={{
                minWidth: 0,

                color: !isActive ? '#1e293b' : '#eff6ff',
              }}>
              {React.cloneElement(<field.src />, { style: { fontSize: '20px' } })}
            </ListItemIcon>
          )}
          {field.moduleIcon &&
            !field.src &&
            getIcons(field.moduleIcon, {
              className: '!w-5 !h-5',
              pathClassName: `${isActive ? 'fill-[#eff6ff]' : 'fill-[#1e293b]'}`,
            })}

          <Typography
            variant={Variant.Body}
            type="semibold"
            className={cx(`${!open && titleConfig.hidden}`, titleConfig.base, {
              [titleConfig.active]: isActive,
              [titleConfig.inactive]: !isActive,
              'text-center w-full': isTextCenter,
            })}>
            {field.title}
          </Typography>
          {fieldTitleString === 'AIra' && (
            <div
              className={`w-[6.625rem] h-[1.5rem] ${
                isActive ? 'sidebar-erica-dark-bg-gradient-2' : 'sidebar-erica-bg-gradient-2'
              }   absolute rounded-2xl bottom-1 right-3 
            `}></div>
          )}
        </div>
        {open && field.subfields && (
          <Icon onClick={onSubFieldClick}>
            <KeyboardArrowDownIcon
              className={cx({
                'transform rotate-180 duration-300 ': isExtendedFieldsVisible,
              })}
              sx={{
                fill: 'white',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </Icon>
        )}
      </div>
    </div>
  )
}

interface EntitiesButtonProps {
  activeLink?: string
  onActiveStateHandler: (path: string) => VoidFunction
  field: ISidearField
  open: boolean
  isSubFieldIncluded?: boolean
  isTextCenter?: boolean
  onSubFieldClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isExtendedFieldsVisible?: boolean
  className?: string
}

export default EntitiesButton
