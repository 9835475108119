import { Search } from '@mui/icons-material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import React, { InputHTMLAttributes, useEffect, useState } from 'react'
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  className,
  ...props
}: {
  value: string
  onChange: (value: string) => void
  debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, debounce, onChange])

  // const Router = useRouter()
  // const dispatch = useDispatch()

  // const rightComponent = useMemo(
  //   () => getRightComponent({ activeLink, Router, dispatch }),
  //   [activeLink, Router, dispatch]

  return (
    <label
      className={cx(
        'border border-solid border-gray-100 rounded-full px-3 py-1.5 flex justify-center items-center gap-2 bg-white',
        className
      )}>
      <div className="w-5 h-5">
        <Search className="text-gray-500 w-5 h-5" />
      </div>
      <input
        className="border-none outline-none focus:outline-none placeholder:text-gray-300 text-callout w-full h-full"
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      {value ? (
        <div
          onClick={() => setValue('')}
          className="w-[1.125rem] h-[1.125rem] flex items-center justify-center cursor-pointer">
          {getIcons(IconsType.cross)}
        </div>
      ) : null}
    </label>
  )
}

export default DebouncedInput
