import { Checkbox } from '@mui/material'
import { TemplateVariableResponse } from 'api/templates/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { primary_entity } from 'api/transactions/getTransaction/types'
import CheckboxActive from 'assets/icons/checkboxActive'
import CheckboxInactive from 'assets/icons/checkboxInactive'
import AccordianTableWrapper from 'components/accordianTableWrapper'
import FlagReader from 'components/flagReader'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { ENTITY_FIELDS_VALUE } from '../../AddFunctionsModal'
import AddAttribute from '../addAttribute'

const EntityAddAttribute = ({
  watch,
  control,
  entityData,
  fallbackLabel,
  tabKey,
  isOtherModal,
  aiButtonDisabled,
  currentTransaction,
  disableFeatures = false,
  handleLoadAILanguage,
  getResponsesUsed,
  clearAIDescriptions,
  showDescription,
  templateVariables,
}: EntityAddAttributeProps) => {
  const isActive = watch(`${entityData?.id || '_common'}.isActive`)

  return (
    <AccordianTableWrapper
      isActive={!!isActive}
      leftTitleContent={
        <Controller
          control={control}
          defaultValue={!!isActive}
          name={`${entityData?.id || '_common'}.isActive`}
          render={({ field: props }) => (
            <Checkbox
              {...props}
              checked={props.value}
              disabled={disableFeatures}
              onChange={e => props.onChange(e.target.checked)}
              icon={<CheckboxInactive className="w-[18px]" />}
              checkedIcon={<CheckboxActive className="w-[18px]" />}
            />
          )}
        />
      }
      className="w-full mb-4"
      title={
        entityData ? (
          <FlagReader
            country={entityData.country}
            otherText={`${entityData.country.name}, ${entityData.name_abbreviation || entityData.name}`}
          />
        ) : (
          fallbackLabel
        )
      }>
      <AddAttribute
        handleLoadAILanguage={handleLoadAILanguage}
        isSource={!!entityData?.is_source}
        fieldId={entityData?.id || '_common'}
        control={control}
        tabKey={tabKey}
        disableFeatures={disableFeatures}
        aiButtonDisabled={aiButtonDisabled}
        getResponsesUsed={getResponsesUsed}
        currentTransaction={currentTransaction}
        showAutoLoadButtons={!isOtherModal}
        clearAIDescriptions={clearAIDescriptions}
        showDescription={showDescription}
        templateVariables={templateVariables}
      />
    </AccordianTableWrapper>
  )
}

interface EntityAddAttributeProps {
  watch: UseFormWatch<ENTITY_FIELDS_VALUE>
  control: Control<ENTITY_FIELDS_VALUE>
  setValue: UseFormSetValue<ENTITY_FIELDS_VALUE>
  entityData?: primary_entity & { is_source?: boolean }
  fallbackLabel?: string | React.ReactNode
  currentTransaction: TransactionByIdResponse
  tabKey: string
  isOtherModal: boolean
  aiButtonDisabled: boolean
  disableFeatures?: boolean
  handleLoadAILanguage: (fieldName: string, fieldId: string | number) => () => void
  getResponsesUsed: (fieldName: string, fieldId: string | number) => string
  clearAIDescriptions: (fieldId: string | number) => void
  showDescription: boolean
  templateVariables: TemplateVariableResponse[] | undefined
}

export default EntityAddAttribute
