import { ListItemIcon, Tooltip } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useRouter } from 'next/router'
import UserInfo from 'organisms/sidebar/organisms/userInfo/UserInfo'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { truncateString } from 'utils/utils'

import GlobalSearch from '../globalSearch/GlobalSearch'
import YearSelector from '../YearSelector/YearSelector'
import { classConfig, navlinks } from './config/navlinks.config'

function Navbar({ isFullwidth }: { isFullwidth: boolean }) {
  const router = useRouter()
  const { organisation } = useSelector((state: RootState) => state.app)

  const handleNavigation = useCallback(
    (path: string) => () => {
      router.push(path)
    },
    [router]
  )

  return (
    <div className="h-[64px]  w-full flex gap-3 justify-between  items-center px-6 py-3">
      {isFullwidth && getIcons(IconsType.integralOpenLogo, { className: 'w-48 h-12' })}
      {!isFullwidth && (
        <Tooltip title={`Organization: ${organisation?.name || ''}`} placement="bottom">
          <div className="h-12 w-fit px-4 py-1 flex items-center justify-center rounded-2xl gap-[0.5rem] cursor-default">
            <Typography className="whitespace-nowrap  text-neutral-700" variant={Variant.Heading1} type="bold">
              {truncateString(organisation?.name || '', 45)}
            </Typography>
          </div>
        </Tooltip>
      )}
      <div className="flex  h-[40px] w-full items-center gap-4 justify-end">
        {!isFullwidth && (
          <>
            <span className="flex items-start text-white text-[0.8rem] font-semibold">
              <YearSelector containerClassname="inline-block h-full	ms-2" />
            </span>
            <GlobalSearch />
          </>
        )}
        {navlinks.map(navLinkItem => {
          const isActive = router.pathname.includes(navLinkItem.path)
          if (isFullwidth && router.pathname !== '/org/[orgId]/user-profile') {
            return null
          }
          return (
            <Tooltip key={navLinkItem.path} title={navLinkItem.title} placement="bottom-end">
              <div
                onClick={handleNavigation(navLinkItem.path)}
                className={cx(classConfig.base, { [classConfig.active]: isActive, [classConfig.inactive]: !isActive })}>
                {navLinkItem.src && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: '#1E40AF',
                    }}>
                    {<navLinkItem.src />}
                  </ListItemIcon>
                )}
                {navLinkItem.icon &&
                  getIcons(navLinkItem.icon, { className: '!w-[20px] !h-[20px]', pathClassName: 'fill-blue-800' })}
                {/* {navLinkItem.title} */}
              </div>
            </Tooltip>
          )
        })}
        <UserInfo />
      </div>
    </div>
  )
}

export default Navbar
