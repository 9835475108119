import { useQuery } from '@tanstack/react-query'
import { ComparableResultConclusion } from 'api/transactions/comparableResults/types'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Loading from 'components/loading'
import NoContentContainer from 'components/noContentContainer'
import { QUERIES } from 'constants/query'
import { useRouter } from 'next/router'
import {
  getConclusionTableColumns,
  getConclusionTableData,
} from 'organisms/armsLengthRangeConclusion/helpers/armsLengthRangeConclusion.helper'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import { useMemo } from 'react'

export const ConclusionTable = ({
  economicAnalysisTransactionResponse,
  backgroundColor = 'white',
}: Props): JSX.Element => {
  const { comparable_result_conclusion } = economicAnalysisTransactionResponse[0]
  const showConclusions =
    !!economicAnalysisTransactionResponse?.[0]?.required_sections.includes('comparability_factors')

  const Router = useRouter()
  const { data: comparableResultConclusionsList, isFetching } = useQuery(
    [QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.key, Router.query.year],
    {
      queryFn: () => QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.function(Number(Router.query.year)),
      enabled: !isNaN(Number(Router.query.year)) && Router.isReady && !!comparable_result_conclusion && showConclusions,
    }
  )

  const { data: armsLengthRangeTypes } = useQuery([QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.key], {
    queryFn: () => QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.function(),
    enabled: !isNaN(Number(Router.query.year)) && Router.isReady && !!comparable_result_conclusion && showConclusions,
  })

  const selectedConclusionResultData: ComparableResultConclusion | undefined = useMemo(() => {
    if (!comparable_result_conclusion || !comparableResultConclusionsList?.length) return undefined
    return comparableResultConclusionsList?.find(item => item.id == comparable_result_conclusion)
  }, [comparableResultConclusionsList, comparable_result_conclusion])

  const selectedRangeData = useMemo(() => {
    if (!armsLengthRangeTypes?.length || !selectedConclusionResultData?.arms_length_range) {
      return undefined
    }
    return armsLengthRangeTypes.find(opt => opt.value == selectedConclusionResultData.arms_length_range)
  }, [selectedConclusionResultData?.arms_length_range, armsLengthRangeTypes])

  const conclusionTable = useMemo(() => {
    return getConclusionTableData({
      type: selectedRangeData,
      editable: false,
      comparableResultConclusion: selectedConclusionResultData,
    })
  }, [selectedConclusionResultData, selectedRangeData])

  const conclusionTableColumns = useMemo(() => {
    return getConclusionTableColumns()
  }, [])
  const loading = isFetching
  if (loading) {
    return <Loading className="flex items-center justify-center bg-white/50 w-full h-[10rem]" />
  }
  if (conclusionTable.length < 1) {
    return (
      <NoContentContainer
        className="mt-4 w-full mb-2 !border !border-solid !border-white !bg-white/50 !rounded-lg"
        title="There is no Conclusion Selected"
      />
    )
  }
  return (
    <>
      {!!selectedConclusionResultData && (
        <BorderlessBox
          label={
            selectedConclusionResultData
              ? `${selectedConclusionResultData.name} (${
                  selectedConclusionResultData.metric?.replace(/_/g, ' ').replace(/\b\w/, char => char.toUpperCase()) ||
                  ''
                }${
                  selectedConclusionResultData.metric && selectedConclusionResultData.currency
                    ? ` - ${selectedConclusionResultData.currency}`
                    : ''
                })`
              : 'Conclusion'
          }
          variant={backgroundColor}
          className="w-full gap-3 items-stretch mb-6">
          <NewEditableTable
            isCommaFormatted
            showHeader={false}
            data={conclusionTable}
            columns={conclusionTableColumns}
          />
        </BorderlessBox>
      )}
    </>
  )
}
interface Props {
  economicAnalysisTransactionResponse: economic_analysis[]
  backgroundColor?: 'grey' | 'white'
}
