import { RecommendationsResponse } from 'api/recommendations/types'

import axios from '../index'
import { TemplatePayload } from './TemplatePayload'
import {
  ApplyTemplatePayload,
  CreateFATemplatePayload,
  GetAIAttributeTemplateDescription,
  TemplateResponse,
  TemplatesMapResponse,
  TemplateTransactionResponse,
  TemplateVariableResponse,
  UpdateEATemplatePayload,
  UpdateFATemplateOrderPayload,
  UpdateFATemplatePayload,
} from './types'

export const getTemplatesMap = (variables: { transaction: number }) => {
  return axios
    .get<TemplatesMapResponse>(`/api/v1/org/{organization}/templates/get_template_map/`, {
      params: variables,
    })
    .then(res => res.data)
}

export const createTemplate = (payolad: TemplatePayload) => {
  return axios.post(`/api/v1/org/{organization}/templates/templates/`, payolad).then(res => res.data)
}

export const getTemplateList = ({
  template_type,
  functional_profile,
  tp_method,
  transaction_type,
}: {
  template_type?: 'functional_analysis' | 'economic_analysis'
  functional_profile?: number
  tp_method?: number
  transaction_type?: number
}) => {
  const params: { [key: string]: number | string | [] } = {}
  if (template_type) {
    params.template_type = template_type
  }
  if (functional_profile) {
    params.functional_profile = functional_profile
  }
  if (tp_method) {
    params.tp_method = tp_method
  }
  if (transaction_type) {
    params.transaction_type = transaction_type
  }
  return axios
    .get<TemplateResponse[]>(`/api/v1/org/{organization}/templates/templates/`, { params })
    .then(res => res.data)
}
export const getTemplateById = (id: number) => {
  return axios.get<TemplateResponse>(`/api/v1/org/{organization}/templates/templates/${id}/`).then(res => res.data)
}
export const updateEconomicAnalysisTemplate = (payload: UpdateEATemplatePayload) => {
  return axios
    .put<TemplateResponse>(
      `/api/v1/org/{organization}/templates/templates/${payload.id}/update_economic_analysis_template/`,
      payload.data
    )
    .then(res => res.data)
}
export const createFunctionalAttributeTemplate = (payload: CreateFATemplatePayload) => {
  return axios
    .post<TemplateResponse>(`/api/v1/org/{organization}/templates/functional_attribute_templates/`, payload)
    .then(res => res.data)
}
export const updateFunctionalAttributeTemplate = ({ id, ...payload }: UpdateFATemplatePayload) => {
  return axios
    .patch<TemplateResponse>(`/api/v1/org/{organization}/templates/functional_attribute_templates/${id}/`, payload)
    .then(res => res.data)
}
export const updateFunctionalAttributeTemplateOrder = (payload: UpdateFATemplateOrderPayload) => {
  return axios
    .put<TemplateResponse>(
      `/api/v1/org/{organization}/templates/functional_attribute_templates/update_functional_attribute_templates_order/`,
      payload
    )
    .then(res => res.data)
}
export const deleteFunctionalAttributeTemplate = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/templates/functional_attribute_templates/${id}`).then(res => res.data)
}
export const applyTemplateToTransactions = (payload: ApplyTemplatePayload) => {
  return axios
    .post(`/api/v1/org/{organization}/templates/templates/${payload.id}/apply_template/`, payload.data)
    .then(res => res.data)
}
export const deleteTemplate = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/templates/templates/${id}`).then(res => res.data)
}
export const getTransactionsBasedOnTemplate = ({ id, year }: { id: number; year: number }) => {
  const params: Record<string, string | number | []> = {}
  if (year) {
    params.year = year
  }
  return axios
    .get<TemplateTransactionResponse[]>(`/api/v1/org/{organization}/templates/templates/${id}/get_transactions/`, {
      params,
    })
    .then(res => res.data)
}

export const getAIAttributeTemplateDescription = (variables?: GetAIAttributeTemplateDescription) => {
  return axios
    .get<RecommendationsResponse>(
      `/api/v1/org/{organization}/recommendations/generate_attribute_template_description/`,
      {
        params: { ...variables },
      }
    )
    .then(res => res.data.recommendations)
}

export const getTemplateVariables = ({
  functional_profile,
  transaction,
}: {
  functional_profile?: number
  transaction?: number
}) => {
  return axios
    .get<TemplateVariableResponse[]>(`/api/v1/org/{organization}/templates/available_template_variables/`, {
      params: { functional_profile, transaction },
    })
    .then(res => res.data)
}
