import axios from 'api'
import { AxiosRequestConfig } from 'axios'

import {
  iCAAgreementFileStatusPayload,
  ICAgreementFilePayload,
  ICAgreementPayload,
  ICAgreementResponse,
  ICAReview,
  ImportICAResponse,
} from './types'

export const createOrEditICAgreement = (variables: ICAgreementPayload) => {
  const { id } = variables
  const apiOptions: AxiosRequestConfig = { headers: {} }
  if (
    apiOptions.headers &&
    (variables.html_file instanceof File ||
      variables.ica_file instanceof File ||
      variables.intercompany_analysis instanceof File)
  ) {
    apiOptions.headers['Content-Type'] = 'multipart/form-data'
  }
  if (id) {
    return axios
      .patch<ICAgreementResponse>(`/api/v1/org/{organization}/intercompany_agreement/${id}/`, variables, apiOptions)
      .then(res => res.data)
  }
  return axios
    .post<ICAgreementResponse>(`/api/v1/org/{organization}/intercompany_agreement/`, variables, apiOptions)
    .then(res => res.data)
}

export const createOrEditICAAgreementFiles = (variables: ICAgreementFilePayload) => {
  const { id, files, files_data } = variables

  const formData = new FormData()
  if (files && files.length > 0) {
    files.forEach(file => {
      formData.append('files', file)
    })
  }

  if (files_data) {
    formData.append('files_data', JSON.stringify(files_data))
  }

  return axios
    .put<ICAgreementResponse>(`/api/v1/org/{organization}/intercompany_agreement/${id}/add_files/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data)
}
export const deleteICAAgreementFiles = (id: number) => {
  return axios
    .delete<ICAgreementResponse>(`/api/v1/org/{organization}/intercompany_agreement/intercompany_agreement_file/${id}/`)
    .then(res => res.data)
}
export const updateICAAgreementFiles = (payload: {
  id: number
  file_name: string | null
  file: File | undefined | string | null
}) => {
  const formData = new FormData()
  if (payload.file) {
    formData.append('file', payload.file)
  }

  if (payload.file_name) {
    formData.append('file_name', payload.file_name)
  }
  return axios
    .patch<ICAgreementResponse>(
      `/api/v1/org/{organization}/intercompany_agreement/intercompany_agreement_file/${payload.id}/`,
      formData
    )
    .then(res => res.data)
}

export const iCAAgreementFileStatus = async ({
  id,
  signers,
  intercompany_agreement_files,
}: iCAAgreementFileStatusPayload) => {
  const payload = {
    signers,
    intercompany_agreement_files,
  }

  const response = await axios.post<ICAgreementResponse>(
    `/api/v1/org/{organization}/intercompany_agreement/${id}/docsign/`,
    payload
  )

  return response.data
}

export const getICAgreementList = (year?: number) => {
  return axios
    .get<ICAgreementResponse[]>(`/api/v1/org/{organization}/intercompany_agreement/`, {
      params: {
        year,
      },
    })
    .then(res => res.data)
}

export const getICAgreement = ({ id = 0 }: { id?: number }) => {
  return axios.get<ICAgreementResponse>(`/api/v1/org/{organization}/intercompany_agreement/${id}`).then(res => res.data)
}

export const getImportableICAsForTransaction = ({ year, transactionId }: { year?: number; transactionId: number }) => {
  return axios
    .get<ImportICAResponse[]>(
      `/api/v1/org/{organization}/transaction/transactions/${transactionId}/importable_intercompany_agreement_transactions/?year=${year}`
    )
    .then(res => res.data)
}
export const importICAForTransaction = ({
  transactionId,
  importICATransactionId,
}: {
  transactionId: number
  importICATransactionId: number
}) => {
  return axios
    .post<ImportICAResponse[]>(
      `/api/v1/org/{organization}/transaction/transactions/${transactionId}/import_intercompany_agreement/`,
      { transaction: importICATransactionId }
    )
    .then(res => res.data)
}

export const deleteICAgreement = ({ id = 0 }: { id?: number }) => {
  return axios.delete(`/api/v1/org/{organization}/intercompany_agreement/${id}`).then(res => res.data)
}

export const updateICATransactions = ({ id, transactions }: { id: number; transactions: number[] }) => {
  return axios
    .put(`/api/v1/org/{organization}/intercompany_agreement/${id}/update_transactions/`, { transactions })
    .then(res => res.data)
}

export const updateICAReviewers = ({ id, users }: { id: number; users: number[] }) => {
  return axios
    .put(`/api/v1/org/{organization}/intercompany_agreement/${id}/add_reviewer/`, { users })
    .then(res => res.data)
}

export const updateICAReview = ({
  id,
  status,
  comment,
}: {
  id: number
  status: ICAReview['status']
  comment?: string
}) => {
  return axios
    .patch(`/api/v1/org/{organization}/intercompany_agreement/intercompany_agreement_file/${id}/review/`, {
      status,
      comment,
    })
    .then(res => res.data)
}
