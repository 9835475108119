import { HomeOutlined } from '@mui/icons-material'
import { IconsType } from 'assets/types'
import { ROUTES } from 'constants/routes'

export const navlinks = [
  { title: 'Home', src: HomeOutlined, path: ROUTES.HOME },
  { title: 'Activity Logs', icon: IconsType.overview, path: ROUTES.ACTIVITY_LOG },
]

export const classConfig = {
  base: 'flex cursor-pointer !h-10 w-full  !w-10  items-center justify-center  rounded-3xl',
  active: 'bg-blue-200 text-blue800',
  inactive: 'hover:bg-blue-100 text-white',
}
