import { getIcons } from 'assets'
import cx from 'classnames'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import React, { forwardRef, useMemo } from 'react'

import DescriptiveText from './components/descriptiveText'
import InfoLabel from './components/infoLabel'
import Label from './components/label'
import { iconPlacementConfig, inputClass } from './input.config'
import { getInputConfigClass } from './input.helpers'
import { IconPlacement, InputProps, InputVariant } from './input.types'

const Input = (
  {
    variant = InputVariant.Default,
    iconPlacement = IconPlacement.Left,
    icon,
    iconClassName,
    descriptiveText,
    label,
    type = 'text',
    name,
    onBlur,
    onChange,
    onIconClickHandler,
    error,
    isAnimatedLabel = false,
    placeholder,
    required,
    defaultValue,
    className,
    disabled = false,
    onClick,
    newInput = true,
    characterLimit,
    labelClassName,
    value,
    autoComplete,
    onKeyDown,
    inputClassName,
    inputBoxClassName,
  }: InputProps,
  ref: React.LegacyRef<HTMLInputElement>
): JSX.Element => {
  const iconPlacementType = iconPlacementConfig[iconPlacement]
  const variantClass = useMemo(() => {
    if (error) {
      return getInputConfigClass(InputVariant.Error)
    }
    return getInputConfigClass(variant)
  }, [error, variant])

  return (
    <div className={className}>
      {!isAnimatedLabel && (
        <InfoLabel
          darkMode={variant == InputVariant.Dark}
          labelClassName={labelClassName}
          newInput={newInput}
          required={required}
          label={label}
        />
      )}
      <div
        className={cx('flex flex-col items-start w-full', {
          'cursor-not-allowed': disabled,
        })}>
        <div
          className={cx(
            'relative',
            variantClass,
            iconPlacementType,
            'w-full h-[2.375rem]',
            {
              '!bg-gray-50': disabled,
            },
            inputBoxClassName
          )}>
          {icon && (
            <div className="flex justify-center items-center" onClick={onIconClickHandler}>
              {getIcons(icon, {
                className: iconClassName,
              })}
            </div>
          )}
          <input
            id={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            type={type}
            style={{
              colorScheme: variant == InputVariant.Dark ? 'dark' : undefined,
            }}
            className={cx(inputClass(disabled, variant == InputVariant.Dark), inputClassName)}
            placeholder={!isAnimatedLabel ? placeholder : ''}
            disabled={disabled}
            onClick={onClick}
            value={value || defaultValue}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
          />
          {!!characterLimit && !disabled && (
            <Typography variant={Variant.Footnote} className={'-me-1 text-neutral-400'}>
              <span className={cx({ 'text-red-400': (value?.length || 0) > characterLimit })}>
                {value?.length || 0}
              </span>
              /{characterLimit}
            </Typography>
          )}
          {isAnimatedLabel && (
            <Label name={name} label={label} iconPlacement={iconPlacement} icon={icon} variant={variant} />
          )}
        </div>
        {descriptiveText && <DescriptiveText error={error} descriptiveText={descriptiveText} variant={variant} />}
      </div>
    </div>
  )
}

export default forwardRef(Input)
