import { Editor } from '@tinymce/tinymce-react'
import Loading from 'components/loading'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Editor as RawEditor } from 'tinymce'

import {
  customExhibitStyles,
  customFootNoteStyles,
  DEFAULT_LEFT_GROUP_BUTTONS,
  defaultEditorPlugins,
  defaultFontFamilyClasses,
  fontFamilyFormats,
  fontSizeFormats,
  TINYMCE_API_KEY,
} from './constants'
import { ai_requests } from './helpers/ai.helpers'
import { setupExhibitButton } from './helpers/exhibit.helpers'
import { setupFootnotesButton } from './helpers/footnote.helpers'
import { resizeImage } from './helpers/imageResize.helpers'
import { useComments, useSpellIgnoreList } from './hooks'
import registerSidebarAI from './plugins/AIraChat/SidebarAI'
import { EditorAuthor, EditorConversation, EditorRef } from './types'

const TinyMceEditor = ({
  author,
  disabled = false,
  height = '100%',
  minHeight,
  maxHeight,
  contentStyles,
  contentCSS,
  onSetup,
  editorData,
  documentAuthors: prevAuthors,
  documentConversations,
  leftGroupButtons = DEFAULT_LEFT_GROUP_BUTTONS.join(' | '),
  setIsDirty,
  editorRef,
}: TinyMceEditorProps) => {
  const [version, setVersion] = useState(1)
  const [isEditorLoading, setIsEditorLoading] = useState(true)
  const [hideEditorInitialization, sethideEditorInitialization] = useState(true)
  const [initialData, setInitialData] = useState<string>()
  const internalRef = useRef<EditorRef>(null)

  const mergedRef = editorRef || (internalRef as MutableRefObject<EditorRef>)
  const {
    createCommentFn,
    replyToCommentFn,
    editCommentFn,
    deleteCommentFn,
    deleteCommentThreadFn,
    deleteAllCommentThreadsFn,
    lookupACommentFn,
    fetchCommentsFn,
  } = useComments({ editorRef: mergedRef as MutableRefObject<EditorRef>, author, prevAuthors: prevAuthors || [] })

  const ignoreList = useSpellIgnoreList()

  const toolbarButtons = Array.isArray(leftGroupButtons) ? leftGroupButtons.join(' | ') : leftGroupButtons

  const isFootnoteEnabled = useMemo(() => {
    return toolbarButtons.includes('footnoteButton')
  }, [toolbarButtons])

  const isExhibitEnabled = useMemo(() => {
    return toolbarButtons.includes('exhibitButton')
  }, [toolbarButtons])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const nativeSetupHandler = async (editor: RawEditor) => {
    // any native setup for editor can be done here
    if (isFootnoteEnabled) {
      await new Promise(resolve => {
        setupFootnotesButton(editor, () => {
          resolve(null)
        })
      })
    }
    if (isExhibitEnabled) {
      await new Promise(resolve => {
        setupExhibitButton(editor, () => {
          resolve(null)
        })
      })
    }

    // To set max width on images
    editor.on('NodeChange', function (e) {
      // Check if the changed node is an image
      if (e.element.nodeName === 'IMG') {
        // Restrict the width to 672 pixels
        const currentWidth = e.element.getAttribute('width')
        if (currentWidth && !!Number(currentWidth) && Number(currentWidth) > 672) {
          setTimeout(() => {
            editor.dom.setAttribs(e.element, { width: 672, height: 'auto' })
          }, 0)
        }
      }
    })
  }

  const defaultContentStyles = `${defaultFontFamilyClasses} ${isFootnoteEnabled ? customFootNoteStyles : ''} ${
    isExhibitEnabled ? customExhibitStyles : ''
  } `

  useEffect(() => {
    if (mergedRef && documentConversations) {
      mergedRef.current = {
        editor: mergedRef?.current?.editor,
        comments: documentConversations || [],
        commentsLog: mergedRef?.current?.commentsLog || [],
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentConversations])

  useEffect(() => {
    setVersion(version => version + 1)
    setIsEditorLoading(true)
    const timer = setTimeout(() => {
      setIsEditorLoading(false)
    }, 500)
    return () => {
      timer && clearTimeout(timer)
    }
  }, [leftGroupButtons, contentStyles, contentCSS])

  useEffect(() => {
    sethideEditorInitialization(true)

    const timer = setTimeout(() => {
      sethideEditorInitialization(false)
    }, 500)

    return () => {
      timer && clearTimeout(timer)
    }
  }, [isEditorLoading])

  const handleEditorChange = useCallback(
    (editor: RawEditor) => {
      if (typeof setIsDirty != 'function' || isEditorLoading) return
      // Compare the current content with the initial content
      if (editor.getContent() !== initialData) {
        setIsDirty(true)
      } else {
        setIsDirty(false)
      }
    },
    [initialData, setIsDirty, isEditorLoading]
  )

  return (
    <>
      <div className={`h-full w-full ${isEditorLoading || hideEditorInitialization ? '' : '!h-0 !w-0'}`}>
        <Loading className={`flex w-full justify-center items-center h-full`} />
      </div>
      {!isEditorLoading && (
        <div className={`h-full ${!isEditorLoading && !hideEditorInitialization ? '' : '!h-0 !w-0'}`}>
          <Editor
            key={`${version}`}
            apiKey={TINYMCE_API_KEY}
            onInit={(evt, editor) => {
              if (mergedRef) {
                mergedRef.current = {
                  editor,
                  comments: mergedRef?.current?.comments || [],
                  commentsLog: mergedRef?.current?.commentsLog || [],
                }
              }
            }}
            onEditorChange={(_, editor) => handleEditorChange(editor)}
            initialValue={editorData}
            disabled={disabled}
            init={{
              invalid_elements: toolbarButtons.includes('image') ? '' : 'img',
              highlight_on_focus: false,
              skin_url: '/skins/ui/integralDark',
              branding: false,
              resize: false,
              promotion: false,
              height: height,
              min_height: minHeight,
              max_height: maxHeight,
              menubar: false,
              plugins: defaultEditorPlugins,
              setup: async editor => {
                await nativeSetupHandler(editor)
                registerSidebarAI(editor)

                if (onSetup && typeof onSetup === 'function') {
                  onSetup(editor)
                }
              },
              pagebreak_separator: '<div class="page-break"></div>',
              pagebreak_split_block: true,
              toolbar: toolbarButtons,
              toolbar_mode: 'wrap',
              block_formats:
                'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
              content_style: `${defaultContentStyles} ${contentStyles}`,
              content_css: contentCSS,
              content_css_cors: false,
              font_family_formats: fontFamilyFormats,
              font_size_formats: fontSizeFormats,

              // Image related options
              automatic_uploads: false,
              images_upload_handler: async blobInfo => {
                const base64Url = 'data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64()

                try {
                  const resizedImage: string = await resizeImage(base64Url, 672 * 1.5, 912 * 1.5)
                  return base64Url.length > resizedImage.length ? resizedImage : base64Url
                } catch (error) {
                  return base64Url
                }
              },

              // power paste related options
              paste_data_images: true,
              paste_merge_formats: true,
              powerpaste_allow_local_images: true,

              // Comments plugin related work
              tinycomments_mode: 'callback',
              tinycomments_author: author.username,
              tinycomments_author_name: author.fullName || author.username,
              tinycomments_author_avatar: author.avatarUrl,
              tinycomments_create: createCommentFn,
              tinycomments_reply: replyToCommentFn,
              tinycomments_edit_comment: editCommentFn,
              tinycomments_delete: deleteCommentThreadFn,
              tinycomments_delete_all: deleteAllCommentThreadsFn,
              tinycomments_delete_comment: deleteCommentFn,
              tinycomments_lookup: lookupACommentFn,
              tinycomments_fetch: fetchCommentsFn,

              sidebar_show: documentConversations && documentConversations.length ? 'showcomments' : '',

              init_instance_callback: editor => {
                setTimeout(() => {
                  setInitialData(editor.getContent())
                }, 100)
                editor.on('CommentChange', evt => {
                  const eventLog = evt.getEventLog()
                  // prettier-ignore
                  if (eventLog?.events && eventLog.events.length) {
                    // @ts-expect-error The mergedRef is being sent from parent and needs more understanding of TypeScript to solve
                    mergedRef.current = { editor: mergedRef?.current?.editor, comments: mergedRef?.current?.comments, commentsLog: eventLog.events }
                  }
                })
              },
              paste_preprocess(editor, args) {
                args.content = args.content.replace(/font-family\s*:\s*[^;]+;/gi, '')
                // replace empty anchor tags being pasted from word documents
                args.content = args.content.replace(/<a name="[^"]+"><\/a>/g, '')
                //replace non breaking spaces with regular space
                args.content = args.content
                  .replace(/\u00A0/g, ' ') //unicode
                  .replace(/&nbsp;/g, ' ') //html
                  .replace(/&NonBreakingSpace;/g, ' ') //html
              },
              ai_request: ai_requests(mergedRef.current?.editor ?? undefined),
              link_target_list: false,
              link_default_target: '_blank',
              extended_valid_elements: 'a[href|target=_blank]',

              spellchecker_languages: 'English (United States)=en_US,English (United Kingdom)=en_GB',
              spellchecker_ignore_list: ignoreList,
            }}
          />
        </div>
      )}
    </>
  )
}

TinyMceEditor.displayName = 'TinyMCEEditor'
interface TinyMceEditorProps {
  author: EditorAuthor
  height?: string | number
  minHeight?: number
  maxHeight?: number
  disabled?: boolean
  editorData: string
  contentStyles?: string
  contentCSS?: string | boolean | string[]
  actionToolbar?: string[]
  documentConversations?: EditorConversation[]
  documentAuthors?: EditorAuthor[]
  onSetup?: (editor: RawEditor) => void
  setEditorData?: React.Dispatch<React.SetStateAction<string>>
  leftGroupButtons?: string | string[]
  setIsDirty?: (dirty: boolean) => void
  editorRef?: MutableRefObject<EditorRef | null>
}

export default TinyMceEditor
