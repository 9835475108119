import { IconProps } from 'assets'

const User = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / 18px / User">
        <path
          className={pathClassName}
          id="Vector"
          d="M15 16.25V14.75C15 13.9544 14.6839 13.1913 14.1213 12.6287C13.5587 12.0661 12.7956 11.75 12 11.75H6C5.20435 11.75 4.44129 12.0661 3.87868 12.6287C3.31607 13.1913 3 13.9544 3 14.75V16.25"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_2"
          d="M9 8.75C10.6569 8.75 12 7.40685 12 5.75C12 4.09315 10.6569 2.75 9 2.75C7.34315 2.75 6 4.09315 6 5.75C6 7.40685 7.34315 8.75 9 8.75Z"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default User
