import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import classNames from 'classnames'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'
const DrawerHeader = ({
  title,
  handleClose,
  closeIconClassName,
  closeIconPathClassName,
  titleClassName,
}: DrawerHeaderProps) => {
  return (
    <div className="flex items-center w-full justify-between gap-3">
      {title && (
        <Typography
          variant={Variant.Heading2}
          type="semibold"
          className={classNames('text-gray-700 max-w-[calc(100%-10px))] w-[calc(100%-10px)] truncate', titleClassName)}>
          {title}
        </Typography>
      )}
      <div
        className="hover:backdrop-contrast-50 hover:rounded-full flex flex-col items-center justify-center p-1 cursor-pointer"
        onClick={handleClose}>
        {getIcons(IconsType.cross, {
          className: cx('w-[18px] h-[18px]', closeIconClassName),
          pathClassName: closeIconPathClassName,
        })}
      </div>
    </div>
  )
}

interface DrawerHeaderProps {
  title?: string | React.ReactNode
  handleClose: VoidFunction
  closeIconClassName?: string
  titleClassName?: string
  closeIconPathClassName?: string
}

export default DrawerHeader
