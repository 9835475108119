import { Dialog, Transition } from '@headlessui/react'
import { ArrowUpward } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { getLocalFileCss } from 'api/css'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import { IconPlacement } from 'components/button/button.types'
import { integralCss } from 'components/tinyMceEditor/constants'
import TinyMceEditorControlledField from 'components/tinyMceEditor/TinyMceEditorControlledField'
import { EditorAuthor, EditorConversation, EditorRef } from 'components/tinyMceEditor/types'
import { Fragment, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'

interface EditorModalProps<T extends FieldValues> {
  isOpen: boolean
  onClose: VoidFunction
  containerClassName?: string
  className?: string
  modalId?: string
  modalDialogId?: string
  // Save button props
  onSave?: VoidFunction
  saveButtonText?: string
  saveButtonIcon?: IconsType
  saveButtonClassName?: string
  saveButtonIconClassName?: string
  leftComponent?: ReactNode
  // Editor specific props
  control: Control<T>
  name: Path<T>
  editorHeight?: string | number
  minHeight?: number
  maxHeight?: number
  placeholder?: string
  disabled?: boolean
  required?: boolean
  editorRef: MutableRefObject<EditorRef | null>
  loading?: boolean
  leftGroupButtons?: string | string[]
  disableSaveButton?: boolean
  contentCSS?: string | boolean | string[] | undefined
  contentStyles?: string
  author?: EditorAuthor
  children?: ReactNode
  documentConversations?: EditorConversation[] | undefined
  documentAuthors?: EditorAuthor[] | undefined
  topComponent?: ReactNode
}

function EditorModal<T extends FieldValues>({
  isOpen,
  onClose,
  containerClassName,
  className,
  modalId = 'editorModal',
  modalDialogId,
  // Save button props
  onSave,
  saveButtonText = 'Done',
  saveButtonIcon,
  saveButtonClassName,
  saveButtonIconClassName,
  leftComponent,
  // Editor specific props
  control,
  editorRef,
  name,
  editorHeight = '100%',
  minHeight = 200,
  maxHeight = 800,
  placeholder = 'Start typing...',
  disabled = false,
  required = false,
  loading,
  leftGroupButtons,
  disableSaveButton = false,
  contentCSS,
  contentStyles,
  author,
  children,
  documentConversations,
  documentAuthors,
  topComponent,
}: EditorModalProps<T>) {
  const { data: localFileCss } = useQuery(['getLocalFileCss'], {
    queryFn: getLocalFileCss,
  })

  const [isAIsidebarActive, setIsAIsidebarActive] = useState(false)
  const [aiQuery, setAiQuery] = useState('')

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isSideBarActive = !editorRef?.current?.editor
        ? true
        : editorRef?.current?.editor?.queryCommandValue('ToggleSidebar') === 'ai-sidebar'
      setIsAIsidebarActive(isSideBarActive)
      isSideBarActive && setAiQuery('')
    }, 100)
    return () => {
      clearInterval(intervalId)
    }
  }, [editorRef])

  const handleAiSubmit = useCallback(
    async (query: string) => {
      const trimmedQuery = query.trim()
      if (!trimmedQuery) return

      const editor = editorRef?.current?.editor
      if (!editor) return
      editor.execCommand('runAIQuery', true, { prompt: trimmedQuery })
      setAiQuery('')
    },
    [editorRef]
  )

  const css = useMemo(() => {
    if (contentStyles) {
      return contentStyles
    }
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; background-color: #E0E7FF; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; background-color: #E0E7FF; }`)
  }, [contentStyles, localFileCss])
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative commonIntegralModalClass z-50" id={modalId} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex h-screen items-stretch justify-end p-6">
            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-0 origin-bottom-right"
              enterTo="opacity-100 scale-100 origin-bottom-right"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100 origin-bottom-right"
              leaveTo="opacity-0 scale-0 origin-bottom-right">
              <Dialog.Panel
                id={modalDialogId}
                className={cx(
                  'transform rounded-xl bg-[#1A193B] transition-all h-[calc(100vh-86px)] w-[calc(100vw-7rem)] mt-10 self-end relative',
                  containerClassName
                )}>
                <div
                  onClick={onClose}
                  className="bg-[rgba(26,25,59,0.75)] absolute backdrop-blur-sm -top-[40px] right-0 rounded-full w-9 h-9 flex items-center justify-center shadow-lg hover:scale-110">
                  {getIcons(IconsType.cross, {
                    className: cx('w-4 h-4'),
                    pathClassName: 'fill-white',
                  })}
                </div>
                <div
                  className={cx('grid w-full h-full p-6', className, {
                    'grid-rows-[auto_1fr_auto_auto]': topComponent,
                    'grid-rows-[1fr_auto_auto]': !topComponent,
                  })}>
                  {topComponent && <div className="w-full mb-4">{topComponent}</div>}
                  <div className="w-full min-h-0">
                    <TinyMceEditorControlledField
                      control={control}
                      name={name}
                      height={editorHeight}
                      minHeight={minHeight}
                      maxHeight={maxHeight}
                      placeholder={placeholder}
                      disabled={disabled}
                      contentStyles={css}
                      contentCSS={contentCSS}
                      editorRef={editorRef}
                      required={required}
                      author={author}
                      loading={loading}
                      leftGroupButtons={leftGroupButtons}
                      documentConversations={documentConversations}
                      documentAuthors={documentAuthors}
                    />
                  </div>
                  <div className="flex justify-between items-center w-full mt-4">
                    <div>{leftComponent}</div>
                    {!isAIsidebarActive && (
                      <div
                        className="mx-auto max-w-2xl"
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: '48px',
                          padding: '6px 6px 6px 16px',
                          alignItems: 'center',
                          gap: '4px',
                          borderRadius: '25px',
                          border: '1px solid #374151',
                          background: '#212048',
                        }}>
                        <input
                          type="text"
                          value={aiQuery}
                          disabled={loading || disabled}
                          className="focus:outline-none focus:ring-0"
                          onChange={e => setAiQuery(e.target.value)}
                          onKeyDown={e =>
                            e.key === 'Enter' && !(loading || disabled) && !!aiQuery.trim() && handleAiSubmit(aiQuery)
                          }
                          placeholder="Ask AIra anything ..."
                          style={{
                            flex: 1,
                            padding: 8,
                            border: 'none',
                            borderRadius: 4,
                            color: '#D1D5DB',
                            fontSize: 13,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '19px',
                            background: 'transparent',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            padding: 6,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 4,
                            borderRadius: '100%',
                            border: '1px solid #B889FF',
                            background: 'linear-gradient(110deg, #6B84FF 11.7%, #C898FF 88.78%)',
                            filter: !(!!aiQuery.trim() && !(loading || disabled)) ? 'grayscale(70%)' : 'none',
                            cursor: !(!!aiQuery.trim() && !(loading || disabled)) ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() => !!aiQuery.trim() && !(loading || disabled) && handleAiSubmit(aiQuery)}>
                          <ArrowUpward style={{ width: 20, height: 20, color: 'white' }} />
                        </div>
                      </div>
                    )}
                    {onSave && (
                      <Button
                        onClick={onSave}
                        disabled={disableSaveButton}
                        variant={ButtonVariant.Primary}
                        iconPlacement={IconPlacement.Right}
                        iconPathClassName="fill-white"
                        className={cx('!bg-indigo-400 hover:!bg-indigo-500', saveButtonClassName)}
                        icon={saveButtonIcon}
                        iconCLass={saveButtonIconClassName}>
                        {saveButtonText}
                      </Button>
                    )}
                  </div>
                  {children && <div className="mt-4">{children}</div>}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EditorModal
