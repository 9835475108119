/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React, { ReactNode, useMemo } from 'react'
import { formatNumberStringToUS } from 'utils/numberUtils'

export const EditableTableLabelCell: ColumnDefTemplate<CellContext<any, any>> = data => {
  const isRequired = !!data.row.original.required
  return (
    <Typography
      className={cx(
        'flex items-center border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 text-start mr-2 whitespace-nowrap',
        {
          '!text-container-text !text-blue900': !data.row.original.isEditable,
        }
      )}
      variant={Variant.Callout}
      type="semibold">
      {data.getValue()}
      {isRequired && <span className="text-red-600 relative bottom-0.5">*</span>}
    </Typography>
  )
}

export const EditableTableNonInputCell = ({
  cellContext,
  children,
}: {
  cellContext: CellContext<any, any>
  children: ReactNode
}) => {
  const {
    row: { original, getVisibleCells },
    cell,
  } = cellContext
  const totalCells = getVisibleCells().length
  const cellIndex = getVisibleCells().findIndex(c => c.column.id === cell.column.id)
  const readonlyCells =
    getVisibleCells()
      .map(cell => (cell.column.columnDef.meta?.isReadOnly ? 1 : 0))
      .filter(value => !!value).length || 1
  return (
    <div
      className={cx('flex items-center border-0 border-y w-full border-solid border-neutral-200 h-[39px] mb-1', {
        'rounded-l  border-l': cellIndex == readonlyCells,
        'rounded-r border-r': cellIndex == totalCells - 1,
        'bg-white': original.isEditAllowed && original.isEditable,
        'bg-gray-100': !original.isEditAllowed && !!original.isEditable,
        'bg-blue-50': !original.isEditable,
      })}>
      <Typography
        variant={Variant.Callout}
        type="semibold"
        className={cx('text-gray-600 h-[27px] w-full text-start px-4 flex items-center', {
          'border-0 border-l border-solid border-gray-200': cellIndex > 1,
        })}>
        {children}
      </Typography>
    </div>
  )
}

const getDefaultColumn = (isCommaFormatted: boolean) => {
  const defaultColumn: Partial<ColumnDef<any>> = {
    cell: ({ getValue, row: { index, original, getVisibleCells }, column: { id, columnDef }, table, cell }) => {
      const totalCells = getVisibleCells().length
      const cellIndex = getVisibleCells().findIndex(c => c.column.id === cell.column.id)

      const isEditableCell = !!original.isEditable && !!original.isEditAllowed

      const doNotFormat = !!original.unformatted

      const readonlyCells =
        getVisibleCells()
          .map(cell => (cell.column.columnDef.meta?.isReadOnly ? 1 : 0))
          .filter(value => !!value).length || 1

      let value = getValue<string>()
      const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updateValue =
          (isCommaFormatted || columnDef.meta?.commaFormatted) && !doNotFormat
            ? event.target.value.replace(/([^0-9.+-])+/g, '')
            : event.target.value
        table.options.meta?.updateData && table.options.meta?.updateData(index, id, updateValue)
      }
      if (typeof value == 'number') {
        value = String(value)
      }
      if ((isCommaFormatted || columnDef.meta?.commaFormatted) && !doNotFormat && typeof value == 'string') {
        value = formatNumberStringToUS(value)
      }
      return (
        <label
          className={cx(
            'flex items-center border-0 border-y w-full border-solid border-neutral-200 h-[39px] mb-1',
            isEditableCell ? 'cursor-text' : 'cursor-not-allowed',
            {
              'rounded-l  border-l': cellIndex == readonlyCells,
              'rounded-r border-r': cellIndex == totalCells - 1,
              'bg-white': isEditableCell,
              'bg-gray-100': !original.isEditAllowed && !!original.isEditable,
              'bg-blue-50': !original.isEditable,
            }
          )}>
          <Typography
            variant={Variant.Callout}
            type="semibold"
            className={cx('text-gray-600 h-[27px] w-full text-start px-2 flex items-center', {
              'border-0 border-l border-solid border-gray-200': cellIndex > readonlyCells,
            })}>
            <input
              disabled={!isEditableCell}
              placeholder={isEditableCell ? 'Type here' : ''}
              className={cx(
                'border-0 overflow-auto text-callout disabled:bg-transparent font-semibold',
                'border-b-[1.5px] placeholder:text-neutral-300 placeholder:font-normal',
                'border-neutral-400 border-none  flex h-6 flex-col justify-center w-full',
                'items-start focus:outline-none focus:!border-solid text-start',
                isEditableCell ? 'cursor-text' : 'cursor-not-allowed',
                {
                  'text-blue-900': !original.isEditable,
                  'text-gray-600': !!original.isEditable,
                }
              )}
              value={value as string}
              onChange={onChange}
            />
          </Typography>
        </label>
      )
    },
    size: 150,
  }
  return defaultColumn
}

function NewEditableTable<T extends { isEditable?: boolean; required?: boolean }>({
  columns,
  data,
  setData,
  isCommaFormatted = false,
  emptyState,
  showHeader = true,
  setTableAsDirty,
}: TableProps<T>) {
  const memoisedDefaultColumn = useMemo(() => getDefaultColumn(isCommaFormatted), [isCommaFormatted])
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    defaultColumn: memoisedDefaultColumn,
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setTableAsDirty && setTableAsDirty(true)
        setData &&
          setData(old =>
            old.map((row, index: number) => {
              if (index === rowIndex) {
                const prev = old[rowIndex]
                return {
                  ...(prev as any),
                  [columnId]: value,
                }
              }
              return row
            })
          )
      },
    },
    debugTable: false,
    paginateExpandedRows: false,
  })

  const rowModel = table.getRowModel()

  const visibleColumnsLength = table.getVisibleLeafColumns().length

  return (
    <table className={`w-full h-full`} rules="none">
      {showHeader && (
        <thead>
          {table?.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const size = header.column.columnDef.size
                const content = flexRender(header.column.columnDef.header, header.getContext())

                const isStarPresent = content?.toString().includes('*')

                const preContent = (content?.toString()?.split('*') || [])[0]

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: size !== 150 ? `${size}px` : undefined,
                      width: size !== 150 ? `${size}px` : undefined,
                    }}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={cx(
                          'flex items-center border-0 border-y w-full border-solid border-gray-200 bg-blue-50 h-[42px] mb-3',
                          {
                            'rounded-l  border-l': index == 0,
                            'rounded-r border-r': index == headerGroup.headers.length - 1,
                          }
                        )}>
                        <Typography
                          variant={Variant.ContainerText}
                          type="semibold"
                          className={cx('text-blue900 h-[26px] w-full text-start pl-4 flex items-center', {
                            'border-0 border-l border-solid border-blue-300': index > 0,
                          })}>
                          {isStarPresent ? preContent : content}
                          {isStarPresent && <span className="text-red-600">*</span>}
                        </Typography>
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {rowModel.rows.length ? (
          rowModel.rows.map(row => {
            const currentRowIndex = rowModel.rows.findIndex(val => val.id == row.id)
            const isCurrentRowEditable = !!row.original?.isEditable
            const isNextRowEditable = !!rowModel.rows?.[currentRowIndex + 1]?.original.isEditable
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {
                  const size = cell.column.columnDef.size
                  return (
                    <td
                      style={{
                        width: `${size}px`,
                      }}
                      className={cx('text-center', !isCurrentRowEditable && isNextRowEditable && 'pb-2')}
                      key={cell.id}>
                      <Typography variant={Variant.Callout} type="semibold">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Typography>
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr className="text-center">
            <td colSpan={visibleColumnsLength}>
              {emptyState ? (
                emptyState
              ) : (
                <Typography variant={Variant.Heading2} className="p-10 text-neutral600">
                  No entries found
                </Typography>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

interface TableProps<T extends { isEditable?: boolean }> {
  columns: ColumnDef<T>[]
  className?: string
  data: T[]
  setData?: (action: (data: T[]) => T[]) => void
  isCommaFormatted?: boolean
  emptyState?: ReactNode
  showHeader?: boolean
  setTableAsDirty?: (data: boolean) => void
}
export default NewEditableTable
