import { KeyboardArrowUp } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { getIcons } from 'assets/index'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import BorderlessBox, { BorderlessBoxProps } from 'components/BorderlessBox/BorderlessBox'
import Typography, { Variant } from 'components/typography'
import React, { ReactNode, useCallback, useState } from 'react'

type Props = Omit<BorderlessBoxProps, 'label'> & {
  isOpen?: boolean
  onChange?: () => void
  successState?: boolean
  label: NonNullable<ReactNode>
  defaultOpen?: boolean
  disableDrop?: boolean
  rightComponentAlwaysVisible?: boolean
}

function BorderlessBoxAccordion({
  variant = 'grey',
  bgClass,
  className,
  label,
  topRightComponents,
  children,
  footer,
  isOpen,
  onChange,
  successState,
  disableDrop,
  defaultOpen = false,
  rightComponentAlwaysVisible = false,
}: Props) {
  const [internalState, setInternalState] = useState(!!defaultOpen)
  const isControlled = typeof isOpen == 'boolean' && typeof onChange == 'function'

  const state = (isControlled ? isOpen : internalState) && !disableDrop
  const onClick = useCallback(() => {
    if (disableDrop) return
    isControlled ? onChange() : setInternalState(open => !open)
  }, [isControlled, onChange, disableDrop])

  return (
    <BorderlessBox
      label={
        <div onClick={onClick} className="flex items-center w-full">
          {typeof label == 'string' ? (
            <div className="flex items-center">
              <Typography variant={Variant.Callout} type="semibold" className="text-gray-700 mr-auto">
                {label}
              </Typography>
              {successState && getIcons(IconsType.greenTick, { className: 'ml-1 w-4 h-4 flex-shrink-0' })}
            </div>
          ) : (
            label
          )}
          <div className="ms-auto flex items-center">
            <div onClick={e => e.stopPropagation()}>{(rightComponentAlwaysVisible || state) && topRightComponents}</div>
            {!disableDrop && (
              <IconButton className="-my-2.5 -mr-2">
                <KeyboardArrowUp className={cx('transition-transform', !state ? 'rotate-180' : '')} />
              </IconButton>
            )}
          </div>
        </div>
      }
      variant={variant}
      bgClass={bgClass}
      className={cx(className, 'transition-[max-height] duration-500', state ? 'max-h-[10000px]' : 'max-h-[3.3rem]')}>
      {state ? (
        <>
          {children}
          {footer}
        </>
      ) : (
        <></>
      )}
    </BorderlessBox>
  )
}

export default BorderlessBoxAccordion
