import useFileUploader from 'hooks/useFileUploader/useFileUploader'
import React, { useCallback } from 'react'

import UploadBox from './components/uploadBox/UploadBox'

function UploadDocuments({
  file,
  setFile,
  label,
  fileName,
  preview,
  showDelete = false,
  accept = '.pdf,.doc,.docx,.odt,.csv,.xlsx,.xlsm',
  fileUrl,
  handleSuccessMutation,
  handleDelete,
  className,
  boxClassName,
  darkMode,
  required,
}: UploadDocumentsProps) {
  const handleFileUpload = useCallback(
    (file: File) => {
      if (setFile) {
        setFile(file)
        if (handleSuccessMutation) {
          handleSuccessMutation(file)
        }
      }
    },
    [handleSuccessMutation, setFile]
  )

  const { handleClick, ref } = useFileUploader({
    onFileSelected: handleFileUpload,
    accept: accept,
  })

  return (
    <UploadBox
      fileName={fileName}
      file={file}
      handleClick={handleClick}
      ref={ref}
      label={label}
      fileTypes={accept}
      showDelete={showDelete}
      handleDelete={handleDelete}
      fileUrl={fileUrl}
      preview={preview}
      className={className}
      boxClassName={boxClassName}
      darkMode={darkMode}
      required={required}
    />
  )
}

interface UploadDocumentsProps {
  file: File | null
  setFile: (file: File) => void
  label?: string
  fileName?: string
  handleDelete?: VoidFunction
  showDelete?: boolean
  accept?: string
  fileUrl?: string
  preview?: boolean
  className?: string
  boxClassName?: string
  handleSuccessMutation?: (file: File) => void
  darkMode?: boolean
  required?: boolean
}

export default UploadDocuments
