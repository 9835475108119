import { Info } from '@mui/icons-material'
import { ColumnDef } from '@tanstack/react-table'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Tabs from 'components/tabs'
import Typography, { Variant } from 'components/typography'
import React, { SetStateAction, useMemo, useState } from 'react'

import { FinancialData, FinancialDataTableData } from '../../types/financialData.types'
import { getTabData } from './helpers/TestedPartyFinancials.helpers'

const TestedPartyFinancialTable = ({
  setUsGaap,
  usGaapData,
  financialColumns,
  showBalanceSheet,
  balanceSheetData,
  setBalanceSheetData,
  balanceSheetColumns,
  balanceSheetAvgData,
  balanceSheetAvgColumns,
  setUSGaapIsDirty,
  setBalanceSheetDirty,
  initialTab,
  pliName = 'PLI',
  averagePli = '',
  readonly = false,
  usGaapIsDirty,
  balanceSheetDirty,
}: TestedPartyFinancialTableProps) => {
  const [isEdit, setIsEdit] = useState(false)
  const tabData = useMemo(() => {
    return getTabData({
      usGaapData,
      setUsGaap,
      financialColumns,
      setUSGaapIsDirty,
      isEdit,
      setIsEdit,
      balanceSheetData,
      setBalanceSheetData,
      balanceSheetColumns,
      setBalanceSheetDirty,
      balanceSheetAvgColumns,
      balanceSheetAvgData,
      showBalanceSheet,
      readonly,
    })
  }, [
    balanceSheetAvgColumns,
    balanceSheetAvgData,
    balanceSheetColumns,
    balanceSheetData,
    financialColumns,
    isEdit,
    setBalanceSheetData,
    setBalanceSheetDirty,
    setUSGaapIsDirty,
    setUsGaap,
    usGaapData,
    showBalanceSheet,
    readonly,
  ])
  return (
    <>
      <BorderlessBox>
        <Tabs key={initialTab} initialActiveTabIndex={initialTab} tabType="pill" tabsData={tabData} />
        {(usGaapIsDirty || balanceSheetDirty) && (
          <Typography
            variant={Variant.ContainerText}
            className="text-gray-600 !text-xs inline-flex items-center ms-auto gap-1 mt-1">
            <Info className="h-4 w-4 text-orange-400 inline" /> Save financial data to see updated calculated values
          </Typography>
        )}
      </BorderlessBox>
      <BorderlessBox className="border border-solid border-gray-100" variant="white">
        <div className="w-full flex items-center justify-between">
          <div>
            <Typography variant={Variant.Callout} className="text-gray-700" type="semibold">
              {pliName} - Avg
            </Typography>
            <Typography
              variant={Variant.ContainerText}
              className="text-gray-600 !text-xs inline-flex items-center gap-1">
              <Info className="h-4 w-4 text-orange-400 inline" />{' '}
              {showBalanceSheet
                ? 'Add both Tested Party Financials and Balance Sheet to view the margin'
                : 'Add Tested Party Financials to view the margin'}
            </Typography>
          </div>
          {!!averagePli && <div className="text-2xl text-blue-900 font-semibold">{averagePli}%</div>}
        </div>
      </BorderlessBox>
    </>
  )
}

interface TestedPartyFinancialTableProps {
  setUsGaap: React.Dispatch<React.SetStateAction<FinancialDataTableData[]>>
  usGaapData: FinancialDataTableData[]
  balanceSheetData: FinancialDataTableData[]
  balanceSheetAvgData: FinancialDataTableData[]
  setBalanceSheetData: React.Dispatch<React.SetStateAction<FinancialDataTableData[]>>
  financialColumns: ColumnDef<FinancialData>[]
  balanceSheetColumns: ColumnDef<FinancialData>[]
  balanceSheetAvgColumns: ColumnDef<FinancialData>[]
  showBalanceSheet?: boolean
  setUSGaapIsDirty: (value: SetStateAction<boolean>) => void
  setBalanceSheetDirty: (value: SetStateAction<boolean>) => void
  initialTab: number
  pliName?: string
  averagePli?: string
  readonly?: boolean
  usGaapIsDirty: boolean
  balanceSheetDirty: boolean
}

export default TestedPartyFinancialTable
