import { useMutation, useQuery } from '@tanstack/react-query'
import { updateRejectionMatrix } from 'api/transactions/createTransaction'
import { TransferPricingMethods } from 'api/transactions/createTransaction/types/complianceRegion.type'
import { updateRejectionMatrixPayload } from 'api/transactions/createTransaction/types/economicAnalysis.types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import Loading from 'components/loading/Loading'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import useTemplatesMap from 'hooks/useTemplatesMap/useTemplatesMap'
import { useRouter } from 'next/router'
import FormWithSubmission from 'organisms/formWithSubmission'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { cleanupHTML, getToastErrorMessage, replaceTemplateVariables, sleep } from 'utils/utils'

import ReorderMethods from './component/reorderMethods/ReorderMethods'
import { getFields } from './helpers/rejectionMatrix.fields.helper'

function RejectionMatrix({
  transactionResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionFetching,
}: RejectionMatrixProps) {
  const Router = useRouter()
  const [reorderDrawer, setReorderDrawer] = useState<boolean>(false)
  const { control, handleSubmit, reset, watch, setValue, getValues, formState } = useForm<REJECTION_MATRIX_FIELDS>()

  const toggleReorderDrawer = useCallback(() => {
    setReorderDrawer(o => !o)
  }, [])
  const { isDirty, setIsDirty } = useRoutingHandlerState()

  const { data: templateVariables } = useQuery({
    queryKey: [
      QUERIES.GET_TEMPLATE_VARIABLES.key,
      transactionResponse?.functional_profile?.id,
      transactionResponse?.id,
    ],
    queryFn: () =>
      QUERIES.GET_TEMPLATE_VARIABLES.function({
        functional_profile: transactionResponse?.functional_profile?.id,
        transaction: transactionResponse?.id,
      }),
    enabled: !!transactionResponse?.functional_profile?.id && !!transactionResponse?.id,
  })

  useEffect(() => {
    setIsDirty(!!Object.keys(formState.dirtyFields).length)

    return () => {
      setIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields, Object.keys(formState.dirtyFields).length, formState.isDirty, setIsDirty])

  const { getRejectionMatrixTemplateOptions, data } = useTemplatesMap({ transaction: transactionResponse?.id || 0 })

  const { data: complianceRegionData, isLoading: complianceRegionLoading } = useQuery(
    [QUERIES.GET_COMPLIANCE_REGION.key, transactionResponse?.compliance_region, transactionResponse?.transaction_type],
    {
      queryFn: () =>
        QUERIES.GET_COMPLIANCE_REGION.function({
          complianceRegion: transactionResponse?.compliance_region as string,
          transactionType:
            transactionResponse?.compliance_region === 'us' ? transactionResponse?.transaction_type ?? 'services' : '',
        }),
      enabled: !!transactionResponse?.compliance_region,
    }
  )

  const { refetchChecklist } = useReportWizardContext()

  const updateRejectionMatrixMutation = useMutation(updateRejectionMatrix, {
    onSuccess: () => {
      toast.success('Method Selection Updated', { autoClose: 3000 })
      economicAnalysisTransactionRefetch()
      setIsDirty(false)
      reset({ ...getValues() })
      refetchChecklist()
    },
    onError: (erorr: AxiosError) => {
      getToastErrorMessage(erorr)
    },
  })

  const [selectedTemplate] = watch(['template'])
  const methodData = useMemo(() => {
    const methods =
      complianceRegionData?.data.map(method => ({
        ...method,
        description: '',
      })) || []

    if (economicAnalysisTransactionResponse && economicAnalysisTransactionResponse?.rejection_matrix.length > 0) {
      const rejectionMatrixData = (economicAnalysisTransactionResponse?.rejection_matrix || [])
        .map(method => {
          const data = methods.find(d => d.id === method.transfer_pricing_method)
          return data
            ? { ...data, description: replaceTemplateVariables(method.description || '', templateVariables || []) }
            : null
        })
        .filter(Boolean) as TransferPricingMethods[]
      const rejectionIds = new Set(rejectionMatrixData.map(method => method.id))
      const newMethods = methods.filter(newMethod => !rejectionIds.has(newMethod.id))

      return [...rejectionMatrixData, ...newMethods]
    } else {
      return methods
    }
  }, [complianceRegionData?.data, economicAnalysisTransactionResponse, templateVariables])

  const rejectionMatrixTemplateOptions = useMemo(() => {
    return getRejectionMatrixTemplateOptions(String(selectedTemplate?.value || ''))
  }, [getRejectionMatrixTemplateOptions, selectedTemplate?.value])

  useEffect(() => {
    if (rejectionMatrixTemplateOptions.length && !selectedTemplate?.value) {
      setValue('template', rejectionMatrixTemplateOptions[0])
    } else if (rejectionMatrixTemplateOptions.length && selectedTemplate?.value) {
      const templateInOptions = rejectionMatrixTemplateOptions.find(opt => opt.value == selectedTemplate?.value)
      if (!templateInOptions) {
        setValue('template', rejectionMatrixTemplateOptions[0])
      }
    }
  }, [rejectionMatrixTemplateOptions, selectedTemplate?.value, setValue])

  const onSubmitHandler = useCallback(async () => {
    await sleep(0)
    const data = getValues()
    const payload: updateRejectionMatrixPayload['data'] = []
    methodData?.map(method => {
      payload.push({
        transfer_pricing_method: Number(method.id),
        description: cleanupHTML(data.methods[method.id]?.description || ''),
      })
    })
    transactionResponse?.economic_analysis?.id &&
      updateRejectionMatrixMutation.mutate({ id: transactionResponse?.economic_analysis?.id, data: payload })
  }, [getValues, methodData, transactionResponse?.economic_analysis?.id, updateRejectionMatrixMutation])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const fields = useMemo(() => {
    return getFields({
      setValue,
      transactionId: transactionResponse?.id,
      tPMethods: methodData,
      selectedTPM: economicAnalysisTransactionResponse?.transfer_pricing_method
        ? Number(economicAnalysisTransactionResponse?.transfer_pricing_method)
        : undefined,
      selectedTemplate: String(selectedTemplate?.value || '') || undefined,
      rejectionMatrixTemplateOptions: rejectionMatrixTemplateOptions,
      control,
      rejectionMatrixTemplates: data?.rejection_matrix,
      templateVariables,
    })
  }, [
    setValue,
    transactionResponse?.id,
    methodData,
    economicAnalysisTransactionResponse?.transfer_pricing_method,
    selectedTemplate?.value,
    rejectionMatrixTemplateOptions,
    control,
    data?.rejection_matrix,
    templateVariables,
  ])

  useEffect(() => {
    if (economicAnalysisTransactionResponse?.rejection_matrix?.length) {
      economicAnalysisTransactionResponse?.rejection_matrix.forEach(obj => {
        setValue(`methods.${obj.transfer_pricing_method}`, {
          description: replaceTemplateVariables(obj.description || '', templateVariables || []),
        })
      })
    }
  }, [economicAnalysisTransactionResponse, setValue, templateVariables])

  const loading =
    complianceRegionLoading ||
    !transactionResponse ||
    updateRejectionMatrixMutation.isLoading ||
    economicAnalysisTransactionFetching

  return (
    <BorderlessBox
      className="relative"
      label="Accepted & Rejected Methods"
      topRightComponents={
        <Button
          disabled={!complianceRegionData?.data || complianceRegionData?.data?.length <= 1}
          icon={IconsType.sixDotDrag}
          variant={ButtonVariant.Secondary}
          onClick={toggleReorderDrawer}
          className="!py-0 !px-2 !bg-blue">
          Reorder
        </Button>
      }>
      <FormWithSubmission
        onSubmitHandler={onSubmitHandler}
        reset={reset}
        style={{ maxHeight: 'calc(100vh - 26.7rem)' }}
        className="w-full overflow-y-auto mt-2"
        control={control}
        fields={fields}
        handleSubmit={handleSubmit}>
        {loading && <Loading className="absolute inset-0 bg-white flex items-center justify-center z-10" />}
        <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
          <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
            <Button
              isDefaultSize={false}
              onClick={handleCancel}
              iconCLass="w-5 h-5 transform rotate-180"
              iconPathClassName="stroke-blue900"
              icon={IconsType.arrowRight}
              variant={ButtonVariant.Secondary}>
              Back
            </Button>
            <Button
              size={ButtonSize.ExtraSmall}
              onClick={onSubmitHandler}
              variant={ButtonVariant.Primary}
              disabled={!isDirty || loading}
              icon={IconsType.save}>
              Save
            </Button>
          </div>
        </Portal>
      </FormWithSubmission>
      {methodData && transactionResponse?.economic_analysis && (
        <ReorderMethods
          isOpen={reorderDrawer}
          id={transactionResponse?.economic_analysis?.id}
          onClose={toggleReorderDrawer}
          data={methodData}
          key={reorderDrawer ? 0 : 1}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          refetchChecklist={refetchChecklist}
          selectedTPM={
            economicAnalysisTransactionResponse?.transfer_pricing_method
              ? Number(economicAnalysisTransactionResponse?.transfer_pricing_method)
              : undefined
          }
        />
      )}
    </BorderlessBox>
  )
}

export default RejectionMatrix

export type REJECTION_MATRIX_FIELDS = {
  methods: {
    [key: string]: {
      description: string
    }
  }
  template?: SelectOptions
}

type RejectionMatrixProps = {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionFetching: boolean
  economicAnalysisTransactionRefetch: () => void
}
