import { IconProps } from 'assets'

const Overview = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="overview">
        <path
          className={pathClassName}
          id="Vector"
          d="M16.3958 16.9792L16.9792 16.3958L15.4167 14.8333V12.5H14.5833V15.1667L16.3958 16.9792ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V9.75C17.2361 9.625 16.9653 9.51736 16.6875 9.42708C16.4097 9.3368 16.125 9.27083 15.8333 9.22917V4.16667H4.16667V15.8333H9.20833C9.25 16.1389 9.31597 16.4306 9.40625 16.7083C9.49653 16.9861 9.60417 17.25 9.72917 17.5H4.16667ZM4.16667 15V15.8333V4.16667V9.22917V9.16667V15ZM5.83333 14.1667H9.22917C9.27083 13.875 9.3368 13.5903 9.42708 13.3125C9.51736 13.0347 9.61805 12.7639 9.72917 12.5H5.83333V14.1667ZM5.83333 10.8333H10.9167C11.3611 10.4167 11.8576 10.0694 12.4062 9.79167C12.9549 9.51389 13.5417 9.32639 14.1667 9.22917V9.16667H5.83333V10.8333ZM5.83333 7.5H14.1667V5.83333H5.83333V7.5ZM15 19.1667C13.8472 19.1667 12.8646 18.7604 12.0521 17.9479C11.2396 17.1354 10.8333 16.1528 10.8333 15C10.8333 13.8472 11.2396 12.8646 12.0521 12.0521C12.8646 11.2396 13.8472 10.8333 15 10.8333C16.1528 10.8333 17.1354 11.2396 17.9479 12.0521C18.7604 12.8646 19.1667 13.8472 19.1667 15C19.1667 16.1528 18.7604 17.1354 17.9479 17.9479C17.1354 18.7604 16.1528 19.1667 15 19.1667Z"
          fill="#1E40AF"
        />
      </g>
    </svg>
  )
}

export default Overview
