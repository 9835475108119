import { getAuditLogDetail } from 'api/activityLog'
import {
  getAcceptRejectKeywords,
  getBenchmarkingCompaniesList,
  getBenchmarkingCompany,
  getBenchmarkingStudy,
  listBenchmarkingStudy,
} from 'api/benchmarkingStudy'
import { getAllBenchmarks, getBenchmarkById, getImportableBenchmarks } from 'api/benchmarks'
import { getCountriesList } from 'api/common'
import { getLocalFileCss } from 'api/css'
import {
  getAIReviewedLocalFile,
  getAllArchivedDocuments,
  getAllDocumentsList,
  getAllowedTemplates,
  getChecklist,
  getChecklistDiff,
  getLegalEntitiesAndLocalFiles,
} from 'api/documents'
import { getTPDocumentRequirements } from 'api/documents/documentRequirements'
import { getLocalFileById, getLocalFileCheckListById } from 'api/documents/localFiles'
import { getGlobalSearchResults } from 'api/globalSearch'
import { getImportableICAsForTransaction } from 'api/intercompanyAgreement'
import {
  getAllAddress,
  getAllLegalEntities,
  getImportableEntities,
  getLegalEntityById,
  getTopFiveLegalEntity,
} from 'api/legalEntity'
import { getLegalEntityAppendices } from 'api/legalEntity/appendixFiles'
import { getCoreBusinessInfo } from 'api/legalEntity/coreBusinessInfo'
import { getCountryListOFAllLegalEntities } from 'api/legalEntity/getAllLegalEntities'
import { getAllBODsInfo } from 'api/legalEntity/getPrerequisites'
import { getArmsLengthRangeData, getLegalEntityMetricsData } from 'api/legalEntityMetrics'
import { getUserDetails } from 'api/login'
import { getLegalEntityAndTransactionsFiltersData } from 'api/mainDashboard'
import { getAppendicesList, getMasterFileByID, getMasterFileList, getMasterFileSections } from 'api/masterFile'
import { getChat, getChatList } from 'api/oecdChat'
import { getDepartmentsList, getHomePageData, getOrganizationDetails, getTitlesList } from 'api/organization'
import { getSubscriptionsList } from 'api/subscriptions'
import {
  getTemplateById,
  getTemplateList,
  getTemplatesMap,
  getTemplateVariables,
  getTransactionsBasedOnTemplate,
} from 'api/templates'
import { getOrganizations } from 'api/tenant'
import { getBenchmarkingMetricsData, getTransactionMetricsData } from 'api/transactionMetrics'
import { getImportableTransaction } from 'api/transactions'
import {
  getAllFunctionalProfileAttribute,
  getHistoricalTransaction,
  getTransactionDistribution,
  getTransactionFunctionalProfile,
  getTransactionsByBenchmark,
} from 'api/transactions'
import { getComparabilityMetricsList, getSelectedComparabilityFactors } from 'api/transactions/comparabilityMetrics'
import {
  getArmsLengthRangeTypes,
  getComparableResultConclusionsList,
  getTermsAndCharacteristics,
} from 'api/transactions/comparableResults'
import {
  economicAnalysisTransaction,
  getComplianceRegion,
  getLegalEntity,
  getTransactionAttributesByFilters,
  getTransactionType,
  getTransferPricingMethods,
} from 'api/transactions/createTransaction'
import { getFunctionalProfile, getFunctionalProfileById } from 'api/transactions/getFunctionalProfile'
import { getTransactionById } from 'api/transactions/getTransaction'
import { geAllTransactionByLegalEntityId, getTransactions } from 'api/transactions/getTransaction'
import { getTransactionMethodsList } from 'api/transactions/getTransactionMethodsList'
import { getTransactionPliMethod } from 'api/transactions/getTransactionPliMethod/getTransactionPliMethod'
import { getTransactionsBetweenCountries } from 'api/transactions/getTransactionsBetweenCountries'
import { getSameTransactionsFromPreviousYears } from 'api/transactions/importTransactions'
import { getAllUsers, getUser } from 'api/userAccess'
import { getTPForm } from 'views/transactions/components/createAndUpdateTPForm'
export const QUERIES = {
  GET_USER_DETAILS: { key: 'getUserDetails', function: getUserDetails },
  GET_TPDOCUMENT_REQUIREMENTS: { key: 'getTPDocumentRequirements', function: getTPDocumentRequirements },
  GET_ALL_FUNCTIONAL_PROFILE_ATTRIBUTE: {
    key: 'getAllFunctionalProfileAttribute',
    function: getAllFunctionalProfileAttribute,
  },
  FOREIGN_ATTRIBUTES: { key: 'foreignAttributes', function: getAllFunctionalProfileAttribute },
  GET_ALL_BENCHMARKS: { key: 'getAllBenchmarks', function: getAllBenchmarks },
  GET_BENCHMARK_BY_ID: { key: 'getBenchmarkById', function: getBenchmarkById },
  GET_IMPORTABLE_BENCHMARKS: { key: 'getImportableBenchmarks', function: getImportableBenchmarks },
  GET_DEPARTMENTS_LIST: { key: 'getDepartmentsList', function: getDepartmentsList },
  GET_LEGAL_ENTITY_BY_ID: { key: 'getLegalEntityById', function: getLegalEntityById },
  GET_LOCAL_FILE_CHECK_LIST_BY_ID: { key: 'getLocalFileCheckListById', function: getLocalFileCheckListById },
  GET_TRANSACTION_PLI_METHOD: { key: 'getTransactionPliMethod', function: getTransactionPliMethod },
  GET_SUBSCRIPTIONS: { key: 'getSubscriptions', function: getSubscriptionsList },
  GET_TITLES_LIST: { key: 'getTitlesList', function: getTitlesList },
  GET_TRANSACTION_FUNCTIONAL_PROFILE: {
    key: 'getTransactionFunctionalProfile',
    function: getTransactionFunctionalProfile,
  },
  GET_GLOBAL_SEARCH_RESULTS: { key: 'getGlobalSearchResults', function: getGlobalSearchResults },
  GET_HOME_PAGE_DATA: { key: 'getHomePageData', function: getHomePageData },
  GET_TRANSACTION_BY_BENCHMARK: { key: 'getTransactionByBenchmark', function: getTransactionsByBenchmark },
  GET_LEGAL_ENTITY: { key: 'getLegalEntity', function: getLegalEntity },
  GET_TRANSACTION_TYPE: { key: 'getTransactionType', function: getTransactionType },
  GET_FUNCTIONAL_PROFILE: { key: 'getFunctionalProfile', function: getFunctionalProfile },
  GET_FUNCTIONAL_PROFILE_BY_ID: { key: 'getFunctionalProfileById', function: getFunctionalProfileById },
  GET_TRANSFER_PRICING_METHODS: { key: 'getTransferPricingMethods', function: getTransferPricingMethods },
  GET_ORGN: { key: 'getOrgn', function: getOrganizations },
  GET_CORE_BUSINESS_INFO: { key: 'getCoreBusinessInfo', function: getCoreBusinessInfo },
  GET_LOCAL_FILE_CSS: { key: 'getLocalFileCss', function: getLocalFileCss },
  GET_ALL_ADDRESS: { key: 'getAllAddress', function: getAllAddress },
  GET_ALL_BODS_INFO: { key: 'getAllBODsInfo', function: getAllBODsInfo },
  GET_LOCAL_FILE_BY_ID: { key: 'getLocalFileById', function: getLocalFileById },
  GET_ALLOWED_TEMPLATES: { key: 'getAllowedTemplates', function: getAllowedTemplates },
  GET_LEGAL_ENTITY_APPENDICES: { key: 'getLegalEntityAppendices', function: getLegalEntityAppendices },
  GET_ALL_TP_FORMS: { key: 'getTPForm', function: getTPForm },
  GET_TRANSACTION_DISTRIBUTION_BY_TYPE: { key: 'getTransactionDistributionByType', function: getHistoricalTransaction },
  GET_ATTRIBUTES_BY_TRANSACTION: { key: 'getAttributesByTransaction', function: getTransactionAttributesByFilters },
  GET_ALL_TRANSACTION_BY_LEGAL_ENTITY_ID: {
    key: 'geAllTransactionByLegalEntityId',
    function: geAllTransactionByLegalEntityId,
  },
  ECONOMIC_ANALYSIS_TRANSACTION: { key: 'economicAnalysisTransaction', function: economicAnalysisTransaction },
  GET_COMPLIANCE_REGION: { key: 'getComplianceRegion', function: getComplianceRegion },
  GET_TRANSACTION_METHODS_LIST: { key: 'getTransactionMethodsList', function: getTransactionMethodsList },
  GET_ALL_USERS: { key: 'getAllUsers', function: getAllUsers },
  GET_USER_BY_ID: { key: 'getUser', function: getUser },
  GET_TRANSACTION_DISTRIBUTION: { key: 'getTransactionDistribution', function: getTransactionDistribution },
  GET_LEGAL_ENTITY_METRICS_DATA: { key: 'getLegalEntityMetricsData', function: getLegalEntityMetricsData },
  GET_LEGAL_ENTITY_AND_TRANSACTIONS_FILTERS_DATA: {
    key: 'getLegalEntityAndTransactionsFiltersData',
    function: getLegalEntityAndTransactionsFiltersData,
  },
  GET_TRANSACTION_METRICS_DATA: { key: 'getTransactionMetricsData', function: getTransactionMetricsData },
  GET_BENCHMARKING_METRICS_DATA: { key: 'getBenchmarkingMetricsData', function: getBenchmarkingMetricsData },
  GET_ARMS_LENGTH_RANGE_DATA: { key: 'getArmsLengthRangeData', function: getArmsLengthRangeData },
  GET_TRANSACTION_DISTRIBUTION_BY_NATURE: {
    key: 'getTransactionDistributionByNature',
    function: getTransactionDistribution,
  },
  GET_ALL_DOCUMENTS_LIST: { key: 'getAllDocumentsList', function: getAllDocumentsList },
  GET_ALL_LEGAL_ENTITIES: { key: 'getAllLegalEntities', function: getAllLegalEntities },
  GET_ALL_ARCHIVED_DOCUMENTS: { key: 'getAllArchivedDocuments', function: getAllArchivedDocuments },
  GET_LEGAL_ENTITIES_AND_LOCAL_FILES: { key: 'getLegalEntitiesAndLocalFiles', function: getLegalEntitiesAndLocalFiles },

  GET_COUNTRY_LIST_OF_ALL_LEGAL_ENTITIES: {
    key: 'getCountryListOFAllLegalEntities',
    function: getCountryListOFAllLegalEntities,
  },
  USE_ALL_TRANSACTION_LIST: { key: 'useAllTransactionList', function: getTransactions },

  GET_IMPORTABLE_ICAS_FOR_TRANSACTION: {
    key: 'getImportableICAsForTransaction',
    function: getImportableICAsForTransaction,
  },
  GET_TRANSACTIONS_BETWEEN_COUNTRIES: {
    key: 'getTransactionsBetweenCountries',
    function: getTransactionsBetweenCountries,
  },
  GET_TRANSACTIONS_BASED_ON_TEMPLATE: {
    key: 'getTransactionsBasedOnTemplate',
    function: getTransactionsBasedOnTemplate,
  },
  GET_COUNTRIES_LIST: { key: 'getCountriesList', function: getCountriesList },
  GET_ORGANIZATION_DETAILS: { key: 'getOrganizationDetails', function: getOrganizationDetails },
  GET_TRANSACTION_BY_ID: { key: 'getTransactionById', function: getTransactionById },
  GET_IMPORTABLE_ENTITIES: { key: 'getImportableEntities', function: getImportableEntities },
  GET_IMPORTABLE_TRANSACTION: { key: 'getImportableTransaction', function: getImportableTransaction },
  GET_TOP_FIVE_LEGAL_ENTITY: { key: 'getTopFiveLegalEntity', function: getTopFiveLegalEntity },
  GET_AUDIT_LOG_DETAIL: { key: 'getAuditLogDetail', function: getAuditLogDetail },
  GET_TEMPLATES_MAP: {
    key: 'getTemplatesMap',
    function: getTemplatesMap,
  },
  GET_TEMPLATE_LIST: {
    key: 'getTemplateList',
    function: getTemplateList,
  },
  GET_TEMPLATE_BY_ID: {
    key: 'getTemplateById',
    function: getTemplateById,
  },
  GET_COMPARABILITY_METRICS_LIST: {
    key: 'getComparabilityMetricsList',
    function: getComparabilityMetricsList,
  },
  GET_ARMS_LENGTH_RANGE_TYPES: {
    key: 'getArmsLengthRangeTypes',
    function: getArmsLengthRangeTypes,
  },
  GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST: {
    key: 'getComparableResultConclusionsList',
    function: getComparableResultConclusionsList,
  },
  GET_TERMS_AND_CHARACTERISTICS: {
    key: 'getTermsAndCharacteristics',
    function: getTermsAndCharacteristics,
  },
  GET_SELECTED_COMPARABILITY_FACTORS: {
    key: 'getSelectedComparabilityFactors',
    function: getSelectedComparabilityFactors,
  },
  GET_MASTER_FILE_SECTIONS: {
    key: 'getMasterFileSections',
    function: getMasterFileSections,
  },
  GET_MASTER_FILE_LIST: {
    key: 'getMasterFileList',
    function: getMasterFileList,
  },
  GET_MASTER_FILE_BY_ID: {
    key: 'getMasterFileByID',
    function: getMasterFileByID,
  },

  GET_APPENDICES_LIST: {
    key: 'getAppendicesList',
    function: getAppendicesList,
  },
  GET_CHECKLIST: {
    key: 'getChecklist',
    function: getChecklist,
  },
  GET_CHECKLIST_DIFF: {
    key: 'getChecklistDiff',
    function: getChecklistDiff,
  },
  GET_BENCHMARK_STUDY: {
    key: 'getBenchmarkingStudy',
    function: getBenchmarkingStudy,
  },
  LIST_BENCHMARK_STUDY: {
    key: 'listBenchmarkingStudy',
    function: listBenchmarkingStudy,
  },
  GET_BENCHMARK_COMPANIES_LIST: {
    key: 'getBenchmarkingCompaniesList',
    function: getBenchmarkingCompaniesList,
  },
  GET_BENCHMARK_COMPANY: {
    key: 'getBenchmarkingCompany',
    function: getBenchmarkingCompany,
  },
  GET_ACCEPT_REJECT_KEYWORDS: {
    key: 'getAcceptRejectKeywords',
    function: getAcceptRejectKeywords,
  },
  GET_CHAT: {
    key: 'getChat',
    function: getChat,
  },
  GET_CHAT_LIST: {
    key: 'getChatList',
    function: getChatList,
  },
  GET_AI_REVIEWED_LOCAL_FILE: {
    key: 'getAIReviewedLocalFile',
    function: getAIReviewedLocalFile,
  },
  GET_SAME_TRANSACTIONS_FROM_PREVIOUS_YEARS: {
    key: 'getSameTransactionsFromPreviousYears',
    function: getSameTransactionsFromPreviousYears,
  },
  GET_TEMPLATE_VARIABLES: {
    key: 'getTemplateVariables',
    function: getTemplateVariables,
  },
}
