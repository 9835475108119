import { TemplatesMapResponse, TemplateVariableResponse } from 'api/templates/types'
import { TransferPricingMethods } from 'api/transactions/createTransaction/types/complianceRegion.type'
import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import { addPropsToRenderer } from 'organisms/formWithSubmission'
import { Field } from 'organisms/formWithSubmission/types/form.types'
import { Control, UseFormSetValue } from 'react-hook-form'
import { SelectOptions } from 'types/common.types'

import RejectionMatrixField from '../component/RejectionMatrixField'
import { REJECTION_MATRIX_FIELDS } from '../RejectionMatrix'

export const getFields = ({
  setValue,
  tPMethods,
  selectedTPM,
  control,
  transactionId,
  selectedTemplate,
  rejectionMatrixTemplateOptions,
  rejectionMatrixTemplates,
  templateVariables,
}: getFieldsProps) => {
  if (!tPMethods?.length) {
    return []
  }
  const fields: (Field | Field[])[] = []

  const fieldCreator = ({
    id,
    label,
    selected,
    showAIButton,
  }: {
    id: number
    label: string
    selected: boolean
    showAIButton: boolean
  }) => {
    return {
      id: String(id),
      fieldToRenderer: RejectionMatrixField,
      label: label,
      fieldClassName: '!mb-3',
      props: {
        selected: selected,
        descriptionField: `methods.${id}.description`,
        setValue,
        control,
        transactionId,
        selectedTemplate,
        showAIButton,
        templateVariables,
      },
    }
  }

  tPMethods.forEach(method => {
    const showAIbutton = rejectionMatrixTemplates?.[
      method.abbreviation.toLowerCase().replaceAll(' ', '_')
    ]?.selected?.find(opt => opt.name == selectedTemplate)
    fields.push(
      fieldCreator({
        id: method.id,
        label: `${method.abbreviation} (${method.name})`,
        selected: Number(method.id) === Number(selectedTPM) ? true : false,
        showAIButton: !!showAIbutton && false,
      })
    )
  })

  const fieldsWithProps = addPropsToRenderer(
    [
      [
        {
          id: 'template',
          fieldToRenderer: SELECT_DROPDOWN,
          label: 'Select Template',
          fieldClassName: 'w-1/2 mr-[50%]',
        },
      ],
    ],
    [
      {
        id: 'template',
        props: {
          options: rejectionMatrixTemplateOptions,
          required: true,
          disabled: !rejectionMatrixTemplateOptions.length,
        },
      },
    ]
  )
  return fields
  return [...fieldsWithProps, ...fields]
}

type getFieldsProps = {
  transactionId?: number
  setValue: UseFormSetValue<REJECTION_MATRIX_FIELDS>
  tPMethods?: TransferPricingMethods[]
  selectedTPM?: number
  selectedTemplate: string | undefined
  rejectionMatrixTemplateOptions: SelectOptions[]
  rejectionMatrixTemplates: TemplatesMapResponse['rejection_matrix']
  control: Control<REJECTION_MATRIX_FIELDS>
  templateVariables?: TemplateVariableResponse[]
}
