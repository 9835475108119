import { CheckCircle } from '@mui/icons-material'
import { ColumnDef } from '@tanstack/react-table'
import { TransferPricingMethods } from 'api/transactions/createTransaction/types/complianceRegion.type'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'

export const reorderMethodsColumns = ({
  selectedTPM,
}: {
  selectedTPM?: number
}): ColumnDef<TransferPricingMethods>[] => {
  return [
    {
      header: '',
      id: 'name',
      accessorKey: 'attribute_name',
      size: '100%' as unknown as number,
      cell: props => {
        const selected = Number(props.row.original.id) === Number(selectedTPM)
        const label = `${props.row.original.abbreviation} (${props.row.original.name})`
        return (
          <Typography
            type="semibold"
            variant={Variant.Callout}
            className={cx('flex items-center gap-2 -mr-2', selected ? 'text-gray-700' : 'text-red-400')}>
            {label}
            {selected ? (
              <span className="text-green-600 inline-flex	text-footnote font-semibold items-center gap-1">
                <CheckCircle className="text-green-600 w-[14px] h-[14px]" /> Selected
              </span>
            ) : null}
          </Typography>
        )
      },
    },
  ]
}
