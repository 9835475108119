import Button, { ButtonSize } from 'components/button'
import Loading from 'components/loading'
import Typography, { Variant } from 'components/typography'
import { useCallback, useEffect, useRef } from 'react'

const WizardItemRemovalWarning = ({
  isLoading = false,
  showWindow,
  title,
  setShowWindow,
  confirmationMessage,
  doConfirmationAction,
  confirmationCtaText = 'Yes, I am sure',
}: WizardItemRemovalWarningProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const handleModalClose = useCallback(() => {
    setShowWindow(false)
  }, [setShowWindow])
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleModalClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleModalClose])

  return showWindow ? (
    <>
      <div className="absolute inset-0 backdrop-blur-sm ">
        <div
          ref={modalRef}
          className="flex gap-6 absolute bottom-5 left-6 right-6 flex-col justify-between  rounded-lg shadow-[0px_0px_80px_0px_rgba(59,130,246,0.24)] bg-[#0A0929] border border-solid border-indigo-900 p-6">
          {!!title && (
            <Typography variant={Variant.Heading2} type="semibold" className="text-gray-300">
              {title}
            </Typography>
          )}
          {isLoading ? (
            <div className="flex justify-center items-center h-[5rem]">
              <Loading className="w-full h-full bg-white" />
            </div>
          ) : (
            <>
              <Typography variant={Variant.Callout} type="semibold" className="text-gray-300">
                {confirmationMessage}
              </Typography>
              <div className="flex w-full justify-between gap-3">
                <Button
                  onClick={handleModalClose}
                  size={ButtonSize.Small}
                  className="flex !flex-row items-center !gap-0.5 !py-0  bg-indigo-950 hover:!bg-indigo-900 !text-indigo-300">
                  Cancel
                </Button>
                <Button
                  onClick={doConfirmationAction}
                  size={ButtonSize.Small}
                  className="flex !flex-row items-center !gap-1 !py-0  bg-indigo-400 hover:!bg-indigo-500 !text-indigo-950">
                  {confirmationCtaText}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : null
}

interface WizardItemRemovalWarningProps {
  isLoading?: boolean
  showWindow: boolean
  confirmationMessage: string | React.ReactNode
  doConfirmationAction: () => void
  confirmationCtaText?: string
  setShowWindow: (x: boolean) => void
  title?: string
}

export default WizardItemRemovalWarning
