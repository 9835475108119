import axios from 'api'
import {
  CreateTPFormResponse,
  UpdateTPFormNameResponse,
} from 'api/transactions/createTransaction/types/UploadUserFileProps'
import { TPFormResponse } from 'api/transactions/getTransaction/types'

export const createTPForm = (variables: CreateTPFormResponse) => {
  return axios.post<TPFormResponse>(`api/v1/org/{organization}/dms/tp_forms/generate_tp_form/`, variables)
}
export const getTPForm = ({ year, legal_entities }: { year?: number; legal_entities?: string }) => {
  return axios.get<TPFormResponse[]>(`api/v1/org/{organization}/dms/tp_forms/`, {
    params: { year, legal_entities },
  })
}
export const deleteTPForm = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/dms/tp_forms/${id}/`)
}
export const updateTPFormName = ({ id, variables }: { id: number; variables: UpdateTPFormNameResponse }) => {
  return axios.put<TPFormResponse>(`/api/v1/org/{organization}/dms/tp_forms/${id}/rename_tp_form/`, variables)
}

export const archiveTPForm = ({ id }: { id: number }) => {
  return axios.put<TPFormResponse>(`/api/v1/org/{organization}/dms/tp_forms/${id}/archive/`)
}
