import { useMutation } from '@tanstack/react-query'
import { setTaxRulingAppendices } from 'api/documents'
import { getIcons } from 'assets/index'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import cx from 'classnames'
import classNames from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import { InputVariant } from 'components/input'
import Loading from 'components/loading'
import UploadDocuments from 'components/uploadDocuments'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { INPUT_FIELDS, SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { relevantTaxRulingTableData } from '../RelavantTaxDocsTab'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  documentTypeOptions: SelectOptions[]
  leId: number
  selectedDocument?: relevantTaxRulingTableData
}

function UploadTaxRulingsDocument({ isOpen, onClose, documentTypeOptions, selectedDocument, leId }: Props) {
  const { refetchChecklist } = useReportWizardContext()
  const { control, setValue, getValues, watch, formState, reset, handleSubmit } = useForm<FIELD_VALUES>({
    defaultValues: {},
  })

  const handleClose = useCallback(() => {
    onClose()
    reset({
      documentType: documentTypeOptions?.[0],
      file: undefined,
      file_name: '',
    })
  }, [documentTypeOptions, onClose, reset])

  const setFile = useCallback(
    (file: File) => {
      setValue('file', file, {
        shouldDirty: true,
      })
      if (!getValues('file_name'))
        setValue('file_name', file.name, {
          shouldValidate: true,
          shouldDirty: true,
        })
    },
    [getValues, setValue]
  )

  const updateTaxRulingMutation = useMutation(setTaxRulingAppendices)

  const file = watch('file')
  const documentType = watch('documentType')

  const handleSave = useCallback(
    (data: FIELD_VALUES) => {
      if (
        updateTaxRulingMutation.isLoading ||
        !data.file ||
        !(selectedDocument?.documentType || data.documentType?.value)
      ) {
        return
      }
      updateTaxRulingMutation.mutate(
        {
          leId: leId,
          payload: {
            [(selectedDocument?.documentType || data.documentType?.value) + '_file']:
              typeof data.file == 'string' ? undefined : data.file,
            [(selectedDocument?.documentType || data.documentType?.value) + '_file_name']:
              data.file == undefined
                ? undefined
                : data.file_name?.trim() ||
                  (data.file instanceof File
                    ? data.file.name
                    : selectedDocument?.label || String(data.documentType?.label || '') || 'Tax ruling document'),
          },
        },
        {
          onSuccess: () => {
            toast.success('Document updated!!')
            refetchChecklist()
            handleClose()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    },
    [
      handleClose,
      leId,
      refetchChecklist,
      selectedDocument?.documentType,
      selectedDocument?.label,
      updateTaxRulingMutation,
    ]
  )

  const disabled =
    (!!selectedDocument && !Object.keys(formState.dirtyFields).length) ||
    !file ||
    !documentType?.value ||
    updateTaxRulingMutation.isLoading

  useEffect(() => {
    if (isOpen && selectedDocument) {
      reset({
        documentType: {
          label: selectedDocument.label,
          value: selectedDocument.documentType,
        },
        file: selectedDocument.file,
        file_name: selectedDocument.file_name || '',
      })
    } else if (isOpen) {
      reset(data => {
        return {
          documentType: documentTypeOptions?.[0],
          file: data.file instanceof File ? data.file : undefined,
          file_name: '',
        }
      })
    }
  }, [documentTypeOptions, isOpen, reset, selectedDocument])

  useEffect(() => {
    if (!isOpen && file instanceof File) {
      onClose()
    }
  }, [file, isOpen, onClose])

  if (!documentTypeOptions.length && !selectedDocument) {
    return null
  }

  if (!isOpen) {
    return (
      <UploadDocuments
        fileName={typeof file == 'string' ? selectedDocument?.file_name || selectedDocument?.label : undefined}
        label="Upload File"
        file={file instanceof File ? file : null}
        setFile={setFile}
        fileUrl={typeof file == 'string' ? file : undefined}
        accept=".pdf"
        className="w-full"
        darkMode
      />
    )
  }

  return (
    <>
      {' '}
      <div className="w-full space-y-4">
        <div className="w-full flex items-center justify-between text-white text-callout font-semibold">
          {selectedDocument ? 'Edit' : 'Add'} Appendix{' '}
          <div
            className="hover:backdrop-contrast-50 hover:rounded-full flex flex-col items-center justify-center -my-1 p-1 cursor-pointer"
            onClick={handleClose}>
            {getIcons(IconsType.cross, {
              className: cx('w-[18px] h-[18px]'),
              pathClassName: 'fill-white',
            })}
          </div>
        </div>
        <SELECT_DROPDOWN
          id="documentType"
          label="Select document type"
          control={control}
          required
          isDark
          className="text-gray-300"
          newSelectDropDown={false}
          options={documentTypeOptions}
        />
        <INPUT_FIELDS
          newInput={false}
          control={control}
          id={'file_name'}
          label={'Document Name'}
          placeholder={'Enter Name'}
          variant={InputVariant.Dark}
        />
        <UploadDocuments
          fileName={typeof file == 'string' ? selectedDocument?.file_name || selectedDocument?.label : undefined}
          label="Supporting Document"
          file={file instanceof File ? file : null}
          setFile={setFile}
          fileUrl={typeof file == 'string' ? file : undefined}
          accept=".pdf"
          darkMode
          preview={true}
        />
        <Button
          disabled={disabled}
          onClick={handleSubmit(handleSave)}
          className={classNames('ms-auto', {
            '!bg-gray-800 !text-gray-600': disabled,
            '!bg-indigo-950 !text-indigo-300': !disabled,
          })}
          isDefaultSize={false}
          icon={IconsType.save}
          iconPathClassName={disabled ? 'fill-gray-600' : 'fill-indigo-300'}
          variant={ButtonVariant.Primary}>
          Save
        </Button>
      </div>
      {updateTaxRulingMutation.isLoading && (
        <Loading className="bg-[#00000011] backdrop-blur-sm absolute inset-0 flex items-center justify-center" />
      )}
    </>
  )
}

type FIELD_VALUES = {
  documentType?: SelectOptions
  file_name?: string
  file?: File | null | string
}

export default UploadTaxRulingsDocument
