import { default as MuiDrawer, DrawerProps } from '@mui/material/Drawer'
import cx from 'classnames'
import { useEffect, useState } from 'react'

import DrawerHeader from './components/drawerHeader/DrawerHeader'

const Drawer = ({
  isOpen,
  onClose,
  children,
  className,
  title,
  hideDrawer,
  zIndex = 50,
  titleClassName,
  containerClassName,
  slider = false,
  closeIconClassName,
  closeIconPathClassName = 'fill-blue-900',
  muiDrawerProps,
}: CustomDrawerProps) => {
  const [delayedOpen, setDelayedOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setDelayedOpen(true), 0)
      return () => clearTimeout(timer)
    } else {
      setDelayedOpen(false)
    }
  }, [isOpen])

  return (
    <MuiDrawer
      open={delayedOpen}
      onClose={onClose}
      style={{
        zIndex: zIndex,
        ...(slider ? { width: '100%' } : { height: 'calc(100vh - 2rem)' }),
        ...(hideDrawer && { display: 'none' }),
      }}
      anchor={slider ? 'bottom' : 'right'}
      transitionDuration={500}
      disableEnforceFocus={true}
      slotProps={{
        ...(slider
          ? {
              backdrop: {
                className: 'bg-sliderBackdropGradient',
                style: {
                  backgroundColor: 'unset',
                },
              },
            }
          : {
              backdrop: {
                className: 'bg-drawerBackdropGradient',
                style: {
                  backgroundColor: 'unset',
                },
              },
            }),
      }}
      PaperProps={{
        className: cx(
          {
            'absolute right-0 shadow-[-12px_4px_30px_5px_rgba(24,77,195,0.12)] bg-blue-50 top-0 rounded-lg m-4 min-w-[60%] w-[888px] max-w-[calc(100%-2rem)]':
              !slider,
            'absolute right-0 shadow-[-4px_-12px_30px_5px_rgba(24,77,195,0.12)] left-0 bg-blue-50 bottom-0 rounded-lg m-6 min-h-[30%] h-[588px]  max-h-[calc(100%-4rem)]':
              slider,
          },
          {
            'translate-x-0': isOpen,
            'translate-x-full': !isOpen,
          },
          containerClassName
        ),
      }}
      {...muiDrawerProps}>
      <div
        className={cx('flex flex-col items-start p-4 min-h-full', className, {
          'gap-4': title,
        })}>
        <DrawerHeader
          closeIconClassName={closeIconClassName}
          closeIconPathClassName={closeIconPathClassName}
          handleClose={onClose}
          title={title}
          titleClassName={titleClassName}
        />
        {children}
      </div>
    </MuiDrawer>
  )
}

interface CustomDrawerProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: VoidFunction
  title: string | React.ReactNode
  containerClassName?: string
  className?: string
  hideDrawer?: boolean
  closeIconClassName?: string
  titleClassName?: string
  closeIconPathClassName?: string
  muiDrawerProps?: DrawerProps
  slider?: boolean
  zIndex?: number
}

export default Drawer
