import { ColumnDef } from '@tanstack/react-table'
import cx from 'classnames'
import FlagReader from 'components/flagReader/FlagReader'
import Typography, { Variant } from 'components/typography'
import { formatAsPercent } from 'utils/numberUtils'

import { FinancialData, FinancialDataEntityTableData } from '../types/financialData.types'

export function filterDataByYear(data: FinancialData[], year: number): { [key: string]: string | null } {
  const yearStr = year.toString() as keyof FinancialData

  return data.reduce((filteredData: { [key: string]: string | null }, item: FinancialData) => {
    if (item.hasOwnProperty(yearStr)) {
      filteredData[item.key as string] =
        item[yearStr] === '' || item[yearStr] === undefined ? null : (item[yearStr] as string)
    }
    return filteredData
  }, {})
}

export function getDataByYear(
  data?: { [key: string]: number | null | string }[]
): Map<number, { [key: string]: number | null | string }[]> {
  const dataByYear = new Map<number, { [key: string]: number | null | string }[]>()

  data?.forEach(item => {
    if (typeof item.year == 'number') {
      if (dataByYear.has(item.year)) {
        dataByYear.get(item.year)?.push(item)
      } else {
        dataByYear.set(item.year, [item])
      }
    }
  })

  return dataByYear
}

export const mappedYearsFinancialStructureData = (
  dataByYear: Map<number, { [key: string]: number | null | string }[]>,
  year: number
) => dataByYear?.get(year)?.[0]

export const getFinancialEntityTableColumns = ({
  testingMethodology,
  startYear,
  endYear,
  openPreviewModal,
  handleLEClick,
  isReadOnly,
}: {
  testingMethodology?: 'testing_p_and_l' | 'testing_a_policy' | string
  startYear: number
  endYear: number
  handleLEClick: (id: number) => void
  isReadOnly: boolean
  openPreviewModal?: (data: FinancialDataEntityTableData) => void
}): ColumnDef<FinancialDataEntityTableData>[] => {
  const nameColumn: ColumnDef<FinancialDataEntityTableData> = {
    header: 'Legal Entity Name',
    accessorFn: originalRow => originalRow.entity.name_abbreviation || originalRow.entity.name,
    sortingFn: 'alphanumeric',
    cell(data) {
      return (
        <div
          onClick={() => handleLEClick(data.row.original.entity.id)}
          className="flex items-center gap-1 cursor-pointer">
          <FlagReader country={data.row.original.entity.country} />
          <Typography variant={Variant.Callout} type="semibold" className="max-w-[30rem] break-words text-blue-800">
            {data.row.original.entity.name_abbreviation || data.row.original.entity.name}
          </Typography>
        </div>
      )
    },
    size: 200,
  }
  const yearColumns: ColumnDef<FinancialDataEntityTableData>[] = getYearRange(startYear, endYear).map(currentYear => {
    const col: ColumnDef<FinancialDataEntityTableData> = {
      header: `${currentYear}`,
      accessorFn: data => {
        return Number(data.financial_structure?.find(val => val.year == currentYear)?.profit_level_indicator || 0)
      },
      sortingFn: 'alphanumeric',
      size: 100,
      cell(data) {
        const pli = data.row.original.financial_structure?.find(val => val.year == currentYear)?.profit_level_indicator
        return (
          <Typography
            variant={Variant.Callout}
            type="semibold"
            className={cx({
              'text-gray-300': pli == null,
            })}>
            {pli != null ? formatAsPercent(String(pli), 2) : 'No data'}
          </Typography>
        )
      },
    }
    return col
  })
  const averagePliColumn: ColumnDef<FinancialDataEntityTableData> = {
    header: `PLI Average`,
    accessorFn: data => {
      return Number(data.averagePli || 0)
    },
    sortingFn: 'alphanumeric',
    size: 100,
    cell(data) {
      const pliAverage = data.row.original.averagePli
      return (
        <Typography
          variant={Variant.Callout}
          type="semibold"
          className={cx({
            'text-gray-300': pliAverage == null,
          })}>
          {pliAverage != null ? `${pliAverage}%` : 'No data'}
        </Typography>
      )
    },
  }
  const policyRateColumn: ColumnDef<FinancialDataEntityTableData> = {
    header: `Policy Rate`,
    accessorFn: data => {
      return Number(data.policy_rate || 0)
    },
    sortingFn: 'alphanumeric',
    size: 200,
    cell(data) {
      const policyRate = data.row.original.policy_rate
      return (
        <Typography
          variant={Variant.Callout}
          type="semibold"
          className={cx({
            'text-gray-300': policyRate == null,
          })}>
          {policyRate != null ? policyRate + '%' : 'No data'}
        </Typography>
      )
    },
  }
  const actionColumn: ColumnDef<FinancialDataEntityTableData> = !isReadOnly
    ? {
        id: 'actions',
        header: 'Actions',
        cell(data) {
          let hasFinancialData = false
          if (testingMethodology == 'testing_p_and_l') {
            if (
              data.row.original.averagePli != null &&
              !!data.row.original.gaap_type &&
              !!data.row.original.tested_party_currency
            ) {
              hasFinancialData = true
            }
          }
          if (testingMethodology == 'testing_a_policy') {
            if (data.row.original.policy_rate != null && !!data.row.original.testing_policy_description) {
              hasFinancialData = true
            }
          }
          return (
            <Typography
              variant={Variant.Callout}
              type="semibold"
              onClick={() => openPreviewModal && openPreviewModal(data.row.original)}
              className="max-w-[10rem] text-blue-800 text-center break-words cursor-pointer">
              {hasFinancialData ? 'Edit' : 'Add'}
            </Typography>
          )
        },
        size: 50,
      }
    : {
        id: 'actions',
        header: 'Actions',
        cell(data) {
          let hasFinancialData = false
          if (testingMethodology == 'testing_p_and_l') {
            if (
              data.row.original.averagePli != null &&
              !!data.row.original.gaap_type &&
              !!data.row.original.tested_party_currency
            ) {
              hasFinancialData = true
            }
          }
          if (testingMethodology == 'testing_a_policy') {
            if (data.row.original.policy_rate != null && !!data.row.original.testing_policy_description) {
              hasFinancialData = true
            }
          }
          if (hasFinancialData) {
            return (
              <Typography
                variant={Variant.Callout}
                type="semibold"
                onClick={() => openPreviewModal && openPreviewModal(data.row.original)}
                className="max-w-[10rem] text-blue-800 text-start break-words cursor-pointer">
                View
              </Typography>
            )
          }
        },
        size: 50,
      }
  if (testingMethodology == 'testing_p_and_l') {
    return [nameColumn, ...yearColumns, averagePliColumn, actionColumn]
  }
  if (testingMethodology == 'testing_a_policy') {
    return [nameColumn, policyRateColumn, actionColumn]
  }
  return [nameColumn, actionColumn]
}

export const getYearRange = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => end - i)
}
