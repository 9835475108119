import { IconProps } from 'assets'

const DualScreen = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M6 17.6L12 20V6.4L6 4V17.6ZM5.25 19.45C4.86667 19.3 4.5625 19.0583 4.3375 18.725C4.1125 18.3917 4 18.0167 4 17.6V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2L12.7 4.525C13.0833 4.675 13.3958 4.92083 13.6375 5.2625C13.8792 5.60417 14 5.98333 14 6.4V20C14 20.7167 13.7083 21.2708 13.125 21.6625C12.5417 22.0542 11.9167 22.1167 11.25 21.85L5.25 19.45ZM12 19V17H18V4H6V2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V17C20 17.55 19.8042 18.0208 19.4125 18.4125C19.0208 18.8042 18.55 19 18 19H12Z"
        fill="black"
      />
    </svg>
  )
}

export default DualScreen
