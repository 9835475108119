import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import { BaseUrl } from 'constants/apis'
import { COOKIE } from 'constants/cookie'
import { logoutEvent } from 'hooks/useAuth/event'
import { checkAndUpdateAccessToken } from 'hooks/useAuth/useAuth'
import { getCookie } from 'hooks/useCookie/useCookie.helper'
import { jwtDecode } from 'jwt-decode'
import { getMockResponse, mockAPIAdapter } from 'mock'
import { getOrgIdFromUrl, sleep } from 'utils/utils'

import { CustomAxiosError } from './types'

const api = axios.create({
  baseURL: BaseUrl,
  validateStatus: status => status >= 200 && status < 300,
  headers: {
    Accept: 'application/json, text/plain, */*',
  },
})

api.interceptors.request.use(mockAPIAdapter)

api.interceptors.request.use(
  async config => {
    let authToken = getCookie(COOKIE.authToken)

    if (authToken) {
      const decodedToken = jwtDecode(authToken)

      const timeDifferenceInMin = ((decodedToken.exp || 0) - Date.now() / 1000) / 60

      if (timeDifferenceInMin < 1) {
        const newToken = await checkAndUpdateAccessToken()
        if (!newToken) {
          throw new Error('Failed to fetch access token')
        }
        authToken = newToken
      }
      config.headers.Authorization = `Auth0Bearer ${authToken}`
    }
    const isTrainingUser = sessionStorage.getItem('isTrainingUser') == 'true'
    if (isTrainingUser) {
      config.headers.set('X-User-Type', 'TrainingUser')
    }
    const orgId = sessionStorage.getItem('orgId') ?? getOrgIdFromUrl() ?? localStorage.getItem('orgId')

    if (config.url?.includes('{organization}')) {
      if (orgId && !!Number(orgId)) {
        config.url = config.url.replace('{organization}', orgId)
      }
    }
    if (config.url) {
      // Ensure the URL has a trailing slash, except for query params
      if (!config.url.endsWith('/') && !config.url.includes('?')) {
        config.url += '/'
      }
    }

    return config
  },
  error => Promise.reject(error)
)

api.interceptors.response.use(
  response => {
    return response
  },
  async (error: CustomAxiosError) => {
    try {
      // Check if the request came from a mocked API
      if (error.config && error.config.isMock) {
        const response = getMockResponse(error)
        await sleep(2500) // sleeping for few seconds for developer to visualise other states
        return response
      }

      const originalRequest = error.config

      const maxRetry = 1
      const retryCount = originalRequest?.retryCount || 0

      const authTokenError = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Error for an Auth0 error instance
        error.response?.data?.detail === 'token is expired' ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Error for an Auth0 error instance
        error.response?.data?.detail === 'User not found' ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Error for an Auth0 error instance
        error.response?.data?.detail === 'Authentication credentials were not provided.'

      if (error.response?.status === 401 && authTokenError && retryCount < maxRetry) {
        let authToken
        originalRequest.retryCount = retryCount + 1
        if (typeof window !== 'undefined' && window !== undefined) {
          authToken = await checkAndUpdateAccessToken()
        }
        if (authToken) {
          originalRequest.headers.Authorization = `Auth0Bearer ${authToken}`
          return axios(originalRequest)
        } else {
          throw new Error('Refresh token failed', { cause: error.cause })
        }
      }

      if (error.response?.status === 401 && authTokenError && retryCount >= maxRetry) {
        logoutEvent.emit('logout')
        return
      }

      Sentry.captureException(error)
      return Promise.reject(error)
    } catch (error) {
      logoutEvent.emit('logout')
      Sentry.captureException(error)
      return Promise.reject(error)
    }
  }
)

export default api
