import { Info } from '@mui/icons-material'
import { UseQueryResult } from '@tanstack/react-query'
import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { attributes } from 'api/transactions/getTransaction/types'
import HalfArrow from 'assets/icons/halfArrow'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import EditSuccessFullModal from 'components/editSuccesfullModal'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

import AddFunctionModal from './components/addFunctionsModal'
import OtherTabKeyTable from './components/otherTabKeyTable'
import SelectEntities from './components/selectedEntities'
import { getAttributeTableData } from './helpers/functionSelector.helpers'
import { GroupedEntitiesByAttributes } from './types/types'

const FunctionSelector = ({
  currentAttributes,
  currentTransaction,
  selectedTransactionId,
  transactionRefetch,
  functionProfileRefetch,
  selectedAttributes,
  unselectedAttributes,
  tabKey,
}: FunctionSelectorProps): JSX.Element => {
  const [isOtherModal, setIsOtherModal] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isFunctionModalOpen, setIsFunctionModalOpen] = useState<boolean>(false)
  const [attributeId, setAttributeId] = useState<number>(0)
  const [selectedAttributeData, setSelectedAttributeData] = useState<FunctionalAttributes>()
  const Router = useRouter()

  const isEdit = Router.query.isEdit === 'true'

  const groupedEntitiesByAttributes = useMemo(() => {
    const entitiesByAttributes: GroupedEntitiesByAttributes = {}

    currentAttributes?.forEach(attribute => {
      const attributeId = attribute.attribute_id
      if (!entitiesByAttributes[attributeId]) {
        entitiesByAttributes[attributeId] = {
          id: attribute.id,
          basic_info: attribute.basic_info,
          added_by_integral: !!attribute.added_by_integral,
          attribute_name: attribute.attribute_name,
          attribute_type: attribute.attribute_type,
          data: [],
        }
      }
      if (attribute.primary_entity_attribute) {
        entitiesByAttributes[attributeId].data.push({
          id: attribute.id,
          isActive: true,
          weight: attribute.primary_entity_attribute.weight,
          description: attribute.primary_entity_attribute.description,
          legal_entity: currentTransaction.primary_entity,
          is_common_attribute: false,
        })
      }
      if (attribute.group_attribute) {
        entitiesByAttributes[attributeId].data.push({
          id: attribute.id,
          isActive: true,
          weight: attribute.group_attribute.weight,
          description: attribute.group_attribute.description,
          legal_entity: null,
          is_common_attribute: true,
        })
      }
      if (attribute.individual_transaction_attributes) {
        attribute.individual_transaction_attributes.forEach(associatedEntityAttribute => {
          const entity = currentTransaction.individual_transactions.find(
            txn => txn.id === associatedEntityAttribute.individual_transaction
          )?.associated_entity
          if (entity) {
            entitiesByAttributes[attributeId].data.push({
              id: attribute.id,
              isActive: true,
              weight: associatedEntityAttribute.weight,
              description: associatedEntityAttribute.description,
              legal_entity: entity,
              is_common_attribute: false,
            })
          }
        })
      }
    })

    return entitiesByAttributes
  }, [currentAttributes, currentTransaction.individual_transactions, currentTransaction.primary_entity])

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false)
    setAttributeId(0)
    setSelectedAttributeData(undefined)
  }

  // on modal close cleanup
  useEffect(() => {
    if (!isFunctionModalOpen) {
      setAttributeId(0)
      setSelectedAttributeData(undefined)
    }
  }, [isFunctionModalOpen])

  useEffect(() => {
    if (isEdit && isEditModalOpen) {
      setTimeout(() => {
        handleCloseEditModal()
        Router.push({
          pathname: `${ROUTES.TRANSACTION_MANAGEMENT}/${currentTransaction.id}`,
          query: {
            txnName: currentTransaction.name,
            year: Router.query.year,
          },
        })
      }, 1000)
    }
  }, [Router, currentTransaction.id, currentTransaction.name, isEdit, isEditModalOpen])

  const tableData = useMemo(() => getAttributeTableData(unselectedAttributes), [unselectedAttributes])

  const handleModalOpen = useCallback((data: FunctionalAttributes) => {
    setAttributeId(data.id)
    setSelectedAttributeData(data)
    setIsOtherModal(false)
    setTimeout(() => {
      setIsFunctionModalOpen(true)
    }, 0)
  }, [])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const handleAddOtherFunctionModal = useCallback(
    (isSource: boolean) => () => {
      console.log('is source', isSource)
      setIsOtherModal(true)
      setIsFunctionModalOpen(true)
    },
    []
  )

  // Extended functional attribute contains entity information for the edit other than common metadata
  const selectedAttributeDataExtended = groupedEntitiesByAttributes[attributeId] || {
    ...selectedAttributeData,
    id: undefined,
    attribute_id: selectedAttributeData?.id,
    attribute_name: selectedAttributeData?.name,
  }

  return (
    <div>
      <BorderlessBox
        label={
          <div className="flex items-center gap-1">
            <Info className="h-4 w-4 text-orange-400" />
            <Typography variant={Variant.Callout} className="text-gray-600">
              {`You can add new ${tabKey}s which can be viewed in Selected ${capitalizeFirstLetter(tabKey)}s panel.`}
            </Typography>
          </div>
        }
        topRightComponents={
          <Button
            variant={ButtonVariant.Tertiary}
            icon={IconsType.plus}
            onClick={handleAddOtherFunctionModal(false)}
            className="text-blue-900 ml-auto">
            New {capitalizeFirstLetter(tabKey)}
          </Button>
        }>
        <div className="flex justify-between w-full gap-6 mt-2">
          <OtherTabKeyTable
            attributesOnTabKey={unselectedAttributes}
            handleModalOpen={handleModalOpen}
            tableData={tableData}
            tabKey={tabKey}
          />
          <div className="flex flex-col gap-2 items-center justify-center">
            <HalfArrow />
            <HalfArrow className="rotate-180" />
          </div>

          <SelectEntities
            selectedTransactionId={selectedTransactionId}
            attributeId={attributeId}
            setAttributeId={setAttributeId}
            setSelectedAttributeData={setSelectedAttributeData}
            transactionRefetch={transactionRefetch}
            groupedEntitiesByAttributes={groupedEntitiesByAttributes}
            functionProfileRefetch={functionProfileRefetch}
            attributesOnTabKey={selectedAttributes}
            currentTransaction={currentTransaction}
            tabKey={tabKey}
          />
        </div>
      </BorderlessBox>

      {isFunctionModalOpen && (
        <AddFunctionModal
          hideIntegralLanguageButton={!selectedAttributeData?.default_description}
          isFunctionModalOpen={isFunctionModalOpen}
          setIsFunctionModalOpen={setIsFunctionModalOpen}
          attributeId={attributeId}
          transactionRefetch={transactionRefetch}
          functionProfileRefetch={functionProfileRefetch}
          tabKey={tabKey}
          currentAttributeData={selectedAttributeDataExtended}
          currentTransaction={currentTransaction}
          isOtherModal={isOtherModal}
          key={`${attributeId}-${selectedTransactionId}`}
        />
      )}
      <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
        <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
          <Button
            isDefaultSize={false}
            onClick={handleCancel}
            iconCLass="w-5 h-5 transform rotate-180"
            iconPathClassName="stroke-blue900"
            icon={IconsType.arrowRight}
            variant={ButtonVariant.Secondary}>
            Back
          </Button>
        </div>
      </Portal>
      <EditSuccessFullModal
        isOpen={isEditModalOpen}
        handleClose={handleCloseEditModal}
        txnName={currentTransaction.name}
      />
    </div>
  )
}

interface FunctionSelectorProps {
  currentTransaction: TransactionByIdResponse
  currentAttributes: attributes[]
  selectedTransactionId: number
  transactionRefetch: VoidFunction
  functionProfileRefetch: UseQueryResult['refetch']
  selectedAttributes: FunctionalAttributes[]
  unselectedAttributes: FunctionalAttributes[]
  tabKey: string
}

export default memo(FunctionSelector)
