import { useMutation, useQuery } from '@tanstack/react-query'
import { updateEconomicAnalysisTransaction } from 'api/transactions'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import CustomDropDown from 'components/CustomDropDown'
import Loading from 'components/loading'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { usePliMethods } from 'hooks/usePliMethods'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { generateYearOptions } from 'lib/getYears'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import AddFinancialDataModal from './components/AddFinancialDataModal/AddFinancialDataModal'
import ImportFinancialDataModal from './components/ImportFinancialDataModal/ImportFinancialDataModal'
import { getFinancialEntityTableColumns } from './helpers/financialData.helpers'
import type { FinancialDataEntityTableData } from './types/financialData.types'

export interface FORM_FIELDS {
  from_year: number
  to_year: number
}

const FinancialDataComponent = ({
  transactionResponse = {} as TransactionByIdResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionFetching,
  transactionByIdLoading,
  onTabChange,
}: FinancialDataProps) => {
  const [selectedEntity, setSelectedEntity] = useState<FinancialDataEntityTableData>()
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()
  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const openPreviewModal = useCallback((data: FinancialDataEntityTableData) => {
    setSelectedEntity(data)
    setShowPreviewModal(true)
  }, [])
  const closePreviewModal = useCallback(() => {
    setSelectedEntity(undefined)
    setShowPreviewModal(false)
  }, [])
  const {
    financial_structure_start_year,
    financial_structure_end_year,
    pli_method,
    testing_methodology: testingMethodology,
    is_primary_entity_tested_party,
    transfer_pricing_method,
  } = economicAnalysisTransactionResponse?.[0]
  const router = useRouter()

  const { year } = router.query
  const { refetchChecklist } = useReportWizardContext()
  const { setValue, watch, handleSubmit, reset, getValues, formState } = useForm<FORM_FIELDS>({})

  const { from_year, to_year } = watch()
  const startYear = useMemo(() => {
    return financial_structure_start_year || (Number(year) || new Date().getFullYear()) - 2
  }, [financial_structure_start_year, year])

  const endYear = useMemo(() => {
    return financial_structure_end_year || Number(year) || new Date().getFullYear()
  }, [financial_structure_end_year, year])

  useEffect(() => {
    reset({
      from_year: startYear,
      to_year: endYear,
    })
  }, [endYear, financial_structure_end_year, financial_structure_start_year, reset, startYear, year])

  const { data: sameTransactions, isFetching: sameTransactionsFetching } = useQuery({
    queryKey: [QUERIES.GET_SAME_TRANSACTIONS_FROM_PREVIOUS_YEARS.key, transactionResponse?.id],
    queryFn: () => QUERIES.GET_SAME_TRANSACTIONS_FROM_PREVIOUS_YEARS.function({ id: transactionResponse?.id }),
    enabled: !!transactionResponse?.id,
  })

  const [showImportFinancialDataModal, setShowImportFinancialDataModal] = useState(false)
  const closeImportFinancialDataModal = useCallback(() => {
    setShowImportFinancialDataModal(false)
  }, [])

  const importableTransactions = useMemo(() => {
    if (testingMethodology == 'testing_p_and_l') {
      return (
        sameTransactions?.filter(txn => {
          let hasFinancialData = false

          if (!financial_structure_start_year || !financial_structure_end_year) return false

          Object.values(txn.economic_analysis?.financial_structure || {}).forEach(financialData => {
            financialData.forEach(data => {
              if (data.year >= financial_structure_start_year && data.year <= financial_structure_end_year) {
                hasFinancialData = true
              }
            })
          })
          return hasFinancialData
        }) || []
      )
    } else {
      return (
        sameTransactions?.filter(txn => {
          return txn.economic_analysis?.policy_testing?.length
        }) || []
      )
    }
  }, [testingMethodology, sameTransactions, financial_structure_start_year, financial_structure_end_year])

  const updateEconomicAnalysisMutation = useMutation(updateEconomicAnalysisTransaction, {
    onSuccess: () => {
      reset({ ...getValues() })
      toast.success('Economic analysis updated successfully')
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      reset({ ...getValues() })
      getToastErrorMessage(error)
    },
  })
  const handleYearChange = useCallback(
    async (data: FORM_FIELDS) => {
      const dataOutsideRange =
        financial_structure_start_year && financial_structure_end_year
          ? financial_structure_start_year < from_year || financial_structure_end_year > to_year
          : false
      if (dataOutsideRange) {
        const getYearChangeConfirmation = getConfirmation(
          <>The data outside the selected year range will be deleted. Do you want to proceed?</>
        )

        const canChangeYear = await getYearChangeConfirmation
        if (canChangeYear) {
          updateEconomicAnalysisMutation.mutate({
            id: transactionResponse?.economic_analysis?.id || 0,
            financial_structure_end_year: data.to_year,
            financial_structure_start_year: data.from_year,
          })
        } else {
          reset({ ...getValues() })
        }
      } else {
        updateEconomicAnalysisMutation.mutate({
          id: transactionResponse?.economic_analysis?.id || 0,
          financial_structure_end_year: data.to_year,
          financial_structure_start_year: data.from_year,
        })
      }
    },
    [
      financial_structure_end_year,
      financial_structure_start_year,
      from_year,
      getConfirmation,
      getValues,
      reset,
      to_year,
      transactionResponse?.economic_analysis?.id,
      updateEconomicAnalysisMutation,
    ]
  )

  const { pliMethods } = usePliMethods({
    transactionResponse,
    tp_methods: transfer_pricing_method,
    enabled: !!transfer_pricing_method,
  })
  const pliMethod = pliMethods?.find(pliMethod => pliMethod.id === pli_method)

  useEffect(() => {
    if (economicAnalysisTransactionResponse?.[0]) {
      const pliPresent = !!economicAnalysisTransactionResponse[0].pli_method
      const testingMethodologyPresent = !!economicAnalysisTransactionResponse[0].testing_methodology
      const testedPartyPresent =
        typeof economicAnalysisTransactionResponse[0].is_primary_entity_tested_party == 'boolean'

      const Labels = ['PLI', 'Testing Methodology', 'Tested Party']

      if (!testedPartyPresent || !testingMethodology || !pliPresent) {
        const message = `Please select and save ${[pliPresent, testingMethodologyPresent, testedPartyPresent]
          .map((val, index) => (val ? Labels[index] : null))
          .filter(val => !!val)
          .join(', ')
          .replace(/, ([^,]*)$/, ' and $1')} before adding financial data`

        toast.warning(message)
        onTabChange(0)
      }
    }
  }, [economicAnalysisTransactionResponse, onTabChange, testingMethodology])

  useEffect(() => {
    economicAnalysisTransactionRefetch()
  }, [economicAnalysisTransactionRefetch])

  const handleLEClick = useCallback(
    (id: number) => {
      router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${id}`)
    },
    [router]
  )
  const financialEntityColumns = useMemo(() => {
    return getFinancialEntityTableColumns({
      testingMethodology: testingMethodology || undefined,
      openPreviewModal,
      startYear: financial_structure_start_year || from_year,
      endYear: financial_structure_end_year || to_year,
      handleLEClick,
      isReadOnly: false,
    })
  }, [
    financial_structure_end_year,
    financial_structure_start_year,
    from_year,
    handleLEClick,
    openPreviewModal,
    testingMethodology,
    to_year,
  ])
  const setFromYear = useCallback(
    (year: string) => {
      setValue('from_year', Number(year), { shouldDirty: true })
      if (to_year < Number(year)) {
        setValue('to_year', Number(year), { shouldDirty: true })
      } else if (to_year - Number(year) > 9) {
        setValue('to_year', Number(year) + 9, { shouldDirty: true })
      }
    },
    [setValue, to_year]
  )

  const setToYear = useCallback(
    (year: string) => {
      if (Number(year) > from_year && Number(year) - from_year < 9) {
        setValue('to_year', Number(year), { shouldDirty: true })
      } else if (Number(year) < from_year && from_year - Number(year) < 9) {
        setValue('to_year', from_year, { shouldDirty: true })
        setValue('from_year', Number(year))
      } else if (Number(year) > from_year && Number(year) - from_year > 9) {
        setValue('to_year', from_year + 9, { shouldDirty: true })
      } else {
        setValue('to_year', from_year, { shouldDirty: true })
      }
    },
    [from_year, setValue]
  )

  const yearOptions = useMemo(() => generateYearOptions(9), [])

  const filteredToYearOptions = useMemo(() => {
    return yearOptions.filter(opt => Number(opt.value) - from_year < 10 && Number(opt.value) >= from_year)
  }, [from_year, yearOptions])

  const tabledata: FinancialDataEntityTableData[] = useMemo(() => {
    if (!testingMethodology || !pli_method) return []
    if (is_primary_entity_tested_party) {
      return [
        {
          entity: transactionResponse.primary_entity,
          financial_data_appendix: economicAnalysisTransactionResponse?.[0]?.financial_data_appendices.find(
            val => val.tested_party == transactionResponse.primary_entity.id
          )?.tested_party_financial_data_appendix,
          tested_party_currency:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id]?.[0]
              .tested_party_currency || '',
          gaap_type:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id]?.[0]
              .gaap_type || '',

          policy_rate: economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
            return struc.tested_party == transactionResponse.primary_entity.id || ''
          })?.policy_rate,
          testing_policy_description:
            economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
              return struc.tested_party == transactionResponse.primary_entity.id
            })?.testing_policy_description || '',
          financial_structure:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id],
          balance_sheets:
            economicAnalysisTransactionResponse?.[0]?.balance_sheets?.[transactionResponse.primary_entity.id],
          balance_sheet_averages:
            economicAnalysisTransactionResponse?.[0]?.balance_sheet_averages?.[transactionResponse.primary_entity.id] ||
            [],
          averagePli: economicAnalysisTransactionResponse?.[0]?.pli_percentage?.[transactionResponse.primary_entity.id],
        },
      ]
    } else {
      return transactionResponse.individual_transactions.map(txn => {
        return {
          entity: txn.associated_entity,
          financial_data_appendix: economicAnalysisTransactionResponse?.[0]?.financial_data_appendices.find(
            val => val.tested_party == txn.associated_entity.id
          )?.tested_party_financial_data_appendix,
          tested_party_currency:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id]?.[0]
              .tested_party_currency || '',
          gaap_type:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id]?.[0].gaap_type ||
            '',
          policy_rate: economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
            return struc.tested_party == txn.associated_entity.id
          })?.policy_rate,
          testing_policy_description:
            economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
              return struc.tested_party == txn.associated_entity.id
            })?.testing_policy_description || '',
          financial_structure:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id],
          balance_sheets: economicAnalysisTransactionResponse?.[0]?.balance_sheets?.[txn.associated_entity.id],
          balance_sheet_averages:
            economicAnalysisTransactionResponse?.[0]?.balance_sheet_averages?.[txn.associated_entity.id] || [],
          averagePli: economicAnalysisTransactionResponse?.[0]?.pli_percentage?.[txn.associated_entity.id],
        }
      })
    }
  }, [
    economicAnalysisTransactionResponse,
    is_primary_entity_tested_party,
    pli_method,
    testingMethodology,
    transactionResponse.individual_transactions,
    transactionResponse.primary_entity,
  ])

  const isLoading = economicAnalysisTransactionFetching || transactionByIdLoading
  return (
    <div className="flex flex-col h-full w-full gap-4">
      {isLoading ? (
        <Loading className="w-full h-[800px] shrink-0 flex items-center justify-center" />
      ) : (
        <>
          <BorderlessBox variant="grey">
            <div className="flex w-full items-center gap-2">
              {testingMethodology == 'testing_p_and_l' && (
                <div className="w-full max-w-[448px] flex gap-4 mb-6">
                  <div className="flex gap-4 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <Typography variant={Variant.Callout} type="semibold">
                        From
                      </Typography>
                      <CustomDropDown
                        className="bg-white border border-solid border-neutral300 !w-full"
                        options={yearOptions}
                        value={String(from_year)}
                        onChange={setFromYear}
                      />
                    </div>

                    <Typography variant={Variant.Callout} type="semibold" className="flex items-end text-gray-400 mb-2">
                      -
                    </Typography>
                    <div className="flex flex-col gap-1 w-full">
                      <Typography variant={Variant.Callout} type="semibold">
                        To
                      </Typography>
                      <CustomDropDown
                        className="bg-white border border-solid border-neutral300 !w-full"
                        options={filteredToYearOptions}
                        value={String(to_year)}
                        onChange={setToYear}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-end">
                    <Button
                      disabled={!formState.isDirty || updateEconomicAnalysisMutation.isLoading}
                      icon={IconsType.tick}
                      variant={ButtonVariant.Secondary}
                      isDefaultSize={false}
                      iconPathClassName={
                        !formState.isDirty || updateEconomicAnalysisMutation.isLoading
                          ? 'stroke-gray-500'
                          : 'stroke-blue-900'
                      }
                      iconCLass="!w-5 !h-5"
                      className={`!w-[2rem] !p-0 !rounded-full ${
                        !formState.isDirty || updateEconomicAnalysisMutation.isLoading
                          ? '!border-gray-300'
                          : '!border-blue-200'
                      } shrink-0 !h-[2rem]`}
                      onClick={handleSubmit(handleYearChange)}
                    />
                  </div>
                </div>
              )}
              {!!importableTransactions.length && !sameTransactionsFetching && (
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => setShowImportFinancialDataModal(true)}
                  className="ms-auto"
                  icon={IconsType.download}>
                  Import
                </Button>
              )}
            </div>
            <NewTable
              data={tabledata}
              height={testingMethodology == 'testing_p_and_l' ? 'calc(100vh - 24rem)' : 'calc(100vh - 20rem)'}
              noEntryText={'Please select PLI and Testing Methodology'}
              columns={financialEntityColumns}
              tableHeadClassName="whitespace-nowrap"
            />
          </BorderlessBox>
          <ConfirmationModal />
          {!!selectedEntity && (
            <AddFinancialDataModal
              startYear={financial_structure_start_year || from_year}
              endYear={financial_structure_end_year || to_year}
              key={selectedEntity.entity.id}
              testing_methodology={testingMethodology == 'testing_a_policy' ? 'testing_a_policy' : 'testing_p_and_l'}
              economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
              pliMethod={pliMethod}
              selectedEntityData={selectedEntity}
              isOpen={showPreviewModal}
              onClose={closePreviewModal}
              economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
            />
          )}
        </>
      )}
      <ImportFinancialDataModal
        key={'showImportFinancialDataModal' + String(showImportFinancialDataModal)}
        isOpen={showImportFinancialDataModal}
        onClose={closeImportFinancialDataModal}
        importableTransactions={importableTransactions}
        transactionResponse={transactionResponse}
      />
    </div>
  )
}

interface FinancialDataProps {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse: economic_analysis[]
  economicAnalysisTransactionRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
  onTabChange: (idx: number) => void
}

export default FinancialDataComponent
