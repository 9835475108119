import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

const InfoLabel = ({ newInput = true, label, required = false, labelClassName, darkMode }: InfoLabelProps) => {
  if (!label && !required) return null
  return newInput ? (
    <Typography
      variant={Variant.ContainerText}
      type="semibold"
      className={classNames(
        'mb-[0.125rem] flex items-center ',
        {
          'text-gray-300': darkMode,
          'text-gray-600': !darkMode,
        },
        labelClassName
      )}>
      <span className="my-1">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  ) : (
    <Typography
      variant={Variant.Callout}
      type="semibold"
      className={classNames(
        'mb-[0.125rem]  flex items-center',
        {
          'text-gray-300': darkMode,
          'text-gray-700': !darkMode,
        },
        labelClassName
      )}>
      <span className="my-1">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  )
}

interface InfoLabelProps {
  label: string
  required?: boolean
  newInput?: boolean
  labelClassName?: string
  darkMode?: boolean
}

export default InfoLabel
