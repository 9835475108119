import { CheckCircle } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { TemplateVariableResponse } from 'api/templates/types'
import { getRejectionMatrixDefaultDescription } from 'api/transactions/createTransaction'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import cx from 'classnames'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE, integralCss } from 'components/tinyMceEditor/constants'
import TinyMceEditorControlledField from 'components/tinyMceEditor/TinyMceEditorControlledField'
import { EditorRef } from 'components/tinyMceEditor/types'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import Lottie from 'lottie-react'
import React, { useEffect, useRef, useState } from 'react'
import { Control, FieldValues, Path, PathValue, UseFormSetValue, useWatch } from 'react-hook-form'
import { getToastErrorMessage, sleep } from 'utils/utils'

const DEFAULT_PLACEHOLDER = 'Tap here to enter a valid description...'

interface RejectionMatrixFieldProps<T extends FieldValues> {
  id: string
  label: string
  props: {
    selected: boolean
    setValue: UseFormSetValue<T>
    control: Control<T>
    descriptionField: Path<T & FieldValues>
    transactionId?: number
    selectedTemplate: string | undefined
    showAIButton: boolean
    templateVariables: TemplateVariableResponse[] | undefined
  }
}

function RejectionMatrixField<T>({ id, label, props }: RejectionMatrixFieldProps<T & FieldValues>) {
  const [iFrame, setIFrame] = useState<HTMLIFrameElement | null>(null)
  const editorRef = useRef<EditorRef>(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [aiButtonClicked, setAiButtonClicked] = useState(false)

  const value = useWatch({ control: props.control, name: props.descriptionField as Path<T & FieldValues> })

  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })

  const getAutomatedDescriptionMutation = useMutation(getRejectionMatrixDefaultDescription, {
    onSuccess: data => {
      props.setValue(props.descriptionField, data.description as PathValue<T & FieldValues, Path<T & FieldValues>>)
      setAiButtonClicked(true)
    },
    onError: (erorr: AxiosError) => {
      getToastErrorMessage(erorr)
    },
  })

  const getAutomatedDescription = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) event.stopPropagation()
    props.selectedTemplate &&
      getAutomatedDescriptionMutation.mutate({
        tpMethodId: id,
        transactionId: props.transactionId || 0,
        template_name: props.selectedTemplate,
      })
  }

  const handleIframeClick = async () => {
    setIsEditMode(true)
    let editorIsActive = false
    let retryCount = 0

    // Logic to wait for editor to come up for 1.5 seconds and then focus on the editor
    while (!editorIsActive && retryCount < 5) {
      await sleep(300)
      retryCount++
      if (editorRef.current?.editor?.focus) {
        editorRef.current.editor.focus()
        editorIsActive = true
      }
    }
    editorRef.current?.editor?.focus()
  }

  useEffect(() => {
    const frame = iFrame
    if (frame) {
      const loadHandler = () => {
        frame.contentDocument?.addEventListener('click', handleIframeClick)
      }
      frame.addEventListener('load', loadHandler)
      return () => frame.removeEventListener('load', loadHandler)
    }
  }, [iFrame])

  return (
    <BorderlessBoxAccordion
      label={
        <Typography
          type="semibold"
          variant={Variant.Callout}
          className={cx('flex items-center gap-2 -mr-2', props.selected ? 'text-gray-700' : 'text-red-400')}>
          {label}
          {props?.selected ? (
            <span className="text-green-600 inline-flex	text-footnote font-semibold items-center gap-1">
              <CheckCircle className="text-green-600 w-[14px] h-[14px]" /> Selected
            </span>
          ) : null}
        </Typography>
      }
      className={cx('rounded-lg border border-solid !py-1.5', props.selected ? 'border-green-300' : 'border-gray-200')}
      variant="white">
      <div className="w-full py-3">
        <div className="z-10">
          <BorderBoxWrapper
            className={`bg-gray-100 h-[30rem] px-[3rem] py-[1rem] ${!isEditMode ? 'visible' : 'hidden'}`}>
            <iframe
              ref={node => setIFrame(node)}
              title={`Preview`}
              srcDoc={`<!DOCTYPE html>
                        <html><head><style>
                        ${localFileCss || integralCss}
                        </style></head><body>${!value ? DEFAULT_PLACEHOLDER : value}</body></html>`}
              style={{ width: '100%', height: '98%', border: 'none' }}
              className="w-full h-full border-none"
            />
          </BorderBoxWrapper>
          <div className={`min-h-[30rem] ${isEditMode ? 'visible' : 'hidden'}`}>
            <TinyMceEditorControlledField
              editorRef={editorRef}
              control={props.control}
              name={props.descriptionField}
              height="30rem"
              statusbar
              className="!bg-[#1a193b]"
              leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
              templateVariables={props.templateVariables}
            />
          </div>

          {aiButtonClicked && <AITextDisclaimer />}
        </div>
        {getAutomatedDescriptionMutation.isLoading && (
          <div className="z-20 absolute inset-0 flex justify-center items-center opacity-90 bg-white">
            <Lottie animationData={LoadingStars} className="w-full" />
          </div>
        )}
        <div className="mt-4 flex gap-4 justify-end">
          {!!props.selectedTemplate && !!props.showAIButton && (
            <Tooltip title={'Load AI generated language'} placement="top">
              <div className="flex justify-center items-center">
                <Button
                  onClick={getAutomatedDescription}
                  isDefaultSize={false}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  iconCLass="w-5 h-5"
                  iconPathClassName={`${getAutomatedDescriptionMutation.isLoading ? 'fill-gray-500' : 'fill-white'}`}
                  className={`whitespace-nowrap ms-auto ${
                    getAutomatedDescriptionMutation.isLoading
                      ? '!bg-gray-100 !text-gray-500'
                      : 'aira-bg-gradient !text-white'
                  }`}
                  disabled={getAutomatedDescriptionMutation.isLoading}
                  icon={IconsType.autoAwesome}>
                  <span className="text-footnote font-semibold">
                    <span className="!font-aleo">AI</span>ra
                  </span>
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </BorderlessBoxAccordion>
  )
}

export default RejectionMatrixField
