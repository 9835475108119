/* eslint-disable @typescript-eslint/ban-ts-comment */
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, PaletteOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    integralText: Palette['primary']
    mainGrey: Palette['divider']
    mainYellow: Palette['divider']
  }
  interface PaletteOptions {
    integralText: PaletteOptions['primary']
    mainGrey: PaletteOptions['divider']
    mainYellow: PaletteOptions['divider']
  }
}

declare module '@mui/material/Button' {
  interface ButtonNewProps {
    variant?: string
  }

  interface ButtonPropsVariantOverrides {
    mainButton: true
    integral: true
    integralOutlined: true
    integralAction: true
    intgeralDisabled: true
  }
}

const palette: PaletteOptions = {
  primary: {
    light: '#3B82F6',
    main: '#1E40AF',
    dark: '#1E3A8A',
  },
  secondary: {
    light: '#EDEAF7',
    main: '#CBD5E1',
    dark: '#C8C1E6',
  },
  error: {
    light: '#EDEAF7',
    main: '#FF4842',
    dark: '#B72136',
  },
  warning: {
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
  },
  success: {
    light: '#AAF27F',
    main: '#10B981',
    dark: '#007B55',
  },
  integralText: {
    light: '#637381',
    main: '#1E293B',
    dark: '#161C24',
  },
  mainGrey: '#94A3B8',
  mainYellow: '#EA580C',
}

const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
      styleOverrides: {
        root: {
          border: '1px solid red',
          width: '1800px',
          px: 0,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          width: '200',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ['& fieldset']: {
            borderRadius: 12,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'integral' },
          style: {
            textTransform: 'none',
            borderRadius: '12px',
            color: 'white',
            height: '42px',
            backgroundColor: '#1E3A8A',
            '&:hover': {
              backgroundColor: '#93C5FD',
            },
            '&:disabled': {
              color: '#9CA3AF',
              backgroundColor: '#F3F4F6',
            },
          },
        },
        {
          props: { variant: 'integralOutlined' },
          style: {
            textTransform: 'none',
            border: '1px solid #BFDBFE',
            borderRadius: '12px',
            //@ts-ignore
            color: palette.primary['main'],
            backgroundColor: '#EFF6FF',
            '&:hover': {
              backgroundColor: '#DBEAFE',
            },
          },
        },
        {
          props: { variant: 'integralAction' },
          style: {
            color: 'white',
            border: '1px solid',
            borderColor: palette.mainYellow,
            backgroundColor: palette.mainYellow,
            '&:hover': {
              color: palette.mainYellow,
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: palette.mainYellow,
            },
          },
        },
        {
          props: { variant: 'intgeralDisabled' },
          style: {
            backgroundColor: '#F3F4F6',
            color: '#6B7280',
            border: 'none',
            borderRadius: '12px',
            cursor: 'pointer',
            minHeight: '40px',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '12px',
          color: '#475569',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 12px rgba(30, 58, 138, 0.1)',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 12px rgba(30, 58, 138, 0.1)',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#1E293B !important',
          '&:hover': {
            color: '#1E293B  !important',
          },
          '&$active': {
            color: 'x#1E293B  !important',
          },
        },
        icon: {
          color: '#1E293B !important',
          opacity: 1,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&$active': {
            backgroundColor: 'red !important',
          },
        },
      },
    },
  },
})

const cache = createCache({
  key: 'cssprefix',
  prepend: true,
})

const ThemeProvider = ({ children }: Props) => {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CacheProvider value={cache}>
          <CssBaseline />
          {children}
        </CacheProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

interface Props {
  children: React.ReactNode
}

export default ThemeProvider
