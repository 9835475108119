export const TINYMCE_API_KEY = process.env.NEXT_PUBLIC_TINYMCE_API_KEY || ''

export const LOCAL_FILE_EDITOR_LEFT_GROUP_BUTTONS = [
  'undo redo',
  'blocks fontfamily fontsize lineheight bold italic strikethrough underline',
  'backcolor forecolor',
  'alignleft aligncenter alignright alignjustify alignnone',
  'table bullist numlist',
  'indent outdent',
  'pagebreak',
  'removeformat',
  'link',
  'image',
  'showcomments addcomment',
  'footnoteButton',
  'ai-sidebar aiShortcutsNew',
  'spellchecker',
]

export const DEFAULT_LEFT_GROUP_BUTTONS = [
  'undo redo',
  'blocks lineheight bold italic strikethrough underline',
  'backcolor forecolor',
  'alignleft aligncenter alignright alignjustify alignnone',
  'table bullist numlist',
  'indent outdent',
  'pagebreak',
  'removeformat',
  'link',
  'image',
  'footnoteButton',
  'ai-sidebar aiShortcutsNew',
  'exhibitButton',
  'spellchecker',
]

export const DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE = DEFAULT_LEFT_GROUP_BUTTONS.filter(button => button !== 'image')

// TODO: Please not that the export, powerpaste and tinycomments plugin can be enabled after the purchase of premium
export const defaultEditorPlugins = [
  'advlist',
  'anchor',
  'autolink',
  'charmap',
  'code',
  'code',
  'export',
  'fullscreen',
  'image',
  'insertdatetime',
  'link',
  'lists',
  'media',
  'pagebreak',
  'powerpaste',
  'preview',
  'searchreplace',
  'table',
  'tinycomments',
  'tinymcespellchecker',
  'visualblocks',
  'wordcount',
]

/*
  Fonts added in stylesheet can be used in the font family formats avaialble in the EditorInstance
*/
export const DEFAULT_AI_SHORTCUTS = [
  {
    title: 'Summarize content',
    prompt: 'Provide the key points and concepts in this content in a succinct summary.',
  },
  {
    title: 'Improve writing',
    prompt:
      'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.',
  },
  {
    title: 'Simplify language',
    prompt:
      'Rewrite this content with simplified language and reduce the complexity of the writing, so that the content is easier to understand.',
  },
  {
    title: 'Expand upon',
    prompt:
      'Expand upon this content with descriptive language and more detailed explanations, to make the writing easier to understand and increase the length of the content.',
  },
  {
    title: 'Trim content',
    prompt:
      'Remove any repetitive, redundant, or non-essential writing in this content without changing the meaning or losing any key information.',
  },
  {
    title: 'Change tone',
    subprompts: [
      {
        title: 'Professional',
        prompt:
          'Rewrite this content using polished, formal, and respectful language to convey professional expertise and competence.',
      },
      {
        title: 'Casual',
        prompt:
          'Rewrite this content with casual, informal language to convey a casual conversation with a real person.',
      },
      {
        title: 'Direct',
        prompt: 'Rewrite this content with direct language using only the essential information.',
      },
      {
        title: 'Confident',
        prompt: 'Rewrite this content using compelling, optimistic language to convey confidence in the writing.',
      },
      {
        title: 'Friendly',
        prompt: 'Rewrite this content using friendly, comforting language, to convey understanding and empathy.',
      },
    ],
  },
  {
    title: 'Change style',
    subprompts: [
      {
        title: 'Business',
        prompt: 'Rewrite this content as a business professional with formal language.',
      },
      {
        title: 'Legal',
        prompt: 'Rewrite this content as a legal professional using valid legal terminology.',
      },
      {
        title: 'Journalism',
        prompt:
          'Rewrite this content as a journalist using engaging language to convey the importance of the information.',
      },
      {
        title: 'Medical',
        prompt: 'Rewrite this content as a medical professional using valid medical terminology.',
      },
      {
        title: 'Poetic',
        prompt: 'Rewrite this content as a poem using poetic techniques without losing the original meaning.',
      },
    ],
  },
  {
    title: 'Translate',
    subprompts: [
      {
        title: 'Translate to English',
        prompt: 'Translate this content to English language.',
      },
      {
        title: 'Translate to Spanish',
        prompt: 'Translate this content to Spanish language.',
      },
      {
        title: 'Translate to Portuguese',
        prompt: 'Translate this content to Portuguese language.',
      },
      {
        title: 'Translate to German',
        prompt: 'Translate this content to German language.',
      },
      {
        title: 'Translate to French',
        prompt: 'Translate this content to French language.',
      },
      {
        title: 'Translate to Norwegian',
        prompt: 'Translate this content to Norwegian language.',
      },
      {
        title: 'Translate to Ukrainian',
        prompt: 'Translate this content to Ukrainian language.',
      },
      {
        title: 'Translate to Japanese',
        prompt: 'Translate this content to Japanese language.',
      },
      {
        title: 'Translate to Korean',
        prompt: 'Translate this content to Korean language.',
      },
      {
        title: 'Translate to Simplified Chinese',
        prompt: 'Translate this content to Simplified Chinese language.',
      },
      {
        title: 'Translate to Hebrew',
        prompt: 'Translate this content to Hebrew language.',
      },
      {
        title: 'Translate to Hindi',
        prompt: 'Translate this content to Hindi language.',
      },
      {
        title: 'Translate to Arabic',
        prompt: 'Translate this content to Arabic language.',
      },
    ],
  },
]

const defaultFamilies = ['Oswald', 'Roboto']

export const defaultFontFamilyClasses = `
  /* Custom Fonts added for Font Selection */
  ${defaultFamilies
    .map(className => `@import url('https://fonts.googleapis.com/css2?family=${className}&display=swap');`)
    .join('\n')}
`
export const editAreaStyling = `
  body {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
    background-color: #E0E7FF;
  }

  span[data-variable-name] {
    border-right: 2px solid var(--gray-950, #030712);
    border-left: 2px solid var(--gray-950, #030712);
    background: rgba(164, 54, 219, 0.30);
    padding-inline: 5px;
    margin-inline: 2px;
    white-space: nowrap;
    display: inline-block;
  }
`
export const defaultFontFamilyScripts = `
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  ${defaultFamilies
    .map(
      className => `<link href="https://fonts.googleapis.com/css2?family=${className}&display=swap" rel="stylesheet">`
    )
    .join('\n')}
`

export const customFootNoteStyles = `
  /* Custom footnote plugin class changes */
  .footnote {
    background: yellow;
    border-radius: 5px;
    color: black;
    float: footnote;
    font-family: 'Georgia';
    font-size: 12px;
    font-weight: normal;
    footnote-style-position: inside;
    padding-inline: 5px;
    text-align: left;
    vertical-align: super;
  }
`
export const customExhibitStyles = `
  /* Custom footnote plugin class changes */
  .exhibit {
    background: #00ffff88;
    border-radius: 5px;
  }
  .exhibit::before {
    content: "Exhibit:  "
  ;
}

`
/*
  Fonts from default stylesheet can be leveraged in the font family format list
*/
export const fontFamilyFormats = `
  Andale Mono=andale mono,times;
  Arial Black=arial black,avant garde;
  Arial=arial,helvetica,sans-serif;
  Book Antiqua=book antiqua,palatino;
  Comic Sans MS=comic sans ms,sans-serif;
  Courier New=courier new,courier;
  Georgia=georgia,palatino;
  Helvetica=helvetica;
  Impact=impact,chicago;
  Oswald=oswald;
  Roboto=roboto;
  Sans Serif=sans-serif;
  Symbol=symbol;
  Tahoma=tahoma,arial,helvetica,sans-serif;
  Terminal=terminal,monaco;
  Times New Roman=times new roman,times;
  Trebuchet MS=trebuchet ms,geneva;
  Verdana=verdana,geneva;
  Webdings=webdings;
  Wingdings=wingdings,zapf dingbats
`
export const fontSizeFormats = '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'

export const integralCss = `@page {
    margin: 0.75in;
    size: Letter;
    border-top: 1px solid #1E3A8A;
    /* Change color for the top line */
    border-bottom: 1px solid #1E3A8A;
    /* Change color for the bottom line */
    padding-top: 10px;
    padding-bottom: 10px;
    @top-left {
        content: "{{ legal_entity.name|safe }}\A FY{{financial_year}} Local File Report";
        font-size: 10pt;
        font-family: 'Roboto';
        font-weight: 500;
        white-space: pre;
        margin-top: 0.25in;
    }
    @bottom-right {
        content: counter(page);
        font-family: 'Roboto';
        font-style: italic;
    }
    @footnote {
        border-top: 1px solid #1E3A8A;
        padding-top: 10px;
    }
}


/* Selects the first page when printing */

@page :first {
    margin: None;
    border: None;
    @top-left {
        content: None;
    }
    @bottom-right {
        content: None;
    }
}

div.page-break {
    page-break-before: always;
}

body {
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
}


/* common styles */

h1 {
    font-size: 32pt;
    font-weight: 700;
    font-style: italic;
}

h2 {
    font-size: 28pt;
    font-weight: 700;
    font-style: italic;
}

h3 {
    font-size: 16pt;
    font-weight: 700;
    font-style: italic;
    color: #1E3A8A;
}

h4 {
    font-size: 14pt;
    color: #1E3A8A;
    font-weight: 700;
    font-style: italic;
    /* Add some spacing below the heading */
}

h5 {
    font-size: 12pt;
    color: #1E3A8A;
    font-weight: 700;
    font-style: italic;
    /* Add some spacing below the heading */
}

h6 {
    font-size: 10pt;
    color: #1E3A8A;
    font-weight: 700;
    font-style: italic;
    /* Add some spacing below the heading */
}

.exhibit {
    font-size: 10pt;
    font-weight: 700;
    color: #1E3A8A;
    text-align: left;
    font-style: italic;
    /* Center align the heading */
    margin-bottom: 10px;
}

p {
    font-size: 12pt;
    text-align: justify;
}


/* Style unordered list */

ul {
    font-size: 12pt;
}


/* Style ordered list */

ol {
    font-size: 12pt;
}

table {
    border-collapse: collapse;
    text-align: center;
    font-size: 11pt;
    width: 100%;
    /* Make the table full width */
    border: 1px solid #1E3A8A;
}

thead {
    display: table-header-group;
}

th {
    text-align: center;
    color: #1E3A8A;
    padding: 5px;
    border: 1px solid #1E3A8A;
}

tr {
    page-break-inside: avoid; /* Prevent row from breaking across pages */
}

td {
    text-align: center;
    font-size: 10pt;
    padding: 5px;
    border: 1px solid #1E3A8A;
}

td p {
    font-size: 10pt;
}

.attribute-description-table th,
.attribute-description-table td {
    font-size: 10pt;
}

hr {
    color: #1E3A8A;
}

a {
    color: inherit;
    /* inherit color */
    text-decoration: inherit;
    /* inherit text-decoration */
}

img {
    max-width: 100%;
    max-height: 850px;
    display: block;
}

.quote {
    position: relative;
    margin: 1rem;
    font-style: italic;
}


/* information_requested_under_oecd_guidelines.html */

.index-table {
    border-collapse: collapse;
    font-size: 10pt;
    font-style: italic;
    border: 1px solid #1E3A8A;
    width: 100%;
    page-break-inside: auto;
}

.index-table th {
    text-align: center;
    font-size: 13pt;
    color: #1E3A8A;
    font-style: italic;
    padding: 8px;
}

.index-table td {
    text-align: left;
    font-size: 11pt;
    color: #1E3A8A;
    font-style: italic;
    padding: 8px;
}

.index-table .td-special {
    text-align: center;
    color: #FFFFFF;
    background-color: #1E3A8A;
    border: 1px solid #1E3A8A;
}


/* table_of_contents.html */

.table-of-contents-list {
    padding-left: 15px;
    counter-reset: item
}

.table-of-contents-list li {
    display: block;
    font-size: 11pt;
    font-style: italic;
    color: #1E3A8A;
}

.table-of-contents-list li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.table-of-contents-list a::after {
    content: ' ' leader('.') ' ' target-counter(attr(href), page);
}

.appendix-list {
    padding-left: 15px;
}

.appendix-list li {
    display: block;
    font-size: 11pt;
    font-style: italic;
    color: #1E3A8A;
}

.appendix-list a::after {
    content: ' ' leader('.') ' ' target-counter(attr(href), page);
}


/* local_entity.html */

.container {
    border: 4px solid #1E3A8A;
    padding: 10px;
}

.box {
    display: inline-block;
    text-align: center;
}

.box p {
    border: 1px solid #1E3A8A;
    padding: 5px;
    margin: 0;
    display: inline-block;
}

.arrow {
    display: inline-block;
    /* Allows us to set width and height */
    color: solid #1E3A8A;
    /* Adjust color as desired */
    font-size: 45px;
    /* Adjust the size of the arrow */
    line-height: 40px;
    /* Helps with vertical centering */
    text-align: center;
    /* Centers the arrow in the given width */
}


/* functional_analysis.html */

.transaction-attribute ul {
    font-size: 10pt;
    padding-left: 15px;
}

.transaction-attribute h6 {
    font-size: 12pt;
    font-style: italic;
    color: #1E3A8A;
    margin-bottom: 0.5em
}

.footnote {
    float: footnote;
    footnote-style-position: inside;
    /* forces marker inside footnote area */
    font-weight: normal;
    font-style: italic;
    color: black;
    text-align: left;
    vertical-align: super;
    font-size: 8pt;
}

::footnote-call {
    font-size: 0.5em;
    /* Make the footnote call smaller */
    vertical-align: super;
    /* Align vertically as superscript */
    position: relative;
    top: -0.5em;
    /* Lift it up a bit more */
    line-height: 0;
    /* Prevent it from affecting line height */
}


/* code tag generated by markdown package*/

pre code {
    font-size: 12pt;
    font-family: 'Roboto', monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
}

pre {
    white-space: pre-wrap; /* Ensure wrapping in <pre> as well */
    overflow-x: auto; /* Add horizontal scrolling for long lines */
}

.significance-table {
    width: 50%;
}

.text-align-justify {
    text-align: justify;
    padding: 10px;
}

.text-align-left {
    text-align: left;
    padding: 5px;
}

.text-align-right {
    text-align: right;
    padding: 5px;
}

.text-align-center {
    text-align: center;
    padding: 5px;
}

.semi-bold {
    font-weight: 500;
}`
