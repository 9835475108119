import { getIcons } from 'assets/index'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { ROUTES } from 'constants/routes'
import useFTUTour from 'hooks/useFTUTour/useFTUTour'
import Router, { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { appActions } from 'store/reducer/app'

import SidebarExtender from './components/sidebarHeader/SidebarExtender'
import { findActiveMenuItem } from './helpers/sidebar.helpers'
import SideBarMenuRenderer from './organisms/sideBarMenuRenderer'
// import UserInfo from './organisms/userInfo'

const Sidebar = ({ activeLink, setActiveLink }: SidebarProps): JSX.Element => {
  const [isExtendedFieldsVisible, setIsExtendedFieldsVisible] = useState(false)
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const { isTourActive } = useFTUTour()

  const { pathname } = useRouter()

  useEffect(() => {
    // because in server side window is not defined
    const sidebarOpen = window.localStorage.getItem('sidebarOpen')
    if (sidebarOpen) {
      dispatch(appActions.updateSidebar(sidebarOpen === 'true'))
    }
  }, [dispatch])

  const handleSideBarToggle = useCallback(() => {
    dispatch(appActions.updateSidebar(!isSidebarOpen))
    window.localStorage.setItem('sidebarOpen', String(!isSidebarOpen))
  }, [dispatch, isSidebarOpen])

  useEffect(() => {
    if (isTourActive && !isSidebarOpen) {
      handleSideBarToggle()
    }
  }, [handleSideBarToggle, isSidebarOpen, isTourActive])

  const onActiveStateHandler = useCallback(
    (path: string) => () => {
      setActiveLink(path)
      Router.push(path, undefined, { shallow: true })
    },
    [setActiveLink]
  )

  useEffect(() => {
    if (activeLink?.includes(ROUTES.DOCUMENT_MANAGEMENT)) {
      setIsExtendedFieldsVisible(true)
    }
  }, [activeLink])

  useEffect(() => {
    const activeMenuItem = findActiveMenuItem(pathname)
    if (activeMenuItem) {
      setActiveLink(pathname)
    }
  }, [setActiveLink, pathname])
  const logoToRender = isSidebarOpen ? IconsType.integralOpenLogo : IconsType.closeIntegralLogo
  return (
    <div className="flex">
      <div
        className={cx(
          'bg-blue100 sidebar min-h-screen max-h-screen relative duration-150 flex flex-col items-center overflow-hidden overflow-y-auto no-scrollbar',
          {
            'w-[13.75rem]': isSidebarOpen,
            'w-[4.5rem]': !isSidebarOpen,
          }
        )}>
        <div
          className={cx('flex flex-col mt-2.5 w-full', {
            'items-center': !isSidebarOpen,
          })}>
          <div className="px-3">
            {getIcons(logoToRender, {
              className: cx('relative top-[-3px]', {
                'w-[10.8125rem] h-[2.9906rem]': isSidebarOpen,
                'w-[2.9906rem] h-[2.9906rem]': !isSidebarOpen,
              }),
            })}
          </div>
        </div>

        <SideBarMenuRenderer
          isExtendedFieldsVisible={isExtendedFieldsVisible}
          setIsExtendedFieldsVisible={setIsExtendedFieldsVisible}
          onActiveStateHandler={onActiveStateHandler}
          open={isSidebarOpen}
          activeLink={activeLink}
        />

        <SidebarExtender handleSideBarToggle={handleSideBarToggle} open={isSidebarOpen} />
      </div>
    </div>
  )
}

interface SidebarProps {
  activeLink?: string
  setActiveLink: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default Sidebar
