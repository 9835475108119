import { User } from 'api/login/login.types'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { Editor } from 'tinymce'

import { DEFAULT_AI_SHORTCUTS } from '../../constants'
import { SidebarAIContent } from './SidebarAIContent'

const registerSidebarAI = (editor: Editor, user?: User): void => {
  let root: Root | null = null

  // Function to render sidebar content
  const renderSidebar = (sidebarElement: HTMLElement) => {
    if (!sidebarElement) return

    // Clear any existing content
    sidebarElement.innerHTML = ''

    // Create React root and render component
    const root = createRoot(sidebarElement)
    root.render(<SidebarAIContent editor={editor} user={user} />)
  }

  editor.ui.registry.addSidebar('ai-sidebar', {
    icon: 'ai',
    tooltip: 'Ask AIra',
    onSetup(api) {
      const sidebarElement = api.element()
      renderSidebar(sidebarElement)
      return () => {
        if (root) {
          root.unmount()
          root = null
        }
      }
    },
  })
  editor.ui.registry.addContextToolbar('textselection', {
    predicate: () => !editor.selection.isCollapsed(),
    items: 'ai-sidebar aiShortcutsNew',
    position: 'selection',
    scope: 'node',
  })
  editor.ui.registry.addMenuButton('aiShortcutsNew', {
    icon: 'ai-prompt',
    tooltip: 'AIra shortcuts',
    fetch: function (Fetchallback) {
      const items = DEFAULT_AI_SHORTCUTS.map(shortcut => {
        if (shortcut.subprompts) {
          // Create submenu for subprompts
          return {
            type: 'nestedmenuitem',
            text: shortcut.title,
            getSubmenuItems: () =>
              shortcut.subprompts.map(sub => ({
                type: 'menuitem',
                text: sub.title,
                onAction: () => {
                  editor.execCommand('runAIQuery', true, { prompt: sub.prompt })
                },
              })),
          }
        } else {
          // Create a simple menu item
          return {
            type: 'menuitem',
            text: shortcut.title,
            onAction: () => {
              editor.execCommand('runAIQuery', true, { prompt: shortcut.prompt })
            },
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Fetchallback(items)
    },
  })
}

export default registerSidebarAI
