import { useMutation, useQuery } from '@tanstack/react-query'
import { updateComparabilityFactorName, updateComparabilitySubfactorName } from 'api/transactions/comparabilityMetrics'
import { SelectedComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button from 'components/button'
import InfoLabel from 'components/input/components/infoLabel'
import Loading from 'components/loading'
import Modal from 'components/modal/Modal'
import { CommonEditableNameCell } from 'components/newTable/organisms/editableNameCell/EditableNameCell'
import TinyMceEditor from 'components/tinyMceEditor'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE, integralCss } from 'components/tinyMceEditor/constants'
import { EditorRef } from 'components/tinyMceEditor/types'
import Typography, { Variant } from 'components/typography'
import UploadDocuments from 'components/uploadDocuments'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import useConfirmationModal from 'hooks/useConfirmationModal'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Editor as RawEditor } from 'tinymce'
import { getToastErrorMessage } from 'utils/utils'

import { AddToSelectedData } from '../../types'

type Props = {
  unselectedCriteria?: AddToSelectedData
  selectedCriteria?: SelectedComparabilityFactor
  isOpen: boolean
  onClose: VoidFunction
  onAddCriteria: (unselectedCriteria: AddToSelectedData, data: AddEditComparabilityCriteriaFieldValue) => void
  onUpdateCriteria: (
    selectedCriteria: SelectedComparabilityFactor,
    data: AddEditComparabilityCriteriaFieldValue
  ) => void
  loading: boolean
  refetchSelectedFactors?: () => void
}

const AddEditComparabilityCriteria = ({
  unselectedCriteria,
  selectedCriteria,
  isOpen,
  onClose,
  refetchSelectedFactors,
  onAddCriteria,
  onUpdateCriteria,
  loading,
}: Props) => {
  const [fileUrl, setFileUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [resetData, setResetData] = useState(false)
  const { handleSubmit, reset, watch, setValue } = useForm<FIELDS_VALUE>({})
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()
  const { user } = useAuth()
  const editorRef = useRef<EditorRef>(null)
  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })
  const css = useMemo(() => {
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
  }, [localFileCss])
  const [htmlIsDirty, setHtmlIsDirty] = useState(false)

  const updateSubfactorMutation = useMutation(updateComparabilitySubfactorName, {
    onSuccess() {
      toast.success('Sub factor name updated Successfully!!')
      setResetData(false)
      refetchSelectedFactors && refetchSelectedFactors()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })
  const updateComparabilityFactorMutation = useMutation(updateComparabilityFactorName, {
    onSuccess() {
      toast.success('Comparability factor name updated Successfully!!')
      setResetData(false)
      refetchSelectedFactors && refetchSelectedFactors()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })
  const handleSaveName = useCallback(
    async (name: string) => {
      const confirmation = getConfirmation(
        <>
          Are you sure that you want to rename
          <span className="font-semibold ml-1">{selectedCriteria?.comparability_factor_full_name} ?</span>
          <br />
          Renaming this comparability factor will update its name across all transactions where it is used.
          <br />
        </>
      )
      const canUpdateName = await confirmation
      if (canUpdateName) {
        if (selectedCriteria?.comparability_sub_factor) {
          updateSubfactorMutation.mutate({
            id: selectedCriteria && Number(selectedCriteria?.comparability_sub_factor),
            sub_factor_names: name,
          })
        } else {
          updateComparabilityFactorMutation.mutate({
            id: selectedCriteria && Number(selectedCriteria?.comparability_factor),
            factor_names: name,
          })
        }
      } else {
        setResetData(true)
      }
    },
    [getConfirmation, selectedCriteria, updateSubfactorMutation, updateComparabilityFactorMutation]
  )
  const closeModal = useCallback(async () => {
    if (
      htmlIsDirty &&
      !(await getConfirmation('You may have unsaved changes, are you sure you want to exit?', 'Attention!'))
    ) {
      return
    }
    setHtmlIsDirty(false)
    onClose()
  }, [getConfirmation, htmlIsDirty, onClose])

  const justification = watch('justification')
  useEffect(() => {
    if (isOpen) {
      if (unselectedCriteria) {
        reset({})
      } else if (selectedCriteria) {
        reset({
          justification: selectedCriteria.justification,
        })
        setFileUrl(selectedCriteria.comparability_appendix?.file || '')
        setFileName(
          selectedCriteria.comparability_appendix?.file
            ? selectedCriteria.comparability_appendix?.file_name || 'File'
            : ''
        )
      }
    }
  }, [isOpen, reset, selectedCriteria, unselectedCriteria])

  const onSubmit = useCallback(
    async (data: FIELDS_VALUE) => {
      if (unselectedCriteria) {
        onAddCriteria(unselectedCriteria, data)
      } else if (selectedCriteria) {
        onUpdateCriteria(selectedCriteria, data)
        setHtmlIsDirty(false)
      }
    },
    [onAddCriteria, onUpdateCriteria, selectedCriteria, unselectedCriteria]
  )

  const [file] = watch(['document'])

  const setFile = useCallback(
    (file: File) => {
      setValue('document', file)
    },
    [setValue]
  )
  const handleEditorSetup = (editor: RawEditor) => {
    const handleSave = () => {
      if (editor) {
        setValue('justification', editor.getContent())
      }
    }

    editor.on('blur', handleSave)
  }

  return (
    <Modal
      containerClassName="p-6 w-[1024px] max-w-full"
      title={`${unselectedCriteria ? 'Add Suggested Comparability Factor' : 'Edit Comparability Factor'}`}
      isOpen={isOpen}
      className="gap-6 w-full !items-stretch text-start"
      onClose={closeModal}>
      {loading ? (
        <Loading className="w-full h-[650px] flex items-center justify-center " />
      ) : (
        <>
          <div className="flex justify-start items-center gap-1">
            {unselectedCriteria ? (
              <Typography type="semibold" variant={Variant.Callout} className="text-gray-700 whitespace-nowrap">
                {unselectedCriteria?.fullName}
              </Typography>
            ) : selectedCriteria?.added_by_integral ? (
              <Typography type="semibold" variant={Variant.Callout} className="text-gray-700 whitespace-nowrap">
                {selectedCriteria?.comparability_factor_full_name}
              </Typography>
            ) : (
              <Typography type="semibold" variant={Variant.Callout} className="text-gray-700 whitespace-nowrap">
                {selectedCriteria?.comparability_sub_factor && selectedCriteria?.comparability_factor_name}
              </Typography>
            )}
            {selectedCriteria &&
              !selectedCriteria?.added_by_integral &&
              (selectedCriteria?.comparability_sub_factor ? (
                <div className="flex gap-1 items-center w-full">
                  <span>-</span>
                  <CommonEditableNameCell
                    resetData={resetData}
                    data={selectedCriteria?.comparability_sub_factor_name || ''}
                    onEdit={handleSaveName}
                  />
                </div>
              ) : (
                <div className="flex gap-1 items-center w-full">
                  <CommonEditableNameCell
                    resetData={resetData}
                    data={selectedCriteria?.comparability_factor_name || ''}
                    onEdit={handleSaveName}
                  />
                </div>
              ))}
          </div>
          <div className="doc-editor doc-editor-modal overflow-auto w-full">
            <InfoLabel label="Description" newInput={false} />
            <TinyMceEditor
              author={{
                id: user?.id,
                username: user?.username || 'unknown',
                fullName: user
                  ? user.first_name || user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.username
                  : 'Unknown',
                avatarUrl: user?.profile_photo || undefined,
              }}
              height={'55vh'}
              editorRef={editorRef}
              editorData={justification || ''}
              onSetup={handleEditorSetup}
              contentStyles={css}
              leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
              setIsDirty={setHtmlIsDirty}
            />
          </div>
          <UploadDocuments
            fileName={file?.name || fileName || (fileUrl ? 'Document' : '')}
            file={file || null}
            label="Supporting Documents"
            accept=".pdf"
            setFile={setFile}
            fileUrl={fileUrl}
          />
          <ConfirmationModal />
          {unselectedCriteria ? (
            <Button icon={IconsType.plus} className="ms-auto self-end !gap-1" onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          ) : (
            <Button
              icon={IconsType.save}
              isDefaultSize={false}
              className="ms-auto self-end !gap-1"
              onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          )}
        </>
      )}
    </Modal>
  )
}

export default AddEditComparabilityCriteria

type FIELDS_VALUE = {
  document?: File
  justification?: string
}

export type AddEditComparabilityCriteriaFieldValue = FIELDS_VALUE
