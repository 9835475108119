import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { download_local_file } from 'api/documents'
import { ChecklistResponse } from 'api/documents/types'
import { AxiosError } from 'axios'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import { useAllTransactionList } from 'hooks/useAllTransactionList'
import useConfirmationForReportWizard from 'hooks/useConfirmationForReportWizard/useConfirmationForReportWizard'
import { isNaN, set } from 'lodash'
import { useRouter } from 'next/router'
import { moduleKeyType } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter, getToastErrorMessage } from 'utils/utils'

type ReportWizardContextType = {
  orgId?: number
  dMSFlow?: boolean
  setOrgId: React.Dispatch<React.SetStateAction<number | undefined>>
  legalEntity?: number
  setLegalEntity: React.Dispatch<React.SetStateAction<number | undefined>>
  reportId?: number
  setReportId: React.Dispatch<React.SetStateAction<number | undefined>>
  isActive: boolean
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  setIsDMSFlow: React.Dispatch<React.SetStateAction<boolean>>
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  control: Control<ReportWizardFieldValues>
  templateOptions: SelectOptions[]
  setActiveModule: React.Dispatch<React.SetStateAction<moduleKeyType>>
  activeModule: moduleKeyType
  checklistData?: ChecklistResponse
  refetchChecklist: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ChecklistResponse, unknown> | undefined>
  handleGenerateClick: () => void
  loading: boolean
  isFetching: boolean
  stickyFooterHeight: number
  setStickyFooterHeight: React.Dispatch<React.SetStateAction<number>>
  handleTemplateChange: (val: SelectOptions | null) => Promise<void>
  selectedTemplate: SelectOptions | undefined
  setSelectedTemplate: React.Dispatch<React.SetStateAction<SelectOptions | undefined>>
  selectedTransactions: number[] | undefined
  setSelectedTransactions: React.Dispatch<React.SetStateAction<number[] | undefined>>
  legalEntityName: string
  setlegalEntityName: React.Dispatch<React.SetStateAction<string>>
  generating: boolean
  transactionOptions: SelectOptions[]
  formSelectedTransactions: SelectOptions[] | undefined
  setSkipFieldsRefValue: (key: string, value: string | number | boolean) => void
  handleSkipClick: (
    key: string,
    additionalSkipData?: {
      key: string
      value: string | number | boolean
    }
  ) => void
  ConfirmationWindow: () => JSX.Element
  getConfirmation: (text: string | React.ReactNode, title?: string, confirmationCtaText?: string) => Promise<boolean>
  resolveConfirmationPromise: (status: boolean) => void
}

const ReportWizardContext = React.createContext<ReportWizardContextType | undefined>(undefined)

export const ReportWizardContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const [isActive, setIsActive] = useState(false)
  const [isDMSFlow, setIsDMSFlow] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [orgId, setOrgId] = useState<number>()
  const [year, setYear] = useState<number>()
  const [legalEntity, setLegalEntity] = useState<number>()
  const [legalEntityName, setlegalEntityName] = useState<string>('')
  const [reportId, setReportId] = useState<number>()
  const [stickyFooterHeight, setStickyFooterHeight] = useState(0)
  const [activeModule, setActiveModule] = useState<moduleKeyType>('company_overview')
  const [selectedTransactions, setSelectedTransactions] = useState<number[]>()

  const [selectedTemplate, setSelectedTemplate] = useState<SelectOptions>()

  const { control, setValue, reset, getValues, watch } = useForm<ReportWizardFieldValues>({
    defaultValues: {},
  })

  const skipFieldsRef = useRef<SkippedFields>({})

  const formSelectedTransactions = watch('transactions')

  const queryClient = useQueryClient()

  const routerOrgId = router.query.orgId
  const routerYear = router.query.year

  const queryEnabled = !!(legalEntity || reportId) && !!isActive && !(!selectedTemplate?.value && !reportId)

  const txnKey = (selectedTransactions || []).join('-')

  const {
    data: checklistData,
    refetch,
    isFetching,
  } = useQuery([QUERIES.GET_CHECKLIST.key, routerOrgId, reportId, legalEntity, selectedTemplate?.value, txnKey], {
    queryFn: () =>
      QUERIES.GET_CHECKLIST.function({
        entityId: legalEntity,
        localFileIndex: reportId,
        template: String(selectedTemplate?.value || '') || undefined,
        transactions: selectedTransactions,
      }),
    enabled: queryEnabled,
    onSuccess(data) {
      const isTXNSame = selectedTransactions?.join('-') == data?.transactions_order?.join('-')

      if (isActive) {
        skipFieldsRef.current = {}
        if (!reportId || !isTXNSame) {
          queryClient.refetchQueries({
            type: 'active',
            queryKey: [QUERIES.GET_LEGAL_ENTITY_BY_ID.key],
          })
          if (isDMSFlow) {
            queryClient.refetchQueries({
              type: 'active',
              queryKey: [QUERIES.GET_LEGAL_ENTITIES_AND_LOCAL_FILES.key],
            })
          }
        }
        data.legal_entity && setLegalEntity(data.legal_entity.id)
        data.id && setReportId(data.id)
        if (!isTXNSame) {
          setValue(
            'transactions',
            data?.transactions_order.map(txn => {
              return {
                label: txn,
                value: txn,
              }
            })
          )
          data.transactions_order && setSelectedTransactions(data.transactions_order)
        }
        setlegalEntityName(data.legal_entity?.name || '')
        if (data.template_name != selectedTemplate?.value) {
          setValue('template', {
            label: capitalizeFirstLetter(data.template_name),
            value: data.template_name,
          })
          setSelectedTemplate({
            label: capitalizeFirstLetter(data.template_name),
            value: data.template_name,
          })
        }
      }
    },
    onError(err) {
      getToastErrorMessage(err as AxiosError)
    },
  })

  const generating =
    checklistData?.local_file_creation_status == 'in_progress' || checklistData?.local_file_creation_status == 'pending'
  useQuery(
    [QUERIES.GET_CHECKLIST.key, routerOrgId, reportId, legalEntity, selectedTemplate?.value, txnKey, 'polling'],
    {
      queryFn: () =>
        QUERIES.GET_CHECKLIST.function({
          entityId: legalEntity,
          localFileIndex: reportId,
          template: String(selectedTemplate?.value || '') || undefined,
          transactions: selectedTransactions,
        }),
      enabled: generating && isActive && isExpanded,
      onSuccess(data) {
        if (
          generating &&
          data?.local_file_creation_status &&
          !(data?.local_file_creation_status == 'in_progress' || data?.local_file_creation_status == 'pending')
        ) {
          refetch()
          queryClient.refetchQueries({
            type: 'active',
            queryKey: [QUERIES.GET_LEGAL_ENTITY_BY_ID.key],
          })
        }
      },
      retry: 10,
      refetchInterval(data, query) {
        const pending =
          data?.local_file_creation_status == 'in_progress' || data?.local_file_creation_status == 'pending'
        const count = query.state.dataUpdateCount
        const interval = 10 * 1000
        if (pending && count < 200) {
          return interval
        } else if (!pending && count) {
          query.reset()
        }
        return false
      },
    }
  )

  const skipFieldMutation = useMutation(QUERIES.GET_CHECKLIST.function)

  const refetchChecklist = useCallback(
    async <TPageData,>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => {
      if (queryEnabled) {
        return refetch(options)
      }
    },
    [queryEnabled, refetch]
  )

  const { transactionsList } = useAllTransactionList(
    !!(legalEntity || checklistData?.legal_entity?.id) && activeModule == 'transactions',
    undefined,
    {
      legal_entity: legalEntity || checklistData?.legal_entity?.id || undefined,
      compliance_region:
        selectedTemplate?.additionalData && selectedTemplate?.additionalData['complianceRegion']
          ? selectedTemplate.additionalData['complianceRegion']
          : undefined,
    },
    legalEntity || checklistData?.legal_entity?.id
      ? selectedTemplate?.additionalData && selectedTemplate?.additionalData['complianceRegion']
        ? [
            (legalEntity || checklistData?.legal_entity?.id)?.toString(),
            selectedTemplate.additionalData['complianceRegion'],
          ]
        : [(legalEntity || checklistData?.legal_entity?.id)?.toString()]
      : []
  )

  const transactionOptions: SelectOptions[] = useMemo(() => {
    if (!transactionsList?.length) return []
    return transactionsList.map(txn => {
      return {
        label: txn.name,
        value: txn.id,
        additionalData: txn,
      }
    })
  }, [transactionsList])

  const resetState = useCallback(() => {
    setIsActive(false)
    setIsDMSFlow(false)
    setIsExpanded(true)
    setOrgId(undefined)
    setYear(undefined)
    setLegalEntity(undefined)
    setReportId(undefined)
    setActiveModule('company_overview')
    setSelectedTemplate(undefined)
    setSelectedTransactions(undefined)
    setlegalEntityName('')
    skipFieldsRef.current = {}
    reset({
      template: {
        label: '',
        value: '',
      },
    })
  }, [reset])

  useEffect(() => {
    if (!isActive) {
      resetState()
    }
  }, [isActive, resetState])

  useEffect(() => {
    if (
      typeof orgId == 'number' &&
      !isNaN(Number(routerOrgId)) &&
      Number(routerOrgId) &&
      orgId !== Number(routerOrgId)
    ) {
      resetState()
    }
  }, [orgId, resetState, routerOrgId])

  useEffect(() => {
    if (typeof year == 'number' && !isNaN(Number(routerYear)) && Number(routerYear) && year !== Number(routerYear)) {
      resetState()
    }
  }, [year, resetState, routerYear])

  useEffect(() => {
    if (isActive && typeof orgId !== 'number' && !isNaN(Number(routerOrgId)) && Number(routerOrgId)) {
      setOrgId(Number(routerOrgId))
    }
  }, [isActive, orgId, routerOrgId])

  useEffect(() => {
    if (isActive && typeof year !== 'number' && !isNaN(Number(routerYear)) && Number(routerYear)) {
      setYear(Number(routerYear))
    }
  }, [isActive, orgId, routerYear, year])

  const { data } = useQuery([QUERIES.GET_ALLOWED_TEMPLATES.key, routerOrgId], {
    queryFn: QUERIES.GET_ALLOWED_TEMPLATES.function,
    enabled: !!routerOrgId && !isNaN(Number(routerOrgId)),
  })

  const templateOptions: SelectOptions[] = useMemo(() => {
    if (!data?.length) {
      return []
    } else {
      return data.map(template => {
        return {
          label: capitalizeFirstLetter(template.label),
          value: template.name,
          additionalData: { complianceRegion: template.compliance_region },
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (selectedTemplate?.value && !selectedTemplate?.additionalData?.complianceRegion && templateOptions.length) {
      const foundTemplate = templateOptions.find(opt => opt.value == selectedTemplate.value)
      if (foundTemplate) {
        setSelectedTemplate(foundTemplate)
        setValue('template', foundTemplate)
      }
    }
  }, [selectedTemplate?.additionalData?.complianceRegion, selectedTemplate?.value, setValue, templateOptions])

  const {
    getConfirmation,
    ConfirmationWindow,
    resolve: resolveConfirmationPromise,
    isOpen: isConfirmationOpen,
  } = useConfirmationForReportWizard()

  useEffect(() => {
    if ((!isActive || !isExpanded) && isConfirmationOpen) {
      resolveConfirmationPromise(false)
    }
  }, [isActive, isConfirmationOpen, isExpanded, resolveConfirmationPromise])

  const handleTemplateChange = useCallback(
    async (val: SelectOptions | null) => {
      if (
        val?.additionalData?.complianceRegion &&
        selectedTemplate?.additionalData?.complianceRegion &&
        val?.additionalData?.complianceRegion !== selectedTemplate?.additionalData?.complianceRegion &&
        checklistData?.transactions_order?.length
      ) {
        const confirmation = await getConfirmation(
          'Changing template will clear the previous transaction selection. Are you sure you want to proceed?'
        )
        if (!confirmation) {
          setValue('template', selectedTemplate)
        } else {
          setValue('transactions', [])
          setSelectedTemplate(getValues('template'))
        }
        return
      } else {
        setSelectedTemplate(getValues('template'))
      }
    },
    [checklistData?.transactions_order?.length, getConfirmation, getValues, selectedTemplate, setValue]
  )

  const generateLocalFileMutation = useMutation(download_local_file, {
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
    onSuccess() {
      toast.success('Report added to queue!!')
      queryClient.refetchQueries([QUERIES.GET_LEGAL_ENTITY_BY_ID.key, legalEntity], { type: 'active' })
      if (isDMSFlow) {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            entityId: checklistData?.legal_entity?.id || state.legalEntity,
          },
        })
      } else {
        router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${checklistData?.legal_entity?.id || state.legalEntity}`)
      }
      setIsActive(false)
      setIsDMSFlow(false)
    },
  })

  const setSkipFieldsRefValue = useCallback((key: string, value: string | number | boolean) => {
    set(skipFieldsRef.current, key, value)
  }, [])

  const handleSkipClick = useCallback(
    (
      key: string,
      additionalSkipData?: {
        key: string
        value: string | number | boolean
      }
    ) => {
      const formSkipFields = skipFieldsRef.current

      let updatedFormSkipFields = set(formSkipFields, key, true)
      if (additionalSkipData) {
        updatedFormSkipFields = set(updatedFormSkipFields, additionalSkipData.key, additionalSkipData.value)
      }
      updatedFormSkipFields.transactions = Array.isArray(updatedFormSkipFields.transactions)
        ? updatedFormSkipFields.transactions.filter(val => !!val)
        : undefined

      reportId &&
        skipFieldMutation.mutate(
          {
            localFileIndex: reportId,
            skip_fields: JSON.stringify(updatedFormSkipFields),
          },
          {
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
            onSuccess() {
              refetch()
            },
          }
        )
    },
    [refetch, reportId, skipFieldMutation]
  )

  const handleGenerateClick = useCallback(async () => {
    if (!selectedTransactions?.length) {
      toast.info('Please select transactions to generate a report')
      setActiveModule('transactions')
      return
    }
    if (checklistData?.edited) {
      const confirmation = await getConfirmation(
        'This report has been manually edited. You will lose the changes if you regenerate the report. Are you sure you want to proceed?'
      )
      if (!confirmation) return
    }
    reportId &&
      generateLocalFileMutation.mutate({
        reportId,
      })
  }, [checklistData?.edited, generateLocalFileMutation, getConfirmation, reportId, selectedTransactions?.length])

  const loading = generateLocalFileMutation.isLoading

  const state: ReportWizardContextType = useMemo(() => {
    return {
      orgId,
      legalEntity,
      reportId,
      isActive,
      isExpanded,
      setIsActive,
      setIsDMSFlow,
      setIsExpanded,
      setOrgId,
      setLegalEntity,
      setReportId,
      control,
      templateOptions,
      activeModule,
      setActiveModule,
      checklistData,
      refetchChecklist,
      handleGenerateClick,
      loading: loading || skipFieldMutation.isLoading,
      isFetching,
      setStickyFooterHeight,
      stickyFooterHeight,
      handleTemplateChange,
      selectedTemplate,
      setSelectedTemplate,
      setSelectedTransactions,
      selectedTransactions,
      legalEntityName,
      setlegalEntityName,
      generating,
      transactionOptions,
      formSelectedTransactions,
      handleSkipClick,
      setSkipFieldsRefValue,
      getConfirmation,
      ConfirmationWindow,
      resolveConfirmationPromise,
    }
  }, [
    orgId,
    legalEntity,
    reportId,
    isActive,
    isExpanded,
    control,
    templateOptions,
    activeModule,
    checklistData,
    refetchChecklist,
    handleGenerateClick,
    loading,
    skipFieldMutation.isLoading,
    isFetching,
    stickyFooterHeight,
    handleTemplateChange,
    selectedTemplate,
    selectedTransactions,
    legalEntityName,
    generating,
    transactionOptions,
    formSelectedTransactions,
    handleSkipClick,
    setSkipFieldsRefValue,
    getConfirmation,
    ConfirmationWindow,
    resolveConfirmationPromise,
  ])

  return <ReportWizardContext.Provider value={state}>{children}</ReportWizardContext.Provider>
}

export const useReportWizardContext = () => {
  const context = useContext(ReportWizardContext)
  if (!context) {
    throw new Error('ReportWizardContext used without provider')
  }
  return context
}

export type ReportWizardFieldValues = {
  template?: SelectOptions
  transactions?: SelectOptions[]
}

type SkippedFields = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
