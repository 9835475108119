import { getIcons } from 'assets/index'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import { useEffect, useState } from 'react'

import { TabData } from './Tabs'

function PillTab({
  className,
  tabsData,
  containerClassName,
  initialActiveTabIndex = 0,
  handleActiveIndex,
  forcedActiveIndex,
  onTabChange,
  rightTabHeaderComponent,
}: PillTabProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex)

  const { isDirty, getExitConfirmation, setIsDirty } = useRoutingHandlerState()

  const handleTabChange = async (idx: number, label: string) => {
    if (!isDirty) {
      setActiveTabIndex(idx)
      if (onTabChange) {
        onTabChange(idx, label)
      }
    } else if (await getExitConfirmation()) {
      setIsDirty(false)
      setActiveTabIndex(idx)
      if (onTabChange) {
        onTabChange(idx, label)
      }
    }
  }

  useEffect(() => {
    if (handleActiveIndex && forcedActiveIndex !== undefined) {
      setActiveTabIndex(forcedActiveIndex)
    }
  }, [forcedActiveIndex, handleActiveIndex])

  const iconsWithStroke = [
    IconsType.eye,
    IconsType.basicInfo,
    IconsType.functionalAnalysis,
    IconsType.economicAnalysis,
    IconsType.leftParagraph,
    IconsType.userBusinessCeo,
    IconsType.locationPointer,
    IconsType.lightBulb,
    IconsType.listBullet,
    IconsType.gear,
    IconsType.alertTriangle,
    IconsType.handShake,
    IconsType.newsPaperFolding,
    IconsType.info,
  ]
  const iconsWithStrokeAndFill = [IconsType.assets]

  return (
    <div className={cx('w-full', containerClassName)}>
      <div className="flex justify-between">
        <div className="flex flex-wrap border-b gap-5">
          {tabsData.map((tab, idx) => {
            if (tab.hide) return null
            return (
              <button
                key={idx}
                style={{ background: tab.activeBGColor }}
                className={cx(
                  'h-[31px] px-4 whitespace-nowrap rounded-2xl justify-center items-center gap-2.5 inline-flex  focus:outline-none',
                  `${
                    activeTabIndex !== idx
                      ? tab.inactiveClassname || 'text-neutral600 bg-gray-100 border border-solid border-gray-200'
                      : tab.activeClassname || 'bg-blue-800 text-white border-0'
                  }`,
                  {
                    'opacity-50 cursor-not-allowed': tab.disabled,
                    'cursor-pointer': !tab.disabled,
                  }
                )}
                onClick={() => handleTabChange(idx, tab.label)}>
                <Typography variant={Variant.Callout} type="semibold" className="flex items-center gap-[0.38rem]">
                  {tab.icon &&
                    getIcons(tab.icon, {
                      className: 'w-[16px] h-[16px]',
                      pathClassName: cx({
                        'fill-white ':
                          activeTabIndex === idx &&
                          !iconsWithStroke.includes(tab.icon) &&
                          !iconsWithStrokeAndFill.includes(tab.icon),
                        'fill-gray-700 ':
                          activeTabIndex !== idx &&
                          !iconsWithStroke.includes(tab.icon) &&
                          !iconsWithStrokeAndFill.includes(tab.icon),
                        'stroke-gray-700': tab.icon && iconsWithStroke.includes(tab.icon) && activeTabIndex !== idx,
                        'stroke-white': tab.icon && iconsWithStroke.includes(tab.icon) && activeTabIndex === idx,
                      }),
                      primaryColor: cx({
                        'stroke-gray-700':
                          tab.icon && iconsWithStrokeAndFill.includes(tab.icon) && activeTabIndex !== idx,
                        'stroke-white': tab.icon && iconsWithStrokeAndFill.includes(tab.icon) && activeTabIndex === idx,
                      }),
                      secondaryColor: cx({
                        'fill-gray-700 stroke-gray-700':
                          tab.icon && iconsWithStrokeAndFill.includes(tab.icon) && activeTabIndex !== idx,
                        'fill-white stroke-white':
                          tab.icon && iconsWithStrokeAndFill.includes(tab.icon) && activeTabIndex === idx,
                      }),
                    })}
                  {tab.label}
                </Typography>
              </button>
            )
          })}
        </div>
        <div className="self-center ml-auto">{rightTabHeaderComponent}</div>
      </div>
      <div className={cx('mt-4', className)}>{tabsData[activeTabIndex].content}</div>
    </div>
  )
}

interface PillTabProps {
  className?: string
  containerClassName?: string
  tabsData: TabData[]
  initialActiveTabIndex?: number
  handleActiveIndex?: boolean
  forcedActiveIndex?: number
  onTabChange?: (idx: number, label: string) => void
  rightTabHeaderComponent?: React.ReactNode
  tabType?: 'pill' | 'default'
}

export default PillTab
