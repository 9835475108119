import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createSelectedComparabilityFactors,
  deleteComparabilityJustification,
  editComparabilityFactors,
  updateSelectedComparabilityFactors,
} from 'api/transactions/comparabilityMetrics'
import {
  ComparabilityFactor,
  ComparabilitySubFactor,
  SelectedComparabilityFactor,
} from 'api/transactions/comparabilityMetrics/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import HalfArrow from 'assets/icons/halfArrow'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import Loading from 'components/loading'
import NewAttributeTable from 'components/newAttributeTable'
import DebouncedInput from 'components/newTable/components/debouncedInput'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE } from 'components/tinyMceEditor/constants'
import Typography, { Variant } from 'components/typography'
import UploadDocuments from 'components/uploadDocuments'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import { useRouter } from 'next/router'
import HTMLInputCardField from 'organisms/fieldRenderers/fields/accordionInputCard/components/fields/HTMLInputCardField'
import FileOrTableRadio from 'organisms/fieldRenderers/fields/FileOrTableRadio/FileOrTableRadio'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import ImportFinancialDataModal from '../financialData/components/ImportFinancialDataModal/ImportFinancialDataModal'
import AddEditComparabilityCriteria, {
  AddEditComparabilityCriteriaFieldValue,
} from './components/AddComparabilityCriteria/AddComparabilityCriteria'
import CreateFactorsModal from './components/CreateFactorsModal/CreateFactorsModal'
import ReorderComparabilityFactors from './components/reorderComparabilityFactors/ReorderComparabilityFactors'
import { getSelectedMetricsColumns } from './helpers/comparabilityMetrics.helpers'
import { AddToSelectedData } from './types'

type Props = {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionRefetch: () => void
  transactionByIdRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
}

function ComparabilityFactors({
  transactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionResponse,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('')
  const [addCriteriaModal, setAddCriteriaModal] = useState(false)
  const [createFactorsModal, setCreateFactorsModal] = useState(false)
  const [addToSelectedData, setAddToSelectedData] = useState<AddToSelectedData>()
  const [selectedCriteria, setSelectedCriteria] = useState<SelectedComparabilityFactor>()
  const [reorderModal, setReorderModal] = useState(false)
  const { setIsDirty } = useRoutingHandlerState()
  const { watch, control, setValue, getValues } = useForm<{ type: 'File' | 'Table'; comparability_narrative?: string }>(
    {
      defaultValues: {
        type: 'File',
      },
    }
  )

  const [file, setFile] = useState<File | null | string>(null)

  const toggleReorderModal = useCallback(() => {
    setReorderModal(open => {
      return !open
    })
  }, [])

  const type = watch('type')
  const Router = useRouter()

  const {
    data: metricsList,
    isFetching: loadingMetricsList,
    refetch: refetchMetricsList,
  } = useQuery([QUERIES.GET_COMPARABILITY_METRICS_LIST.key], {
    queryFn: () => QUERIES.GET_COMPARABILITY_METRICS_LIST.function(),
  })

  const {
    data: selectedFactors,
    isFetching: fetchingSelectedFactors,
    refetch: refetchSelectedFactors,
  } = useQuery([QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.key, transactionResponse?.economic_analysis?.id], {
    queryFn: () => QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.function(transactionResponse?.economic_analysis?.id || 0),
    enabled: !!transactionResponse?.economic_analysis?.id,
  })

  const { data: sameTransactions, isFetching: sameTransactionsFetching } = useQuery({
    queryKey: [QUERIES.GET_SAME_TRANSACTIONS_FROM_PREVIOUS_YEARS.key, transactionResponse?.id],
    queryFn: () => QUERIES.GET_SAME_TRANSACTIONS_FROM_PREVIOUS_YEARS.function({ id: transactionResponse?.id || 0 }),
    enabled: !!transactionResponse?.id,
  })

  useEffect(() => {
    if (economicAnalysisTransactionResponse?.comparability_factors_appendix?.file) {
      setFile(economicAnalysisTransactionResponse?.comparability_factors_appendix?.file)
      setValue('type', 'File')
    } else {
      setFile('')
      setValue('type', 'Table')
    }
    setValue('comparability_narrative', economicAnalysisTransactionResponse?.comparability_narrative || '')
  }, [
    economicAnalysisTransactionResponse?.comparability_factors_appendix?.file,
    economicAnalysisTransactionResponse?.comparability_narrative,
    setValue,
    selectedFactors,
  ])

  const [showImportFinancialDataModal, setShowImportFinancialDataModal] = useState(false)
  const closeImportFinancialDataModal = useCallback(() => {
    setShowImportFinancialDataModal(false)
  }, [])

  const importableTransactions = useMemo(() => {
    return sameTransactions || []
  }, [sameTransactions])

  const selectedFactorsTableData = useMemo(() => {
    if (!selectedFactors?.length) return []
    return selectedFactors.sort((a, b) => a.order - b.order)
  }, [selectedFactors])

  const unselectedTableData = useMemo(() => {
    const data: (ComparabilityFactor & { alreadySeleted?: true })[] = []
    metricsList?.forEach(metric => {
      const hasSubfactors = metric.comparability_sub_factors?.length
      const hasUnselectedSubfactors = !!metric.comparability_sub_factors?.find(
        subFactor => !selectedFactors?.find(factor => factor.comparability_sub_factor == subFactor.id)
      )
      const mainFactorSelected = !!selectedFactors?.find(factor => factor.comparability_factor == metric.id)
      if (!hasSubfactors && !mainFactorSelected) {
        data.push(metric)
      }
      if (hasSubfactors && !mainFactorSelected) {
        data.push({
          ...metric,
          comparability_sub_factors:
            metric.comparability_sub_factors?.filter(
              subFactor => !selectedFactors?.find(factor => factor.comparability_sub_factor == subFactor.id)
            ) ?? null,
        })
      }
      if (hasSubfactors && mainFactorSelected && hasUnselectedSubfactors) {
        data.push({
          ...metric,
          alreadySeleted: true,
          comparability_sub_factors:
            metric.comparability_sub_factors?.filter(
              subFactor => !selectedFactors?.find(factor => factor.comparability_sub_factor == subFactor.id)
            ) ?? null,
        })
      }
    })

    if (!searchQuery.trim()) return data

    return data.reduce(
      (
        filtered: (ComparabilityFactor & { alreadySeleted?: true })[],
        factor: ComparabilityFactor & { alreadySeleted?: true }
      ) => {
        // Check if ComparabilityFactor.name matches the searchQuery

        if (factor.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          filtered.push(factor) // Add the entire factor
          return filtered
        }

        // Check if any ComparabilitySubFactor.name matches the searchQuery
        const matchingSubFactors =
          factor.comparability_sub_factors?.filter(subFactor =>
            subFactor.name.toLowerCase().includes(searchQuery.toLowerCase())
          ) || []

        if (matchingSubFactors.length > 0) {
          // Create a new ComparabilityFactor with only the matched sub factors
          filtered.push({
            ...factor,
            comparability_sub_factors: matchingSubFactors,
          })
        }

        return filtered
      },
      []
    )
  }, [metricsList, searchQuery, selectedFactors])

  const toggleAddCriteriaModal = useCallback(() => {
    setAddCriteriaModal(open => {
      if (open) {
        setSelectedCriteria(undefined)
        setAddToSelectedData(undefined)
      }
      return !open
    })
  }, [])
  const toggleCreateFactorsModal = useCallback(() => {
    setCreateFactorsModal(open => {
      return !open
    })
  }, [])

  const { refetchChecklist } = useReportWizardContext()

  const createSelectedComparabilityFactorsMutation = useMutation(createSelectedComparabilityFactors)

  const onAddCriteria = useCallback(
    (unselectedCriteria: AddToSelectedData, data: AddEditComparabilityCriteriaFieldValue) => {
      economicAnalysisTransactionResponse?.id &&
        createSelectedComparabilityFactorsMutation.mutate(
          {
            economic_analysis: economicAnalysisTransactionResponse?.id,
            comparability_sub_factor: unselectedCriteria.isSubfactor ? unselectedCriteria.id : undefined,
            comparability_factor: !unselectedCriteria.isSubfactor ? unselectedCriteria.id : undefined,
            justification: data.justification ? data.justification : '',
            comparability_file: data.document instanceof File ? data.document : undefined,
            comparability_file_name: data.document instanceof File ? data.document.name : undefined,
          },
          {
            onSuccess() {
              toast.success(`Added ${unselectedCriteria.fullName} to selected factor`)
              setSelectedCriteria(undefined)
              setAddToSelectedData(undefined)
              setAddCriteriaModal(false)
              refetchSelectedFactors()
              refetchChecklist()
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
    },
    [
      createSelectedComparabilityFactorsMutation,
      economicAnalysisTransactionResponse?.id,
      refetchChecklist,
      refetchSelectedFactors,
    ]
  )

  const updateSelectedComparabilityFactorsMuation = useMutation(updateSelectedComparabilityFactors)
  const deleteComparabilityJustificationMutation = useMutation(deleteComparabilityJustification)

  const onUpdateCriteria: (
    selectedCriteria: SelectedComparabilityFactor,
    data: AddEditComparabilityCriteriaFieldValue
  ) => void = useCallback(
    (selectedCriteria, data) => {
      updateSelectedComparabilityFactorsMuation.mutate(
        {
          id: selectedCriteria.id,
          justification: data.justification ? data.justification : '',
          comparability_file: data.document instanceof File ? data.document : undefined,
          comparability_file_name: data.document instanceof File ? data.document.name : undefined,
        },
        {
          onSuccess() {
            toast.success(`Successfully updated selected factor`)
            setSelectedCriteria(undefined)
            setAddToSelectedData(undefined)
            setAddCriteriaModal(false)
            refetchSelectedFactors()
            refetchChecklist()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    },
    [updateSelectedComparabilityFactorsMuation, refetchSelectedFactors, refetchChecklist]
  )
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const onDeleteJustification = useCallback(
    async (data: SelectedComparabilityFactor) => {
      const name = data.comparability_factor_full_name
      const confirmation = await getConfirmation(
        `Are you sure you want to delete "${name}". It will be removed from any transaction where it is added.`
      )
      if (confirmation) {
        deleteComparabilityJustificationMutation.mutate(
          { id: data.id || 0 },
          {
            onSuccess() {
              toast.success(`Successfully deleted ${name}`)
              refetchMetricsList()
              refetchSelectedFactors()
              refetchChecklist()
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    },
    [
      getConfirmation,
      deleteComparabilityJustificationMutation,
      refetchMetricsList,
      refetchSelectedFactors,
      refetchChecklist,
    ]
  )

  const onEditSelectedCriteria = useCallback((data: SelectedComparabilityFactor) => {
    setSelectedCriteria(data)
    setAddToSelectedData(undefined)
    setAddCriteriaModal(true)
  }, [])

  const selectedColumns = useMemo(() => {
    return getSelectedMetricsColumns({ onEditSelectedCriteria, onDeleteJustification })
  }, [onEditSelectedCriteria, onDeleteJustification])

  const editComparabilityFactorsMutations = useMutation(editComparabilityFactors, {
    onSuccess() {
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
  })

  const handleDeleteFile = useCallback(async () => {
    const confirmation = await getConfirmation(`Are you sure you want to delete the file`)
    confirmation && setFile(null)
  }, [getConfirmation])

  const onSave = useCallback(async () => {
    const isFileUpload = getValues('type') == 'File'
    editComparabilityFactorsMutations.mutate(
      {
        id: transactionResponse?.economic_analysis?.id || 0,
        comparability_factors_file: file instanceof File && isFileUpload ? file : file === null ? null : undefined,
        comparability_factors_file_name:
          file instanceof File && isFileUpload ? file.name : file === null ? null : undefined,
        comparability_narrative: getValues('comparability_narrative'),
      },
      {
        onSuccess() {
          toast.success('Economic Analysis updated!!')
          setIsDirty(false)
          refetchChecklist()
        },
        onError(error) {
          getToastErrorMessage(error as AxiosError)
        },
      }
    )
  }, [
    editComparabilityFactorsMutations,
    file,
    getValues,
    refetchChecklist,
    setIsDirty,
    transactionResponse?.economic_analysis?.id,
  ])
  const onComparabilityNarrativeDelete = useCallback(async () => {
    editComparabilityFactorsMutations.mutate(
      {
        id: transactionResponse?.economic_analysis?.id || 0,
        comparability_narrative: '',
      },
      {
        onSuccess() {
          toast.success('Comparability Narrative deleted successfully')
          refetchChecklist()
        },
        onError(error) {
          getToastErrorMessage(error as AxiosError)
        },
      }
    )
  }, [editComparabilityFactorsMutations, refetchChecklist, transactionResponse?.economic_analysis?.id])
  const loadingLists =
    loadingMetricsList ||
    fetchingSelectedFactors ||
    deleteComparabilityJustificationMutation.isLoading ||
    editComparabilityFactorsMutations.isLoading

  const mutationsLoading =
    updateSelectedComparabilityFactorsMuation.isLoading || createSelectedComparabilityFactorsMutation.isLoading

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const onAddMainfactor = useCallback(
    (
      data: ComparabilityFactor & {
        alreadySeleted?: true
      }
    ) => {
      setAddToSelectedData({
        id: data.id,
        name: data.name,
        fullName: data.name,
        isSubfactor: false,
      })
      setAddCriteriaModal(true)
      setSelectedCriteria(undefined)
    },
    []
  )
  const onAddSubfactor = useCallback((data: ComparabilitySubFactor) => {
    setAddToSelectedData({
      id: data.id,
      name: data.comparability_factor_name,
      fullName: data.comparability_factor_full_name,
      subfactorName: data.name,
      isSubfactor: true,
    })
    setSelectedCriteria(undefined)
    setAddCriteriaModal(true)
  }, [])

  const onSearchChange: (value: string | number) => void = useCallback(value => {
    setSearchQuery(String(value || ''))
  }, [])

  const disableSave = useMemo(() => {
    return !(
      file instanceof File ||
      (file === null && !!economicAnalysisTransactionResponse?.comparability_factors_appendix)
    )
  }, [economicAnalysisTransactionResponse?.comparability_factors_appendix, file])
  useEffect(() => {
    setIsDirty(!disableSave)

    return () => {
      setIsDirty(false)
    }
  }, [disableSave, setIsDirty])
  return (
    <div className="w-full relative h-full">
      <div className="flex flex-col w-full gap-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 22.5rem)' }}>
        {(mutationsLoading || loadingLists) && (
          <Loading className="absolute inset-0 flex items-center justify-center z-20 bg-white" />
        )}

        <BorderlessBox
          bgClass="bg-yellow-50"
          label={
            <Typography variant={Variant.Callout} type="semibold" className="!text-yellow-500 mr-auto">
              As part of the Method Application the following comparable factors are selected
            </Typography>
          }>
          {null}
        </BorderlessBox>
        <HTMLInputCardField
          id="comparability_narrative"
          control={control}
          leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
          label="Comparability Narrative"
          saveButtonText="Save"
          classname="border-r-0 border-t-0 border-b-0 border-l border-solid border-l-gray-300 ml-auto pl-4 py-1"
          containerClassname="!bg-gray-50 !py-5 !rounded-[0.625rem] border-solid !border !border-gray-100"
          handleSave={onSave}
          handleDelete={onComparabilityNarrativeDelete}
        />
        <BorderlessBox
          label={'Comparability Factors'}
          topRightComponents={
            <div className="w-max flex items-center gap-2">
              <FileOrTableRadio control={control} id="type" />
              {!!importableTransactions.length && !sameTransactionsFetching && (
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => setShowImportFinancialDataModal(true)}
                  className="ms-2"
                  icon={IconsType.download}>
                  Import
                </Button>
              )}
            </div>
          }
          className="space-y-4"
          variant="grey">
          {type == 'Table' ? (
            <div className="flex justify-between w-full gap-5">
              <BorderlessBox
                label="Suggested Factors"
                variant="white"
                className=" h-max"
                topRightComponents={
                  <>
                    <DebouncedInput placeholder="Search" value={searchQuery} onChange={onSearchChange} />
                  </>
                }
                footer={
                  <Button onClick={toggleCreateFactorsModal} className="!py-2 !gap-1 ml-auto" icon={IconsType.plus}>
                    Comparability Factor
                  </Button>
                }>
                <div className="max-h-80 flex w-full gap-3 py-3 flex-col overflow-y-auto">
                  {unselectedTableData.length ? (
                    unselectedTableData.map(factor => {
                      if (factor.comparability_sub_factors?.length) {
                        return (
                          <BorderlessBoxAccordion
                            className="border border-solid border-gray-200 px-4 py-2"
                            key={factor.id + (searchQuery || '')}
                            label={factor.name}
                            topRightComponents={
                              factor.alreadySeleted ? undefined : (
                                <Button
                                  onClick={() => onAddMainfactor(factor)}
                                  className="!py-0 !h-auto"
                                  variant={ButtonVariant.Tertiary}>
                                  Add
                                </Button>
                              )
                            }
                            defaultOpen={
                              !!searchQuery.trim() && !factor.name.toLowerCase().includes(searchQuery.toLowerCase())
                            }
                            rightComponentAlwaysVisible
                            variant="white">
                            <div
                              style={{ borderWidth: 0, borderTopWidth: 1 }}
                              className="w-full gap-2 border-solid mt-1.5 space-y-1 py-1 border-gray-200">
                              <ul className="ps-4">
                                {factor.comparability_sub_factors.map(subFactor => {
                                  return (
                                    <li key={subFactor.id}>
                                      <div className="flex items-center justify-between">
                                        <Typography
                                          variant={Variant.Callout}
                                          type="semibold"
                                          className="text-gray-700 mr-auto">
                                          {subFactor.name}
                                        </Typography>
                                        <Button
                                          onClick={() => onAddSubfactor(subFactor)}
                                          className="!py-0 !h-auto"
                                          variant={ButtonVariant.Tertiary}>
                                          Add
                                        </Button>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </BorderlessBoxAccordion>
                        )
                      } else {
                        return (
                          <BorderlessBox
                            className="border border-solid border-gray-200 px-4 py-2"
                            variant="white"
                            topRightComponents={
                              <Button
                                onClick={() => onAddMainfactor(factor)}
                                className="!py-0 !h-auto"
                                variant={ButtonVariant.Tertiary}>
                                Add
                              </Button>
                            }
                            key={factor.id}
                            label={factor.name}>
                            {null}
                          </BorderlessBox>
                        )
                      }
                    })
                  ) : searchQuery.trim() ? (
                    <Typography variant={Variant.Callout} className="p-10 w-full text-center text-neutral600">
                      No entries found.{' '}
                      <span onClick={() => setSearchQuery('')} className="text-blue-900 font-semibold cursor-pointer">
                        Clear search?
                      </span>
                    </Typography>
                  ) : (
                    <Typography variant={Variant.Heading2} className="p-10 text-neutral600">
                      No entries found{' '}
                      <span onClick={toggleCreateFactorsModal} className="text-blue-900 font-semibold cursor-pointer">
                        Create new?
                      </span>
                    </Typography>
                  )}
                </div>
              </BorderlessBox>
              <div className="flex flex-col items-center justify-center gap-2">
                <HalfArrow />
                <HalfArrow className="rotate-180" />
              </div>
              <BorderlessBox
                label="Selected factors"
                variant="white"
                className="flex w-full gap-3 h-max"
                topRightComponents={
                  selectedFactorsTableData?.length > 1 && (
                    <Button onClick={toggleReorderModal} icon={IconsType.sixDotDrag} variant={ButtonVariant.Secondary}>
                      Reorder
                    </Button>
                  )
                }>
                <NewAttributeTable
                  className="max-h-[330px] overflow-y-auto mt-[-12px]"
                  hideHeader
                  tableClassName="!border-spacing-y-3"
                  data={selectedFactorsTableData}
                  columns={selectedColumns}
                />
              </BorderlessBox>
            </div>
          ) : (
            <>
              <UploadDocuments
                className="w-full"
                boxClassName="bg-white"
                accept=".pdf"
                file={file instanceof File ? file : null}
                fileUrl={typeof file == 'string' ? file : undefined}
                fileName={
                  economicAnalysisTransactionResponse?.comparability_factors_appendix?.file &&
                  economicAnalysisTransactionResponse?.comparability_factors_appendix?.file === file
                    ? economicAnalysisTransactionResponse?.comparability_factors_appendix?.file_name ||
                      'Comparability File'
                    : undefined
                }
                setFile={setFile}
                showDelete
                handleDelete={handleDeleteFile}
              />
            </>
          )}
          {(!!addToSelectedData || !!selectedCriteria) && (
            <AddEditComparabilityCriteria
              isOpen={addCriteriaModal}
              refetchSelectedFactors={refetchSelectedFactors}
              onClose={toggleAddCriteriaModal}
              onAddCriteria={onAddCriteria}
              onUpdateCriteria={onUpdateCriteria}
              key={`${addCriteriaModal ? 1 : 0}-${addToSelectedData?.id || '0'}-${selectedCriteria?.id || '0'}`}
              loading={mutationsLoading}
              unselectedCriteria={addToSelectedData}
              selectedCriteria={selectedCriteria}
            />
          )}
          <CreateFactorsModal
            key={createFactorsModal ? 0 : 1}
            metricsList={metricsList}
            isOpen={createFactorsModal}
            onClose={toggleCreateFactorsModal}
          />
        </BorderlessBox>

        <ConfirmationModal />
        {selectedFactorsTableData && economicAnalysisTransactionResponse && (
          <ReorderComparabilityFactors
            economicAnalysisId={economicAnalysisTransactionResponse?.id}
            refetchSelectedFactors={refetchSelectedFactors}
            key={String(reorderModal)}
            isOpen={reorderModal}
            onClose={toggleReorderModal}
            comparabilityFactorsList={selectedFactorsTableData}
          />
        )}
      </div>
      <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
        <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
          <Button
            isDefaultSize={false}
            onClick={handleCancel}
            iconCLass="w-5 h-5 transform rotate-180"
            iconPathClassName="stroke-blue900"
            className="!gap-1"
            icon={IconsType.arrowRight}
            variant={ButtonVariant.Secondary}>
            Back
          </Button>
          <Button onClick={onSave} disabled={disableSave} className="ms-auto" icon={IconsType.save}>
            Save
          </Button>
        </div>
      </Portal>

      <ImportFinancialDataModal
        key={'showImportFinancialDataModal' + String(showImportFinancialDataModal)}
        isOpen={showImportFinancialDataModal}
        onClose={closeImportFinancialDataModal}
        importableTransactions={importableTransactions}
        transactionResponse={transactionResponse}
      />
    </div>
  )
}

export default ComparabilityFactors
