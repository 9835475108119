import { Portal } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Editor } from '@tinymce/tinymce-react'
import { TemplateVariableResponse } from 'api/templates/types'
import classNames from 'classnames'
import InfoLabel from 'components/input/components/infoLabel'
import Loading from 'components/loading'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, FieldValues, Path, useController, useWatch } from 'react-hook-form'
import { Editor as RawEditor } from 'tinymce'

import {
  customExhibitStyles,
  customFootNoteStyles,
  DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE,
  defaultEditorPlugins,
  defaultFontFamilyClasses,
  editAreaStyling,
  fontFamilyFormats,
  fontSizeFormats,
  integralCss,
  TINYMCE_API_KEY,
} from './constants'
import { ai_requests } from './helpers/ai.helpers'
import { setupExhibitButton } from './helpers/exhibit.helpers'
import { setupFootnotesButton } from './helpers/footnote.helpers'
import { resizeImage } from './helpers/imageResize.helpers'
import { useComments, useSpellIgnoreList } from './hooks'
import registerSidebarAI from './plugins/AIraChat/SidebarAI'
import registerTemplateVariables from './plugins/TemplateVariables/TemplateVariables.register'
import { EditorAuthor, EditorConversation, EditorRef } from './types'

const TinyMceEditorControlledField = <T extends FieldValues>({
  disabled = false,
  height = '100%',
  minHeight,
  name,
  maxHeight,
  contentStyles,
  contentCSS,
  label,
  onSetup,
  leftGroupButtons = DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE.join(' | '),
  editorRef,
  control,
  required,
  className,
  topRightComponents,
  children,
  loading,
  placeholder = 'Type here...',
  documentConversations,
  documentAuthors = [],
  statusbar = false,
  templateVariables,
}: TinyMceEditorControlledFieldProps<T>) => {
  const {
    field: { onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })

  const [editAreaDOMRect, setEditAreaDOMRect] = useState<DOMRect | null>(null)

  const css = useMemo(() => {
    if (contentStyles != undefined || contentCSS != undefined) {
      return contentStyles || ''
    }
    return localFileCss || integralCss
  }, [contentCSS, contentStyles, localFileCss])
  const { user } = useAuth()

  const author = useMemo(() => {
    return {
      id: user?.id,
      username: user?.username || 'unknown',
      fullName: `${(user?.first_name ? `${user?.first_name} ` : '') + (user?.last_name || '')}` || undefined,
      avatarUrl: user?.profile_photo || undefined,
    }
  }, [user])
  const [isClient, setIsClient] = useState(false)
  useEffect(() => setIsClient(true), [])
  const value = useWatch({ control, name })
  const [version, setVersion] = useState(1)
  const [isEditorLoading, setIsEditorLoading] = useState(true)
  const [isEditorInitialized, setIsEditorInitialized] = useState(false)

  const internalRef = useRef<EditorRef>(null)

  const mergedRef = editorRef || (internalRef as MutableRefObject<EditorRef>)

  const {
    createCommentFn,
    replyToCommentFn,
    editCommentFn,
    deleteCommentFn,
    deleteCommentThreadFn,
    deleteAllCommentThreadsFn,
    lookupACommentFn,
    fetchCommentsFn,
  } = useComments({
    editorRef: mergedRef as MutableRefObject<EditorRef>,
    author: author,
    prevAuthors: documentAuthors,
  })
  const ignoreList = useSpellIgnoreList()

  useEffect(() => {
    mergedRef.current = {
      editor: mergedRef?.current?.editor,
      comments: documentConversations || [],
      commentsLog: mergedRef?.current?.commentsLog || [],
    }
  }, [documentConversations, mergedRef])

  let toolbarButtons = Array.isArray(leftGroupButtons) ? leftGroupButtons.join(' | ') : leftGroupButtons

  if (templateVariables?.length) {
    toolbarButtons = toolbarButtons + ' | templateVariables'
  }

  const isFootnoteEnabled = useMemo(() => {
    return toolbarButtons.includes('footnoteButton')
  }, [toolbarButtons])

  const isCommentsEnabled = useMemo(() => {
    return toolbarButtons.includes('showcomments')
  }, [toolbarButtons])

  const isExhibitEnabled = useMemo(() => {
    return toolbarButtons.includes('exhibitButton')
  }, [toolbarButtons])

  const nativeSetupHandler = useCallback(
    async (editor: RawEditor) => {
      if (isFootnoteEnabled) {
        await new Promise(resolve => {
          setupFootnotesButton(editor, () => {
            resolve(null)
          })
        })
      }
      if (isExhibitEnabled) {
        await new Promise(resolve => {
          setupExhibitButton(editor, () => {
            resolve(null)
          })
        })
      }
    },
    [isExhibitEnabled, isFootnoteEnabled]
  )

  const defaultContentStyles = `${editAreaStyling} ${defaultFontFamilyClasses}  ${
    isFootnoteEnabled ? customFootNoteStyles : ''
  } ${isExhibitEnabled ? customExhibitStyles : ''} `

  useEffect(() => {
    setVersion(version => version + 1)
    setIsEditorLoading(true)
    const timer = setTimeout(() => {
      setIsEditorLoading(false)
    }, 500)
    return () => {
      timer && clearTimeout(timer)
    }
  }, [toolbarButtons, contentStyles, contentCSS, templateVariables])

  useEffect(() => {
    if (ref) {
      ref({
        focus: () => mergedRef?.current?.editor?.focus(),
      })
    }
  }, [mergedRef, ref])

  const loadingState = isEditorLoading || loading || !isEditorInitialized

  useEffect(() => {
    let interval: NodeJS.Timer
    const getEditAreaRect = () => {
      const isHidden =
        (
          document.querySelector('#headlessui-portal-root > [data-headlessui-portal]:not(:last-child)') as HTMLElement
        )?.getAttribute('aria-hidden') === 'true'

      if (mergedRef.current?.editor && !isHidden) {
        const editorElement = mergedRef.current?.editor?.getContainer()
        const editAreaElements = editorElement?.getElementsByClassName('tox-edit-area')

        const editAreaIFrame = editAreaElements?.[0]?.querySelector('iframe')
        if (editAreaIFrame) {
          setEditAreaDOMRect(editAreaIFrame.getBoundingClientRect())
          return
        }
      }
      setEditAreaDOMRect(null)
    }
    if (error?.message) {
      getEditAreaRect()
      interval = setInterval(() => {
        getEditAreaRect()
      }, 1000)
    } else {
      setEditAreaDOMRect(null)
    }

    return () => interval && clearInterval(interval)
  }, [error?.message, isEditorInitialized, mergedRef])
  if (!isClient) return null

  return (
    <div className={classNames('w-full h-full doc-editor-blue flex flex-col', className)}>
      {(!!label || !!topRightComponents) && (
        <div className="flex items-center w-full mb-2">
          {!!label && typeof label == 'string' ? <InfoLabel label={label} required={required} /> : label}
          {topRightComponents}
        </div>
      )}

      <div className={`h-full w-full ${loadingState ? '' : '!h-0 !w-0'}`}>
        <Loading className={`flex w-full justify-center items-center h-full`} />
      </div>

      {!isEditorLoading && (
        <div className={`h-full ${!loadingState ? '' : '!h-0 !w-0'}`}>
          <Editor
            key={`${version}`}
            onEditorChange={value => {
              onChange(value)
            }}
            id={`${name}`}
            value={value || ''}
            apiKey={TINYMCE_API_KEY}
            onInit={(evt, editor) => {
              mergedRef.current = {
                editor,
                comments: mergedRef?.current?.comments || [],
                commentsLog: mergedRef?.current?.commentsLog || [],
              }
              setIsEditorInitialized(true)
            }}
            onRemove={() => {
              setIsEditorInitialized(false)
            }}
            onBlur={onBlur}
            disabled={disabled}
            init={{
              invalid_elements: toolbarButtons.includes('image') ? '' : 'img',
              sidebar_show: templateVariables?.length ? 'templateVariables' : undefined,
              highlight_on_focus: false,
              skin_url: '/skins/ui/integralDark',
              branding: false,
              resize: false,
              statusbar: statusbar,
              promotion: false,
              height: height,
              min_height: minHeight,
              max_height: maxHeight,
              menubar: false,
              plugins: defaultEditorPlugins,
              setup: async editor => {
                await nativeSetupHandler(editor)

                // Register our custom AI sidebar plugin
                registerSidebarAI(editor, user)
                if (templateVariables?.length) {
                  registerTemplateVariables(editor, templateVariables)
                }

                if (onSetup && typeof onSetup === 'function') {
                  onSetup(editor)
                }
              },
              pagebreak_separator: '<div class="page-break"></div>',
              pagebreak_split_block: true,
              toolbar: toolbarButtons,
              toolbar_mode: 'wrap',
              block_formats:
                'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
              content_style: `${defaultContentStyles} ${css}`,
              content_css: contentCSS,
              content_css_cors: false,
              font_family_formats: fontFamilyFormats,
              font_size_formats: fontSizeFormats,

              comments: isCommentsEnabled, // Explicitly disable comments
              // Comments plugin related work
              tinycomments_mode: 'callback',
              tinycomments_author: author?.username,
              tinycomments_author_name: author?.fullName || author?.username,
              tinycomments_author_avatar: author?.avatarUrl,
              tinycomments_create: createCommentFn,
              tinycomments_reply: replyToCommentFn,
              tinycomments_edit_comment: editCommentFn,
              tinycomments_delete: deleteCommentThreadFn,
              tinycomments_delete_all: deleteAllCommentThreadsFn,
              tinycomments_delete_comment: deleteCommentFn,
              tinycomments_lookup: lookupACommentFn,
              tinycomments_fetch: fetchCommentsFn,

              // Image related options
              automatic_uploads: false,

              // power paste related options
              paste_data_images: true,
              paste_merge_formats: true,
              powerpaste_allow_local_images: true,
              paste_preprocess(editor, args) {
                args.content = args.content.replace(/font-family\s*:\s*[^;]+;/gi, '')
                // replace empty anchor tags being pasted from word documents
                args.content = args.content.replace(/<a name="[^"]+"><\/a>/g, '')
                //replace non breaking spaces with regular space
                args.content = args.content
                  .replace(/\u00A0/g, ' ') //unicode
                  .replace(/&nbsp;/g, ' ') //html
                  .replace(/&NonBreakingSpace;/g, ' ') //html
              },
              ai_request: ai_requests(mergedRef.current?.editor ?? undefined),
              link_target_list: false,
              link_default_target: '_blank',
              extended_valid_elements: 'a[href|target=_blank] span[variable-name|data-variable-name]',

              spellchecker_languages: 'English (United States)=en_US,English (United Kingdom)=en_GB',
              spellchecker_ignore_list: ignoreList,
              placeholder: placeholder,
              images_upload_handler: async blobInfo => {
                const base64Url = 'data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64()

                try {
                  const resizedImage: string = await resizeImage(base64Url, 672 * 1.5, 912 * 1.5)
                  return base64Url.length > resizedImage.length ? resizedImage : base64Url
                } catch (error) {
                  return base64Url
                }
              },
              init_instance_callback: editor => {
                editor.on('CommentChange', evt => {
                  const eventLog = evt.getEventLog()
                  mergedRef.current = {
                    editor: mergedRef?.current?.editor,
                    comments: mergedRef?.current?.comments || [],
                    commentsLog: eventLog.events,
                  }
                })
              },
            }}
          />
        </div>
      )}
      {error &&
        error.message &&
        (!editAreaDOMRect ? (
          <Typography variant={Variant.Callout} className="pt-[0.125rem] text-center text-red500">
            {error.message}
          </Typography>
        ) : (
          <div>
            <Portal>
              <Typography
                style={{
                  left: editAreaDOMRect ? `${editAreaDOMRect.left}px` : '0',
                  top: editAreaDOMRect ? `${editAreaDOMRect.bottom}px` : '0',
                  zIndex: 100,
                  position: 'fixed',
                }}
                variant={Variant.Callout}
                className="pt-[0.125rem] text-red500">
                {error.message}
              </Typography>
            </Portal>
            <Typography
              style={{
                visibility: 'hidden',
              }}
              variant={Variant.Callout}
              className="pt-[0.125rem] text-red500">
              {error.message}
            </Typography>
          </div>
        ))}

      {children}
    </div>
  )
}

TinyMceEditorControlledField.displayName = 'TinyMceEditorControlledField'
type TinyMceEditorControlledFieldProps<T extends FieldValues> = {
  height?: string | number
  minHeight?: number
  maxHeight?: number
  disabled?: boolean
  contentStyles?: string
  contentCSS?: string | boolean | string[]
  actionToolbar?: string[]
  onSetup?: (editor: RawEditor) => void
  leftGroupButtons?: string | string[]
  control: Control<T>
  name: Path<T>
  editorRef?: MutableRefObject<EditorRef | null>
  loading?: boolean
  label?: string
  required?: boolean
  className?: string
  topRightComponents?: ReactNode
  children?: React.ReactNode
  placeholder?: string
  author?: EditorAuthor
  documentConversations?: EditorConversation[] | undefined
  documentAuthors?: EditorAuthor[] | undefined
  statusbar?: boolean
  templateVariables?: TemplateVariableResponse[]
}

export default TinyMceEditorControlledField
