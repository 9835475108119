import {
  AutoAwesomeOutlined,
  DashboardOutlined,
  FolderCopyOutlined,
  HandshakeOutlined,
  LanguageOutlined,
  MapsHomeWorkOutlined,
} from '@mui/icons-material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { IconsType } from 'assets/types'
import { ROUTES } from 'constants/routes'
import { ROUTES_TITLES } from 'organisms/layout/constants/layout.constants'
import { ReactNode } from 'react'

export const getMenus = (props?: { hideICA?: boolean; hideChat?: boolean; hideBenchmarkStudy?: boolean }): IMenu[] => [
  {
    type: 'Entities',
    fields: [
      { title: ROUTES_TITLES[ROUTES.DASHBOARD], src: DashboardOutlined, path: ROUTES.DASHBOARD },
      { title: ROUTES_TITLES[ROUTES.ORGANIZATION], src: LanguageOutlined, path: ROUTES.ORGANIZATION },
      {
        title: ROUTES_TITLES[ROUTES.LEGAL_ENTITY_MANAGEMENT],
        src: MapsHomeWorkOutlined,
        path: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
      {
        title: ROUTES_TITLES[ROUTES.TRANSACTION_MANAGEMENT],
        path: ROUTES.TRANSACTION_MANAGEMENT,
        moduleIcon: IconsType.transactionManagement,
      },
      {
        title: ROUTES_TITLES[ROUTES.BENCHMARK_MANAGEMENT],
        moduleIcon: IconsType.benchmark,
        path: ROUTES.BENCHMARK_MANAGEMENT,
      },
      {
        title: ROUTES_TITLES[ROUTES.TEMPLATE_MANAGEMENT],
        moduleIcon: IconsType.dualScreen,
        path: ROUTES.TEMPLATE_MANAGEMENT,
      },
      {
        title: ROUTES_TITLES[ROUTES.DOCUMENT_MANAGEMENT],
        src: FolderCopyOutlined,
        path: ROUTES.DOCUMENT_MANAGEMENT,
      },
      {
        title: ROUTES_TITLES[ROUTES.IC_AGREEMENTS_MANAGEMENT],
        src: HandshakeOutlined,
        path: ROUTES.IC_AGREEMENTS_MANAGEMENT,
        hide: !!props?.hideICA,
      },

      { title: ROUTES_TITLES[ROUTES.CHAT_BOT], src: AutoAwesomeOutlined, path: ROUTES.CHAT_BOT, hide: props?.hideChat },
      {
        title: ROUTES_TITLES[ROUTES.BENCHMARK_STUDIES],
        moduleIcon: IconsType.bookSpark,
        path: ROUTES.BENCHMARK_STUDIES,
        hide: props?.hideBenchmarkStudy,
      },
    ],
  },
]

export interface IMenu {
  type: string
  fields: ISidearField[]
}

export interface ISidearField {
  title: NonNullable<ReactNode>
  src?:
    | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
        muiName: string
      })
    | string
  path: string
  moduleIcon?: IconsType
  subfields?: ISidearField[]
  hide?: boolean
}
