import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { applyTemplateToTransactions } from 'api/templates'
import { TemplateResponse } from 'api/templates/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { requiredDropdownOptionSchema } from 'schemas/schemas'
import { SelectOptions } from 'types/common.types'
import { createQueryParams, getToastErrorMessage } from 'utils/utils'
import * as yup from 'yup'
interface ApplyFATemplateModalProps {
  openModal: boolean
  toggleModal: () => void
  attributesRefecth: () => void
  transactionResponse: TransactionByIdResponse
}
export interface FIELDS_VALUE {
  template?: SelectOptions
}
export const ApplyFATemplateModal: React.FC<ApplyFATemplateModalProps> = ({
  openModal,
  toggleModal,
  transactionResponse,
  attributesRefecth,
}) => {
  const Router = useRouter()
  const { control, handleSubmit, setValue, watch } = useForm<FIELDS_VALUE>({
    mode: 'onSubmit',
    resolver: yupResolver(yup.object({ template: requiredDropdownOptionSchema })),
  })
  const handleModalClose = () => {
    toggleModal()
  }
  const year = Router.query?.year
  const template = watch('template')
  const { data: templateData, isLoading: templateDataLoading } = useQuery(
    [QUERIES.GET_TEMPLATE_LIST.key, Router.query?.orgId, transactionResponse.functional_profile.id],
    {
      queryFn: () =>
        QUERIES.GET_TEMPLATE_LIST.function({
          template_type: 'functional_analysis',
          functional_profile: transactionResponse.functional_profile.id,
        }),
      enabled: !!Router.query?.orgId && !isNaN(Number(Router.query?.orgId)),
    }
  )

  const getTemplateOptions = useCallback((templateData: TemplateResponse[]): SelectOptions[] => {
    return (templateData ?? []).map(template => ({
      value: template.id,
      label: (
        <div className="flex gap-1">
          <div>{template.name}</div>
        </div>
      ),
    }))
  }, [])
  const templateOptions: SelectOptions[] = useMemo(
    () => getTemplateOptions(templateData || []),
    [getTemplateOptions, templateData]
  )
  const redirectToTemplateDashboard = useCallback(() => {
    Router.push(
      `${ROUTES.TEMPLATE_MANAGEMENT}?${createQueryParams({
        year,
      })}`
    )
  }, [Router, year])
  const templateCreateButton = useMemo(() => {
    return { buttonLabel: 'Create New', buttonAction: redirectToTemplateDashboard }
  }, [redirectToTemplateDashboard])
  const ApplyTemplateMutation = useMutation(applyTemplateToTransactions, {
    onSuccess: () => {
      toast.success(`Template applied Successfully!!`)
      setValue('template', undefined)
      attributesRefecth()
      toggleModal()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })
  const handleApplyTemplate = useCallback(() => {
    ApplyTemplateMutation.mutate({
      id: Number(template?.value),
      data: {
        transactions: [transactionResponse?.id || 0],
        reset_previously_applied_transactions: false,
        year: Number(year),
      },
    })
  }, [ApplyTemplateMutation, template?.value, transactionResponse?.id, year])

  const loading = useMemo(() => {
    return templateDataLoading || ApplyTemplateMutation.isLoading
  }, [ApplyTemplateMutation.isLoading, templateDataLoading])
  return (
    <Modal
      title="Select Template"
      className="gap-6 p-6 relative h-full"
      containerClassName="w-[54rem] h-[32rem]"
      isOpen={openModal}
      onClose={handleModalClose}>
      {loading && (
        <Loading className="absolute left-0 right-0 bottom-0 top-14 !bg-white  !mt-0 z-50  flex items-center justify-center rounded-xl" />
      )}
      <div className="w-full flex flex-col h-full gap-4 ">
        <BorderlessBox
          bgClass="bg-yellow-50"
          label={
            <Typography variant={Variant.Callout} type="semibold" className="!text-yellow-500 mr-auto">
              Select a template to fill the information for functional analysis. All the data will be auto-populated
              from the selected template.
            </Typography>
          }>
          {null}
        </BorderlessBox>
        <SelectDropDown
          label="Select Functional Analysis Template"
          id="template"
          showSelectMenuButton={true}
          maxHeight="10rem"
          selectMenuButtonProps={templateCreateButton}
          control={control}
          required
          options={templateOptions}
        />
      </div>
      <div className="flex w-full justify-between items-end">
        <Button variant={ButtonVariant.Secondary} onClick={handleModalClose}>
          Skip & Add Manually
        </Button>
        <Button onClick={handleSubmit(handleApplyTemplate)} icon={IconsType.save}>
          Save
        </Button>
      </div>
    </Modal>
  )
}
