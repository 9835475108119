import {
  FunctionalAttributes,
  FunctionalProfileAttributeResponse,
} from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import {
  associated_entity,
  attributes,
  primary_entity,
  TransactionResponse,
} from 'api/transactions/getTransaction/types'
import { formatCurrency } from 'lib/formatCurrency'
export const segregateEntitiesFromTransactions = (transaction: TransactionByIdResponse | TransactionResponse) => {
  const sourceEntities: ((associated_entity | primary_entity) & { is_source?: boolean })[] = []
  const targetEntities: ((associated_entity | primary_entity) & { is_source?: boolean })[] = []
  const perEntityCosts: Record<string, { cost: string; costInUsd: number }> = {}

  if (transaction.is_primary_le_source) {
    sourceEntities.push({ ...transaction.primary_entity, is_source: true })
    transaction.individual_transactions.forEach(singleTransaction => {
      const entity: (associated_entity | primary_entity) & { is_source?: boolean } = singleTransaction.associated_entity
      entity.is_source = false
      perEntityCosts[entity.id] = {
        cost: `${transaction.currency} ${formatCurrency(singleTransaction.cost)}`,
        costInUsd: singleTransaction.cost_in_usd,
      }
      targetEntities.push(entity)
    })
  } else {
    targetEntities.push({ ...transaction.primary_entity, is_source: false })
    transaction.individual_transactions.forEach(singleTransaction => {
      const entity: (associated_entity | primary_entity) & { is_source?: boolean } = singleTransaction.associated_entity
      entity.is_source = true
      perEntityCosts[entity.id] = {
        cost: `${transaction.currency} ${formatCurrency(singleTransaction.cost)}`,
        costInUsd: singleTransaction.cost_in_usd,
      }
      sourceEntities.push(entity)
    })
  }

  const primaryEntity = transaction.is_primary_le_source ? sourceEntities[0] : targetEntities[0]

  const associatedEntities = !transaction.is_primary_le_source ? sourceEntities : targetEntities

  return { primaryEntity, associatedEntities, sourceEntities, targetEntities, perEntityCosts }
}

export const segregateAttributesFromTransactions = ({
  tabKey,
  attributesOnTabKey,
  functionsProfileAttributesResponse,
}: SegregateAttributesFromTransactionsProps) => {
  const selectedAttributes: (FunctionalAttributes & {
    order?: number
  })[] = []
  const unselectedAttributes: FunctionalAttributes[] = []

  functionsProfileAttributesResponse.forEach(globalAttribute => {
    if (globalAttribute.attribute_type === tabKey) {
      const isSelected = attributesOnTabKey.find(attribute => attribute.attribute_id === globalAttribute.id)
      if (isSelected) {
        selectedAttributes.push({ ...globalAttribute, order: isSelected.order || 0 })
      } else {
        unselectedAttributes.push(globalAttribute)
      }
    }
  })
  attributesOnTabKey.forEach(attribute => {
    const existsInGlobalAttributes = functionsProfileAttributesResponse.some(
      globalAttribute => globalAttribute.id === attribute.attribute_id
    )
    if (!existsInGlobalAttributes) {
      selectedAttributes.push({
        id: attribute.attribute_id,
        order: attribute.order || 0,
        name: attribute.attribute_name,
        attribute_type: attribute.attribute_type,
        added_by_integral: attribute.added_by_integral,
        organization: null,
        default_description: '',
      })
    }
  })

  const sortedSelectedAttributes = selectedAttributes.sort((a, b) => {
    return (a?.order || 0) - (b?.order || 0)
  })

  return { selectedAttributes: sortedSelectedAttributes, unselectedAttributes }
}

interface SegregateAttributesFromTransactionsProps {
  tabKey: string
  attributesOnTabKey: attributes[]
  functionsProfileAttributesResponse: FunctionalProfileAttributeResponse
}
