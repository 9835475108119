import { IconProps } from 'assets'

const ArticlePerson = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="article_person">
        <path
          className={pathClassName}
          id="Vector"
          d="M5 19V5V9.475V9V19ZM7 13H12.525C12.5583 12.6333 12.6417 12.2833 12.775 11.95C12.9083 11.6167 13.075 11.3 13.275 11H7V13ZM7 17H10.925C11.2083 16.6667 11.5333 16.3958 11.9 16.1875C12.2667 15.9792 12.65 15.8083 13.05 15.675C12.9833 15.575 12.925 15.4667 12.875 15.35C12.825 15.2333 12.7833 15.1167 12.75 15H7V17ZM7 9H17V7H7V9ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11.45C20.7667 11.0167 20.4833 10.6333 20.15 10.3C19.8167 9.96667 19.4333 9.69167 19 9.475V5H5V19H10.05C10.0333 19.1 10.0208 19.2 10.0125 19.3C10.0042 19.4 10 19.5 10 19.6V21H5ZM17 16C16.3 16 15.7083 15.7583 15.225 15.275C14.7417 14.7917 14.5 14.2 14.5 13.5C14.5 12.8 14.7417 12.2083 15.225 11.725C15.7083 11.2417 16.3 11 17 11C17.7 11 18.2917 11.2417 18.775 11.725C19.2583 12.2083 19.5 12.8 19.5 13.5C19.5 14.2 19.2583 14.7917 18.775 15.275C18.2917 15.7583 17.7 16 17 16ZM12 21V19.6C12 19.2 12.1042 18.8292 12.3125 18.4875C12.5208 18.1458 12.8167 17.9 13.2 17.75C13.8 17.5 14.4208 17.3125 15.0625 17.1875C15.7042 17.0625 16.35 17 17 17C17.65 17 18.2958 17.0625 18.9375 17.1875C19.5792 17.3125 20.2 17.5 20.8 17.75C21.1833 17.9 21.4792 18.1458 21.6875 18.4875C21.8958 18.8292 22 19.2 22 19.6V21H12Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default ArticlePerson
