import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import DocumentPreviewModal from 'components/documentPreviewModal'
import IconPlaceHolder from 'components/iconPlaceholder'
import ImagePanZoom from 'components/imagePanZoom/ImagePanZoom'
import InfoLabel from 'components/input/components/infoLabel'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'
import React, { ForwardedRef, useEffect, useMemo, useState } from 'react'
import { getFileExtension } from 'utils/fileUtils'
import { formatBytes } from 'utils/utils'

const UploadBox = (props: UploadBoxProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    handleClick,
    file,
    preview = true,
    label,
    fileName,
    showDelete,
    handleDelete,
    fileTypes,
    fileUrl,
    darkMode,
    required = false,
  } = props

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false)
  const [showInFullScreen, setShowInFullScreen] = useState<boolean>(false)

  const renderText = useMemo(() => {
    if (file || fileName) {
      return (
        <Typography
          className={cx('p-0', {
            '!text-indigo-400': darkMode,
          })}
          variant={Variant.Callout}
          type="semibold">
          {file ? file.name : fileName}
        </Typography>
      )
    }
    return (
      <Button
        className={cx('!p-[0rem] !h-[unset] !text-container-text  font-semibold', {
          '!text-indigo-400': darkMode,
          ' ': !darkMode,
        })}
        variant={ButtonVariant.Tertiary}>
        Click to Upload
      </Button>
    )
  }, [darkMode, file, fileName])

  const isPdf = useMemo(() => {
    if (file) {
      return file?.type == 'application/pdf'
    }
    if (fileUrl) {
      const pathname = new URL(fileUrl).pathname

      const fileExtension = pathname?.split('.')?.pop()?.toLowerCase()

      return fileExtension === 'pdf'
    }
  }, [file, fileUrl])

  const fileExtention = getFileExtension(file?.name || '')
  const fileSize = file?.size ? formatBytes(file?.size) : ''

  const objectUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file)
    }
  }, [file])

  useEffect(() => {
    return () => {
      if (!!objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [objectUrl])

  // const handleDownload = useCallback(() => {
  //   if (objectUrl || fileUrl) {
  //     const link = document.createElement('a')
  //     link.href = objectUrl || fileUrl || ''
  //     link.download = file?.name || fileName || label || 'file'
  //     link.click()
  //   }
  // }, [file?.name, fileName, fileUrl, label, objectUrl])

  const handleImageModalClose = () => {
    setShowImagePreviewModal(false)
  }
  return (
    <div ref={ref} onClick={handleClick} className={cx('cursor-pointer', props.className)}>
      {label && <InfoLabel darkMode={darkMode} label={label} required={required} />}
      <div
        className={cx(
          ' rounded-xl   flex items-center p-3 pr-5 ',
          {
            'bg-[#161532]': darkMode,
            'bg-gray-50': !darkMode,
          },
          props.boxClassName
        )}>
        <IconPlaceHolder
          icon={file || fileName ? IconsType.file : IconsType.upload}
          iconPathClassName={cx({
            'stroke-indigo-900': darkMode && !(file || fileName),
            'fill-indigo-900': darkMode && !!(file || fileName),
            'fill-blue900 stroke-blue900': !darkMode,
          })}
          className={cx('h-9 w-9', {
            '!bg-indigo-100': darkMode,
            '!bg-blue-100': !darkMode,
          })}
        />
        <div className="flex flex-col items-start cursor-pointer ps-3">
          {renderText}
          <Typography
            className={cx('', {
              'text-neutral600': !darkMode,
              'text-gray-400': darkMode,
            })}
            variant={Variant.Body}>
            {objectUrl || fileUrl
              ? `${fileExtention.toUpperCase()} ${fileSize}`
              : `${fileTypes ?? '.pdf,.docx,doc,.odt'} Max 25MB`}
          </Typography>
          {(file || fileName) && !darkMode ? (
            <Typography variant={Variant.Callout} type="bold" className="text-teal600 mt-2 py-0   flex items-center">
              Uploaded {getIcons(IconsType.tick, { pathClassName: 'stroke-teal600', className: 'ms-1' })}
            </Typography>
          ) : null}
        </div>
        <div
          className={cx(
            'ms-auto self-stretch  flex items-center border-0 gap-4 pl-4 border-l border-solid ',
            {
              'border-gray-300': !darkMode,
              'border-gray-800': darkMode,
            },
            ((file || fileName) && (objectUrl || fileUrl) && preview) ||
              (showDelete && handleDelete && !!(file || fileName))
              ? 'border-solid'
              : 'border-none'
          )}>
          {(file || fileName) && (objectUrl || fileUrl) && preview ? (
            <>
              {isPdf ? (
                <Button
                  className={cx('!p-[0rem] !h-auto ', {
                    'text-gray-300': darkMode,
                  })}
                  variant={ButtonVariant.Tertiary}
                  isDefaultSize={false}
                  onClick={e => {
                    e.stopPropagation()
                    setShowPreviewModal(true)
                  }}>
                  Preview
                </Button>
              ) : (
                <Button
                  className={cx('!p-[0rem] !h-auto ', {
                    'text-gray-300': darkMode,
                  })}
                  variant={ButtonVariant.Tertiary}
                  isDefaultSize={false}
                  onClick={e => {
                    e.stopPropagation()
                    setShowImagePreviewModal(true)
                  }}>
                  Preview
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
          {showDelete && handleDelete && !!(file || fileName) ? (
            <Button
              className="!p-[0rem]"
              variant={ButtonVariant.Tertiary}
              icon={IconsType.delete}
              isDefaultSize={false}
              onClick={e => {
                e.stopPropagation()
                handleDelete()
              }}
              iconPathClassName="stroke-orange-600"
            />
          ) : null}
        </div>
      </div>
      {(objectUrl || fileUrl) && preview ? (
        <DocumentPreviewModal
          documentPreviewModal={showPreviewModal}
          isFullScreen={showInFullScreen}
          selectedFile={objectUrl || fileUrl}
          setDocumentPreviewModal={setShowPreviewModal}
          setIsFullScreen={setShowInFullScreen}
        />
      ) : null}
      {objectUrl || fileUrl ? (
        <Modal
          containerClassName="max-w-[62.5rem] w-full min-w-[50rem]"
          icon={IconsType.eye}
          iconPathClassName="stroke-blue800"
          title="Preview File"
          className="p-5"
          isOpen={showImagePreviewModal}
          onClose={handleImageModalClose}>
          <ImagePanZoom src={objectUrl || fileUrl} scaleUp={true} />
        </Modal>
      ) : null}
    </div>
  )
}

interface UploadBoxProps {
  handleClick: VoidFunction
  ref: React.RefObject<HTMLDivElement>
  file: File | null
  label?: string
  fileName?: string
  handleDelete?: VoidFunction
  showDelete?: boolean
  fileTypes?: string
  fileUrl?: string
  preview?: boolean
  className?: string
  boxClassName?: string
  darkMode?: boolean
  required?: boolean
}

export default React.forwardRef(UploadBox)
