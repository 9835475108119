import { IconProps } from 'assets'
import React from 'react'

const Category = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="category">
        <path
          className={pathClassName}
          id="Vector"
          d="M6.5 11.5L12 2.5L17.5 11.5H6.5ZM17.5 22.5C16.25 22.5 15.1875 22.0625 14.3125 21.1875C13.4375 20.3125 13 19.25 13 18C13 16.75 13.4375 15.6875 14.3125 14.8125C15.1875 13.9375 16.25 13.5 17.5 13.5C18.75 13.5 19.8125 13.9375 20.6875 14.8125C21.5625 15.6875 22 16.75 22 18C22 19.25 21.5625 20.3125 20.6875 21.1875C19.8125 22.0625 18.75 22.5 17.5 22.5ZM3 22V14H11V22H3ZM17.5 20.5C18.2 20.5 18.7917 20.2583 19.275 19.775C19.7583 19.2917 20 18.7 20 18C20 17.3 19.7583 16.7083 19.275 16.225C18.7917 15.7417 18.2 15.5 17.5 15.5C16.8 15.5 16.2083 15.7417 15.725 16.225C15.2417 16.7083 15 17.3 15 18C15 18.7 15.2417 19.2917 15.725 19.775C16.2083 20.2583 16.8 20.5 17.5 20.5ZM5 20H9V16H5V20ZM10.05 9.5H13.95L12 6.35L10.05 9.5Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Category
