import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import { useContext } from 'react'
import { components, GroupBase, MenuProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

import { SelectCheckboxContext } from '../../SelectWithCheckbox'

const Menu = components.Menu

export const CustomMenuRenderer = (props: MenuProps<SelectOptions, true, GroupBase<SelectOptions>>) => {
  const { showSelectMenuButton, setMenuOpen, selectMenuButtonProps } = useContext(SelectCheckboxContext)
  return (
    <Menu className="z-20" {...props}>
      {props.children}
      {showSelectMenuButton && selectMenuButtonProps && (
        <div className="p-3 max-h-[200px] overflow-y-auto">
          <Button
            icon={IconsType.plus}
            className="!rounded-none w-full"
            onClick={() => {
              selectMenuButtonProps.buttonAction()
              setMenuOpen && setMenuOpen(false)
            }}
            variant={ButtonVariant.Primary}>
            {selectMenuButtonProps.buttonLabel}
            {selectMenuButtonProps.children}
          </Button>
        </div>
      )}
    </Menu>
  )
}
