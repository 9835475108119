import React, { useCallback, useState } from 'react'

import WizardItemRemovalWarning from './components/WizardItemRemovalWarning/WizardItemRemovalWarning'

const createPromise = (): [Promise<boolean>, (value: boolean) => void] => {
  let resolver: ((value: boolean) => void) | null = null
  const promise = new Promise<boolean>(resolve => {
    resolver = resolve
  })
  if (!resolver) throw new Error('Resolver should be initialized')
  return [promise, resolver]
}

const useConfirmationForReportWizard = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState<string | undefined>()
  const [ctaText, setCtaText] = useState<string>()
  const [label, setLabel] = useState<string | React.ReactNode>('')
  const [resolver, setResolver] = useState<((value: boolean) => void) | null>(null)

  const getConfirmation = useCallback(
    async (text: string | React.ReactNode, title?: string, confirmationCtaText?: string) => {
      setTitle(title)
      setLabel(text)
      setIsOpen(true)
      setCtaText(confirmationCtaText || undefined)
      const [promise, resolve] = createPromise()
      setResolver(() => resolve)
      return promise
    },
    []
  )

  const onClick = useCallback(
    (status: boolean) => {
      setIsOpen(false)
      if (resolver) resolver(status)
    },
    [resolver]
  )

  const ConfirmationWindow = useCallback(
    () => (
      <WizardItemRemovalWarning
        showWindow={isOpen}
        title={title}
        setShowWindow={() => onClick(false)}
        confirmationMessage={label}
        doConfirmationAction={() => onClick(true)}
        confirmationCtaText={ctaText}
      />
    ),
    [ctaText, isOpen, label, , onClick, title]
  )

  return {
    getConfirmation,
    ConfirmationWindow,
    resolve: onClick,
    isOpen,
  }
}

export default useConfirmationForReportWizard
