import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { importFinancialData } from 'api/transactions/importTransactions'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import { QUERIES } from 'constants/query'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { SELECT_DROPDOWN, SELECT_WITH_CHECKBOX } from 'organisms/fieldRenderers'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

type Props = {
  isOpen: boolean
  onClose: () => void
  importableTransactions: TransactionByIdResponse[]
  transactionResponse: TransactionByIdResponse | undefined
}

const ImportFinancialDataModal = ({ isOpen, onClose, importableTransactions, transactionResponse }: Props) => {
  const { control, watch, setValue } = useForm<ImportFinancialDataModalFormData>()

  const importFrom = watch('importFrom')
  const importYears = watch('importYears')

  const importFromOptions = useMemo(() => {
    return importableTransactions.map(transaction => ({
      label: transaction.year,
      value: transaction.id,
    }))
  }, [importableTransactions])

  const importYearOptions = useMemo(() => {
    if (
      importFrom?.value &&
      transactionResponse?.economic_analysis?.testing_methodology == 'testing_p_and_l' &&
      transactionResponse?.economic_analysis?.financial_structure_start_year &&
      transactionResponse?.economic_analysis?.financial_structure_end_year
    ) {
      const selectedTransaction = importableTransactions.find(transaction => transaction.id === importFrom.value)
      const years = new Set<number>()
      Object.values(selectedTransaction?.economic_analysis?.financial_structure || {}).forEach(financialStructure => {
        financialStructure.forEach(financialData => {
          if (
            transactionResponse?.economic_analysis?.financial_structure_start_year &&
            transactionResponse?.economic_analysis?.financial_structure_end_year &&
            financialData.year >= transactionResponse?.economic_analysis?.financial_structure_start_year &&
            financialData.year <= transactionResponse?.economic_analysis?.financial_structure_end_year &&
            !years.has(financialData.year)
          ) {
            years.add(financialData.year)
          }
        })
      })
      if (years.size > 0) {
        setValue('importYears', [])
        return Array.from(years)
          .sort((a, b) => a - b)
          .map(year => ({
            label: year.toString(),
            value: year.toString(),
          }))
      }
    }
    setValue('importYears', [])
    return []
  }, [
    importFrom?.value,
    importableTransactions,
    setValue,
    transactionResponse?.economic_analysis?.financial_structure_end_year,
    transactionResponse?.economic_analysis?.financial_structure_start_year,
    transactionResponse?.economic_analysis?.testing_methodology,
  ])

  const isValidForm = useMemo(() => {
    return (
      !!importFrom?.value &&
      !!(transactionResponse?.economic_analysis?.testing_methodology == 'testing_p_and_l' &&
      !transactionResponse?.economic_analysis.required_sections.includes('comparability_factors')
        ? !!importYears?.length
        : true)
    )
  }, [
    importFrom?.value,
    importYears?.length,
    transactionResponse?.economic_analysis?.required_sections,
    transactionResponse?.economic_analysis?.testing_methodology,
  ])

  const { mutate: importFinancialDataMutation, isLoading: isImportingFinancialData } = useMutation(importFinancialData)

  const queryClient = useQueryClient()

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleImport = useCallback(async () => {
    if (!isImportingFinancialData && transactionResponse?.id && importFrom?.value && isValidForm) {
      const confirmation = await getConfirmation(
        'Any present financial data will be overwritten by the imported data. Are you sure you want to continue?'
      )
      if (confirmation) {
        importFinancialDataMutation(
          {
            id: transactionResponse?.id,
            transactionId: Number(importFrom?.value),
            years:
              transactionResponse?.economic_analysis?.testing_methodology == 'testing_p_and_l'
                ? importYears?.map(year => Number(year.value))
                : [transactionResponse.year],
          },
          {
            onSuccess: () => {
              queryClient.refetchQueries({
                queryKey: [QUERIES.ECONOMIC_ANALYSIS_TRANSACTION.key, transactionResponse?.id],
                type: 'active',
              })
              queryClient.refetchQueries({
                queryKey: [QUERIES.GET_TRANSACTION_BY_ID.key, transactionResponse?.id, 'economicAnalysis'],
                type: 'active',
              })
              queryClient.refetchQueries({
                queryKey: [QUERIES.GET_COMPARABILITY_METRICS_LIST.key],
                type: 'active',
              })
              queryClient.refetchQueries({
                queryKey: [QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.key, transactionResponse?.economic_analysis?.id],
                type: 'active',
              })
              onClose()
            },
            onError: error => {
              getToastErrorMessage(error as AxiosError, 'Failed to import financial data')
            },
          }
        )
      }
    }
  }, [
    getConfirmation,
    importFinancialDataMutation,
    importFrom?.value,
    importYears,
    isImportingFinancialData,
    isValidForm,
    onClose,
    queryClient,
    transactionResponse?.economic_analysis?.id,
    transactionResponse?.economic_analysis?.testing_methodology,
    transactionResponse?.id,
    transactionResponse?.year,
  ])

  return (
    <Modal
      containerClassName="w-[624px] p-4 text-start"
      title="Import Financial Data"
      className="stroke-current"
      isOpen={isOpen}
      onClose={onClose}>
      <SELECT_DROPDOWN
        control={control}
        required
        disabled={isImportingFinancialData}
        id="importFrom"
        options={importFromOptions}
        placeholder="Select Year"
        label="Select Year of Transaction"
      />
      {!!importYearOptions.length && (
        <SELECT_WITH_CHECKBOX
          control={control}
          required
          disabled={isImportingFinancialData}
          id="importYears"
          options={importYearOptions}
          label="Select Financial Data Years"
          placeholder="Select Years"
        />
      )}
      <Button
        variant={ButtonVariant.Primary}
        icon={IconsType.download}
        isDefaultSize={false}
        className="ms-auto"
        iconCLass="!w-4 !h-4"
        iconPathClassName="stroke-current"
        disabled={!isValidForm || isImportingFinancialData}
        onClick={handleImport}>
        Import
      </Button>
      <ConfirmationModal />
      {isImportingFinancialData && (
        <Loading className="absolute inset-0 w-full h-full flex bg-white/50 items-center justify-center backdrop-blur-sm rounded-xl" />
      )}
    </Modal>
  )
}
type ImportFinancialDataModalFormData = {
  importFrom?: SelectOptions
  importYears?: SelectOptions[]
}
export default ImportFinancialDataModal
