import { ROUTES } from 'constants/routes'

export const disabledForRoutes = [
  ROUTES.LEGAL_ENTITY_MANAGEMENT + '/[id]',
  ROUTES.CREATE_LEGAL_ENTITY,
  ROUTES.TRANSACTION_MANAGEMENT + '/[id]',
  ROUTES.CREATE_TRANSACTION,
  ROUTES.BENCHMARK_MANAGEMENT + '/[id]',
  ROUTES.CREATE_BENCHMARK_STUDIES,
]
