import cx from 'classnames'
import React, { forwardRef } from 'react'

const RadioButton = (
  {
    onChange,
    name,
    onBlur,
    value,
    label,
    defaultValue,
    labelClassName,
    disabled,
    inputClassname,
    containerClassname,
  }: RadioButtonProps,
  ref: React.LegacyRef<HTMLInputElement>
): JSX.Element => {
  return (
    <div className={cx('flex items-center', containerClassname)}>
      <input
        defaultValue={defaultValue}
        defaultChecked={defaultValue === value}
        value={value}
        id={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        type="radio"
        className={cx({ 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled }, '!w-4 !h-4', inputClassname)}
        disabled={disabled}
      />
      {label && (
        <label
          className={cx(labelClassName, {
            'text-callout text-gray-700 ml-[0.678rem] whitespace-nowrap': !labelClassName,
          })}
          htmlFor={name}>
          {label}
        </label>
      )}
    </div>
  )
}

interface RadioButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  value: string
  inputClassname?: string
  label?: string | React.ReactNode
  defaultValue?: string
  labelClassName?: string
  disabled?: boolean
  containerClassname?: string
}

export default forwardRef(RadioButton)
