import { useMutation } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { updateRejectionMatrix } from 'api/transactions/createTransaction'
import { TransferPricingMethods } from 'api/transactions/createTransaction/types/complianceRegion.type'
import { updateRejectionMatrixPayload } from 'api/transactions/createTransaction/types/economicAnalysis.types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button, { ButtonSize } from 'components/button'
import Drawer from 'components/drawer/Drawer'
import Loading from 'components/loading'
import NewTable from 'components/newTable'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'
import { reorderMethodsColumns } from 'views/templates/createEconomicAnalysisTemplate/components/methodSelection/components/reorderMethodsInTemplates/columns'

const ReorderMethods = ({
  isOpen,
  onClose,
  data,
  id,
  selectedTPM,
  economicAnalysisTransactionRefetch,
  refetchChecklist,
}: ReorderMethodsProps) => {
  const [reorderedMethods, setReorderedMethods] = useState<TransferPricingMethods[]>(data)

  const reorderRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedMethods = [...reorderedMethods]
      swappedMethods.splice(targetRowIndex, 0, swappedMethods.splice(draggedRowIndex, 1)[0])
      setReorderedMethods([...swappedMethods])
    },
    [reorderedMethods]
  )

  const updateMethodOrderMutation = useMutation(updateRejectionMatrix, {
    onSuccess: () => {
      toast.success(`Methods reordered successfully!!`)
      economicAnalysisTransactionRefetch && economicAnalysisTransactionRefetch()
      refetchChecklist && refetchChecklist()
      onClose()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const columns = useMemo(() => reorderMethodsColumns({ selectedTPM }), [selectedTPM])
  const handleReorder = useCallback(() => {
    const payload: updateRejectionMatrixPayload['data'] = []

    reorderedMethods.map(method => {
      payload.push({
        transfer_pricing_method: method.id,
        description: method.description || '',
      })
    })

    updateMethodOrderMutation.mutate({ id: id, data: payload })
  }, [id, reorderedMethods, updateMethodOrderMutation])

  const loading = updateMethodOrderMutation.isLoading

  const getRowClassname = useCallback(
    (row: Row<TransferPricingMethods>) => {
      return row.original.id === Number(selectedTPM) ? '!border-green-300' : ''
    },
    [selectedTPM]
  )

  const disableSave = useMemo(() => {
    return reorderedMethods == data || loading
  }, [data, loading, reorderedMethods])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={`Reorder Accepted & Rejected Methods`}
      containerClassName="w-[60%]"
      className="!gap-4 h-full relative">
      {loading && (
        <Loading className="absolute left-0 right-0 bottom-0 top-14 bg-blue-50 backdrop-blur-sm !mt-0 z-50	 flex items-center justify-center" />
      )}
      <div className="w-full flex flex-col h-full gap-4 overflow-y-auto ">
        <NewTable
          columns={columns}
          classes={{
            table: { head: '!bg-blue50 py-0' },
            container: 'rounded-xl',
          }}
          getRowClassname={getRowClassname}
          data={reorderedMethods}
          reorderRow={reorderRow}
          showHeader={false}
        />
      </div>
      <div className="flex justify-end w-full items-end">
        <Button
          disabled={disableSave}
          onClick={handleReorder}
          icon={IconsType.save}
          size={ButtonSize.Small}
          className={`flex !flex-row-reverse items-center ms-auto !gap-1 !py-0  ${
            disableSave ? '!border !border-solid !border-gray-300' : ''
          }  `}>
          Save
        </Button>
      </div>
    </Drawer>
  )
}

interface ReorderMethodsProps {
  isOpen: boolean
  onClose: VoidFunction
  data: TransferPricingMethods[]
  selectedTPM?: number
  refetchChecklist?: () => void
  id: number
  economicAnalysisTransactionRefetch?: () => void
}

export default ReorderMethods
