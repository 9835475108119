import { IconProps } from 'assets'

const Assets = ({ className, primaryColor, secondaryColor }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / 18px / Product - assets">
        <path
          className={secondaryColor}
          id="Vector"
          d="M1.91926 6.58854L2.0625 6.51692V12.4831L1.91926 12.4115L1.75156 12.3276L1.66771 12.4953L1.38412 13.0625H0.9375V5.9375H1.38412L1.66771 6.50469L1.75156 6.67239L1.91926 6.58854ZM0.969657 13.4375H1.19662L1.09512 13.6405C1.03929 13.5826 0.996478 13.5133 0.969657 13.4375ZM1.41615 13.837L1.61588 13.4375H2.25H2.4375V13.25V13.0898L8.58075 16.1615L8.41305 16.2453L8.49689 16.413L8.79037 17L8.58074 17.4193L1.41615 13.837ZM16.5838 13.837L9.41926 17.4193L9.20963 17L9.50311 16.413L9.58695 16.2453L9.41926 16.1615L15.5625 13.0898V13.25V13.4375H15.75H16.3841L16.5838 13.837ZM16.9049 13.6405L16.8034 13.4375H17.0303C17.0035 13.5133 16.9607 13.5826 16.9049 13.6405ZM17.0625 13.0625H16.6159L16.3323 12.4953L16.2485 12.3276L16.0807 12.4115L15.9375 12.4831V6.51692L16.0807 6.58854L16.2485 6.6724L16.3323 6.50468L16.6159 5.9375H17.0625V13.0625ZM16.9049 5.35948C16.9607 5.41739 17.0035 5.48667 17.0303 5.5625H16.8034L16.9049 5.35948ZM16.5838 5.16302L16.3841 5.5625H15.75H15.5625V5.75V5.91015L9.41925 2.83852L9.58696 2.75467L9.50311 2.58697L9.20963 2L9.41925 1.58074L16.5838 5.16302ZM9.06946 1.4418L9 1.58073L8.93054 1.4418C8.97666 1.43607 9.02334 1.43607 9.06946 1.4418ZM8.58075 1.58074L8.79037 2L8.49689 2.58697L8.41304 2.75467L8.58074 2.83852L2.4375 5.91016V5.75V5.5625H2.25H1.61588L1.41615 5.16301L8.58075 1.58074ZM1.09512 5.35949L1.19662 5.5625H0.969657C0.996478 5.48667 1.03929 5.41739 1.09512 5.35949ZM9.08384 2.58696L9 2.62889L8.91616 2.58696L9 2.41927L9.08384 2.58696ZM1.80338 5.9375H2.0625V6.09766L1.91927 6.16928L1.80338 5.9375ZM16.1966 5.9375L16.0807 6.16928L15.9375 6.09765V5.9375H16.1966ZM15.9375 13.0625V12.9023L16.0807 12.8307L16.1966 13.0625H15.9375ZM1.91927 12.8307L2.0625 12.9023V13.0625H1.80338L1.91927 12.8307ZM9 16.5807L8.91615 16.413L9 16.3711L9.08385 16.413L9 16.5807ZM9 17.4193L9.06946 17.5582C9.02334 17.5639 8.97666 17.5639 8.93054 17.5582L9 17.4193Z"
          fill={'#1E3A8A'}
          stroke="#1E3A8A"
          strokeWidth="0.375"
        />
        <path
          className={primaryColor}
          id="Vector_2"
          d="M1.5 5.75L9 9.5"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={primaryColor}
          id="Vector_3"
          d="M9 17V9.5"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={primaryColor}
          id="Vector_4"
          d="M16.5 5.75L9 9.5"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={secondaryColor}
          id="Vector_5"
          d="M12.834 4.46215L5.66942 8.04444L5.16632 7.03818L12.3309 3.4559L12.834 4.46215ZM4.84522 7.23452L5.31969 8.18351C5.08976 8.21213 4.85657 8.09578 4.74703 7.87671C4.63752 7.65766 4.68434 7.40129 4.84522 7.23452ZM13.1551 4.26581L12.6806 3.31682C12.9106 3.28819 13.1438 3.40455 13.2533 3.62361C13.3628 3.84267 13.316 4.09904 13.1551 4.26581Z"
          fill="#1E3A8A"
          stroke="#1E3A8A"
          strokeWidth="0.375"
        />
      </g>
    </svg>
  )
}

export default Assets
