export const weightSelectionOptions = [
  {
    label: 'Significant',
    value: 'majority',
  },
  {
    label: 'Limited',
    value: 'limited',
  },
  {
    label: 'NA',
    value: 'na',
  },
]
