import axios from 'api'

import {
  AcceptRejectCriteria,
  BenchmarkCompany,
  BenchmarkCompanyListResponseType,
  BenchmarkStudyObject,
  CreateBenchmarkingStudyPayload,
  ManualReviewBenchmarkingCompanyPayload,
  SetCriteriaPayload,
} from './types'

export const createBenchmarkingStudy = (payload: CreateBenchmarkingStudyPayload) => {
  return axios
    .postForm<BenchmarkStudyObject>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/`, payload)
    .then(res => res.data)
}

export const deleteBenchmarkingStudy = ({ id }: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}`).then(res => res.data)
}

export const listBenchmarkingStudy = ({ year }: { year: number }) => {
  return axios
    .get<BenchmarkStudyObject[]>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/`, {
      params: {
        year,
      },
    })
    .then(res => res.data)
}

export const getBenchmarkingStudy = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkStudyObject>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}`)
    .then(res => res.data)
}

export const getBenchmarkingCompaniesList = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkCompanyListResponseType[]>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_company/`, {
      params: {
        benchmarking_study: id,
      },
    })
    .then(res => res.data)
}
export const getBenchmarkingCompany = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkCompany>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_company/${id}`)
    .then(res => res.data)
}

export const generateAiDescription = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkStudyObject>(
      `/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}/trigger_generate_description/`
    )
    .then(res => res.data)
}
export const stopAiDescription = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkStudyObject>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}/stop_generation/`)
    .then(res => res.data)
}

export const completeBenchmarkingStudyCompanies = ({ id }: { id: number }) => {
  return axios
    .get<BenchmarkStudyObject>(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}/complete/`)
    .then(res => res.data)
}

export const getAcceptRejectKeywords = () => {
  return axios.get<AcceptRejectCriteria[]>(`/api/v1/org/{organization}/ai_benchmarking/keyword/`).then(res => res.data)
}
export const createAcceptRejectKeywords = ({ name, description }: { name: string; description: string }) => {
  return axios
    .post<AcceptRejectCriteria>(`/api/v1/org/{organization}/ai_benchmarking/keyword/`, { name, description })
    .then(res => res.data)
}
export const updateAcceptRejectKeywords = ({
  id,
  description,
  name,
}: {
  id: number
  description: string
  name: string
}) => {
  return axios
    .patch<AcceptRejectCriteria>(`/api/v1/org/{organization}/ai_benchmarking/keyword/${id}/`, { description, name })
    .then(res => res.data)
}

export const setAcceptRejectKeywords = ({ payload, id }: { payload: SetCriteriaPayload; id: number }) => {
  return axios
    .put(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}/set_criteria/`, payload)
    .then(res => res.data)
}

export const manualReviewBenchmarkingCompany = ({
  id,
  payload,
}: {
  id: number
  payload: ManualReviewBenchmarkingCompanyPayload
}) => {
  return axios
    .patch<BenchmarkCompany>(
      `/api/v1/org/{organization}/ai_benchmarking/benchmarking_company/${id}/manual_review`,
      payload
    )
    .then(res => res.data)
}

export const exportBenchmarkStudyFile = ({ id }: { id: number }) => {
  {
    return axios
      .get(`/api/v1/org/{organization}/ai_benchmarking/benchmarking_study/${id}/export/`, {
        responseType: 'blob',
      })
      .then(response => {
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'download.xlsx' // Default name

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/)
          if (match && match[1]) {
            fileName = match[1]
          }
        }
        return { blob: response.data, fileName }
      })
  }
}
