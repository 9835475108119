import { IconProps } from 'assets'

const LabelIcon = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00049 19C2.4482 19 2.00049 18.5523 2.00049 18V6.00033C2.00049 5.44804 2.4482 5.00033 3.00049 5.00033L17.4855 5.00033C17.8085 5.00033 18.1115 5.15629 18.2992 5.41909L22.5848 11.4189C22.8332 11.7666 22.8332 12.2337 22.5848 12.5814L18.2992 18.5812C18.1115 18.844 17.8084 19 17.4855 19L3.00049 19ZM4.00044 16C4.00044 16.5523 4.44816 17 5.00044 17L16.4575 17C16.7805 17 17.0835 16.8441 17.2713 16.5812L20.1279 12.5814C20.3763 12.2337 20.3762 11.7666 20.1278 11.4189L17.2703 7.41898C17.0825 7.15622 16.7795 7.00028 16.4566 7.00028L5.00044 7.00028C4.44816 7.00028 4.00044 7.44799 4.00044 8.00028V16ZM16.5002 13.5001C15.6717 13.5001 15.0002 12.8286 15.0002 12.0002C15.0002 11.1718 15.6717 10.5002 16.5002 10.5002C17.3286 10.5002 18.0001 11.1718 18.0001 12.0002C18.0001 12.8286 17.3286 13.5001 16.5002 13.5001ZM13.0002 10.0002C13.0002 10.5525 12.5525 11.0002 12.0003 11.0002H7.00037C6.4481 11.0002 6.0004 10.5525 6.0004 10.0002C6.0004 9.44794 6.4481 9.00023 7.00037 9.00023H12.0003C12.5525 9.00023 13.0002 9.44794 13.0002 10.0002ZM13.0002 14.0001C13.0002 14.5524 12.5525 15.0001 12.0003 15.0001H7.00037C6.4481 15.0001 6.0004 14.5524 6.0004 14.0001C6.0004 13.4478 6.4481 13.0001 7.00037 13.0001H12.0003C12.5525 13.0001 13.0002 13.4478 13.0002 14.0001Z"
        fill=""
      />
    </svg>
  )
}

export default LabelIcon
