import { yupResolver } from '@hookform/resolvers/yup'
import { Info } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  deleteControlledOrUncontrolledTransactions,
  updateControlledTransactionTerms,
} from 'api/transactions/comparableResults'
import { TermsAndCharacteristics } from 'api/transactions/comparableResults/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import Button, { ButtonVariant } from 'components/button'
import { IconPlacement } from 'components/input'
import Loading from 'components/loading'
import NewAttributeTable from 'components/newAttributeTable'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import UploadDocuments from 'components/uploadDocuments'
import { currencyOptions } from 'constants/currencies'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import { useRouter } from 'next/router'
import { INPUT_FIELDS as InputField } from 'organisms/fieldRenderers'
import FileOrTableRadio from 'organisms/fieldRenderers/fields/FileOrTableRadio/FileOrTableRadio'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getCharacterLimitArgs } from 'schemas/schemas.helpers'
import { SelectOptions } from 'types/common.types'
import { getIndividualTransactionOptions, getTransactionOptions } from 'utils/optionsGeneratorUtils'
import { getToastErrorMessage } from 'utils/utils'
import { number, object } from 'yup'
import * as yup from 'yup'

import AddComparableControlledTransaction from './Components/AddComparableControlledTransaction/AddComparableControlledTransaction'
import ReorderControlledTransactions from './Components/reorderControlledTransactions/ReorderControlledTransactions'
import { TransactionTypeOptions, ValueOptions } from './controlledTransactionsData.constants'
import { getComparabilityTableColumns } from './helpers/controlledTransactionsData.helper'

type Props = {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionRefetch: () => void
  transactionByIdRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
}

const ControlledTransactionsData = ({
  transactionResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
}: Props) => {
  const Router = useRouter()

  const [selectedRow, setSelectedRow] = useState<ControlledTransactionDataTableData>()
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [reorderModal, setReorderModal] = useState(false)

  const {
    data: termsAndConditions,
    isFetching: fetchingTermsAndConditions,
    refetch: refetchTermsAndConditions,
  } = useQuery([QUERIES.GET_TERMS_AND_CHARACTERISTICS.key, transactionResponse?.economic_analysis?.id], {
    enabled: !!transactionResponse?.economic_analysis?.id,
    queryFn: () => QUERIES.GET_TERMS_AND_CHARACTERISTICS.function(transactionResponse?.economic_analysis?.id || 0),
  })

  const tableData: ControlledTransactionDataTableData[] = useMemo(() => {
    if (!termsAndConditions?.length || !transactionResponse) return []

    return termsAndConditions
      .filter((term): term is ControlledTransactionDataTableData => term.is_controlled_transaction)
      .map(term => {
        return { ...term, transactionData: transactionResponse }
      }) as ControlledTransactionDataTableData[]
  }, [termsAndConditions, transactionResponse])

  const { control, watch, setValue, handleSubmit, formState, reset, getValues } = useForm<FIELD_VALUES>({
    defaultValues: {
      controlled: {
        type: 'File',
        value: ValueOptions[0],
      },
    },
    resolver: yupResolver(
      object({
        policy_rate: number().required('This field is required').typeError('This must be a number'),
        controlled: yup.object().shape({
          termsAndConditions: yup.string().when('type', {
            is: (type: string) => type !== 'File',
            then: schema => schema.required('This field is required').max(...getCharacterLimitArgs(255, 'text')),
            otherwise: schema => schema,
          }),
          value: yup.mixed().when('type', {
            is: (type: string) => type !== 'File',
            then: schema => schema.required('This field is required'),
            otherwise: schema => schema,
          }),
          currency: yup.mixed().when(['type', 'value'], {
            is: (type: string, value: SelectOptions) => type !== 'File' && value?.value !== 'percentage',
            then: schema => schema.required('This field is required'),
            otherwise: schema => schema,
          }),
        }),
      })
    ),
    mode: 'all',
  })
  const { setIsDirty } = useRoutingHandlerState()

  useEffect(() => {
    setIsDirty(!!Object.keys(formState.dirtyFields).length)

    return () => {
      setIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields, Object.keys(formState.dirtyFields).length, formState.isDirty, setIsDirty])

  const [controlled] = watch(['controlled'])

  const isGroupTransaction = watch('isGroupTransaction')

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()
  const transactionOptions: SelectOptions[] = useMemo(() => {
    return isGroupTransaction?.value
      ? getTransactionOptions(transactionResponse)
      : getIndividualTransactionOptions(transactionResponse)
  }, [transactionResponse, isGroupTransaction?.value])

  const { data: selectedComparabilityFactors, isFetching: fetchingselectedComparabilityFactors } = useQuery(
    [QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.key, transactionResponse?.economic_analysis?.id],
    {
      enabled: !!transactionResponse?.economic_analysis?.id,
      queryFn: () =>
        QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.function(transactionResponse?.economic_analysis?.id || 0),
    }
  )
  const orderedselectedComparabilityFactors = selectedComparabilityFactors
    ? selectedComparabilityFactors.sort((a, b) => a.order - b.order)
    : []

  const filteredTransactionOptions = useMemo(() => {
    const preselectedTransactions: number[] = []
    termsAndConditions?.forEach(term => {
      term.is_controlled_transaction &&
        selectedRow?.individual_transaction?.id !== term.individual_transaction?.id &&
        preselectedTransactions.push(term.individual_transaction.id)
    })
    return transactionOptions.filter(opt => {
      return !preselectedTransactions.includes(Number(opt.value))
    })
  }, [selectedRow?.individual_transaction, termsAndConditions, transactionOptions])
  const setFile = useCallback(
    (type: 'controlled') => (file: File) => {
      setValue(`${type}.document`, file)
    },
    [setValue]
  )
  const toggleReorderModal = useCallback(() => {
    setReorderModal(open => {
      return !open
    })
  }, [])

  const onClickEdit = useCallback((row: ControlledTransactionDataTableData) => {
    setSelectedRow(row)
    setEditModalOpen(true)
  }, [])

  useEffect(() => {
    reset({
      controlled: {
        document: economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file || undefined,
        type: economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file ? 'File' : 'Table',
        value: economicAnalysisTransactionResponse?.comparison_metric
          ? {
              label: economicAnalysisTransactionResponse?.comparison_metric,
              value: economicAnalysisTransactionResponse?.comparison_metric,
            }
          : undefined,
        currency: economicAnalysisTransactionResponse?.comparison_currency
          ? {
              label: economicAnalysisTransactionResponse?.comparison_currency,
              value: economicAnalysisTransactionResponse?.comparison_currency,
            }
          : undefined,
        termsAndConditions: economicAnalysisTransactionResponse?.comparison_metric_name
          ? economicAnalysisTransactionResponse?.comparison_metric_name
          : undefined,
      },
      policy_rate: economicAnalysisTransactionResponse?.policy_rate || undefined,
      isGroupTransaction:
        (tableData.length > 0 && !tableData[0].include_all_individual_transactions) ||
        (transactionResponse && transactionResponse?.individual_transactions.length === 1)
          ? TransactionTypeOptions[1]
          : TransactionTypeOptions[0],
    })
  }, [
    economicAnalysisTransactionResponse?.comparison_currency,
    economicAnalysisTransactionResponse?.comparison_metric,
    economicAnalysisTransactionResponse?.comparison_metric_name,
    economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file,
    economicAnalysisTransactionResponse?.policy_rate,
    reset,
    tableData,
    transactionResponse,
  ])
  const transactionTypeChange =
    tableData.length > 0 && tableData[0].include_all_individual_transactions === (isGroupTransaction?.value === 1)
  const toggleEditModal = useCallback(() => {
    setEditModalOpen(open => {
      open && setSelectedRow(undefined)
      return !open
    })
  }, [])

  const { refetchChecklist } = useReportWizardContext()

  const deleteTransactionMutation = useMutation(deleteControlledOrUncontrolledTransactions)

  const deleteFileHandler = useCallback(
    async (row: ControlledTransactionDataTableData) => {
      const getDeleteConfirmation = getConfirmation(<>Are you sure you want to remove this transaction ?</>)

      const canDeleteFile = await getDeleteConfirmation
      if (canDeleteFile) {
        deleteTransactionMutation.mutate(row.id, {
          onSuccess: () => {
            toast.success(`controlled transaction deleted successfully`, { autoClose: 1000 })
            refetchTermsAndConditions()
            refetchChecklist()
          },
          onError: error => {
            getToastErrorMessage(error as AxiosError)
          },
        })
      }
    },
    [getConfirmation, deleteTransactionMutation, refetchTermsAndConditions, refetchChecklist]
  )

  const controlledComparabilityTableColumns = useMemo(() => {
    return getComparabilityTableColumns({
      onClickEdit,
      deleteFileHandler,
    })
  }, [onClickEdit, deleteFileHandler])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const updateControlledTransactionTermsMutation = useMutation(updateControlledTransactionTerms, {
    onSuccess() {
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
  })

  const onSave: SubmitHandler<FIELD_VALUES> = useCallback(
    data => {
      if (data.controlled.type == 'File') {
        const file = data.controlled.document
        updateControlledTransactionTermsMutation.mutate(
          {
            id: transactionResponse?.economic_analysis?.id || 0,
            controlled_transaction_terms_file: file instanceof File ? file : file === null ? null : undefined,
            controlled_transaction_terms_file_name: file instanceof File ? file.name : file === null ? null : undefined,
            policy_rate: data.policy_rate,
          },
          {
            onSuccess() {
              setIsDirty(false)
              reset({ ...getValues() })
              toast.success('Economic analysis updated!!')
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      } else {
        updateControlledTransactionTermsMutation.mutate(
          {
            id: transactionResponse?.economic_analysis?.id || 0,
            comparison_currency: String(data.controlled.currency?.value || '') || undefined,
            comparison_metric: String(data.controlled.value?.value || '') || undefined,
            policy_rate: data.policy_rate,
            comparison_metric_name: data.controlled.termsAndConditions || '',
          },
          {
            onSuccess() {
              toast.success('Economic analysis updated!!')
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    },
    [getValues, reset, setIsDirty, transactionResponse?.economic_analysis?.id, updateControlledTransactionTermsMutation]
  )

  const onSaveClick = handleSubmit(
    // () => {
    //   return
    // }
    // comparableResults?.id ? onUpdateComparableResults : onCreateComparableResults
    onSave,
    errors => console.log({ errors })
  )

  const handleDelete = useCallback(async () => {
    const confirmation = await getConfirmation(`Are you sure you want to delete the file`)
    confirmation && setValue('controlled.document', null, { shouldDirty: true })
  }, [getConfirmation, setValue])

  const loading =
    updateControlledTransactionTermsMutation.isLoading ||
    fetchingselectedComparabilityFactors ||
    fetchingTermsAndConditions

  const disableSave = updateControlledTransactionTermsMutation.isLoading || !formState.isDirty

  return (
    <div className="w-full relative flex flex-col gap-6">
      <div
        className="flex  flex-col h-full w-full gap-6 overflow-y-auto"
        style={{ maxHeight: 'calc(100vh - 22.5rem)' }}>
        {loading && <Loading className="absolute inset-0 flex items-center justify-center z-20 bg-white" />}
        <InputField
          icon={IconsType.percentage}
          iconPlacement={IconPlacement.Right}
          placeholder="25"
          control={control}
          id={'policy_rate'}
          label={`Policy Rate for the transaction`}
          className="w-1/2"
          required
        />
        <BorderlessBoxAccordion
          className="gap-4 relative"
          defaultOpen
          topRightComponents={<FileOrTableRadio control={control} id="controlled.type"></FileOrTableRadio>}
          label="Terms & Characteristics of the Controlled Transaction">
          {loading && (
            <BorderlessBox className="absolute inset-4 z-10" variant="white">
              <Loading className="flex items-center justify-center bg-white w-full h-full" />
            </BorderlessBox>
          )}
          {controlled.type == 'File' && (
            <BorderlessBox label="Upload File" variant="white" className="w-full gap-3 items-stretch">
              <UploadDocuments
                accept=".pdf"
                setFile={setFile('controlled')}
                file={controlled?.document instanceof File ? controlled?.document : null}
                handleDelete={handleDelete}
                showDelete
                fileUrl={
                  economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file &&
                  economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file ===
                    controlled?.document
                    ? controlled?.document
                    : undefined
                }
                fileName={
                  economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file &&
                  economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file ===
                    controlled?.document
                    ? economicAnalysisTransactionResponse?.controlled_transaction_terms_appendix?.file_name ||
                      'Document'
                    : undefined
                }
              />
            </BorderlessBox>
          )}
          {controlled.type == 'Table' && (
            <>
              <div className="flex items-start justify-between gap-4 w-full">
                {transactionResponse && transactionResponse?.individual_transactions.length > 1 && (
                  <SelectDropDown
                    options={TransactionTypeOptions}
                    control={control}
                    label="Apply Comparability Factors to all Transactions"
                    required
                    id="isGroupTransaction"
                  />
                )}
                <InputField
                  id={'controlled.termsAndConditions'}
                  control={control}
                  newInput={false}
                  className="w-full"
                  label="Transaction Metric Name"
                  placeholder="Enter Name"
                  characterLimit={255}
                />
              </div>
              <div className="flex items-start justify-between gap-4 w-full">
                <SelectDropDown
                  options={ValueOptions}
                  control={control}
                  label="Value"
                  required
                  id={'controlled.value'}
                />
                {controlled.value?.value == ValueOptions[0].value ||
                controlled.value?.value == ValueOptions[2].value ? (
                  <SelectDropDown
                    options={currencyOptions}
                    control={control}
                    label="Currency"
                    required
                    id={'controlled.currency'}
                  />
                ) : (
                  <div className="w-full" />
                )}
              </div>
              <BorderlessBox
                variant="white"
                label="List of Controlled Transactions"
                className="w-full gap-2 items-stretch"
                topRightComponents={
                  tableData?.length > 1 && (
                    <Button onClick={toggleReorderModal} icon={IconsType.sixDotDrag} variant={ButtonVariant.Secondary}>
                      Reorder
                    </Button>
                  )
                }>
                <>
                  <NewAttributeTable
                    data={transactionTypeChange ? tableData : []}
                    columns={controlledComparabilityTableColumns}
                  />
                  {(tableData.length === 1 &&
                    tableData[0].include_all_individual_transactions &&
                    isGroupTransaction?.value) ||
                  filteredTransactionOptions.length === 0 ? (
                    <></>
                  ) : (
                    <div className="w-full flex items-center justify-between">
                      <div className="text-xs text-gray-600 flex items-center">
                        <Info className="text-[#fb9800] w-4 h-4 mr-1" /> You can add more rows to this chart
                      </div>
                      {filteredTransactionOptions.length > 0 && (
                        <Button
                          onClick={toggleEditModal}
                          variant={ButtonVariant.Tertiary}
                          icon={IconsType.plus}
                          className="!gap-1">
                          Add
                        </Button>
                      )}
                    </div>
                  )}
                </>
              </BorderlessBox>
            </>
          )}
        </BorderlessBoxAccordion>

        <AddComparableControlledTransaction
          key={`${editModalOpen ? 0 : 1}-${selectedRow?.id || 0}`}
          transactionResponse={transactionResponse}
          isGroupTransaction={isGroupTransaction}
          selectedComparabilityFactors={orderedselectedComparabilityFactors}
          selectedTerm={selectedRow}
          isOpen={editModalOpen}
          transactionOptions={filteredTransactionOptions}
          onClose={toggleEditModal}
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse}
        />
        {economicAnalysisTransactionResponse && (
          <ReorderControlledTransactions
            transactionsList={tableData}
            isOpen={reorderModal}
            onClose={toggleReorderModal}
            refetchTransactions={refetchTermsAndConditions}
            economicAnalysisId={economicAnalysisTransactionResponse?.id}
          />
        )}
        <ConfirmationModal />
      </div>
      <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
        <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
          <Button
            isDefaultSize={false}
            onClick={handleCancel}
            iconCLass="w-5 h-5 transform rotate-180"
            iconPathClassName="stroke-blue900"
            className="!gap-1"
            icon={IconsType.arrowRight}
            variant={ButtonVariant.Secondary}>
            Back
          </Button>
          <Button
            isDefaultSize={false}
            onClick={onSaveClick}
            icon={IconsType.save}
            disabled={disableSave}
            variant={ButtonVariant.Primary}>
            Save
          </Button>
        </div>
      </Portal>
    </div>
  )
}

type FIELD_VALUES = {
  controlled: {
    type: 'File' | 'Table'
    value?: SelectOptions
    currency?: SelectOptions
    document?: File | string | null
    termsAndConditions?: string | null
  }
  policy_rate?: string
  isGroupTransaction?: SelectOptions
}

export type ComparableResultsFieldValues = FIELD_VALUES
export type ControlledTransactionDataTableData = TermsAndCharacteristics<true> & {
  transactionData?: TransactionByIdResponse
}

export default ControlledTransactionsData
