import { IconProps } from 'assets'
import React from 'react'

const AddNotes = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none">
      <path
        className={pathClassName}
        d="M5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V5.5C3 4.95 3.19583 4.47917 3.5875 4.0875C3.97917 3.69583 4.45 3.5 5 3.5H19C19.55 3.5 20.0208 3.69583 20.4125 4.0875C20.8042 4.47917 21 4.95 21 5.5V12.2C20.6833 12.05 20.3583 11.9208 20.025 11.8125C19.6917 11.7042 19.35 11.625 19 11.575V5.5H5V19.5H11.05C11.1 19.8667 11.1792 20.2167 11.2875 20.55C11.3958 20.8833 11.525 21.2 11.675 21.5H5ZM5 18.5V19.5V5.5V11.575V11.5V18.5ZM7 17.5H11.075C11.125 17.15 11.2042 16.8083 11.3125 16.475C11.4208 16.1417 11.5417 15.8167 11.675 15.5H7V17.5ZM7 13.5H13.1C13.6333 13 14.2292 12.5833 14.8875 12.25C15.5458 11.9167 16.25 11.6917 17 11.575V11.5H7V13.5ZM7 9.5H17V7.5H7V9.5ZM18 23.5C16.6167 23.5 15.4375 23.0125 14.4625 22.0375C13.4875 21.0625 13 19.8833 13 18.5C13 17.1167 13.4875 15.9375 14.4625 14.9625C15.4375 13.9875 16.6167 13.5 18 13.5C19.3833 13.5 20.5625 13.9875 21.5375 14.9625C22.5125 15.9375 23 17.1167 23 18.5C23 19.8833 22.5125 21.0625 21.5375 22.0375C20.5625 23.0125 19.3833 23.5 18 23.5ZM17.5 21.5H18.5V19H21V18H18.5V15.5H17.5V18H15V19H17.5V21.5Z"
        fill="black"
      />
    </svg>
  )
}

export default AddNotes
