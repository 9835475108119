import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import { renderToString } from 'react-dom/server'
import { Editor as RawEditor } from 'tinymce'

export const setupFootnotesButton = (editor: RawEditor, callback?: VoidFunction) => {
  editor.ui.registry.addIcon('footNoteIcon', renderToString(getIcons(IconsType.footnote, { className: 'w-5 h-5' })))
  editor.ui.registry.addIcon(
    'footNoteIconDisabled',
    renderToString(getIcons(IconsType.footnote, { className: 'w-5 h-5' }))
  )

  editor.ui.registry.addButton('footnoteButton', {
    icon: 'footNoteIconDisabled',
    tooltip:
      'Select text and click the button to add an automatically numbered footnote. To remove a footnote, place the cursor inside the footnote and click on the button. Footnotes will appear at the bottom of the page in the final PDF',
    enabled: false,
    onSetup: buttonApi => {
      const editorEventCallback = () => {
        const selectedRange: Range | null = editor.selection.getRng()
        const selectedText: string = selectedRange ? selectedRange.toString().trim() : ''

        const cursorStartContainer = selectedRange.startContainer
        const cursorEndContainer = selectedRange.endContainer

        let isFootnoteRelated = false

        // Check if any ancestor or descendant of the selected range contains a footnote
        if (selectedRange) {
          const selectedNodes = selectedRange.cloneContents().querySelectorAll('.footnote')
          const ancestorWithFootnote = selectedRange.commonAncestorContainer.parentElement?.closest('.footnote')

          if (selectedNodes.length > 0 || ancestorWithFootnote) {
            isFootnoteRelated = true
          }
        }
        let isFootnote = false
        // Check if cursor is on a footnote
        if (selectedRange) {
          const isCursorOnFootnote =
            cursorStartContainer == cursorEndContainer &&
            ((cursorEndContainer as HTMLElement)?.parentElement?.classList.contains('footnote') ||
              (cursorStartContainer as Element)?.classList?.contains('footnote'))
          if (isCursorOnFootnote) {
            isFootnote = true // Overwrite the previous flag if cursor is on a footnote
          }
        }

        // Enable or disable the button based on the presence of selected text and footnote relations
        const buttonEnabled =
          (cursorStartContainer == cursorEndContainer && !isFootnoteRelated && !!selectedText.length) ||
          isFootnote ||
          (!selectedText && selectedRange && selectedRange.collapsed)
        buttonApi.setEnabled(buttonEnabled)
        buttonApi.setIcon(buttonEnabled ? 'footNoteIcon' : 'footNoteIconDisabled')
      }

      editor.on('NodeChange', editorEventCallback)

      // Call the event callback to set initial button state
      editorEventCallback()

      return () => {
        editor.off('NodeChange', editorEventCallback)
      }
    },

    onAction: (): void => {
      editor.undoManager.transact(() => {
        const selectedRange: Range | null = editor.selection.getRng()
        const selectedText: string = selectedRange ? selectedRange.toString().trim() : ''

        const cursorStartContainer = selectedRange.startContainer
        const cursorEndContainer = selectedRange.endContainer
        const isCursorOnFootnote =
          cursorStartContainer == cursorEndContainer &&
          (cursorStartContainer.parentElement?.classList.contains('footnote') ||
            (cursorStartContainer as Element)?.classList?.contains('footnote') ||
            cursorStartContainer.parentElement?.closest('.footnote') !== null)
        if (isCursorOnFootnote) {
          let footnoteNode = cursorStartContainer.parentElement?.closest('.footnote')
          if ((cursorStartContainer as Element)?.classList?.contains('footnote') && !footnoteNode) {
            footnoteNode = cursorStartContainer as Element
          }
          if (footnoteNode) {
            const parent = footnoteNode.parentElement
            if (parent) {
              const cursorPosition = editor?.selection?.getSel()?.getRangeAt(0)?.startOffset || 0
              const clonedContent = footnoteNode.innerHTML
              parent.insertBefore(document.createRange().createContextualFragment(clonedContent.trim()), footnoteNode)
              parent.removeChild(footnoteNode)
              const newRange = editor.dom.createRng()
              newRange.setStart(parent.childNodes[cursorPosition], 0)
              newRange.collapse(true)
              editor.selection.setRng(newRange)
            }
          }
        } else if (!selectedText && selectedRange && selectedRange.collapsed) {
          editor.dom.select('.footnote').forEach(footnote => {
            if (!(footnote?.textContent?.trim && footnote.textContent.trim())) {
              footnote.remove()
            }
          })
          const oldNewFootnotes = editor.dom.select('.latestFootnote')
          oldNewFootnotes.forEach(element => element.classList.remove('latestFootnote'))
          const newFootnote = document.createElement('span')
          newFootnote.classList.add('footnote', 'latestFootnote')
          newFootnote.innerHTML = '&#xFEFF;'

          editor.selection.setContent(newFootnote.outerHTML)
          const newRange = editor.dom.createRng()
          const latestFootnote = editor.dom.select('.latestFootnote')
          newRange.setStart(latestFootnote[0], 1)
          newRange.collapse(true)
          editor.selection.setRng(newRange)
        } else if (selectedText !== '') {
          const wrappedText = `<span class="footnote">${selectedText}</span>`
          editor.selection.setContent(wrappedText)
          editor.selection.collapse()
        }
      })
    },
  })

  callback && callback()
}
