import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import { useEffect, useRef, useState } from 'react'

import { TabData } from './Tabs'

function DefaultTabDark({
  className,
  tabsData,
  containerClassName,
  initialActiveTabIndex = 0,
  handleActiveIndex,
  forcedActiveIndex,
  onTabChange,
  tabSpacingClassname,
}: DefaultTabDarkProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex)
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)

  const tabsRef = useRef<HTMLButtonElement[]>([])

  const { isDirty, getExitConfirmation, setIsDirty } = useRoutingHandlerState()

  const handleTabChange = async (idx: number, label: string) => {
    if (!isDirty) {
      setActiveTabIndex(idx)
      if (onTabChange) {
        onTabChange(idx, label)
      }
    } else if (await getExitConfirmation()) {
      setIsDirty(false)
      setActiveTabIndex(idx)
      if (onTabChange) {
        onTabChange(idx, label)
      }
    }
  }

  useEffect(() => {
    if (handleActiveIndex && forcedActiveIndex !== undefined) {
      setActiveTabIndex(forcedActiveIndex)
    }
  }, [forcedActiveIndex, handleActiveIndex])

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex]
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0)
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
    }
    setTabPosition()
    window.addEventListener('resize', setTabPosition)
    return () => window.removeEventListener('resize', setTabPosition)
  }, [activeTabIndex])

  const iconsWithStroke = [
    IconsType.eye,
    IconsType.basicInfo,
    IconsType.functionalAnalysis,
    IconsType.economicAnalysis,
    IconsType.leftParagraph,
    IconsType.userBusinessCeo,
    IconsType.locationPointer,
    IconsType.lightBulb,
    IconsType.listBullet,
    IconsType.gear,
    IconsType.alertTriangle,
    IconsType.handShake,
    IconsType.newsPaperFolding,
  ]

  return (
    <div className={cx('w-full', containerClassName)}>
      <div className="relative">
        <div className={cx('flex w-full', tabSpacingClassname)}>
          {tabsData.map((tab, idx) => {
            if (tab.hide) return null
            return (
              <button
                disabled={tab.disabled}
                key={idx}
                ref={el => {
                  if (el) {
                    tabsRef.current[idx] = el
                  }
                }}
                className={cx('pb-4 bg-transparent border-0 text-sm font-semibold  focus:outline-none', {
                  'text-indigo-400': activeTabIndex === idx,
                  'text-gray-500': activeTabIndex !== idx,
                  'opacity-50 cursor-not-allowed': tab.disabled,
                  'cursor-pointer': !tab.disabled,
                })}
                onClick={() => handleTabChange(idx, tab.label)}>
                <Typography variant={Variant.Callout} type="semibold" className="flex items-center gap-[0.8rem]">
                  {tab.icon &&
                    getIcons(tab.icon, {
                      className: 'w-[18px] h-[18px]',
                      pathClassName: cx({
                        'fill-blue800 ': activeTabIndex === idx && !iconsWithStroke.includes(tab.icon),
                        'fill-neutral600 ': activeTabIndex !== idx && !iconsWithStroke.includes(tab.icon),
                        'stroke-neutral600': tab.icon && iconsWithStroke.includes(tab.icon) && activeTabIndex !== idx,
                        'stroke-blue800': tab.icon && iconsWithStroke.includes(tab.icon) && activeTabIndex === idx,
                      }),
                    })}
                  {tab.label}
                </Typography>
              </button>
            )
          })}
        </div>
        <div className="w-full border border-solid border-[#1A193B]" />
        <span
          className="absolute bottom-0 block h-[2.5px] bg-indigo-400 transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className={cx('mt-4', className)}>{tabsData[activeTabIndex]?.content}</div>
    </div>
  )
}

interface DefaultTabDarkProps {
  className?: string
  containerClassName?: string
  tabsData: TabData[]
  initialActiveTabIndex?: number
  handleActiveIndex?: boolean
  forcedActiveIndex?: number
  onTabChange?: (idx: number, label: string) => void
  rightTabHeaderComponent?: React.ReactNode
  tabSpacingClassname?: string
}

export default DefaultTabDark
