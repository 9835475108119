import { IconProps } from 'assets'
import React from 'react'

const InstantMix = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none">
      <path
        d="M5 20.5V13.5H3V11.5H9V13.5H7V20.5H5ZM5 9.5V4.5H7V9.5H5ZM9 9.5V7.5H11V4.5H13V7.5H15V9.5H9ZM11 20.5V11.5H13V20.5H11ZM17 20.5V17.5H15V15.5H21V17.5H19V20.5H17ZM17 13.5V4.5H19V13.5H17Z"
        fill="black"
        className={pathClassName}
      />
    </svg>
  )
}

export default InstantMix
