import { postAiQuery } from 'api/tinyMceAssistant'
import { Editor } from 'tinymce'

export const ai_requests = (editor?: Editor) => async (request: AI_REQUEST, respondWith: AI_RESPONDWITH) => {
  if (!editor) {
    throw new Error('Editor is required for AI requests')
  }

  try {
    await respondWith.stream(async (signal, streamMessage) => {
      try {
        // Make API request
        const response = await postAiQuery(request, signal)
        const stream = response.body

        if (!stream) {
          throw new Error('Invalid response: No stream available')
        }

        // Handle streaming response
        const reader = stream.getReader()
        try {
          while (true) {
            if (signal.aborted) {
              reader.cancel()
              return
            }
            const { done, value } = await reader.read()
            if (done) {
              return
            }
            if (signal.aborted) {
              reader.cancel()
              return
            }
            const chunk = new TextDecoder('utf-8').decode(value)
            streamMessage(chunk)
          }
        } catch (error) {
          if (!(error instanceof Error && error.name === 'AbortError') && !signal.aborted) {
            throw error
          }
        } finally {
          reader.releaseLock()
        }
      } catch (error) {
        if (!signal.aborted) {
          throw error
        }
      }
    })
  } catch (error) {
    console.error('Error in AI request:', error)
    throw error
  }
}

export type AI_REQUEST = {
  query: string
  complete_html: string
  user_selection: string
  thread?: ThreadEvent[]
}

interface ThreadEvent {
  eventUid: string
  timestamp: string
  request: {
    query: string
    user_selection: string
  }
  response: {
    type: string
    data: string
  }
}

export type AI_RESPONDWITH = {
  string: (callback: (signal: AbortSignal) => Promise<string>) => void
  stream: (callback: (signal: AbortSignal, streamMessage: (message: string) => void) => Promise<void>) => Promise<void>
}
