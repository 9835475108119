import { Tooltip } from '@mui/material'
import { flexRender, Row, Table } from '@tanstack/react-table'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'

function TableRow<T>({
  row,
  borderedRows,
  reorderRow,
  tableInstance,
  tableRowClassName,
  tableCellClassname,
}: TableRowProps<T>) {
  const reorderRowFn = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) =>
      reorderRow ? reorderRow(draggedRowIndex, targetRowIndex) : () => null,
    [reorderRow]
  )

  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<T>) => reorderRowFn(draggedRow.index, row.index),
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  })

  const rowClassName = tableInstance?.options.meta?.getRowClassname
    ? tableInstance.options.meta?.getRowClassname(row)
    : ''

  return (
    <tr
      ref={
        reorderRow
          ? node => {
              previewRef(node)
              dragRef(node)
              dropRef(node)
            }
          : null
      }
      style={{ opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 999 : 'auto' }}
      className={cx(
        `${borderedRows ? 'border border-solid border-neutral300' : ''}  bg-white hover:bg-gray-50`,
        tableRowClassName
      )}
      key={row.id}>
      {reorderRow ? (
        <td style={{ width: '0px' }} className={cx('newTableTd px-4 py-1.5 ', rowClassName, tableCellClassname)}>
          <div className="flex items-center">
            <Tooltip title={isDragging ? '' : 'Drag to reorder'} placement="top">
              <button className="w-4 bg-transparent border-0 cursor-move opacity-70">
                {getIcons(IconsType.sixDotDrag, { pathClassName: 'stroke-transparent fill-gray-400' })}
              </button>
            </Tooltip>
          </div>
        </td>
      ) : (
        <></>
      )}
      {row.getVisibleCells().map(cell => {
        const size = cell.column.columnDef.size
        return (
          <td
            style={{
              width: `${size}px`,
            }}
            className={cx('newTableTd px-4 py-2.5', rowClassName, tableCellClassname)}
            key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

interface TableRowProps<T> {
  row: Row<T>
  borderedRows: boolean
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
  tableRowClassName?: string
  tableCellClassname?: string
  tableInstance: Table<T>
}

export default TableRow
