import { useMutation, useQueryClient } from '@tanstack/react-query'
import { editUser } from 'api/userAccess'
import { AxiosError } from 'axios'
import StartTourModal from 'components/tourComponents/StartTourModal/StartTourModal'
import TourCompleteModal from 'components/tourComponents/TourCompleteModal/TourCompleteModal'
import TourHighLightTooltip from 'components/tourComponents/TourHighLight/TourHighLightTooltip'
import TourLoadingState from 'components/tourComponents/TourLoadingState/TourLoadingState'
import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getToastErrorMessage } from 'utils/utils'

import { getTourSteps } from './helpers'
import { FTUTourContextType, StepIds, StepTypes } from './types'

export const FTUTourContext = React.createContext<FTUTourContextType | undefined>(undefined)

export const FTUTourContextProvider = ({ children }: { children: ReactNode }) => {
  const { user, refetchUser } = useAuth()
  const router = useRouter()

  const [step, setStep] = React.useState<ReturnType<typeof getTourSteps>[0] | undefined>(undefined)
  const [stepLoading, setStepLoading] = useState(true)
  const [isTourActive, setIsTourActive] = React.useState(false)
  const [tourComplete, setTourComplete] = useState(false)

  const steps = useMemo(() => getTourSteps(user?.product_tour_details), [user?.product_tour_details])
  const [overlayDOMRect, setOverlayDOMRect] = useState<DOMRect | null>(null)

  const queryClient = useQueryClient()

  const handleTourCompletionMutation = useMutation(editUser, {
    onSuccess: async () => {
      refetchUser()
      setIsTourActive(false)
      setStep(undefined)
      setStepLoading(false)
      sessionStorage.removeItem('isTrainingUser')
      queryClient.clear()
      router.push(ROUTES.HOME, undefined, {
        shallow: false,
      })
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  useEffect(() => {
    if (tourComplete && user?.id && user.is_training_completed === false) {
      handleTourCompletionMutation.mutate([
        {
          is_training_completed: true,
        },
        user.id,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourComplete, user?.id])

  useEffect(() => {
    let int: NodeJS.Timer
    if (step?.type == StepTypes.Tooltip && step.highlightBox && !stepLoading) {
      const areDOMRectsEqual = (rect1: DOMRect | null, rect2: DOMRect | null): boolean => {
        if (rect1 === rect2) return true
        if (!rect1 || !rect2) return false

        return (
          rect1.top === rect2.top &&
          rect1.left === rect2.left &&
          rect1.width === rect2.width &&
          rect1.height === rect2.height
        )
      }
      const updateOverlay = () => {
        if (step.highlightBox) {
          const overlayDiv = document.getElementById(step.highlightBox)
          if (overlayDiv) {
            overlayDiv.scrollIntoView({
              block: 'start',
            })
            const newRect = overlayDiv.getBoundingClientRect()
            setOverlayDOMRect(oldRect => {
              if (areDOMRectsEqual(oldRect, newRect)) {
                return oldRect
              }
              return newRect
            })
          } else {
            setOverlayDOMRect(null)
          }
        } else {
          setOverlayDOMRect(null)
        }
      }
      updateOverlay()
      int = setInterval(() => {
        updateOverlay()
      }, 1000)
    } else {
      setOverlayDOMRect(null)
    }
    return () => {
      setOverlayDOMRect(null)
      int && clearInterval(int)
    }
  }, [step, stepLoading])

  useEffect(() => {
    if (user && user.is_training_completed === false) {
      const startingStep = steps.find(step => step.id === StepIds.Start)
      if (startingStep) {
        setStep(startingStep)
        setIsTourActive(true)
        setStepLoading(true)
        setTourComplete(false)
        sessionStorage.setItem('isTrainingUser', 'true')
      }
    } else if (user && user.is_training_completed !== false) {
      setStep(undefined)
      setTourComplete(true)
      setIsTourActive(false)
      setStepLoading(false)
      sessionStorage.removeItem('isTrainingUser')
    }
  }, [steps, user])

  const handleNextStepChange = useCallback(() => {
    const currentStepIndex = steps.findIndex(({ id }) => id === step?.id)

    if (currentStepIndex !== -1) {
      if (steps[currentStepIndex].id == StepIds.Done) {
        setTourComplete(true)
        setStep(undefined)
        return
      }
      const nextStep = steps.find(({ type }, index) => {
        return index > currentStepIndex && type == StepTypes.Tooltip
      })
      if (nextStep) {
        setStep(nextStep)
      } else {
        const doneStep = steps.find(({ id }) => {
          return id == StepIds.Done
        })
        doneStep && setStep(doneStep)
      }
    }
  }, [step?.id, steps])

  const handlePrevStepChange = useCallback(() => {
    const currentStepReverseIndex = [...steps].reverse().findIndex(({ id }) => id === step?.id)
    if (currentStepReverseIndex !== -1) {
      const prevStep = [...steps].reverse().find(({ type }, index) => {
        return index > currentStepReverseIndex && type == StepTypes.Tooltip
      })
      if (prevStep) {
        setStep(prevStep)
      }
    }
  }, [step?.id, steps])

  const totalSteps = useMemo(() => {
    return steps.filter(val => val.type == StepTypes.Tooltip).length
  }, [steps])

  const currentStepNumber = useMemo(() => {
    return steps.filter(val => val.type == StepTypes.Tooltip).findIndex(({ id }) => id === step?.id) + 1
  }, [step?.id, steps])

  const contextValue: FTUTourContextType = useMemo(() => {
    return {
      step,
      isTourActive,
      stepLoading,
      setStepLoading,
      handlePrevStepChange,
      handleNextStepChange,
      totalSteps,
      currentStepNumber,
    }
  }, [currentStepNumber, handleNextStepChange, handlePrevStepChange, isTourActive, step, stepLoading, totalSteps])

  useEffect(() => {
    if (step && (step.pathName || step.queries)) {
      let isDifferent = false
      if (step.pathName && step.pathName !== router.pathname) {
        isDifferent = true
      }
      if (step.queries) {
        Object.entries(step.queries).some(([key, value]) => {
          if (!router.query?.[key] || router.query?.[key] !== value) {
            isDifferent = true
            return true
          }
        })
      }
      if (isDifferent) {
        router.push({
          pathname: step.pathName,
          query: step.pathName !== router.pathname ? step?.queries : { ...router.query, ...step.queries },
        })
      }
    }
  }, [router, step])

  const showLoading =
    isTourActive &&
    (stepLoading || (step?.type == StepTypes.Tooltip && !overlayDOMRect) || handleTourCompletionMutation.isLoading)

  return (
    <FTUTourContext.Provider value={contextValue}>
      {children}
      <StartTourModal />
      <TourCompleteModal />
      {showLoading && <TourLoadingState show={showLoading} />}
      {overlayDOMRect && <TourHighLightTooltip overlayDOMRect={overlayDOMRect} />}
    </FTUTourContext.Provider>
  )
}

const useFTUTour = () => {
  const context = useContext(FTUTourContext)

  if (!context) {
    throw new Error('FTUTourContext used without provider')
  }

  return context
}

export default useFTUTour
