import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Typography, { Variant } from 'components/typography'
import { useCallback, useEffect, useRef } from 'react'

const ItemRemovalWarningWindow = ({
  isLoading = false,
  showWindow,
  title,
  setShowWindow,
  confirmationMessage,
  doConfirmationAction,
  confirmationCtaText = 'Yes, I am sure',
}: ItemRemovalWarningWindowProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const handleModalClose = useCallback(() => {
    setShowWindow(false)
  }, [setShowWindow])
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleModalClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleModalClose])

  const handleDeleteItem = () => {
    doConfirmationAction()
  }

  return showWindow ? (
    <>
      <div className="absolute inset-0 backdrop-blur-[2px] z-20">
        <div
          ref={modalRef}
          className="flex absolute bottom-5 left-5 right-5 flex-col justify-between  rounded-lg bg-white dashboard-modal-shadow p-4 border border-solid border-gray-200">
          <Typography variant={Variant.Heading2} type="semibold" className="text-gray-700 mr-auto mb-2">
            {title}
          </Typography>
          {isLoading ? (
            <div className="flex justify-center items-center h-[5rem]">
              <Loading className="w-full h-full bg-white" />
            </div>
          ) : (
            <>
              <Typography variant={Variant.Body}>{confirmationMessage}</Typography>
              <div className="flex w-full justify-between gap-3 mt-10">
                <Button onClick={handleModalClose} variant={ButtonVariant.Secondary}>
                  Cancel
                </Button>
                <Button onClick={handleDeleteItem}>{confirmationCtaText}</Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : null
}

interface ItemRemovalWarningWindowProps {
  isLoading?: boolean
  showWindow: boolean
  confirmationMessage: string | React.ReactNode
  doConfirmationAction: () => void
  confirmationCtaText?: string
  setShowWindow: (x: boolean) => void
  title?: string
}

export default ItemRemovalWarningWindow
