import { IconProps } from 'assets'

const Info = ({ className, pathClassName = 'fill-[#1E3A8A]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / 18px / Info">
        <path
          className={pathClassName}
          id="Vector"
          d="M9 17C11.071 17 12.9461 16.1605 14.3033 14.8033C15.6605 13.4461 16.5 11.571 16.5 9.5C16.5 7.42895 15.6605 5.55395 14.3033 4.1967C12.9461 2.83947 11.071 2 9 2C6.92895 2 5.05395 2.83947 3.6967 4.1967C2.33947 5.55395 1.5 7.42895 1.5 9.5C1.5 11.571 2.33947 13.4461 3.6967 14.8033C5.05395 16.1605 6.92895 17 9 17Z"
          fill="white"
          stroke="#1E3A8A"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_2"
          d="M9 4.8125C9.41421 4.8125 9.75 5.14829 9.75 5.5625C9.75 5.97671 9.41421 6.3125 9 6.3125C8.58579 6.3125 8.25 5.97671 8.25 5.5625C8.25 5.14829 8.58579 4.8125 9 4.8125Z"
          fill="#1E3A8A"
          stroke="#1E3A8A"
          stroke-width="0.375"
        />
        <path
          className={pathClassName}
          id="Vector_3"
          d="M9.1875 13.25V8H8.8125H8.4375"
          stroke="#1E3A8A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_4"
          d="M7.875 13.25H10.5"
          stroke="#1E3A8A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default Info
