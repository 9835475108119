import { IconProps } from 'assets'

const BookSpark = ({ className, pathClassName = 'fill-black' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        className={pathClassName}
        d="M5 16.175C5.16667 16.125 5.32917 16.0833 5.4875 16.05C5.64583 16.0167 5.81667 16 6 16H7V4H6C5.71667 4 5.47917 4.09583 5.2875 4.2875C5.09583 4.47917 5 4.71667 5 5V16.175ZM6 22C5.16667 22 4.45833 21.7083 3.875 21.125C3.29167 20.5417 3 19.8333 3 19V5C3 4.16667 3.29167 3.45833 3.875 2.875C4.45833 2.29167 5.16667 2 6 2H13V4H9V16H15V13H17V18H6C5.71667 18 5.47917 18.0958 5.2875 18.2875C5.09583 18.4792 5 18.7167 5 19C5 19.2833 5.09583 19.5208 5.2875 19.7125C5.47917 19.9042 5.71667 20 6 20H19V12H21V22H6ZM17.5 12C17.5 10.4667 18.0333 9.16667 19.1 8.1C20.1667 7.03333 21.4667 6.5 23 6.5C21.4667 6.5 20.1667 5.96667 19.1 4.9C18.0333 3.83333 17.5 2.53333 17.5 1C17.5 2.53333 16.9667 3.83333 15.9 4.9C14.8333 5.96667 13.5333 6.5 12 6.5C13.5333 6.5 14.8333 7.03333 15.9 8.1C16.9667 9.16667 17.5 10.4667 17.5 12Z"
        fill="black"
      />
    </svg>
  )
}

export default BookSpark
